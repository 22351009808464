import React, { Component } from 'react'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import classNames from 'classnames'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { map, isEmpty } from 'lodash'
import queryString from 'query-string'
import { Layout } from '@/shared/components'
import IcTuneGrayBig from '@/shared/assets/ic-tune-gray-big.svg'
import IcLocationGrayBig from '@/shared/assets/ic-location-gray-big.svg'

const styles = ({
  container: {
    maxWidth: '946px',
    padding: '24px',
    margin: '0 auto',
  },
  title: {
    textAlign: 'center',
    marginBottom: '44px',
  },
  subTitle: {
    marginBottom: '24px',
  },
  primary: {
    color: '#00c896',
  },
  secondary: {
    color: '#4a90e2',
  },
  section: {
    marginBottom: '14px',
  },
  icon: {
    padding: '14px',
  },
  button: {
    width: '100%',
    color: '#ffffff',
    marginTop: '14px',
  },
})

class CampaignTypeFormPage extends Component {
  state = {
    packageType: {
      region: 'region',
      impression: 'impression',
    },
    impressionItems: [
      { label: '최소 집행 금액', value: '500만원 (VAT 별도)' },
      { label: '단가 방식', value: 'CPM (Cost Per Mile)' },
      { label: '기간', value: '최소 1일에서 최대 1달까지 설정 가능' },
      { label: '지역', value: '전국' },
    ],
    regionItems: [
      { label: '최소 집행 금액', value: '지역에 따라 상이 (15만원 ~ )' },
      { label: '단가 방식', value: 'CPP (Cost Per Period)' },
      { label: '기간', value: '2주 또는 4주' },
      { label: '지역', value: '각 동/읍/면' },
    ],
  }

  handleTypeClick = packageType => () => {
    const { history } = this.props
    const { getCampaignId } = this
    const params = {
      ad_package_type: packageType,
    }

    if (!isEmpty(getCampaignId())) {
      params.campaign_id = getCampaignId()
    }

    history.replace({
      pathname: '/campaign_form',
      search: queryString.stringify(params),
    })
  }

  getCampaignId = () => {
    const { location } = this.props
    return queryString.parse(location.search).campaign_id
  }

  getAdFormType = () => {
    const { location } = this.props
    return queryString.parse(location.search).form_type || 'create'
  }

  render() {
    const { classes } = this.props
    const { packageType, impressionItems, regionItems } = this.state
    const { handleTypeClick } = this

    return (
      <Layout pageTitle="">
        <div className={classes.container}>
          <Typography variant="h5" className={classes.title}>
            생성하실 캠페인 유형을 선택해주세요
          </Typography>
          <Grid container spacing={24}>
            <Grid item md={6}>
              <Card>
                <CardContent>
                  <img className={classes.icon} src={IcTuneGrayBig} alt="icon" />
                  <Typography variant="h5" className={classNames(classes.primary, classes.subTitle)}>
                    노출 보장형 광고
                  </Typography>
                  <Typography className={classes.section} color="textSecondary">
                    설정한 예산에 따라
                    <br />
                    노출 수를 보장해드리는 상품
                  </Typography>
                  <Divider style={{ margin: '14px 0 24px 0' }} />
                  {map(impressionItems, (item, index) => (
                    <Grid container spacing={16} key={index}>
                      <Grid item md={4}>
                        <Typography>
                          {item.label}
                        </Typography>
                      </Grid>
                      <Grid item md={8}>
                        <Typography color="textSecondary">
                          {item.value}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={handleTypeClick(packageType.impression)}
                  >
                    노출 보장형 광고 만들기
                  </Button>
                </CardContent>
              </Card>
            </Grid>

            <Grid item md={6}>
              <Card>
                <CardContent>
                  <img className={classes.icon} src={IcLocationGrayBig} alt="icon" />
                  <Typography variant="h5" className={classNames(classes.secondary, classes.subTitle)}>
                    우리 동네 지역 광고
                  </Typography>
                  <Typography className={classes.section} color="textSecondary">
                    지역을 설정하고
                    <br />
                    광고 노출기간에 따라 과금되는 상품
                  </Typography>
                  <Divider style={{ margin: '14px 0 24px 0' }} />
                  {map(regionItems, (item, index) => (
                    <Grid container spacing={16} key={index}>
                      <Grid item md={4}>
                        <Typography>
                          {item.label}
                        </Typography>
                      </Grid>
                      <Grid item md={8}>
                        <Typography color="textSecondary">
                          {item.value}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    onClick={handleTypeClick(packageType.region)}
                  >
                    우리 동네 지역 광고 만들기
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </Layout>
    )
  }
}

CampaignTypeFormPage.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.string,
    primary: PropTypes.string,
    subTitle: PropTypes.string,
    section: PropTypes.string,
    button: PropTypes.string,
    secondary: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
}

export default compose(
  withStyles(styles),
)(CampaignTypeFormPage)
