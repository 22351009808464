/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { useContext } from 'react'
import { uiStore, VIEW_SIZE_PC } from '@landing/contexts/ui'
import handImage from '@landing/assets/images/hands.png'
import targets from '@landing/assets/images/target_circle.png'
import { InquireButton, ProductIntroductionButton } from '../unit/button'
import { LineBreak } from '../unit/string'
import { AppearanceAnimation } from '../unit/animation'

const makeStyles = () => ({
  btnWrap: ({ spacing }) => ({ mq }) => css`
    ${mq({
    display: 'flex',
    marginTop: [`${spacing(2)}rem`, `${spacing(3)}rem`],
    paddingLeft: ['0', `${spacing(1.625)}rem`],
  })}

    button {
      :first-of-type {
        ${mq({
    marginRight: ['auto', `${spacing(2)}rem`],
  })}
      }
    }
  `,
  content: ({ mq }) => css`
    ${mq({
    alignItems: ['center', 'normal'],
    display: ['flex', 'block'],
    flexDirection: ['column', 'row'],
    justifyContent: ['center', 'flex-start'],
    left: ['auto', '9.9rem'],
    paddingTop: ['4rem', '0'],
    position: ['static', 'absolute'],
    top: ['auto', '23.4rem'],
  })}
  `,
  describe: ({ mq }) => css`
    ${mq({
    paddingLeft: ['0', '1.625rem'],
    textAlign: ['center', 'left'],
    width: ['18.4rem', 'auto'],
  })}
  `,
  hands: ({ mq }) => css`
    ${mq({
    bottom: ['-2rem', '0'],
    left: ['6rem', 'auto'],
    margin: ['0 auto', 'auto'],
    position: 'absolute',
    right: ['0', '6rem'],
    width: ['22.5rem', '46rem'],
  })}

    img {
      display: block;
      width: 100%;
    }
  `,
  root: ({ mq }) => css`
    ${mq({
    background: 'linear-gradient(180deg, #ffffff 69.75%, #eff4f3 100%)',
    height: ['65.6rem', '76.0rem'],
  })}
  `,
  targetCircle: ({ spacing, mq }) => css`
    ${mq({
    marginTop: [`${spacing(3)}rem`, `${spacing(6)}rem`],
    width: ['26.4rem', '41.5rem'],
  })}

    img {
      display: block;
      width: 100%;
    }
  `,
  text: ({ colors: { grey }, font, mq }) => css`
    ${mq({
    color: grey[700],
    fontSize: [font.body2.fontSize, font.body1.fontSize],
    lineHeight: [font.body2.lineHeight, font.body1.lineHeight],
  })};
  `,
  title: ({
    colors: { grey }, font, spacing, mq,
  }) => css`
    color: ${grey[900]};
    font-weight: bold;
    padding-bottom: ${spacing()}rem;

    ${mq({
    color: grey[900],
    fontSize: [font.title2.fontSize, font.title1.fontSize],
    fontWeight: 'bold',
    lineHeight: [font.title2.lineHeight, font.title2.lineHeight],
    paddingBottom: `${spacing()}rem`,
  })}
  `,
  wrap: ({ mq }) => css`
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    overflow: hidden;

    ${mq({
    maxWidth: ['auto', '102.4rem'],
  })}
  `,
})

const Home = () => {
  const styles = makeStyles()
  const {
    state: { viewSize },
  } = useContext(uiStore)

  return (
    <div css={styles.root}>
      <div css={styles.wrap}>
        <AppearanceAnimation>
          <div css={styles.content}>
            <div css={styles.describe}>
              <p css={styles.title}>
                {viewSize === VIEW_SIZE_PC ? (
                  '클래스팅 광고 비즈니스'
                ) : (
                  <LineBreak text={'클래스팅 \n광고 비즈니스'} />
                )}
              </p>
              <p css={styles.text}>
                {viewSize === VIEW_SIZE_PC ? (
                  <LineBreak
                    text={
                      '학교 90%에서 사용하는 클래스팅을 통해\n 학부모, 학생, 교사에게 광고를 진행해보세요.'
                    }
                  />
                ) : (
                  <LineBreak
                    text={'학부모, 학생, 교사에게 \n 효과적인 마케팅하세요.'}
                  />
                )}
              </p>
            </div>
            <div css={styles.btnWrap}>
              {viewSize === VIEW_SIZE_PC && <InquireButton />}
              <ProductIntroductionButton />
            </div>
            <figure css={styles.targetCircle}>
              <img src={targets} alt="" />
            </figure>
          </div>
          <figure css={styles.hands}>
            <img src={handImage} alt="" />
          </figure>
        </AppearanceAnimation>
      </div>
    </div>
  )
}

export default Home
