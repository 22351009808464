/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { Fragment, useContext } from 'react'

import { uiStore, VIEW_SIZE_MOBILE } from '@landing/contexts/ui'
import { LineBreak } from '../unit/string'
import { Accordion } from '../unit/accordion'

const makeStyles = () => ({
  root: ({ colors: { grey }, spacing, mq }) => css`
    ${mq({
    borderTop: `1px solid ${grey[300]}`,
    marginBottom: `${spacing(5)}rem`,
    width: '100%',
  })}
  `,
})

const highlightStyle = ({ colors: { green } }) => css`
    color: ${green[100]};
    font-weight: bold;
  `

const questions = [
  {
    answer: (
      <LineBreak
        text={`
        · 클래스팅 광고는 학부모, 학생, 교사 대상 각각 광고 노출 가능합니다.
        · 학부모와 학생 타겟 동시 노출 가능하며, 캠페인 리포트는 타겟별 링크로 전달 드립니다
        `.trim()}
      />
    ),
    title: '학부모, 학생, 교사 타겟 중 선택하여 광고 노출이 되는 건가요?',
    title2: <LineBreak text={'학부모와 학생 광고 동시 노출\n가능한가요?'} />,
  },
  {
    answer: (
      <Fragment>
        상품에 따라 최소집행금액이 상이하며, 상세 내용은
        {' '}
        <a
          css={highlightStyle}
          href="https://classting.cc/classtingad"
        >
          광고 상품 소개서
        </a>
        를 참고해주세요.
      </Fragment>
    ),
    title: '최소집행금액은 얼마인가요?',
  },
  {
    answer:
      '광고 소재를 전달주시면 클래스팅에서 검수하여 셋팅해드리고 있습니다. ',
    title: '광고 대시보드에서 직접 셋팅해야하나요?',
    title2: <LineBreak text={'광고 대시보드에서 직접\n셋팅해야하나요?'} />,
  },
  {
    answer: (
      <LineBreak
        text={`
        · 광고 상품에 따라 소재 교체 지원 여부가 상이합니다. 소재 교체가 가능한 경우 교체 횟수 제한은 없습니다.
        · 교체하고자 하는 소재를 가이드에 맞춰 메일로 전달주시면 소재 검수 진행 후 운영팀에서 셋팅 진행합니다.
        · 즉시 교체 또는 일자별 예약 교체(익일 0시 교체)는 가능하지만, 시간대 지정 교체는 불가 합니다.
        `.trim()}
      />
    ),
    title: '소재 교체가 가능한가요? 가능하다면 몇 회까지 가능한가요?',
    title2: (
      <LineBreak
        text={'소재 교체가 가능한가요?\n가능하다면 몇 회까지 가능한가요?'}
      />
    ),
  },
  {
    answer: (
      <LineBreak
        text={`
        · 광고 상품에 따라 소재 롤링과 A/B 테스트 지원 여부, 광고 집행금액에 따라 소재 개수가 상이합니다. (최소 2개 ~ 최대 4개)
        · 자세한 내용은 광고팀에 별도 문의해주세요.
        `}
      />
    ),
    title: 'A/B 테스트 또는 소재 롤링이 가능한가요?',
    title2: <LineBreak text={'A/B 테스트 또는 소재 롤링이\n가능한가요?'} />,
  },
  {
    answer: (
      <LineBreak
        text={`
        · 광고 노출 수, 클릭률, 시청률 등을 데일리로 확인하실 수 있는 광고 성과 리포트를 웹링크를 통해 제공해드리고 있습니다.
        · 제공되는 캠페인 리포트를 통해 실시간 광고 데이터를 확인하실 수 는 없지만, 전일 자 데이터를 익일 오전부터 확인 가능합니다.
        · 해당 데이터는 엑셀 파일 다운로드 기능을 지원하여 활용에 도움드리고 있습니다.
        `}
      />
    ),
    title: '광고 성과 리포트는 어떻게 확인할 수 있나요?',
    title2: <LineBreak text={'광고 성과 리포트는 어떻게\n확인할 수 있나요?'} />,
  },
  {
    answer: (
      <LineBreak
        text={`
        · 광고 집행일자로부터 영업일 기준 10일 내 광고주(대행사/렙사)의 일방적인 변경/취소 시 20%의 위약금이 발생합니다.
        · 광고 라이브 이후 중간 정산 또는 취소는 불가합니다.
        `}
      />
    ),
    title: '위약금 가이드가 궁금합니다.',
  },
]

const Questions = () => {
  const {
    state: { viewSize },
  } = useContext(uiStore)
  const styles = makeStyles()

  return (
    <div css={styles.root}>
      {questions.map(({ title, title2, answer }) => (
        <Accordion
          key={title}
          title={viewSize === VIEW_SIZE_MOBILE && title2 ? title2 : title}
          content={answer}
        />
      ))}
    </div>
  )
}

export default Questions
