import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularUnderLoad from './CircularUnderLoad'

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
})

class EnhancedDialog extends React.Component {
  static defaultProps = {
    modalContentText: '',
    children: '',
    isCloseButton: true,
    pending: false,
    handleClose: () => {},
    modalSize: 'sm',
  }

  componentDidMount() {

  }

  render() {
    const {
      isOpen,
      handleClose,
      handleOpen,
      modalTitle,
      children,
      modalContentText,
      isCloseButton,
      pending,
      classes,
      modalSize,
    } = this.props

    return (
      <div>
        <Dialog
          maxWidth={modalSize}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={isOpen}
          onClose={handleClose}
        >
          <DialogTitle id="alert-dialog-title">{modalTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {modalContentText}
            </DialogContentText>
            {children}
          </DialogContent>
          <DialogActions>
            {isCloseButton
              ? (
                <Button onClick={handleClose} color="default">
                  취소
                </Button>
              )
              : null
            }
            <Button onClick={handleOpen} color="primary" autoFocus>
              확인
              {pending && <CircularUnderLoad styles={classes.progressButton} size={20} />}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

EnhancedDialog.propTypes = {
  classes: PropTypes.shape({
    progressButton: PropTypes.string,
  }).isRequired,
  handleClose: PropTypes.func,
  handleOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  modalTitle: PropTypes.object.isRequired,
  modalContentText: PropTypes.string,
  children: PropTypes.node,
  isCloseButton: PropTypes.bool,
  pending: PropTypes.bool,
  modalSize: PropTypes.string,
}

export default withStyles(styles)(EnhancedDialog)
