import { ADVERTISEMENT_NAME_MAP } from './AdvertisementNameMap'

/**
 * Today에 보여줘야 할 항목들
 */
export const TODAY_ADVERTISEMENTS = Object.freeze([
  ADVERTISEMENT_NAME_MAP.POST,
  ADVERTISEMENT_NAME_MAP.POST_BANNER,
  ADVERTISEMENT_NAME_MAP.NOTIFICATION_TEXT_BANNER,
  ADVERTISEMENT_NAME_MAP.NOTIFICATION_IMAGE_BANNER,
  ADVERTISEMENT_NAME_MAP.START_POPUP,
  ADVERTISEMENT_NAME_MAP.END_POPUP,
  ADVERTISEMENT_NAME_MAP.CLASS_TAB_BANNER,
  ADVERTISEMENT_NAME_MAP.GAM_CLOSE_POPUP,
  ADVERTISEMENT_NAME_MAP.GAM_NOTIFICATION_FIRST_SLOT,
  ADVERTISEMENT_NAME_MAP.GAM_NOTIFICATION_SECOND_SLOT,
  ADVERTISEMENT_NAME_MAP.GAM_CLASS_TAB,
  ADVERTISEMENT_NAME_MAP.GAM_POST_BANNER,
  ADVERTISEMENT_NAME_MAP.GAM_POST_DETAIL,
  ADVERTISEMENT_NAME_MAP.GAM_CLASSROOM_POST_LIST,
  ADVERTISEMENT_NAME_MAP.GAM_CLASSROOM_POST_DETAIL,
  ADVERTISEMENT_NAME_MAP.GAM_HOME_TOP_BANNER,
  ADVERTISEMENT_NAME_MAP.GAM_HOME_MIDDLE_BANNER,
  ADVERTISEMENT_NAME_MAP.CLASSTALK_TAB_BANNER,
  ADVERTISEMENT_NAME_MAP.SPLASH_BANNER,
])
