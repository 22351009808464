import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'

const styles = ({
  root: {
    width: '60%',
    background: 'none',
  },
  iconContainer: {
    fill: '#ffffff',
  },
})

class HorizontalNonLinearStepper extends React.Component {
  state = {
    steps: [
      '캠페인 만들기',
      '캠페인 설정하기',
      '소재 등록하기',
      '견적 결제하기',
    ],
  };

  render() {
    const { classes, activeStep } = this.props
    const { steps } = this.state

    return (
      <Stepper
        nonLinear
        activeStep={activeStep}
        className={classes.root}
      >
        {steps.map(item => (
          <Step key={item}>
            <StepLabel StepIconProps={{ classes: { text: classes.iconContainer } }}>
              {item}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    )
  }
}

HorizontalNonLinearStepper.propTypes = {
  classes: PropTypes.object.isRequired,
  activeStep: PropTypes.number.isRequired,
}

export default withStyles(styles)(HorizontalNonLinearStepper)
