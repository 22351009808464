import { createAction } from 'redux-actions'
import eachSeries from 'async/eachSeries'
import { forEach } from 'lodash'
import { http } from '@/shared/utils'

import { CLASSTING_AD_API_URL } from '@/shared/constants'

export const RESET_AD_LIST = 'editing/RESET_AD_LIST'
export const RESET_AD_SETS = 'editing/RESET_AD_SETS'
export const SET_CAMPAIGNS_PENDING = 'editing/CAMPAIGNS_POST_PENDING'
export const SET_CAMPAIGNS_SUCCESS = 'editing/CAMPAIGNS_POST_SUCCESS'
export const SET_CAMPAIGNS_FAILURE = 'editing/CAMPAIGNS_POST_FAILURE'
export const UPDATE_CAMPAIGNS_PENDING = 'editing/CAMPAIGNS_PUT_PENDING'
export const UPDATE_CAMPAIGNS_SUCCESS = 'editing/CAMPAIGNS_PUT_SUCCESS'
export const UPDATE_CAMPAIGNS_FAILURE = 'editing/CAMPAIGNS_PUT_FAILURE'
export const SET_AD_SET_PENDING = 'editing/AD_POST_PENDING'
export const SET_AD_SET_SUCCESS = 'editing/AD_POST_SUCCESS'
export const SET_AD_SET_FAILURE = 'editing/AD_POST_FAILURE'
export const UPDATE_AD_SET_PENDING = 'editing/AD_PUT_PENDING'
export const UPDATE_AD_SET_SUCCESS = 'editing/AD_PUT_SUCCESS'
export const UPDATE_AD_SET_FAILURE = 'editing/AD_PUT_FAILURE'
export const FETCH_AD_LOCATION_PENDING = 'editing/FETCH_AD_LOCATION_GET_PENDING'
export const FETCH_AD_LOCATION_SUCCESS = 'editing/FETCH_AD_LOCATION_GET_SUCCESS'
export const FETCH_AD_LOCATION_FAILURE = 'editing/FETCH_AD_LOCATION_POST_FAILURE'
export const FETCH_AD_SET_PENDING = 'editing/FETCH_AD_SET_PENDING'
export const FETCH_AD_SET_SUCCESS = 'editing/FETCH_AD_SET_SUCCESS'
export const FETCH_AD_SET_FAILURE = 'editing/FETCH_AD_SET_FAILURE'
export const FETCH_AD_PACKAGES_PENDING = 'editing/AD_PACKAGES_GET_PENDING'
export const FETCH_AD_PACKAGES_SUCCESS = 'editing/AD_PACKAGES_GET_SUCCESS'
export const FETCH_AD_PACKAGES_FAILURE = 'editing/AD_PACKAGES_GET_FAILURE'
export const FETCH_REGIONS_PENDING = 'editing/FETCH_REGIONS_GET_PENDING'
export const FETCH_REGIONS_SUCCESS = 'editing/FETCH_REGIONS_GET_SUCCESS'
export const FETCH_REGIONS_FAILURE = 'editing/FETCH_REGIONS_GET_FAILURE'
export const FETCH_CITIES_PENDING = 'editing/FETCH_CITIES_GET_PENDING'
export const FETCH_CITIES_SUCCESS = 'editing/FETCH_CITIES_GET_SUCCESS'
export const FETCH_CITIES_FAILURE = 'editing/FETCH_CITIES_GET_FAILURE'
export const FETCH_ZONES_PENDING = 'editing/FETCH_ZONES_GET_PENDING'
export const FETCH_ZONES_SUCCESS = 'editing/FETCH_ZONES_GET_SUCCESS'
export const FETCH_ZONES_FAILURE = 'editing/FETCH_ZONES_GET_FAILURE'
export const FETCH_AREAS_PENDING = 'editing/FETCH_AREAS_GET_PENDING'
export const FETCH_AREAS_SUCCESS = 'editing/FETCH_AREAS_GET_SUCCESS'
export const FETCH_AREAS_FAILURE = 'editing/FETCH_AREAS_GET_FAILURE'
export const FETCH_THEMES_PENDING = 'editing/FETCH_THEMES_GET_PENDING'
export const FETCH_THEMES_SUCCESS = 'editing/FETCH_THEMES_GET_SUCCESS'
export const FETCH_THEMES_FAILURE = 'editing/FETCH_THEMES_GET_FAILURE'
export const RESET_AD = 'editing/RESET_AD'
export const FETCH_AD_PENDING = 'editing/FETCH_AD_GET_PENDING'
export const FETCH_AD_SUCCESS = 'editing/FETCH_AD_GET_SUCCESS'
export const FETCH_AD_FAILURE = 'editing/FETCH_AD_GET_FAILURE'
export const FETCH_ADS_PENDING = 'editing/FETCH_ADS_GET_PENDING'
export const FETCH_ADS_SUCCESS = 'editing/FETCH_ADS_GET_SUCCESS'
export const FETCH_ADS_FAILURE = 'editing/FETCH_ADS_GET_FAILURE'
export const SET_AD_PENDING = 'editing/SET_AD_POST_PENDING'
export const SET_AD_SUCCESS = 'editing/SET_AD_POST_SUCCESS'
export const SET_AD_FAILURE = 'editing/SET_AD_POST_FAILURE'
export const UPDATE_AD_PENDING = 'editing/UPDATE_AD_PUT_PENDING'
export const UPDATE_AD_SUCCESS = 'editing/UPDATE_AD_PUT_SUCCESS'
export const UPDATE_AD_FAILURE = 'editing/UPDATE_AD_PUT_FAILURE'

export const resetAdList = createAction(RESET_AD_LIST)
export const resetAdSets = createAction(RESET_AD_SETS)
export const setCampaignsPending = createAction(SET_CAMPAIGNS_PENDING)
export const setCampaignsSuccess = createAction(SET_CAMPAIGNS_SUCCESS)
export const setCampaignsFailure = createAction(SET_CAMPAIGNS_FAILURE)
export const updateCampaignsPending = createAction(UPDATE_CAMPAIGNS_PENDING)
export const updateCampaignsSuccess = createAction(UPDATE_CAMPAIGNS_SUCCESS)
export const updateCampaignsFailure = createAction(UPDATE_CAMPAIGNS_FAILURE)
export const setAdSetPending = createAction(SET_AD_SET_PENDING)
export const setAdSetSuccess = createAction(SET_AD_SET_SUCCESS)
export const setAdSetFailure = createAction(SET_AD_SET_FAILURE)
export const updateAdSetPending = createAction(UPDATE_AD_SET_PENDING)
export const updateAdSetSuccess = createAction(UPDATE_AD_SET_SUCCESS)
export const updateAdSetFailure = createAction(UPDATE_AD_SET_FAILURE)
export const fetchAdLocationPending = createAction(FETCH_AD_LOCATION_PENDING)
export const fetchAdLocationSuccess = createAction(FETCH_AD_LOCATION_SUCCESS)
export const fetchAdLocationFailure = createAction(FETCH_AD_LOCATION_FAILURE)
export const fetchAdPackagesPending = createAction(FETCH_AD_PACKAGES_PENDING)
export const fetchAdPackagesSuccess = createAction(FETCH_AD_PACKAGES_SUCCESS)
export const fetchAdPackagesFailure = createAction(FETCH_AD_PACKAGES_FAILURE)
export const fetchAdSetPending = createAction(FETCH_AD_SET_PENDING)
export const fetchAdSetSuccess = createAction(FETCH_AD_SET_SUCCESS)
export const fetchAdSetFailure = createAction(FETCH_AD_SET_FAILURE)
export const fetchRegionsPending = createAction(FETCH_REGIONS_PENDING)
export const fetchRegionsSuccess = createAction(FETCH_REGIONS_SUCCESS)
export const fetchRegionsFailure = createAction(FETCH_REGIONS_FAILURE)
export const fetchCitiesPending = createAction(FETCH_CITIES_PENDING)
export const fetchCitiesSuccess = createAction(FETCH_CITIES_SUCCESS)
export const fetchCitiesFailure = createAction(FETCH_CITIES_FAILURE)
export const fetchZonesPending = createAction(FETCH_ZONES_PENDING)
export const fetchZonesSuccess = createAction(FETCH_ZONES_SUCCESS)
export const fetchZonesFailure = createAction(FETCH_ZONES_FAILURE)
export const fetchAreasPending = createAction(FETCH_AREAS_PENDING)
export const fetchAreasSuccess = createAction(FETCH_AREAS_SUCCESS)
export const fetchAreasFailure = createAction(FETCH_AREAS_FAILURE)
export const fetchThemesPending = createAction(FETCH_THEMES_PENDING)
export const fetchThemesSuccess = createAction(FETCH_THEMES_SUCCESS)
export const fetchThemesFailure = createAction(FETCH_THEMES_FAILURE)
export const resetAd = createAction(RESET_AD)
export const fetchAdPending = createAction(FETCH_AD_PENDING)
export const fetchAdSuccess = createAction(FETCH_AD_SUCCESS)
export const fetchAdsPending = createAction(FETCH_ADS_PENDING)
export const fetchAdsSuccess = createAction(FETCH_ADS_SUCCESS)
export const fetchAdsFailure = createAction(FETCH_ADS_FAILURE)
export const fetchAdFailure = createAction(FETCH_AD_FAILURE)
export const setAdPending = createAction(SET_AD_PENDING)
export const setAdSuccess = createAction(SET_AD_SUCCESS)
export const setAdFailure = createAction(SET_AD_FAILURE)
export const updateAdPending = createAction(UPDATE_AD_PENDING)
export const updateAdSuccess = createAction(UPDATE_AD_SUCCESS)
export const updateAdFailure = createAction(UPDATE_AD_FAILURE)

export const setCampaigns = data => (dispatch, getState) => {
  dispatch(setCampaignsPending())
  return http.post(`${CLASSTING_AD_API_URL}/advertisers/${data.advertiser_id}/campaigns`, { ...data })
    .then(res => (
      dispatch(setCampaignsSuccess(res))
    ))
    .then(() => (
      getState().editing.campaign
    ))
    .catch(err => (
      dispatch(setCampaignsFailure(err.response))
    ))
}

export const updateCampaigns = data => (dispatch, getState) => {
  dispatch(updateCampaignsPending())
  return http.put(`${CLASSTING_AD_API_URL}/campaigns/${data.id}`, { ...data })
    .then(res => (
      dispatch(updateCampaignsSuccess(res))
    ))
    .then(() => (
      getState().editing.campaign
    ))
    .catch(err => (
      dispatch(updateCampaignsFailure(err.response))
    ))
}

export const fetchAdLocations = adSetId => (dispatch, getState) => {
  dispatch(fetchAdLocationPending())
  return http.get(`${CLASSTING_AD_API_URL}/advertisement_sets/${adSetId}/ad_locations`)
    .then(res => (
      dispatch(fetchAdLocationSuccess(res))
    ))
    .then(() => (
      getState().editing.adLocations
    ))
    .catch(err => (
      dispatch(fetchAdLocationFailure(err.response))
    ))
}

function setAdLocations(adSetId, adLocationData) {
  return http.post(`${CLASSTING_AD_API_URL}/advertisement_sets/${adSetId}/ad_locations`, { ...adLocationData })
}

export function deleteAdLocation(adLocationId) {
  return http.delete(`${CLASSTING_AD_API_URL}/ad_locations/${adLocationId}`)
}

export const fetchAdSet = campaignId => (dispatch, getState) => {
  dispatch(fetchAdSetPending())
  return http.get(`${CLASSTING_AD_API_URL}/campaigns/${campaignId}/advertisement_sets`)
    .then(res => (
      dispatch(fetchAdSetSuccess(res))
    ))
    .then(() => (getState().editing.adSets))
    .catch(err => (
      dispatch(fetchAdSetFailure(err.response))
    ))
}

export const setAdSet = data => (dispatch) => {
  const {
    adSetData, selectedRegionType, adLocationData, packageType,
  } = data
  dispatch(setAdSetPending())
  return http.post(`${CLASSTING_AD_API_URL}/campaigns/${adSetData.campaign_id}/advertisement_sets`, { ...adSetData })
    .then((res) => {
      if (selectedRegionType === 'region' || packageType === 'region') {
        eachSeries(adLocationData, (item, callback) => {
          setAdLocations(res.data.id, { [item.type]: item.id })
            .then(() => callback())
        })
        return true
      }

      return true
    })
    .then(res => (
      dispatch(setAdSetSuccess(res))
    ))
    .catch(err => (
      dispatch(setAdSetFailure(err.response))
    ))
}

export const updateAdSet = data => (dispatch) => {
  const {
    adSet, adSetData, selectedRegionType, setAdLocationData, packageType,
  } = data
  dispatch(updateAdSetPending())
  return http.put(`${CLASSTING_AD_API_URL}/advertisement_sets/${adSet.id}`, { ...adSetData })
    .then((res) => {
      if (selectedRegionType === 'region' || packageType === 'region') {
        eachSeries(setAdLocationData, (item, callback) => {
          setAdLocations(res.data.id, { [item.type]: item.id })
            .then(() => callback())
        })
        return true
      }
      return true
    })
    .then(res => (
      dispatch(updateAdSetSuccess(res))
    ))
    .catch(err => (
      dispatch(updateAdSetFailure(err.response))
    ))
}

export const fetchAdPackage = params => (dispatch, getState) => {
  dispatch(fetchAdPackagesPending())
  return http.get(`${CLASSTING_AD_API_URL}/ad_packages`, { params })
    .then(res => (
      dispatch(fetchAdPackagesSuccess(res))
    ))
    .then(() => (
      getState().editing.adPackages
    ))
    .catch(err => (
      dispatch(fetchAdPackagesFailure(err.response))
    ))
}

export const fetchRegions = params => (dispatch) => {
  dispatch(fetchRegionsPending())
  return http.get(`${CLASSTING_AD_API_URL}/regions`, { params })
    .then(res => (
      dispatch(fetchRegionsSuccess(res))
    ))
    .catch(err => (
      dispatch(fetchRegionsFailure(err.response))
    ))
}

export const fetchCities = params => (dispatch) => {
  dispatch(fetchCitiesPending())
  return http.get(`${CLASSTING_AD_API_URL}/cities`, { params })
    .then(res => (
      dispatch(fetchCitiesSuccess(res))
    ))
    .catch(err => (
      dispatch(fetchCitiesFailure(err.response))
    ))
}

export const fetchZones = params => (dispatch) => {
  dispatch(fetchZonesPending())
  return http.get(`${CLASSTING_AD_API_URL}/zones`, { params })
    .then(res => (
      dispatch(fetchZonesSuccess(res))
    ))
    .catch(err => (
      dispatch(fetchZonesFailure(err.response))
    ))
}

export const fetchAreas = params => (dispatch, getState) => {
  dispatch(fetchAreasPending())
  return http.get(`${CLASSTING_AD_API_URL}/areas`, { params })
    .then(res => (
      dispatch(fetchAreasSuccess(res))
    ))
    .then(() => (
      getState().editing.areas
    ))
    .catch(err => (
      dispatch(fetchAreasFailure(err.response))
    ))
}

export const fetchThemes = params => (dispatch) => {
  dispatch(fetchThemesPending())
  return http.get(`${CLASSTING_AD_API_URL}/themes`, { params })
    .then(res => (
      dispatch(fetchThemesSuccess(res))
    ))
    .catch(err => (
      dispatch(fetchThemesFailure(err.response))
    ))
}

export const fetchAd = params => (dispatch, getState) => {
  dispatch(fetchAdPending())
  return http.get(`${CLASSTING_AD_API_URL}/advertisements/`, { params })
    .then(res => (
      dispatch(fetchAdSuccess(res))
    ))
    .then(() => (
      getState().editing.ad
    ))
    .catch(err => (
      dispatch(fetchAdFailure(err.response))
    ))
}

export const fetchAds = params => (dispatch) => {
  dispatch(fetchAdsPending())
  return http.get(`${CLASSTING_AD_API_URL}/advertisements/`, { params })
    .then(res => (
      dispatch(fetchAdsSuccess(res))
    ))
    .catch(err => (
      dispatch(fetchAdsFailure(err.response))
    ))
}

export const setAd = (adData, adSetData, adSetId) => (dispatch) => {
  dispatch(setAdPending())
  return http.post(`${CLASSTING_AD_API_URL}/advertisement_sets/${adSetId}/advertisements`, { ...adData })
    .then((res) => {
      dispatch(setAdSuccess(res))
      return res.data
    })
    .then((advertisement) => {
      http.put(`${CLASSTING_AD_API_URL}/advertisement_sets/${adSetId}`, { ...adSetData })
      return advertisement
    })
    .catch(err => (
      dispatch(setAdFailure(err.response))
    ))
}

export const updateAd = (data, adSetData, adSetId, adId) => (dispatch, getState) => {
  dispatch(updateAdPending())
  return http.put(`${CLASSTING_AD_API_URL}/advertisements/${adId}`, { ...data })
    .then(res => (
      dispatch(updateAdSuccess(res))
    ))
    .then(() => (
      http.put(`${CLASSTING_AD_API_URL}/advertisement_sets/${adSetId}`, { ...adSetData })
    ))
    .then(() => (
      getState().editing.ad
    ))
    .catch(err => (
      dispatch(updateAdFailure(err.response))
    ))
}

export function setImage(data, adId) {
  const formData = new FormData()
  forEach(data, (value, key) => {
    formData.append(key, value || '')
  })
  return http.put(`${CLASSTING_AD_API_URL}/advertisements/${adId}/image`,
    formData,
    { headers: { 'Content-Type': 'multipart/form-data' } })
}

export function deleteImage(adId) {
  return http.delete(`${CLASSTING_AD_API_URL}/advertisements/${adId}/image`)
}

export function setVideo(data, adId) {
  return http.put(`${CLASSTING_AD_API_URL}/advertisements/${adId}/video`, { ...data })
}

export function fetchCoordinates(regionName) {
  return http.get(`https://71kulncovk.execute-api.ap-northeast-2.amazonaws.com/dev/geolocation?location=${encodeURIComponent(regionName)}`)
}
