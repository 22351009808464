import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import { withTracker } from '@/shared/enhancers'
import { NavigationBar } from '@/shared/components'
import terms from '../../policy/page/terms.html'
import privacy from '../../policy/page/privacy.html'

const termsHtml = { __html: terms }
const privacyHtml = { __html: privacy }

const styles = ({
  policyPageContainer: {
    maxWidth: '846px',
    margin: '0 auto',
    padding: '64px 0 0 0',
  },
  title: {
    textAlign: 'left',
    margin: '0 0 64px 0',
  },
  subTitle: {
    fontSize: '20px',
    fontWeight: '500',
    color: '#5aaafa',
  },
  policySection: {
    height: '260px',
    backgroundColor: '#e9eff7',
    padding: '14px',
    overflowY: 'auto',
  },
  signUpPageButton: {
    background: '#5aaafa',
    color: '#ffffff',
    width: '50%',
    margin: '24px auto',
    display: 'block',
  },
})

class PolicyPage extends Component {
  state = {
    policyChecked: false,
    privacyChecked: false,
  }

  isDisabled = () => {
    const { policyChecked, privacyChecked } = this.state
    return policyChecked && privacyChecked
  }

  handleChange = name => (event) => {
    this.setState({ [name]: event.target.checked })
  }

  handleSignUpPageClick = () => {
    const { history } = this.props
    history.push('/sign_up')
  }

  render() {
    const { classes } = this.props
    const { isDisabled, handleSignUpPageClick } = this

    return (
      <div>
        <NavigationBar />
        <div className={classes.policyPageContainer}>
          <Typography variant="h5" gutterBottom className={classes.title}>
            약관동의
          </Typography>

          <div>
            <Typography variant="h5" gutterBottom className={classes.subTitle}>
              클래스팅 AD 서비스 이용약관
            </Typography>
            {/* eslint-disable-next-line react/no-danger */}
            <div className={classes.policySection} dangerouslySetInnerHTML={termsHtml} />
            {/* eslint-disable */}
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={this.handleChange('policyChecked')}
                    value="policyChecked"
                  />
                }
                label="(필수)클래스팅 AD 서비스 이용 약관에 동의합니다."
              />
            </FormGroup>
          </div>

          <div>
            <Typography variant="h5" gutterBottom className={classes.subTitle}>
            개인정보 이용에 대한 안내
            </Typography>
            {/* eslint-disable-next-line react/no-danger */}
            <div className={classes.policySection} dangerouslySetInnerHTML={privacyHtml} />
            {/* eslint-disable */}
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={this.handleChange('privacyChecked')}
                    value="privacyChecked"
                  />
                }
                label="(필수)개인정보 이용에 동의합니다."
              />
            </FormGroup>
          </div>

          <Button className={classes.signUpPageButton}
            variant="contained"
            color="secondary"
            type="button"
            disabled={!isDisabled()}
            onClick={handleSignUpPageClick}
          >
            회원가입을 진행합니다
          </Button>

        </div>
      </div>
    )
  }
}

PolicyPage.propTypes = {
  classes: PropTypes.shape({
    policyPageContainer: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    policySection: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
}


export default compose(
  withTracker,
  withStyles(styles),
)(PolicyPage)