/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { IntlProvider } from 'react-intl'
import { ko } from '../translations'

const translationMessages = {
  ko,
}

const LanguageProvider = ({ children, language }) => (
  <IntlProvider
    locale={language}
    messages={translationMessages[language] || translationMessages[ko]}
  >
    {children}
  </IntlProvider>
)

LanguageProvider.propTypes = {
  children: PropTypes.element.isRequired,
  language: PropTypes.string.isRequired,
}

export default LanguageProvider
