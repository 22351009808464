import React from 'react'
import PropTypes from 'prop-types'
import classesName from 'classnames'

import BaseComponent from './BaseComponent'

const TheadComponent = ({ threadProps, classes }) => {
  const className = threadProps.className === '-header'
    ? classesName(classes.threadHeader) : classesName(classes.thread)

  return (
    <BaseComponent
      tableProps={threadProps}
      className={className}
    />
  )
}

TheadComponent.propTypes = {
  threadProps: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
}

export default TheadComponent
