import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import includes from 'lodash/includes'
import groupBy from 'lodash/groupBy'
import reduce from 'lodash/reduce'
import round from 'lodash/round'
import sumBy from 'lodash/sumBy'

import { handleActions } from 'redux-actions'
import { getViewTemplate } from '@/shared/utils'
import { TODAY_ADVERTISEMENTS } from '@/shared/constants'
import {
  FETCH_AD_PENDING,
  FETCH_AD_SUCCESS,
  FETCH_AD_FAILURE,
  FETCH_AD_COUNT_PENDING,
  FETCH_AD_COUNT_SUCCESS,
  FETCH_AD_COUNT_FAILURE,
  UPDATE_AD_FORCED_STOP_PENDING,
  UPDATE_AD_FORCED_STOP_SUCCESS,
  UPDATE_AD_FORCED_STOP_FAILURE,
} from './actions'

/* eslint-disable  */
function getAd(data, ad) {
  if (isEmpty(data)) {
    return ad
  }

  forEach(data, (item) => {
    const viewTemplate = getViewTemplate({
      view_template: item.advertisement_set.view_template,
      gam_ad_unit_id: item.advertisement_set.gam_ad_unit_id
    })

    if (!includes(TODAY_ADVERTISEMENTS, viewTemplate)) {
      return ;
    }

    if (!ad[item.campaign.id]) {
      ad[item.campaign.id] = {}
    }

    ad[item.campaign.id].id = item.campaign.id
    ad[item.campaign.id].title = item.campaign.title
    ad[item.campaign.id].tag = item.campaign.tag || ''
    ad[item.campaign.id].advertiser = item.advertiser

    if (!ad[item.campaign.id].total) {
      ad[item.campaign.id].total = {}
      ad[item.campaign.id].total.memo = 0
    }
    ad[item.campaign.id].total.memo += Number(item.advertisement_set.memo) || 0

    if (!ad[item.campaign.id].advertisements) {
      ad[item.campaign.id].advertisements = {}
    }

    if (!ad[item.campaign.id].advertisements[item.id]) {
      ad[item.campaign.id].advertisements[item.id] = {}
      ad[item.campaign.id].advertisements[item.id].click = {}
      ad[item.campaign.id].advertisements[item.id].view = {}
      ad[item.campaign.id].advertisements[item.id].watch = {}
      ad[item.campaign.id].advertisements[item.id].memo = 0
      ad[item.campaign.id].advertisements[item.id].advertisement_set = {}
    }

    ad[item.campaign.id].advertisements[item.id].id = item.id
    ad[item.campaign.id].advertisements[item.id].forced_stop = item.forced_stop
    ad[item.campaign.id].advertisements[item.id].name = item.memo || ''
    ad[item.campaign.id].advertisements[item.id].weight = item.weight

    ad[item.campaign.id].advertisements[item.id].advertisement_set.name = item.advertisement_set.name || ''
    ad[item.campaign.id].advertisements[item.id].advertisement_set.id = item.advertisement_set.id
    ad[item.campaign.id].advertisements[item.id].advertisement_set.target_daily_impressions = item.advertisement_set.target_daily_impressions || 0
    ad[item.campaign.id].advertisements[item.id].advertisement_set.view_template = viewTemplate || ''
    ad[item.campaign.id].advertisements[item.id].advertisement_set.memo = Number(item.advertisement_set.memo) || 0
    ad[item.campaign.id].advertisements[item.id].advertisement_set.cpm = item.advertisement_set.cpm
  })

  return ad
}

/*
  pick 'count' and 'date' from adCount data
  f.e) { [adId]: { view: {...}, click: {...}, ...} }
*/
const pickAdCountObject = value => reduce(value, (
  result, {
    advertisement_id: adId, event_type: eventType, count, date,
  },
) => ({
  [adId]: {
    ...result[adId],
    [eventType]: {
      count,
      date,
    },
  },
}), {})

// f.e) { adId: { view: {...}, click: {...}, ... } }
const mergeAdCounts = groupByAdId => (
  reduce(groupByAdId, (result, value) => ({
    ...result,
    ...pickAdCountObject(value),
  }), [])
)

// f.e) { adId: {...}, adId2: {...} }
const mergeAdCountsToAds = (adsInData, mergedAdCounts) => reduce(adsInData, (res, val, id) => ({
  ...res,
  [id]: {
    ...val,
    ...get(mergedAdCounts, val.id),
  },
}), {})

const setTargetDailyImpressionsAndMemo = ad => {
  const remainedValueByWeight = (value, advertisement, advertisement_set) =>
    round(
      (value - advertisement_set.view_count) *
        (advertisement.weight / advertisement_set.weight) +
        get(advertisement, 'view.count', 0),
    );

  let advertisement_sets = groupBy(ad.advertisements, 'advertisement_set.id');
  advertisement_sets = reduce(
    advertisement_sets,
    (result, value, key) => ({
      ...result,
      [key]: {
        weight: sumBy(value, 'weight'),
        view_count: sumBy(value, v => (v.view && v.view.count) || 0),
      },
    }), {}
  );

  forEach(ad.advertisements, (advertisement, _) => {
    advertisement.target_daily_impressions = remainedValueByWeight(
      advertisement.advertisement_set.target_daily_impressions,
      advertisement,
      advertisement_sets[advertisement.advertisement_set.id]);

    advertisement.memo = remainedValueByWeight(
      advertisement.advertisement_set.memo,
      advertisement,
      advertisement_sets[advertisement.advertisement_set.id]);
  });

  ad.total.target_daily_impressions = reduce(ad.advertisements, (result, value, _) => (
    result + value.target_daily_impressions
  ), 0);

  ad.total.memo = reduce(ad.advertisements, (result, value, _) => (
    result + value.memo
  ), 0);
};

function getAdCount(data, ads) {
  const groupByAdId = groupBy(data, 'advertisement_id')
  const mergedAdCounts = mergeAdCounts(groupByAdId)

  const redefinedAds = reduce(ads, (result, value, key) => {
    value.advertisements = mergeAdCountsToAds(value.advertisements, mergedAdCounts);
    setTargetDailyImpressionsAndMemo(value)

    return {
      ...result,
      [key]: {
        ...value,
      },
    }
  }, {})

  return redefinedAds
}

export const initialState = {
  ad: {},
  loading: {
    expander: false,
  },
}

export default handleActions({
  [FETCH_AD_PENDING]: state => ({
    ...state,
  }),

  [FETCH_AD_SUCCESS]: (state, action) => {
    const { data } = action.payload
    const ad = {}

    return {
      ...state,
      ad: getAd(data, ad),
    }
  },

  [FETCH_AD_FAILURE]: (state, action) => {
    const { data } = action.payload
    return {
      ...state,
      data,
    }
  },

  [FETCH_AD_COUNT_PENDING]: state => ({
    ...state,
  }),

  [FETCH_AD_COUNT_SUCCESS]: (state, action) => {
    const { data } = action.payload
    const { ad } = state

    const redefinedAdCount = getAdCount(data, ad)

    return {
      ...state,
      ad: {
        ...ad,
        ...redefinedAdCount,
      },
    }
  },

  [FETCH_AD_COUNT_FAILURE]: (state, action) => {
    const { data } = action.payload
    return {
      ...state,
      data,
    }
  },

  [UPDATE_AD_FORCED_STOP_PENDING]: state => ({
    ...state,
    loading:{
      expander: true,
    }
  }),

  [UPDATE_AD_FORCED_STOP_SUCCESS]: (state, action) => {
    const { data } = action.payload

    return {
      ...state,
      loading:{
        expander: false,
      },
      ad: {
        ...state.ad,
        [data.campaign.id]: {
          ...state.ad[data.campaign.id],
          advertisements: {
            ...state.ad[data.campaign.id].advertisements,
            [data.id]: {
              ...state.ad[data.campaign.id].advertisements[data.id],
              forced_stop: data.forced_stop,
            },
          },
        },
      },
    }
  },

  [UPDATE_AD_FORCED_STOP_FAILURE]: (state, action) => {
    const { data } = action.payload
    return {
      ...state,
      data,
    }
  },

}, initialState)
