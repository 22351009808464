import React from 'react'
import PropTypes from 'prop-types'
import classesName from 'classnames'
import { Button } from 'reactstrap'
import { makeStyles } from '@material-ui/core'
import toArray from 'lodash/toArray'
import ReactTable from 'react-table'
import withFixedColumns from 'react-table-hoc-fixed-columns'
import 'react-table/react-table.css'
import 'react-table-hoc-fixed-columns/lib/styles.css'
import { withStyles } from '@material-ui/core/styles'

import { templateTitle, TODAY_ADVERTISEMENTS } from '@/shared/constants'

import {
  getTotalColumns,
  stringIncludeFilter,
  addColumnOptions,
  getTypeTableColumns,
} from '../utils'
import TableExpander from '../TableExpander'
import { TodayTableOptionDrawer } from './TodayTableOptionDrawer'
import { useColumnWidth } from './useColumnWidth'
import { saveColumnWidth } from './saveColumnWidth'
import tableStyles from './TodayTable.styles'

const useStyles = makeStyles(() => ({
  optionToolbarWrapper: {
    marginBottom: '1rem',
  },
}))

const ReactTableFixedColumns = withFixedColumns(ReactTable)

const TodayTable = ({
  classes,
  onUpdateAdForcedStop,
  ad,
  isExpanderLoading,
}) => {
  const styles = useStyles()
  const { columnWidth, setColumnWidth } = useColumnWidth()
  const [isDrawerOpen, setDrawerOpen] = React.useState(false)

  const memoColumnsOptions = React.useMemo(() => {
    const options = TODAY_ADVERTISEMENTS.map(columnKey => ({
      key: columnKey,
      label: columnKey,
      checked: true,
    }))
    return options
  }, [])

  const [columnOptions, setColumnOptions] = React.useState(memoColumnsOptions)
  const data = toArray(ad)

  const onOpenDrawer = React.useCallback(() => {
    setDrawerOpen(true)
  }, [])

  const onCloseDrawer = React.useCallback(() => {
    setDrawerOpen(false)
  }, [])

  const handleColumnOptions = React.useCallback((newOptions) => {
    setColumnOptions(newOptions)
  }, [])

  const memoVisibleColumns = React.useMemo(() => {
    const options = columnOptions.reduce((arr, option) => {
      if (!option.checked) {
        return arr
      }
      return [...arr, option.key]
    }, [])
    return options
  }, [columnOptions])

  const getTotalTableColumns = () => {
    const columns = getTotalColumns()

    return {
      headerClassName: classesName(classes.basicHeader),
      columns: addColumnOptions({ classes, columns, columnWidth }),
      fixed: true,
    }
  }

  const getTableColumnsByType = (type) => {
    const columns = getTypeTableColumns({ type, onUpdateAdForcedStop })
    const header = type === 'package' ? `${templateTitle[type]} 전체` : `${templateTitle[type]} 타입`

    return {
      Header: header,
      headerClassName: classesName(classes[`${type}Header`]),
      columns: addColumnOptions({ classes, columns, columnWidth }),
    }
  }

  return (
    <>
      <div className={styles.optionToolbarWrapper}>
        <Button onClick={onOpenDrawer}>
          테이블 설정
        </Button>
      </div>
      <ReactTableFixedColumns
        data={data}
        columns={[
          getTotalTableColumns(),
          ...memoVisibleColumns.map(getTableColumnsByType),
        ]}
        showPagination={false}
        defaultPageSize={data.length}
        filterable
        defaultFilterMethod={stringIncludeFilter}
        className={`${classesName(classes.table)} -highlight`}
        onResizedChange={sizeData => saveColumnWidth({ sizeData, columnWidth, setColumnWidth })}
        SubComponent={v => (
          <TableExpander
            {...v}
            onUpdateAdForcedStop={onUpdateAdForcedStop}
            isExpanderLoading={isExpanderLoading}
          />
        )}
        collapseOnDataChange={false}
      />
      <TodayTableOptionDrawer columnOptions={columnOptions} isOpen={isDrawerOpen} onClose={onCloseDrawer} onSubmit={handleColumnOptions} />
    </>
  )
}

TodayTable.propTypes = {
  classes: PropTypes.object.isRequired,
  ad: PropTypes.object.isRequired,
  onUpdateAdForcedStop: PropTypes.func.isRequired,
  isExpanderLoading: PropTypes.bool.isRequired,
}

export default withStyles(tableStyles)(TodayTable)
