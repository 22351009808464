import { createAction } from 'redux-actions'
import { http, transNewPipelineEventType } from '@/shared/utils'

import { CLASSTING_AD_API_URL, CLASSTING_OPEN_API_URL } from '@/shared/constants'
import moment from 'moment'

export const RESET_CAMPAIGN = 'campaign/RESET_CAMPAIGN'
export const FETCH_CAMPAIGNS_PENDING = 'campaign/CAMPAIGNS_GET_PENDING'
export const FETCH_CAMPAIGNS_SUCCESS = 'campaign/CAMPAIGNS_GET_SUCCESS'
export const FETCH_CAMPAIGNS_FAILURE = 'campaign/CAMPAIGNS_GET_FAILURE'
export const FETCH_CAMPAIGN_PENDING = 'campaign/CAMPAIGN_GET_PENDING'
export const FETCH_CAMPAIGN_SUCCESS = 'campaign/CAMPAIGN_GET_SUCCESS'
export const FETCH_CAMPAIGN_FAILURE = 'campaign/CAMPAIGN_GET_FAILURE'
export const FETCH_AD_REPORTS_PENDING = 'campaign/AD_REPORTS_GET_PENDING'
export const FETCH_AD_REPORTS_SUCCESS = 'campaign/AD_REPORTS_GET_SUCCESS'
export const FETCH_AD_REPORTS_FAILURE = 'campaign/AD_REPORTS_GET_FAILURE'
export const FETCH_AD_SETS_PENDING = 'campaign/AD_SETS_GET_PENDING'
export const FETCH_AD_SETS_SUCCESS = 'campaign/AD_SETS_GET_SUCCESS'
export const FETCH_AD_SETS_FAILURE = 'campaign/AD_SETS_GET_FAILURE'
export const FETCH_REPORT_TOKEN_PENDING = 'campaign/REPORT_TOKEN_GET_PENDING'
export const FETCH_REPORT_TOKEN_SUCCESS = 'campaign/REPORT_TOKEN_GET_SUCCESS'
export const FETCH_REPORT_TOKEN_FAILURE = 'campaign/REPORT_TOKEN_GET_FAILURE'
export const FETCH_REPORT_FILE_PENDING = 'campaign/REPORT_FILE_GET_PENDING'
export const FETCH_REPORT_FILE_SUCCESS = 'campaign/REPORT_FILE_GET_SUCCESS'
export const FETCH_REPORT_FILE_FAILURE = 'campaign/REPORT_FILE_GET_FAILURE'
export const FETCH_ADVERTISEMENT_SUCCESS = 'campaign/AD_ADVERTISEMENT_GET_SUCCESS'
export const FETCH_ADVERTISEMENT_FAILURE = 'campaign/AD_ADVERTISEMENT_GET_FAILURE'

export const resetCampaign = createAction(RESET_CAMPAIGN)
export const fetchCampaignsPending = createAction(FETCH_CAMPAIGNS_PENDING)
export const fetchCampaignsSuccess = createAction(FETCH_CAMPAIGNS_SUCCESS)
export const fetchCampaignsFailure = createAction(FETCH_CAMPAIGNS_FAILURE)
export const fetchCampaignPending = createAction(FETCH_CAMPAIGN_PENDING)
export const fetchCampaignSuccess = createAction(FETCH_CAMPAIGN_SUCCESS)
export const fetchCampaignFailure = createAction(FETCH_CAMPAIGN_FAILURE)
export const fetchAdSetsPending = createAction(FETCH_AD_SETS_PENDING)
export const fetchAdSetsSuccess = createAction(FETCH_AD_SETS_SUCCESS)
export const fetchAdSetsFailure = createAction(FETCH_AD_SETS_FAILURE)
export const fetchAdReportsPending = createAction(FETCH_AD_REPORTS_PENDING)
export const fetchAdReportsSuccess = createAction(FETCH_AD_REPORTS_SUCCESS)
export const fetchAdReportsFailure = createAction(FETCH_AD_REPORTS_FAILURE)
export const fetchReportTokenPending = createAction(FETCH_REPORT_TOKEN_PENDING)
export const fetchReportTokenSuccess = createAction(FETCH_REPORT_TOKEN_SUCCESS)
export const fetchReportTokenFailure = createAction(FETCH_REPORT_TOKEN_FAILURE)
export const fetchReportFilePending = createAction(FETCH_REPORT_FILE_PENDING)
export const fetchReportFileSuccess = createAction(FETCH_REPORT_FILE_SUCCESS)
export const fetchReportFileFailure = createAction(FETCH_REPORT_FILE_FAILURE)
export const fetchAdvertisementSuccess = createAction(FETCH_ADVERTISEMENT_SUCCESS)
export const fetchAdvertisementFailure = createAction(FETCH_ADVERTISEMENT_FAILURE)

export const fetchCampaigns = params => (dispatch) => {
  dispatch(fetchCampaignsPending())
  return http.get(`${CLASSTING_AD_API_URL}/campaigns`, { params })
    .then(res => (
      dispatch(fetchCampaignsSuccess(res))
    ))
    .catch(err => (
      dispatch(fetchCampaignsFailure(err.response))
    ))
}

export const fetchCampaign = campaignId => (dispatch, getState) => {
  dispatch(fetchCampaignPending())
  return http.get(`${CLASSTING_AD_API_URL}/campaigns/${campaignId}`)
    .then(res => (
      dispatch(fetchCampaignSuccess(res))
    ))
    .then(() => getState().campaign.campaignReport)
    .catch(err => (
      dispatch(fetchCampaignFailure(err.response))
    ))
}

export const fetchReportToken = campaignId => (dispatch) => {
  dispatch(fetchReportTokenPending())
  return http.post(`${CLASSTING_AD_API_URL}/campaigns/${campaignId}/access_token`)
    .then(res => (
      dispatch(fetchReportTokenSuccess(res))
    ))
    .catch(err => (
      dispatch(fetchReportTokenFailure(err.response))
    ))
}

function fetchAdsCountFromNewPipeline(params) {
  return http.get(`${CLASSTING_OPEN_API_URL}/ad-service/ad-performance-report`, { params })
}

export const handleAdCountFromNewPipeline = params => async (dispatch) => {
  dispatch(fetchAdReportsPending())


  try {
    const startDate = params.startDate || moment().subtract(7, 'days').format('YYYY-MM-DD')
    const endDate = params.endDate || moment().subtract(1, 'days').format('YYYY-MM-DD')
    const adIds = params.adIds || []
    const campaignIdForLegacy = params.campaignId || undefined
    const res = await fetchAdsCountFromNewPipeline({ startDate, endDate, campaignIdForLegacy })
    const { individualAdPerformance } = res.data


    const filteredAdEvents = individualAdPerformance.filter(item => adIds.includes(Number(item.advertisementId)))

    const returnVal = filteredAdEvents.flatMap(adEvent => adEvent.performance.map(({ eventName, count }) => ({
      advertisement_id: adEvent.advertisementId,
      event_type: transNewPipelineEventType(eventName),
      count,
      date: adEvent.date,
      created_at: adEvent.createdAt,
      updated_at: adEvent.updatedAt,
    })))

    dispatch(fetchAdReportsSuccess({ data: returnVal }))
  } catch (error) {
    dispatch(fetchAdReportsFailure(error.response))
  }
}


export const fetchAdCountCaches = (campaignId, params) => (dispatch) => {
  dispatch(fetchAdReportsPending())

  return http.get(`${CLASSTING_AD_API_URL}/ad_count_caches?campaign_id=${campaignId}&per_page=10000`, { params })
    .then(res => (
      dispatch(fetchAdReportsSuccess(res))
    ))
    .catch(err => (
      dispatch(fetchAdReportsFailure(err.response))
    ))
}

export const fetchCountFromNewPipeline = params => async (dispatch) => {
  handleAdCountFromNewPipeline(params, dispatch)
}

export const fetctAdSets = campaignId => (dispatch) => {
  dispatch(fetchAdSetsPending())
  return http.get(`${CLASSTING_AD_API_URL}/campaigns/${campaignId}/advertisement_sets?per_page=100`)
    .then(res => (
      dispatch(fetchAdSetsSuccess(res))
    ))
    .catch(err => (
      dispatch(fetchAdSetsFailure(err.response))
    ))
}

export const fetchReportExport = campaignId => (dispatch) => {
  dispatch(fetchReportFilePending())
  return http.get(`${CLASSTING_AD_API_URL}/campaigns/${campaignId}/export.xlsx `, { responseType: 'blob' })
    .then(res => (
      dispatch(fetchReportFileSuccess(res))
    ))
    .catch(err => (
      dispatch(fetchReportFileFailure(err.response))
    ))
}

export const fetchAdvertisement = advertisementId => dispatch => http.get(`${CLASSTING_AD_API_URL}/advertisements/${advertisementId}`)
  .then(res => dispatch(fetchAdvertisementSuccess(res)))
  .catch((err) => {
    dispatch(fetchAdvertisementFailure(err.response))
  })
