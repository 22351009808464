import React from 'react'
import { COLUMN_WIDTH_LOCAL_STORAGE_KEY } from '../utils'

export const useColumnWidth = () => {
  const [width, setWidth] = React.useState(JSON.parse(localStorage.getItem(COLUMN_WIDTH_LOCAL_STORAGE_KEY) || '{}'))

  const setColumnWidth = React.useCallback(
    (columnWidth) => {
      const mergedWidth = { ...width, ...columnWidth }
      setWidth(mergedWidth)
      localStorage.setItem(COLUMN_WIDTH_LOCAL_STORAGE_KEY, JSON.stringify(mergedWidth))
    },
  )
  return { columnWidth: width, setColumnWidth }
}
