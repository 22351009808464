/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import PropTypes from 'prop-types'

import Remark from './Remark'
import { TextArea } from '../unit/form'

const makeStyles = () => ({
  remark: ({ spacing }) => css`
    margin-left: ${spacing(2)}rem;
  `,
  root: ({ spacing }) => css`
    margin-top: ${spacing(5)}rem;
  `,
  title: ({ colors: { grey }, font }) => css`
    color: ${grey[900]};
    font-size: ${font.title3.fontSize};
    font-weight: bold;
    line-height: ${font.title3.lineHeight};
  `,
  titleWrap: ({ spacing }) => css`
    display: flex;
    align-items: center;
    margin-bottom: ${spacing(2)}rem;
  `,
})

const InquireContent = ({
  inquireInfo: { inquireContent },
  setInquireInfo,
}) => {
  const styles = makeStyles()

  const handleInquireContentChange = ({ target: { value, name } }) => {
    setInquireInfo(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  return (
    <div css={styles.root}>
      <div css={styles.titleWrap}>
        <h3 css={styles.title}>문의내용 *</h3>
        <Remark customStyles={styles.remark} text="필수 입력사항입니다." />
      </div>
      <TextArea
        name="inquireContent"
        placeholder="문의내용을 입력하세요"
        value={inquireContent}
        onChange={handleInquireContentChange}
      />
    </div>
  )
}

InquireContent.propTypes = {
  inquireInfo: PropTypes.shape({
    inquireContent: PropTypes.string,
  }).isRequired,
  setInquireInfo: PropTypes.func.isRequired,
}

export default InquireContent
