import { createAction } from 'redux-actions'
import { http, transNewPipelineEventType } from '@/shared/utils'
import { CLASSTING_AD_API_URL, CLASSTING_OPEN_API_URL } from '@/shared/constants'

import chunk from 'lodash/chunk'
import map from 'lodash/map'

export const FETCH_AD_PENDING = 'today/FETCH_AD_PENDING'
export const FETCH_AD_SUCCESS = 'today/FETCH_AD_SUCCESS'
export const FETCH_AD_FAILURE = 'today/FETCH_AD_FAILURE'
export const FETCH_AD_COUNT_PENDING = 'today/FETCH_AD_COUNT_PENDING'
export const FETCH_AD_COUNT_SUCCESS = 'today/FETCH_AD_COUNT_SUCCESS'
export const FETCH_AD_COUNT_FAILURE = 'today/FETCH_AD_COUNT_FAILURE'
export const UPDATE_AD_FORCED_STOP_PENDING = 'today/UPDATE_FORCED_STOP_AD_PENDING'
export const UPDATE_AD_FORCED_STOP_SUCCESS = 'today/UPDATE_FORCED_STOP_AD_SUCCESS'
export const UPDATE_AD_FORCED_STOP_FAILURE = 'today/UPDATE_FORCED_STOP_AD_FAILURE'

export const fetchAdPending = createAction(FETCH_AD_PENDING)
export const fetchAdSuccess = createAction(FETCH_AD_SUCCESS)
export const fetchAdFailure = createAction(FETCH_AD_FAILURE)
export const fetchAdCountPending = createAction(FETCH_AD_COUNT_PENDING)
export const fetchAdCountSuccess = createAction(FETCH_AD_COUNT_SUCCESS)
export const fetchAdCountFailure = createAction(FETCH_AD_COUNT_FAILURE)
export const updateAdForcedStopPending = createAction(UPDATE_AD_FORCED_STOP_PENDING)
export const updateAdForcedStopSuccess = createAction(UPDATE_AD_FORCED_STOP_SUCCESS)
export const updateAdForcedStopFailure = createAction(UPDATE_AD_FORCED_STOP_FAILURE)

function fetchAds(params) {
  return http.get(`${CLASSTING_AD_API_URL}/advertisements`, { params })
}

function fetchAdsCountFromNewPipeline(params) {
  return http.get(`${CLASSTING_OPEN_API_URL}/ad-service/ad-performance-report`, { params })
}

function fetchAdCountCaches(params) {
  return http.get(`${CLASSTING_AD_API_URL}/ad_count_caches`, { params })
}

function handleAdCountCachesParams(params, dispatch) {
  dispatch(fetchAdCountPending())

  const { advertisement_ids: adIds } = params
  const dividedAdIds = chunk(adIds, 50)

  try {
    map(dividedAdIds, async (ids) => {
      const res = await fetchAdCountCaches({
        ...params,
        advertisement_ids: ids,
      })

      return dispatch(fetchAdCountSuccess(res))
    })
  } catch (error) {
    dispatch(fetchAdCountFailure(error.response))
  }
}

async function handleAdCountFromNewPipeline(params, dispatch) {
  dispatch(fetchAdCountPending())

  try {
    const res = await fetchAdsCountFromNewPipeline(params)
    const { individualAdPerformance } = res.data

    const returnVal = individualAdPerformance.flatMap(({ advertisementId, performance }) => performance.map(({ eventName, count }) => ({
      advertisement_id: advertisementId,
      event_type: transNewPipelineEventType(eventName),
      count,
    })))
    dispatch(fetchAdCountSuccess({ data: returnVal }))
  } catch (error) {
    dispatch(fetchAdCountFailure(error.response))
  }
}

export const fetchAd = params => (dispatch) => {
  dispatch(fetchAdPending())
  return fetchAds(params)
    .then(res => dispatch(fetchAdSuccess(res)))
    .catch(err => dispatch(fetchAdFailure(err.response)))
}

export const fetchAdCount = params => async dispatch => (
  handleAdCountCachesParams(params, dispatch)
)

export const fetchCountFromNewPipeline = params => async (dispatch) => {
  handleAdCountFromNewPipeline(params, dispatch)
}

export const updateAdForcedStop = data => (dispatch) => {
  dispatch(updateAdForcedStopPending())
  return http.put(`${CLASSTING_AD_API_URL}/advertisements/${data.id}`, { ...data })
    .then(res => dispatch(updateAdForcedStopSuccess(res)))
    .catch(err => dispatch(updateAdForcedStopFailure(err.response)))
}
