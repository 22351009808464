/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { useContext } from 'react'

import { uiStore, openModalAction } from '@landing/contexts/ui'
import MobileNavigation from './MobileNavigation'
import { AppearanceAnimation } from '../../unit/animation'

const makeStyles = () => ({
  line: ({ colors: { grey } }) => css`
    position: relative;
    display: block;
    width: 18px;
    height: 2px;
    color: transparent;
    background-color: ${grey[700]};

    ::before {
      top: -5px;
      left: 0;
    }

    ::after {
      top: 5px;
      left: 0;
    }

    ::before,
    ::after {
      position: absolute;
      content: "";
      display: block;
      width: 18px;
      height: 2px;
      background-color: ${grey[700]};
    }
  `,
  root: css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    width: 2.4rem;
    height: 2.4rem;
  `,
})

const HamburgerMenu = () => {
  const { dispatch } = useContext(uiStore)
  const styles = makeStyles()

  const handleClick = () => {
    dispatch(openModalAction(MobileNavigation))
  }

  return (
    <AppearanceAnimation>
      <button type="button" css={styles.root} onClick={handleClick}>
        <span css={styles.line}>line</span>
      </button>
    </AppearanceAnimation>
  )
}

export default HamburgerMenu
