import { css } from 'styled-components'
import { keys, reduce } from 'lodash'

const sizes = {
  sm: 600,
  md: 960,
  ml: 1280,
  lg: 1600,
  xl: 1920,
}

export default reduce(keys(sizes), (acc, label) => ({
  ...acc,
  [label]: (...args) => css`
    @media (min-width: ${sizes[label]}px) {
      ${css(...args)}
    }
  `,
}), {})
