import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import { map } from 'lodash'

const styles = theme => ({
  selectEmpty: {
    width: '160px',
    height: '40px',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    position: 'absolute',
    bottom: '14px',
    right: '0',
  },
})

class EnhancedSelect extends Component {
  static defaultProps = {
    isHideMenuItem: false,
    children: '',
    menuItem: [],
  }

  state = {
    labelWidth: 0,
  }

  handleChange = () => (event) => {
    const { onChange } = this.props
    onChange(event)
  }

  render() {
    const {
      classes, defaultItem, menuItem, isHideMenuItem, children,
    } = this.props
    const { labelWidth } = this.state
    const { handleChange } = this

    return (
      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          value={defaultItem}
          onChange={handleChange()}
          displayEmpty
          className={classes.selectEmpty}
          input={<OutlinedInput labelWidth={labelWidth} name="value" />}
        >
          {isHideMenuItem
            ? children
            : map(menuItem, item => (
              <MenuItem value={item.value} key={item.value}>
                {item.label}
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
    )
  }
}

EnhancedSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    formControl: PropTypes.string,
    selectEmpty: PropTypes.string,
  }).isRequired,
  defaultItem: PropTypes.string.isRequired,
  menuItem: PropTypes.arrayOf(PropTypes.shape({})),
  isHideMenuItem: PropTypes.bool,
  children: PropTypes.node,
}

export default withStyles(styles)(EnhancedSelect)
