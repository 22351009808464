import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Field from './Field'

const useStyles = makeStyles(() => ({
  item: {
    marginBottom: '16px',

    '&:last-child': {
      marginBottom: '0',
    },
  },
}))

const PreviewForm = ({ fields, changeInputValue }) => {
  const classes = useStyles()

  return (
    <>
      {
        fields.map(({
          id, type, value, size, items, buttonName, placeholder, labelName, caution, rule, maxLimit,
        }) => (
          <div className={classes.item} key={id}>
            <Field
              id={id}
              type={type}
              size={size}
              items={items}
              buttonName={buttonName}
              placeholder={placeholder}
              value={value}
              labelName={labelName}
              caution={caution}
              rule={rule}
              maxLimit={maxLimit}
              changeInputValue={changeInputValue}
            />
          </div>
        ))
      }
    </>
  )
}

PreviewForm.propTypes = {
  fields: PropTypes.array.isRequired,
  changeInputValue: PropTypes.func.isRequired,
}

export default PreviewForm
