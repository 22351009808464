/**
 * 광고 상품 네이밍의 기준이 되는 파일입니다.
 */
export const ADVERTISEMENT_NAME_MAP = Object.freeze({
  PACKAGE: 'package', // 패키지
  POST: 'post', // 게시글
  NOTICE: 'notice', // 학급공지
  BANNER: 'banner', // 클래스탭 배너(pc)
  CLASS_BRIEF: 'class_brief', // 클래스탭
  BENEFIT_BANNER: 'benefit_banner', // 혜택리스트 상단 배너
  PLAY_BANNER: 'play_banner', // 플레이탭
  COMMENT: 'comment', // 댓글
  POST_SLIDE: 'post_slide', // 포스트 슬라이드
  STORE_HOME: 'store_home', // 스토어 홈
  STORE_BOOK: 'store_book', // 스토어 도서
  STORE_STATIONERY: 'store_stationery', // 스토어 문구
  STORE_BABYLIFE: 'store_babylife', // 스토어 아이생활
  NOTIFICATION_TEXT_BANNER: 'notification_text_banner', // 알림탭 텍스트 배너
  NOTIFICATION_IMAGE_BANNER: 'notification_image_banner', // 알림 이미지 배너
  POST_BANNER: 'post_banner', // 포스트 배너
  START_POPUP: 'start_popup', // 시작 팝업
  END_POPUP: 'end_popup', // 종료 팝업
  CLASS_TAB_BANNER: 'class_tab_banner', // 클래스탭 배너 네이티브
  GAM_MY_TAB: 'gam_my_tab', // MY탭
  GAM_CLOSE_POPUP: 'gam_close_popup', // 종료 팝업
  GAM_AI_REWARD: 'gam_ai_reward', // AI 리워드
  GAM_POST_BANNER: 'gam_post_banner', // 포스트 배너
  GAM_POST_DETAIL: 'gam_post_detail', // 포스트 배너 (게시글 상세)
  GAM_CLASSROOM_POST_LIST: 'gam_classroom_post_list', // 포스트 배너 (클래스 게시글 목록)
  GAM_CLASSROOM_POST_DETAIL: 'gam_classroom_post_detail', // 포스트 배너 (클래스 게시글 상세)
  GAM_CLASSROOM_NAVIGATION: 'gam_classroom_navigation', // 클래스룸 네비게이션
  GAM_HOME_TOP_BANNER: 'gam_home_top_banner', // 홈 탭 배너
  GAM_HOME_LEFT_TOP_BANNER1: 'gam_home_left_top_banner1', // 홈 좌측 상단 배너1
  GAM_HOME_LEFT_TOP_BANNER2: 'gam_home_left_top_banner2', // 홈 좌측 상단 배너2
  GAM_HOME_MIDDLE_BANNER: 'gam_home_middle_banner', // 홈 탭 포스트
  GAM_HOME_BOTTOM_BANNER: 'gam_home_bottom_banner', // 홈 하단 배너
  GAM_NOTIFICATION_FIRST_SLOT: 'gam_notification_first_slot', // 알림 탭 상단
  GAM_NOTIFICATION_SECOND_SLOT: 'gam_notification_second_slot', // 알림 탭 하단
  CLASSTALK_TAB_BANNER: 'classtalk_tab_banner', // 클래스톡배너
  SPLASH_BANNER: 'splash_banner', // 스플래시
})
