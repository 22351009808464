import { css } from 'styled-components'
import media from './media'

const menuItem = css`
  display: flex;
  align-items: center;
  width: 52px;
  height: 40px;
  border-radius: 4px;
  ${media.ml`
    padding-left: 14px;
    width: 100%;
  `}
`

export default menuItem
