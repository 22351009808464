import React, { Component } from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { getToCommas } from '@/shared/utils'

const styles = ({
  container: {
    padding: '14px',
  },
  paper: {
    padding: '24px',
    margin: '24px 0 24px 0',
    position: 'relative',
  },
  divider: {
    marginTop: '24px',
    marginBottom: '24px',
  },
  label: {
    color: '#212121',
  },
  subLabel: {
    color: '#757575',
  },
  listLabel: {
    color: '#00c896',
  },
  buttonGroup: {
    paddingTop: '14px',
    paddingBottom: '8px',
  },
})

class PaymentInformation extends Component {
  static defaultProps = {
    methodTypeLabel: '',
  }

  state = {
    bankName: '하나은행',
    bankNumber: 12091000928104,
  }

  render() {
    const { classes, payment, methodTypeLabel } = this.props
    const { bankName, bankNumber } = this.state

    return (
      <>
        <Typography variant="title" color="default">
          결제 정보
        </Typography>
        <Typography variant="subtitle1" color="primary">
          {methodTypeLabel}
        </Typography>
        <Typography variant="subtitle1" gutterBottom className={classes.label}>
          입금 금액
        </Typography>
        <Typography variant="subtitle1" gutterBottom className={classes.subLabel}>
          {getToCommas(payment.billing_amount) || 0}
          원 (VAT 10% 포함)
        </Typography>
        <Typography variant="subtitle1" className={classes.label}>
          은행
        </Typography>
        <Typography variant="subtitle1" gutterBottom className={classes.subLabel}>
          {!isEmpty(payment.vbank_name) ? payment.vbank_name : bankName}
        </Typography>
        <Typography variant="subtitle1" className={classes.label}>
          계좌 번호
        </Typography>
        <Typography variant="subtitle1" gutterBottom className={classes.subLabel}>
          {!isEmpty(payment.vbank_number) ? payment.vbank_number : bankNumber}
        </Typography>
        {!isEmpty(payment.vbank_expire_at)
          && (
          <>
            <Typography variant="subtitle1" className={classes.label}>
              입금 기한
            </Typography>
            <Typography variant="subtitle1" gutterBottom className={classes.subLabel}>
              {moment(payment && payment.vbank_expire_at).format('YYYY-MM-DD')}
            </Typography>
          </>
          )
        }
        <Typography variant="subtitle1" className={classes.label}>
          계좌주
        </Typography>
        <Typography variant="subtitle1" gutterBottom className={classes.subLabel}>
          (주)클래스팅
        </Typography>
      </>
    )
  }
}

PaymentInformation.propTypes = {
  classes: PropTypes.object.isRequired,
  payment: PropTypes.object.isRequired,
  methodTypeLabel: PropTypes.string,
}

export default compose(
  withStyles(styles),
)(PaymentInformation)
