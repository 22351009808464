import { keyframes } from '@emotion/react'

export const easeOutQuad = 'cubic-bezier(0.250, 0.460, 0.450, 0.940 )'

export const fadeInSlide = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  50% {
    transform: translateY(0px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`
