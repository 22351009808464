import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Form, Input } from 'reactstrap'
import Button from '@material-ui/core/Button'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Redirect } from 'react-router-dom'
import { withTracker } from '@/shared/enhancers'
import { NavigationBar, Linear } from '@/shared/components'
import { getItem } from '@/shared/utils'
import { adminSignIn } from '../actions'

const styles = ({
  container: {
    maxWidth: '446px',
    margin: '0 auto',
    padding: '64px 0 0 0',
  },
  title: {
    margin: '24px 0 24px 0',
    textAlign: 'center',
  },
  field: {
    margin: '4px 0 4px 0',
  },
  fieldError: {
    margin: '4px 0 4px 0',
    border: '1px solid #e85d7b',
  },
  signInButton: {
    color: '#ffffff',
    width: '100%',
    margin: '24px 0 24px 0',
  },
  failure: {
    color: '#e85d7b',
    fontSize: '14px',
    margin: '14px 0 14px 0',
    textAlign: 'center',
  },
})

const mapStateToProps = state => ({
  pending: state.signIn.pending,
  status: state.signIn.status,
  failure: state.signIn.failure,
  success: state.signIn.success,
})

const mapDispatchToProps = dispatch => ({
  onAdminSignIn: values => dispatch(adminSignIn(values)),
})

class AdminSignInPage extends Component {
  componentDidMount() {
    const { history } = this.props
    if (getItem('token')) {
      history.push('/campaign')
    }
  }

  handleSaveIdChange = name => (event) => {
    this.setState({ [name]: event.target.checked })
  }

  render() {
    const {
      classes, onAdminSignIn, failure, success, pending,
    } = this.props

    return (
      <div>
        {pending && <Linear />}
        <NavigationBar />
        <div className={classes.container}>
          <h4 className={classes.title}>관리자 로그인</h4>
          {failure && <div className={classes.failure}>아이디 또는 비밀번호를 잘못 입력하셨습니다.</div>}
          {success && <Redirect to="/today" />}
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            onSubmit={(values, { setSubmitting }) => {
              onAdminSignIn(values)
                .then(() => setSubmitting(false))
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email()
                .required('Required'),
              password: Yup.string()
                .min('6')
                .max('12')
                .required('Required'),
            })}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                isSubmitting,
                handleSubmit,
                handleChange,
                handleBlur,
              } = props

              return (
                <Form onSubmit={handleSubmit}>
                  <Input
                    type="text"
                    name="email"
                    className={
                      errors.email && touched.email ? `${classes.field_error} ` : `${classes.field}`
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="아이디"
                    value={values.email}
                  />
                  <Input
                    type="password"
                    name="password"
                    className={
                      errors.password && touched.password ? `${classes.field_error}` : `${classes.field}`
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="비밀번호"
                    value={values.pasesword}
                  />
                  <Button
                    className={classes.signInButton}
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    관리자 로그인
                  </Button>
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
    )
  }
}

AdminSignInPage.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string,
    title: PropTypes.string,
    failure: PropTypes.string,
    field: PropTypes.string,
    field_error: PropTypes.string,
    signInButton: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({}).isRequired,
  onAdminSignIn: PropTypes.func.isRequired,
  failure: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  pending: PropTypes.bool.isRequired,
}

export default compose(
  withTracker,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(AdminSignInPage)
