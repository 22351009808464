import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

import isEmpty from 'lodash/isEmpty'
import { getToCommas } from '@/shared/utils'
import {
  TableHeader,
  EnhancedPaper,
  CircularUnderLoad,
} from '@/shared/components'
import { getAdSetsSelector } from '../selectors'


import {
  campaignReportRows,
  campaignReportMemoRows,
} from '../containers/tableData'

const CampaginReport = ({
  campaignReport,
  classes,
  adSets,
  pending,
}) => (
  pending
    ? (
      <EnhancedPaper styles={classes.progressContent}>
        <CircularUnderLoad styles={classes.progressPosition} />
      </EnhancedPaper>
    ) : (
      <EnhancedPaper>
        <Typography variant="h5" gutterBottom>
          캠페인 보고서
        </Typography>

        <TableHeader rows={campaignReportRows}>
          {isEmpty(adSets)
            ? (
              <TableRow>
                <TableCell component="td" scope="row" className={classes.tableCell} colSpan={10}>
                  -
                </TableCell>
              </TableRow>
            )
            : (
              <TableRow>
                <TableCell component="td" scope="row" className={classes.tableCell}>
                  {(campaignReport.advertiser && campaignReport.advertiser.name) || '-'}
                </TableCell>
                <TableCell component="td" scope="row" className={classes.tableCell}>
                  {campaignReport.title || '-'}
                </TableCell>
                <TableCell component="td" scope="row" className={classes.tableCell}>
                  {(campaignReport.budget && getToCommas(String(campaignReport.budget))) || '-'}
                </TableCell>
              </TableRow>
            )
          }
        </TableHeader>
        {
           Array.isArray(campaignReport.memo) && !!campaignReport.memo.length && (
           <div className={classes.campaignDetailReportWrap}>
             <TableHeader rows={campaignReportMemoRows}>
               {
                campaignReport.memo.map(({
                  product, billingMethod, period, role, grade, region, impressions, budget, detail,
                }) => (
                  <TableRow>
                    <TableCell
                      component="td"
                      scope="row"
                      className={classes.tableCell}
                    >
                      {product}
                    </TableCell>
                    <TableCell
                      component="td"
                      scope="row"
                      className={classes.tableCell}
                    >
                      {billingMethod}
                    </TableCell>
                    <TableCell
                      component="td"
                      scope="row"
                      className={classes.tableCell}
                    >
                      {period}
                    </TableCell>
                    <TableCell
                      component="td"
                      scope="row"
                      className={classes.tableCell}
                    >
                      {role}
                    </TableCell>
                    <TableCell
                      component="td"
                      scope="row"
                      className={classes.tableCell}
                    >
                      {grade}
                    </TableCell>
                    <TableCell
                      component="td"
                      scope="row"
                      className={classes.tableCell}
                    >
                      {region}
                    </TableCell>
                    <TableCell
                      component="td"
                      scope="row"
                      className={classes.tableCell}
                    >
                      {impressions}
                    </TableCell>
                    <TableCell
                      component="td"
                      scope="row"
                      className={classes.tableCell}
                    >
                      {budget ? getToCommas(String(budget)) : ''}
                    </TableCell>
                    <TableCell
                      component="td"
                      scope="row"
                      className={classes.tableCell}
                    >
                      {detail}
                    </TableCell>
                  </TableRow>
                ))
              }
             </TableHeader>
           </div>
           )
          }

      </EnhancedPaper>
    )
)

CampaginReport.propTypes = {
  classes: PropTypes.any.isRequired,
  pending: PropTypes.bool.isRequired,
  campaignReport: PropTypes.shape({
    title: PropTypes.string,
    budget: PropTypes.number,
    advertiser: PropTypes.shape({
      name: PropTypes.string,
    }),
    memo: PropTypes.arrayOf(
      PropTypes.shape({
        product: PropTypes.string,
        billingMethod: PropTypes.string,
        period: PropTypes.string,
        role: PropTypes.string,
        grade: PropTypes.string,
        impressions: PropTypes.string,
        detail: PropTypes.string,
      }),
    ),
  }).isRequired,
  adSets: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default connect(
  state => ({
    campaignReport: state.campaign.campaignReport,
    adSets: getAdSetsSelector(state),
    pending: state.campaign.pending,
  }),
  {},
)(CampaginReport)
