import { handleActions } from 'redux-actions'
import {
  RESET_USER,
  USER_UPDATE_PASSWORD_POST_PENDING,
  USER_UPDATE_PASSWORD_POST_SUCCESS,
  USER_UPDATE_PASSWORD_POST_FAILURE,
  USER_GET_PENDING,
  USER_GET_SUCCESS,
  USER_GET_FAILURE,
  UPDATE_USER_PUT_PENDING,
  UPDATE_USER_PUT_SUCCESS,
  UPDATE_USER_PUT_FAILURE,
} from './actions'

export const initialState = {
  pending: false,
  success: false,
  failure: false,
  status: 200,
  userData: {},
}

export default handleActions({
  [RESET_USER]: state => ({
    ...state,
    pending: false,
    success: false,
    failure: false,
    status: 200,
    userData: {},
  }),

  [USER_UPDATE_PASSWORD_POST_PENDING]: state => ({
    ...state,
    pending: true,
  }),

  [USER_UPDATE_PASSWORD_POST_SUCCESS]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      pending: false,
      success: true,
      failure: false,
      data,
      status,
    }
  },

  [USER_UPDATE_PASSWORD_POST_FAILURE]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      pending: false,
      success: false,
      failure: true,
      data,
      status,
    }
  },

  [USER_GET_PENDING]: state => ({
    ...state,
    pending: true,
  }
  ),

  [USER_GET_SUCCESS]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      pending: false,
      success: true,
      failure: false,
      userData: data,
      status,
    }
  },

  [USER_GET_FAILURE]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      pending: false,
      success: false,
      failure: true,
      data,
      status,
    }
  },

  [UPDATE_USER_PUT_PENDING]: state => ({
    ...state,
    pending: true,
  }
  ),

  [UPDATE_USER_PUT_SUCCESS]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      pending: false,
      success: true,
      userData: data,
      status,
    }
  },


  [UPDATE_USER_PUT_FAILURE]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      pending: false,
      failure: true,
      data,
      status,
    }
  },
}, initialState)
