import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import { Skeleton, Text, Avatar } from '../atoms'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '360px',
    padding: '16px',
    backgroundColor: '#fafafa',
  },
  profile: {
    marginRight: '8px',
    flexShrink: '0',
  },
  content: ({ hasImage }) => ({
    width: `${hasImage ? '174px' : '100%'}`,
    marginRight: '8px',
  }),
  title: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: '4px',
  },
  titleText: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  sponsored: {
    color: '#9e9e9e',
    marginLeft: '4px',
  },
  description: {
    marginBottom: '4px',
  },
  descriptionText: {
    wordBreak: 'break-all',
    whiteSpace: 'break-spaces',
  },
  actionLabel: {
    fontSize: '12px',
    lineHeight: '16px',
    color: '#9e9e9e',
  },
  adImage: {
    width: '110px',
    borderRadius: '4px',
    overflow: 'hidden',

    '& img': {
      display: 'block',
      width: '100%',
      color: 'transparent',
    },
  },
}))

const CommentAd = ({
  title,
  advertiserProfileImage,
  actionLabel,
  description,
  imageUrl,
  isImageType,
}) => {
  const classes = useStyles({ hasImage: isImageType })

  return (
    <div className={classes.root}>
      <Avatar className={classes.profile} src={advertiserProfileImage} size="sm" alt="광고주 이미지" />
      <div className={classes.content}>
        <div className={classes.title}>
          <Skeleton isShow={!!title}>
            <Text bold className={classes.titleText}>{title}</Text>
          </Skeleton>
          <Text className={classes.sponsored} size="xs" color="lightGray2">Sponsored</Text>
        </div>
        <div className={classes.description}>
          <Skeleton isShow={!!description}>
            <Text className={classes.descriptionText}>{description}</Text>
          </Skeleton>
        </div>
        <Text size="sm" color="lightGray2">{`${actionLabel} >`}</Text>
      </div>
      {
        isImageType
        && (
        <figure className={classes.adImage}>
          {imageUrl ? <img src={imageUrl} alt="광고 이미지" /> : null}
        </figure>
        )
      }
    </div>
  )
}

CommentAd.defaultProps = {
  imageUrl: '',
}

CommentAd.propTypes = {
  title: PropTypes.string.isRequired,
  advertiserProfileImage: PropTypes.string.isRequired,
  actionLabel: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  isImageType: PropTypes.bool.isRequired,
}

export default CommentAd
