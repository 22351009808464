import * as Yup from 'yup'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Form, Input } from 'reactstrap'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import DialogContentText from '@material-ui/core/DialogContentText'
import { isEmpty, isNil, has } from 'lodash'
import { Formik } from 'formik'
import { Link } from 'react-router-dom'
import { withTracker } from '@/shared/enhancers'
import { NavigationBar, Linear, EnhancedDialog } from '@/shared/components'
import { getItem } from '@/shared/utils'
import { signIn } from '../actions'

const styles = ({
  container: {
    maxWidth: '446px',
    margin: '0 auto',
    padding: '64px 0 0 0',
  },
  title: {
    margin: '24px 0 24px 0',
    textAlign: 'center',
  },
  field: {
    margin: '4px 0 4px 0',
  },
  fieldError: {
    margin: '4px 0 4px 0',
    border: '1px solid #e85d7b',
  },
  actions: {
    margin: '12px 0 14px 0',
    fontSize: '14px',
    position: 'relative',
    height: '18px',
  },
  saveCheckbox: {
    padding: 0,
  },
  saveId: {
    marginLeft: '4px',
    padding: 0,
  },
  findPassword: {
    position: 'absolute',
    right: 0,
    color: '#000000',
    '&:hover': {
      color: '#00c896',
      textDecoration: 'none',
    },
  },
  signInButton: {
    color: '#ffffff',
    width: '100%',
  },
  signUpButton: {
    width: '100%',
    background: '#5aaafa',
    color: '#ffffff',
  },
  link: {
    color: '#ffffff',
    textDecoration: 'none',
    outline: 'none',
    '&:hover': {
      color: '#ffffff',
      outline: 'none',
      textDecoration: 'none',
    },
  },
  support: {
    textAlign: 'center',
    margin: '24px 0 14px 0',
    fontSize: '14px',
  },
  failure: {
    color: '#e85d7b',
    fontSize: '14px',
    margin: '14px 0 14px 0',
    textAlign: 'center',
  },
})

const mapStateToProps = state => ({
  pending: state.signIn.pending,
  status: state.signIn.status,
})

const mapDispatchToProps = dispatch => ({
  onSignIn: values => dispatch(signIn(values)),
})

class SignInPage extends Component {
  state = {
    isSaveId: false,
    savedId: '',
    isOpenModal: false,
  }

  componentWillMount() {
    const saveId = localStorage.getItem('saveId')
    if (saveId) {
      this.setState({ savedId: saveId })
    }
  }

  componentDidMount() {
    const { history } = this.props
    if (getItem('token')) {
      history.push('/campaign')
    }
  }

  handleSignUpClick = () => {
    const { history } = this.props
    return history.push({
      pathname: '/policy',
    })
  }

  handleSaveIdChange = name => (event) => {
    this.setState({ [name]: event.target.checked })
  }

  // getSignInTitle = () => {
  // const { location } = this.props
  // const { type } = queryString.parse(location.search)

  // return '로그인'
  // }

  render() {
    const {
      classes, onSignIn, status, history, pending,
    } = this.props
    const { handleSignUpClick, handleSaveIdChange } = this
    const { isSaveId, savedId, isOpenModal } = this.state

    return (
      <div>
        {pending && <Linear />}
        <NavigationBar />
        <div className={classes.container}>
          <EnhancedDialog
            isOpen={isOpenModal}
            isCloseButton={false}
            modalTitle="이메일 인증을 완료하지 않았습니다."
            handleOpen={() => {
              this.setState({ isOpenModal: false })
            }}
          >
            <DialogContentText>
              이메일 인증 완료 후 다시 로그인해주세요.
            </DialogContentText>
          </EnhancedDialog>
          <h4 className={classes.title}>로그인</h4>
          {status !== 200 && <div className={classes.failure}>아이디 또는 비밀번호를 잘못 입력하셨습니다.</div>}
          <Formik
            initialValues={isEmpty(savedId) ? {
              email: '',
              password: '',
            } : {
              email: savedId,
              password: '',
            }}
            onSubmit={(values, { setSubmitting }) => {
              if (isSaveId) {
                localStorage.setItem('saveId', values.email)
              }
              onSignIn(values)
                .then((data) => {
                  setSubmitting(false)
                  if (has(data, 'confirmed_at') && isNil(data.confirmed_at)) {
                    return this.setState({ isOpenModal: true })
                  }

                  return history.replace('/campaign')
                })
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email()
                .required('Required'),
              password: Yup.string()
                .min('6')
                .max('12')
                .required('Required'),
            })}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                isSubmitting,
                handleSubmit,
                handleChange,
                handleBlur,
              } = props

              return (
                <Form onSubmit={handleSubmit}>
                  <Input
                    type="text"
                    name="email"
                    className={
                      errors.email && touched.email ? `${classes.fieldError} ` : `${classes.field}`
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="아이디(이메일 주소)"
                    value={values.email}
                  />
                  <Input
                    type="password"
                    name="password"
                    className={
                      errors.password && touched.password ? `${classes.fieldError}` : `${classes.field}`
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="비밀번호"
                    value={values.pasesword}
                  />
                  <div className={classes.actions}>
                    <Checkbox
                      className={classes.saveCheckbox}
                      checked={isSaveId}
                      onChange={handleSaveIdChange('isSaveId')}
                      color="primary"
                    />
                    <span className={classes.saveId}>아이디 저장</span>
                    <Link to="/find_password" className={classes.findPassword}>비밀번호 찾기</Link>
                  </div>
                  <Button
                    className={classes.signInButton}
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    로그인
                  </Button>
                  <div className={classes.support}>
                    아이디가 없으신가요?
                    <br />
                    처음 이용하시는 분은 회원가입을 통해 서비스를 이용하실 수 있습니다.
                  </div>
                  <Button
                    className={classes.signUpButton}
                    variant="contained"
                    color="secondary"
                    onClick={handleSignUpClick}
                  >
                    회원가입
                  </Button>
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
    )
  }
}

SignInPage.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string,
    title: PropTypes.string,
    failure: PropTypes.string,
    fieldError: PropTypes.string,
    field: PropTypes.string,
    actions: PropTypes.string,
    saveCheckbox: PropTypes.string,
    saveId: PropTypes.string,
    findPassword: PropTypes.string,
    signInButton: PropTypes.string,
    support: PropTypes.string,
    signUpButton: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({}).isRequired,
  onSignIn: PropTypes.func.isRequired,
  status: PropTypes.number.isRequired,
  pending: PropTypes.bool.isRequired,
}

export default compose(
  withTracker,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(SignInPage)
