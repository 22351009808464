import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

import { useDisclosure, useLockScroll } from '../../hooks'

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 10000,
  },
  background: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
  drawer: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    maxWidth: '30rem',
    width: '100%',
    height: '100%',
    backgroundColor: '#fff',
    borderRadius: '5px 0 0 5px',
    zIndex: 1,
  },
  drawerContent: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  closeButton: {
    position: 'absolute',
    top: '2rem',
    right: '2rem',
    width: '1rem',
    height: '1rem',
    transform: 'translate(-6px, -30px)',
    cursor: 'pointer',
  },
}))

const Drawer = ({
  children,
  className,
  isOpen = false,
  onClose = () => null,
  portalElement,
  ...rests
}) => {
  const styles = useStyles()
  const wrapperRef = React.useRef(null)
  const { visible, onSetVisible } = useDisclosure(isOpen)
  useLockScroll(visible)

  React.useEffect(() => {
    onSetVisible(isOpen)
  }, [isOpen])

  const onCloseByKeydown = (event) => {
    if (wrapperRef.current) {
      if (document.contains(wrapperRef.current) && event.key === 'Escape') {
        onClose()
      }
    }
  }

  React.useEffect(() => {
    document.addEventListener('keydown', onCloseByKeydown)
    return () => {
      document.removeEventListener('keydown', onCloseByKeydown)
    }
  }, [])

  if (!visible) {
    return null
  }

  return ReactDOM.createPortal(
    <div
      {...rests}
      ref={wrapperRef}
      data-testid="Classting__Drawer"
      className={classnames(styles.wrapper, className)}
    >
      <div
        className={styles.background}
        data-testid="Classting__Drawer__Background"
        onClick={onClose}
      />
      <div
        aria-label="Drawer"
        aria-modal="true"
        className={styles.drawer}
        role="dialog"
      >
        <div className={styles.drawerContent}>
          {children}
        </div>
      </div>
    </div>,
    portalElement || document.body,
  )
}

Drawer.propTypes = {
  children: PropTypes.element.isRequired,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  portalElement: PropTypes.element,
}

export { Drawer }
export default Drawer
