/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { useContext, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { uiStore, setHeaderHeightAction, VIEW_SIZE_PC } from '@landing/contexts/ui'
import Logo from '../Logo'
import BarMenu from './BarMenu'
import HamburgerMenu from './HamburgerMenu'
import { AppearanceAnimation } from '../../unit/animation'

const makeStyles = ({ isScrolled }) => ({
  menuWrap: () => css`
    margin-left: auto;
  `,
  root: ({ colors: { grey }, mq }) => css`
    ${mq({
    alignItems: 'center',
    background: '#fff',
    borderBottom: isScrolled ? `1px solid ${grey[200]}` : 'none',
    display: 'flex',
    height: ['6.4rem', '7.2rem'],
    justifyContent: 'center',
    position: 'sticky',
    top: '0',
    zIndex: 100,
  })}
  `,
  wrap: ({ mq, spacing }) => css`
    ${mq({
    alignItems: 'center',
    display: 'flex',
    maxWidth: ['auto', '102.4rem'],
    padding: [`0 ${spacing(1.875)}rem`, `0 ${spacing(4)}rem`],
    width: '100%',
  })}
  `,
})

const Header = ({ isScrolled }) => {
  const styles = makeStyles({ isScrolled })
  const {
    dispatch,
    state: { viewSize },
  } = useContext(uiStore)
  const headerEl = useRef(null)

  useEffect(() => {
    const { height } = headerEl.current.getBoundingClientRect()
    dispatch(setHeaderHeightAction(height))
  }, [])

  return (
    <header css={styles.root} ref={headerEl}>
      <div css={styles.wrap}>
        <AppearanceAnimation>
          <a href="/">
            <Logo />
          </a>
        </AppearanceAnimation>
        <div css={styles.menuWrap}>
          {viewSize === VIEW_SIZE_PC ? <BarMenu /> : <HamburgerMenu />}
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  isScrolled: PropTypes.bool.isRequired,
}

export default Header
