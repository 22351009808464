/** @jsx jsx */
import { css, jsx } from '@emotion/react'

const makeStyles = () => ({
  root: ({ colors: { grey }, mq }) => css`
    ${mq({
    borderLeft: `1px dashed ${grey[400]}`,
    color: 'transparent',
    height: ['calc(100% - 1.2rem)', 'calc(100% - 9rem)'],
    left: ['4.2rem', '6.1rem'],
    position: 'absolute',
    textIndent: '-99999px',
    top: ['0.6rem', '1.1rem'],
  })}
  `,
})

const Line = () => {
  const styles = makeStyles()

  return <span css={styles.root}>line</span>
}

export default Line
