import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import {
  Text, Label, Input, Caution, TextLimitIndicator, TextArea,
} from '../atoms'
import { FileInput, ColorPicker, Dropdown } from '../molecules'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    width: '96px',
    marginRight: '16px',
    paddingTop: '8px',
  },
  field: {
    flex: '1',
  },
  additionalInfo: {
    marginTop: '8px',
  },
  rule: {
    backgroundColor: '#f5f5f5',
    borderRadius: '4px',
    padding: '16px',
    boxSizing: 'border-box',
  },
}))

const getInputByType = ({
  id,
  type,
  value,
  placeholder,
  buttonName,
  size,
  changeValue,
  items,
}) => {
  const handleValueChange = ({ target: { value: newValue } }) => {
    changeValue(newValue)
  }

  switch (type) {
    case 'text':
      return (
        <Input
          id={id}
          value={value}
          placeholder={placeholder}
          onChange={handleValueChange}
        />
      )
    case 'textArea':
      return (
        <TextArea
          size={size || undefined}
          id={id}
          value={value}
          placeholder={placeholder}
          onChange={handleValueChange}
        />
      )
    case 'file':
      return (
        <FileInput
          id={id}
          value={value.name}
          buttonName={buttonName}
          placeholder={placeholder}
          changeValue={changeValue}
        />
      )
    case 'colorPicker':
      return (
        <ColorPicker
          id={id}
          value={value}
          placeholder={placeholder}
          changeValue={changeValue}
        />
      )
    case 'dropdown':
      return (
        <Dropdown items={items} value={value} changeValue={changeValue} />
      )
    case 'plainText':
      return <Text bold>{value}</Text>
    default: return null
  }
}

const Field = ({
  id,
  value,
  type,
  placeholder,
  buttonName,
  items,
  size,
  labelName,
  caution,
  rule,
  maxLimit,
  changeInputValue,
}) => {
  const classes = useStyles()
  const changeValue = (newValue) => {
    if (!!maxLimit && newValue.length > maxLimit) return
    changeInputValue(id, newValue)
  }

  return (
    <div className={classes.root}>
      <div className={classes.label}>
        <Label htmlFor={id}>
          <Text color="darkGray" bold>{labelName}</Text>
        </Label>
        {!!maxLimit && <TextLimitIndicator curLen={value.length} maxLen={maxLimit} />}
      </div>
      <div className={classes.field}>
        {getInputByType({
          id,
          type,
          changeValue,
          value,
          placeholder,
          buttonName,
          items,
          size,
        })}
        {
          caution && (
            <Caution component="p" className={classes.additionalInfo} text={caution} />
          )
        }
        {
          rule && (
            <Text component="p" className={cn(classes.additionalInfo, classes.rule)}>{rule}</Text>
          )
        }
      </div>
    </div>

  )
}

Field.defaultProps = {
  caution: '',
  rule: '',
  maxLimit: 0,
  placeholder: '',
  buttonName: '파일첨부',
  items: [],
  size: '',
}

Field.propTypes = {
  caution: PropTypes.string,
  labelName: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  placeholder: PropTypes.string,
  buttonName: PropTypes.string,
  items: PropTypes.array,
  size: PropTypes.string,
  id: PropTypes.string.isRequired,
  rule: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  maxLimit: PropTypes.number,
  changeInputValue: PropTypes.func.isRequired,
}

export default Field
