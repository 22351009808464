import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import {
  Row, Col, Form, FormGroup, Label, Input,
} from 'reactstrap'
import Button from '@material-ui/core/Button'
import { isEmpty, map } from 'lodash'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import queryString from 'query-string'
import Dropzone from 'react-dropzone'
import { withTracker } from '@/shared/enhancers'
import { CircularUnderLoad, Layout, Stepper } from '@/shared/components'
import emptyLogo from '@/shared/assets/ic-empty-logo.svg'
import { setAdvertiser, fetchAdvertisers } from '../../advertiser/actions'
import { setCampaigns, updateCampaigns } from '../actions'
import { fetchCampaign, resetCampaign } from '../../campaign/actions'

const styles = ({
  container: {
    maxWidth: '946px',
    padding: '24px',
  },
  pageTitle: {
    marginBottom: '24px',
  },
  field: {
    margin: '4px 0 4px 0',
  },
  text: {
    color: '#757575',
  },
  fieldError: {
    margin: '4px 0 4px 0',
    border: '1px solid #e85d7b',
  },
  invalidFeedback: {
    color: '#e85d7b',
    fontSize: '12px',
  },
  actions: {
    margin: '12px 0 14px 0',
    fontSize: '14px',
    position: 'relative',
    height: '18px',
  },
  submitButton: {
    width: '18%',
    float: 'right',
    marginRight: '14px',
  },
  outlined: {
    width: '100%',
    color: '#00c896',
    marginBottom: '14px',
  },
  failure: {
    color: '#e85d7b',
    fontSize: '14px',
    margin: '14px 0 14px 0',
    textAlign: 'center',
  },
  emptyProfile: {
    background: '#f5f5f5',
    width: '236px',
    height: '236px',
    display: 'inline-block',
    color: 'rgba(0, 0, 0, 0.26)',
    padding: '14px',
    textAlign: 'center',
    fontSize: '14px',
  },
  profile: {
    width: '236px',
    height: '236px',
    display: 'inlin-block',
  },
  uploadContainer: {
    display: 'flex',
    marginTop: '22px',
  },
  uploadButton: {
    marginBottom: '14px',
  },
  uploadButtonContainer: {
    paddingLeft: '14px',
  },
  select: {
    width: '160px',
    height: '40px',
    marginBottom: '14px',
  },
  nextButton: {
    color: '#ffffff',
    width: '18%',
    float: 'right',
  },
  paper: {
    padding: '36px',
    margin: '24px 0 24px 0',
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  institueField: {
    borderRadius: '4px',
    border: 'solid 1px #dbdbdb',
    padding: '24px',
    margin: '14px',
  },
  institueLabel: {
    fontSize: '14px',
    color: '#757575',
    marginBottom: '14px',
  },
})

const mapStateToProps = state => ({
  pending: state.advertiser.pending,
  status: state.advertiser.status,
  advertisers: state.advertiser.advertisers,
  advertiser: state.advertiser.advertiserData,
  campaign: state.campaign.campaignReport,
})

const mapDispatchToProps = dispatch => ({
  onAdvertiserSubmit: values => dispatch(setAdvertiser(values)),
  onCampaign: campaignId => dispatch(fetchCampaign(campaignId)),
  onCampaignsSubmit: values => dispatch(setCampaigns(values)),
  onCampaignsUpdate: data => dispatch(updateCampaigns(data)),
  onAdvertisersMount: () => dispatch(fetchAdvertisers()),
  onCampaignReset: () => dispatch(resetCampaign()),
})

class CampaignFormPage extends Component {
  state = {
    loading: false,
    files: null,
    packageType: {
      region: '우리 동네 지역 광고',
      impression: '노출 보장형 광고',
    },
    formType: 'select',
    page: 1,
    perPage: 1000,
    sort: '+name',
    selectAdvertiserId: 0,
  }

  componentDidMount() {
    const { page, perPage, sort } = this.state
    const {
      onAdvertisersMount, onCampaign, onCampaignReset, history,
    } = this.props
    const { getCampaignId } = this

    /* eslint-disable no-alert */
    this.unblock = history.block(() => {
      if (window.confirm('생성 중인 해당 캠페인이 저장되지 않습니다. 그래도 나가시겠습니까?')) {
        return true
      }
      return false
    })
    /* eslint-disable */

    onCampaignReset()
    this.setState({ loading: true })
    Promise.resolve()
      .then(() => {
        if (!isEmpty(getCampaignId())) {
          return onCampaign(getCampaignId())
            .then(campaign => this.setState({ selectAdvertiserId: campaign.advertiser && campaign.advertiser.id }))
        }
        return true
      })
      .then(() => onAdvertisersMount(page, perPage, sort))
      .then(advertisers => {
        if (!isEmpty(advertisers)) {
          return true
        }

        return this.setState({ formType: 'append' })
      })
    .then(() => this.setState({ loading: false }))
  }

  componentWillUnmount() {
    this.unblock()
 }

  getAdFormType = () => {
    const { location } = this.props
    return queryString.parse(location.search).form_type || 'create'
  }

  getPreviewImage = (file, profileImage) => {
    const { classes } = this.props

    if (isEmpty(file) && isEmpty(profileImage)) {
      return (
        <div className={classes.emptyProfile}>
          <img src={emptyLogo} style={{ display: 'block', margin: '44px auto 8px' }} alt="empty-logo" />
          로고 이미지를 업로드 해주세요
        </div>
      )
    }

    if (!isEmpty(file)) {
      return <img className={classes.profile} src={file} alt="profile_image" />
    }

    return <img className={classes.profile} src={profileImage} alt="profile_image" />
  }

  handleTypeChange = event => {
    this.setState({ formType: event.target.value })
  }

  onAdvertiserChange = (setFieldValue) => (event) => {
    this.setState({ selectAdvertiserId: event.target.value })
    setFieldValue('advertiser_id', event.target.value)
  }

  getAdPackageType = () => {
    const { location, campaign } = this.props
    const packageType = campaign.ad_package && campaign.ad_package.package_type
    return queryString.parse(location.search).ad_package_type || packageType
  }

  getCampaignId = () => {
    const { location } = this.props
    return queryString.parse(location.search).campaign_id
  }

  handleNextButtonClick = () => {
    if (!window.confirm('적용버튼을 누르지 않으면 저장되지 않습니다. 다음단계로 이동하시겠습니까?')) {
      return true
    }

    this.unblock()
    const { history } = this.props
    const { getCampaignId, getAdPackageType } = this

    const params = {
      campaign_id: getCampaignId(),
      ad_package_type: getAdPackageType(),
      form_type: 'update',
    }

   return history.push({
      pathname: '/adset_form',
      search: queryString.stringify(params),
    })
  }

  handleInstitueFormClick = () => {
    window.open('http://naver.me/xzVN1jVb')
  }

  render() {
    const {
      classes,
      onCampaignsSubmit,
      onAdvertiserSubmit,
      onCampaignsUpdate,
      history,
      advertisers,
      campaign,
    } = this.props
    const { files, packageType, formType, selectAdvertiserId, loading } = this.state
    const {
      getPreviewImage,
      handleTypeChange,
      onAdvertiserChange,
      getAdPackageType,
      getCampaignId,
      getAdFormType,
      handleNextButtonClick,
      handleInstitueFormClick,
    } = this


    const validationForm = formType === 'append' ?
      {
        name: Yup.string()
          .min('2', '브랜드 이름은 2자리 이상 입력해주세요.')
          .max('24', '브랜드 이름은 24자리 까지 입력해주세요.')
          .required('브랜드 이름은 필수 입력값 입니다.'),
        profile_image: Yup.string()
          .required('브랜드 사진은 필수 입니다.'),
      } : {
        advertiser_id: Yup.string()
          .required('브랜드 선택은 필수 입니다.'),
      }
    return (
      <Layout pageTitle={packageType[getAdPackageType()] || ''}>
        {loading ? <CircularUnderLoad styles={classes.progress} /> :
          <>
            <Stepper activeStep={0} />
            <Paper className={classes.paper}>
              <Row>
                <Col sm={10}>
                  <Typography variant="h6"
                    gutterBottom
                    className={classes.pageTitle}
                  >
                    캠페인 이름 및 브랜드 선택
                  </Typography>
                  <Formik
                    initialValues={{
                      title: !isEmpty(campaign) ? campaign.title : '',
                      name: '',
                      advertiser_id: !isEmpty(campaign) ? campaign.advertiser.id : '',
                      profile_image: !isEmpty(campaign) ? campaign.advertiser.profile_image : '',
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      this.unblock()
                      this.setState({ loading: true })
                      Promise.resolve()
                        .then(() => {
                          if (formType !== 'select') {
                            const advertiserData = {
                              name: values.name,
                              profile_image: values.profile_image,
                            }

                            return onAdvertiserSubmit(advertiserData)
                              .then(data => data.id)
                          }

                          return selectAdvertiserId
                        })
                        .then((advertiserId) => {
                          const campaignData = {
                            title: values.title,
                            advertiser_id: advertiserId,
                          }

                          if (isEmpty(getCampaignId())) {
                            return onCampaignsSubmit(campaignData)
                          }

                          return onCampaignsUpdate({ ...campaignData, id: getCampaignId() })
                        })
                        .then(data => {
                          setSubmitting(false)
                          this.setState({ loading: false })
                          const params = {
                            campaign_id: data.id,
                            ad_package_type: getAdPackageType(),
                            form_type: getAdFormType(),
                          }
                          return history.replace({
                            pathname: '/adset_form',
                            search: queryString.stringify(params),
                          })
                        })
                    }}
                    validationSchema={Yup.object().shape({
                      title: Yup.string()
                        .min('2', '캠페인 이름은 2자리 이상 입력해주세요.')
                        .max('50', '캠페인 이름은 50자리 까지 입력해주세요.')
                        .required('캠페인 이름은 필수 입력값 입니다.'),
                      ...validationForm,
                    })}
                  >
                  {props => {
                    const {
                      values,
                      touched,
                      errors,
                      isSubmitting,
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      setFieldValue,
                    } = props

                    return (
                      <Form onSubmit={handleSubmit}>
                        <FormGroup row>
                          <Label sm={2}>캠페인 이름</Label>
                          <Col sm={10}>
                            <Input type="text"
                              name="title"
                              className={
                                errors.title && touched.title ? classes.fieldError : classes.field
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="캠페인 이름을 입력해주세요"
                              value={values.title}
                            />
                            {(errors.title && touched.title) &&
                              <div className={classes.invalidFeedback}>
                                {errors.title}
                              </div>
                            }
                          </Col>
                          </FormGroup>

                          <FormGroup row>
                            <Label sm={2}>브랜드 선택</Label>
                            <Col sm={10}>
                              <RadioGroup
                                aria-label="formType"
                                name="formType"
                                className={classes.group}
                                value={formType}
                                onChange={handleTypeChange}
                                row
                              >
                                <FormControlLabel value="select"
                                  control={<Radio color="primary" disabled={isEmpty(advertisers)} />}
                                  label="내 브랜드에서 선택"
                                  labelPlacement="end"
                                />
                                <FormControlLabel value="append"
                                  control={<Radio color="primary" />}
                                  label="신규 브랜드 추가"
                                  labelPlacement="end"
                                />
                              </RadioGroup>
                              <Row>
                                {formType === 'select' && !isEmpty(advertisers) &&
                                  <Col sm={12}>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                      <Select onChange={onAdvertiserChange(setFieldValue)}
                                        className={classes.select}
                                        input={<OutlinedInput labelWidth={0} name="value" />}
                                        displayEmpty
                                        value={selectAdvertiserId}
                                      >
                                        {map(advertisers, (item, index) => (
                                          <MenuItem value={item.id} key={index}>
                                            {item.name}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                      {(errors.advertiser_id && touched.advertiser_id) &&
                                        <div className={classes.invalidFeedback}>
                                          {errors.advertiser_id}
                                        </div>
                                      }
                                    </FormControl>
                                  </Col>
                                }

                                {formType === 'append' &&
                                  <Col sm={12}>
                                    <Input type="text"
                                      name="name"
                                      className={
                                        errors.name && touched.name ?
                                        classes.fieldError :
                                        classes.field
                                      }
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      placeholder="광고주 이름을 입력해주세요"
                                      value={values.name}
                                    />
                                    {(errors.name && touched.name) &&
                                      <div className={classes.invalidFeedback}>
                                        {errors.name}
                                      </div>
                                    }
                                    <div className={classes.uploadContainer}>
                                      {getPreviewImage(files, values.profile_image)}
                                      <div className={classes.uploadButtonContainer}>
                                        <Dropzone
                                          accept=".jpg,.png"
                                          onDrop={(acceptedFiles) => {
                                            const fileUrl = URL.createObjectURL(acceptedFiles[0])
                                            this.setState({ files: fileUrl })
                                            setFieldValue('profile_image', acceptedFiles[0])
                                          }}
                                        >
                                          {({getRootProps, getInputProps}) => (
                                            <div {...getRootProps()}>
                                              <input {...getInputProps()} />
                                                <Button 
                                                  className={classes.uploadButton}
                                                  variant="outlined"
                                                  color="primary"
                                                >
                                                  로고 등록하기
                                                </Button>
                                            </div>
                                          )}
                                        </Dropzone>
                                        <div className={classes.text}>
                                          로고 이미지 등록 시 정사각형 규격, 용량 10MB 이하,<br/>
                                          최소 300*300px, 확장자 jpg, gif, png 파일만 등록 가능합니다.
                                        </div>
                                        {(errors.profile_image && touched.profile_image) &&
                                          <div className={classes.invalidFeedback}>
                                            {errors.profile_image}
                                          </div>
                                        }
                                      </div>
                                    </div>
                                  </Col>
                                }
                                {getAdPackageType() === 'region' &&
                                  <div className={classes.institueField}>
                                    <div className={classes.institueLabel}>
                                      학원에 대한 추가적인 정보를 입력하시려면 <br />
                                      상단 ‘학원 정보 추가 입력’ 버튼을 클릭해 양식을 입력해주세요.
                                    </div>
                                    <Button
                                      onClick={handleInstitueFormClick}
                                      variant="contained"
                                      color="secondary"
                                      style={{ color: '#ffffff' }}
                                      type="button"
                                    >
                                      학원 정보 추가 입력
                                    </Button>
                                  </div>
                                  
                                }
                              </Row>
                            </Col>
                          </FormGroup>

                          <FormGroup row>
                            <Label sm={2} />
                            <Col sm={10}>
                              {getAdFormType() === 'update' &&
                                <Button
                                  className={classes.nextButton}
                                  onClick={handleNextButtonClick}
                                  variant="contained"
                                  color="primary"
                                  type="button"
                                >
                                  다음 단계
                                </Button>
                              }
                              <Button
                                className={classes.submitButton}
                                variant="outlined"
                                color="primary"
                                type="submit"
                                disabled={isSubmitting}
                              >
                                {getAdFormType() === 'create' ? '저장' : '적용'}
                              </Button>
                            </Col>
                          </FormGroup>

                        </Form>
                      )
                    }}
                  </Formik>
                </Col>
              </Row>
            </Paper>
          </>
        }
      </Layout>
    )
  }
}

CampaignFormPage.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({
    block: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({}).isRequired,
  onAdvertiserSubmit: PropTypes.func.isRequired,
  onCampaignsSubmit: PropTypes.func.isRequired,
  onAdvertisersMount: PropTypes.func.isRequired,
  onCampaignsUpdate: PropTypes.func.isRequired,
  advertisers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onCampaign: PropTypes.func.isRequired,
  onCampaignReset: PropTypes.func.isRequired,
  campaign: PropTypes.shape({}).isRequired,
}

export default compose(
  withTracker,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(CampaignFormPage)
