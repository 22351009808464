import { createAction } from 'redux-actions'
import { http } from '@/shared/utils'
import { CLASSTING_AD_API_URL } from '@/shared/constants'

export const FETCH_IMPRESSION_RATE_PENDING = 'impression_rate/FETCH_IMPRESSION_RATE_PENDING'
export const FETCH_IMPRESSION_RATE_SUCCESS = 'impression_rate/FETCH_IMPRESSION_RATE_SUCCESS'
export const FETCH_IMPRESSION_RATE_FAILURE = 'impression_rate/FETCH_IMPRESSION_RATE_FAILURE'
export const CREATE_IMPRESSION_RATE_PENDING = 'impression_rate/CREATE_IMPRESSION_RATE_PENDING'
export const CREATE_IMPRESSION_RATE_SUCCESS = 'impression_rate/CREATE_IMPRESSION_RATE_SUCCESS'
export const CREATE_IMPRESSION_RATE_FAILURE = 'impression_rate/CREATE_IMPRESSION_RATE_FAILURE'

export const fetchImpressionRatePending = createAction(FETCH_IMPRESSION_RATE_PENDING)
export const fetchImpressionRateSuccess = createAction(FETCH_IMPRESSION_RATE_SUCCESS)
export const fetchImpressionRateFailure = createAction(FETCH_IMPRESSION_RATE_FAILURE)
export const createImpressionRatePending = createAction(CREATE_IMPRESSION_RATE_PENDING)
export const createImpressionRateSuccess = createAction(CREATE_IMPRESSION_RATE_SUCCESS)
export const createImpressionRateFailure = createAction(CREATE_IMPRESSION_RATE_FAILURE)


function fetchImpressionRates(params) {
  return http.get(`${CLASSTING_AD_API_URL}/ad_impression_rates`, params)
}
function createImpressionRates(params) {
  return http.post(`${CLASSTING_AD_API_URL}/ad_impression_rates`, params)
}

export const fetchImpressionRate = params => (dispatch) => {
  dispatch(fetchImpressionRatePending())
  return fetchImpressionRates(params)
    .then(res => dispatch(fetchImpressionRateSuccess(res)))
    .catch(err => dispatch(fetchImpressionRateFailure(err.response)))
}
export const createImpressionRate = params => (dispatch) => {
  dispatch(createImpressionRatePending())
  return createImpressionRates(params)
    .then(res => dispatch(createImpressionRateSuccess(res)))
    .catch(err => dispatch(createImpressionRateFailure(err.response)))
}
