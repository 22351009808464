import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import { Navigation } from '../organisms'

const useStyles = makeStyles(() => ({
  root: {
    borderBottom: '1px solid #eeeeee',
    padding: '0 40px',
    background: '#fff',
  },
}))

const NavigationTemplate = ({
  children, items, selectedItem, setSelectedItem,
}) => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.root}>
        <Navigation items={items} selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
      </div>
      {children}
    </>
  )
}

NavigationTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  items: PropTypes.any.isRequired,
  selectedItem: PropTypes.string.isRequired,
  setSelectedItem: PropTypes.func.isRequired,
}

export default NavigationTemplate
