import { withTracker } from '@/shared/enhancers'
import ImpressionRatePage from './page'

export default [
  {
    key: 'impression',
    requiresAuth: true,
    exact: true,
    path: '/impression',
    component: withTracker(ImpressionRatePage),
  },
]
