/** @jsx jsx */
import { css, jsx } from '@emotion/react'

import Sitemap from './Sitemap'

const makeStyles = () => ({
  root: ({ colors: { grey } }) => css`
    background-color: ${grey[50]};
  `,
  wrap: ({ mq, spacing }) => css`
    ${mq({
    boxSizing: 'border-box',
    margin: '0 auto',
    marginBottom: [`${spacing(5)}rem`, '0'],
    maxWidth: ['auto', '102.4rem'],
    padding: [
      `${spacing(5)}rem ${spacing(1.875)}rem ${spacing(5.5)}rem`,
      `${spacing(5)}rem ${spacing(14.375)}rem`,
    ],
  })}
  `,
})

const Footer = () => {
  const styles = makeStyles()

  return (
    <div css={styles.root}>
      <div css={styles.wrap}>
        <Sitemap />

      </div>
    </div>
  )
}

export default Footer
