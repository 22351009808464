/**
 * 값이 추가되려면 아래 파일들이 같이 바뀌어야 함
 * 파일 나열은 우선순위 별로 나열되어 있습니다.
 * @see src/shared/constants/advertisements/AdvertisementNameMap.js
 * @see src/shared/constants/gamAdUnitName.js
 * @see src/shared/constants/templateTitle.js
 * @see src/features/campaign/xlsxExport/sections.js
 * @see src/features/today/components/TodayTable/TodayTable.styles.js
 * @see src/features/today/components/TableExpander/TableExpander.styles.js
 * @see src/shared/utils/getViewTemplate.js
 */
const styles = () => ({
  totalTable: {
    width: 1000,
    zIndex: 1,
    maxHeight: 'calc(100vh - 200px)',
  },
  thread: {
    textAlign: 'center',
    borderBottom: '1px solid #f2f2f2',
  },
  threadHeader: {
    textAlign: 'center',
    boxShadow: '0 2px 15px 0 rgba(0,0,0,0.15)',
    borderBottom: '1px solid #f2f2f2',
  },
  tr: {
    textAlign: 'center',
    width: '100%',
    display: 'flex',
    backgroundColor: '#f5f5f5',
  },
  trOdd: {
    textAlign: 'center',
    width: '100%',
    display: 'flex',
    backgroundColor: '#f5f5f5',
    boxShadow: '0 2px 15px 0 rgba(0,0,0,0.15)',
  },
  th: {
    borderRight: 'solid 1px rgba(0,0,0,0.05)',
    backgroundColor: '#fff',
  },
  td: {
    textAlign: 'center',
  },
  totalTypeTable: {
    width: 1130,
    zIndex: 1,
    maxHeight: 'calc(100vh - 200px)',
  },
  totalPostTypeTable: {
    width: 1300,
    zIndex: 1,
    maxHeight: 'calc(100vh - 200px)',
  },
  tableCell: {
    fontSize: 13,
    height: 38,
  },
  tableNumberCell: {
    textAlign: 'right',
    fontSize: 13,
    height: 38,
  },
  tableSwitchCell: {
    textAlign: 'center',
    fontSize: 13,
    height: 38,
  },
  tableHeaderCell: {
    fontSize: 14,
    fontWeight: 600,
    backgroundColor: '#ffffff',
    height: 28,
    paddingTop: 2,
  },
  tableHeaderCellLast: {
    fontSize: 14,
    fontWeight: 600,
    backgroundColor: '#ffffff',
    borderRight: 'unset',
    paddingTop: 2,
  },
  packageHeader: {
    backgroundColor: '#ffe0e0 !important',
    borderRight: 'unset',
  },
  postHeader: {
    backgroundColor: '#fff4e2 !important',
    position: 'inherit',
  },
  post_bannerHeader: {
    backgroundColor: '#c5e6fc !important',
    position: 'inherit',
  },
  notification_text_bannerHeader: {
    backgroundColor: '#c5e6fc !important',
    position: 'inherit',
  },
  notification_image_bannerHeader: {
    backgroundColor: '#c5e6fc !important',
    position: 'inherit',
  },
  start_popupHeader: {
    backgroundColor: '#c5e6fc !important',
    position: 'inherit',
  },
  end_popupHeader: {
    backgroundColor: '#c5e6fc !important',
    position: 'inherit',
  },
  class_tab_bannerHeader: {
    backgroundColor: '#c5e6fc !important',
    position: 'inherit',
  },
  classtalk_tab_bannerHeader: {
    backgroundColor: '#c5e6fc !important',
    position: 'inherit',
  },
  splash_bannerHeader: {
    backgroundColor: '#c5e6fc !important',
    position: 'inherit',
  },
  noticeHeader: {
    backgroundColor: '#eefaef !important',
    position: 'inherit',
  },
  commentHeader: {
    backgroundColor: '#ecf3fc !important',
    position: 'inherit',
  },
  bannerHeader: {
    backgroundColor: '#e0e8ff !important',
  },
  gam_class_tabHeader: {
    backgroundColor: '#e8e0ff !important',
  },
  gam_close_popupHeader: {
    backgroundColor: '#ffe0ff !important',
  },
  gam_home_top_bannerHeader: {
    backgroundColor: '#d0c9e5 !important',
  },
  gam_home_left_top_banner1Header: {
    backgroundColor: '#bbb4ce !important',
  },
  gam_home_left_top_banner2Header: {
    backgroundColor: '#a8a2b9 !important',
  },
  gam_home_middle_bannerHeader: {
    backgroundColor: '#9791a6 !important',
  },
  gam_home_bottom_bannerHeader: {
    backgroundColor: '#878295 !important',
  },
  gam_notification_first_slotHeader: {
    backgroundColor: '#ED9D9D !important',
  },
  gam_notification_second_slotHeader: {
    backgroundColor: '#EBE5A0 !important',
  },
  gam_post_bannerHeader: {
    backgroundColor: '#82ffe0 !important',
  },
  gam_post_detailHeader: {
    backgroundColor: '#82ffe0 !important',
  },
  gam_classroom_post_listHeader: {
    backgroundColor: '#82ffe0 !important',
  },
  gam_classroom_post_detailHeader: {
    backgroundColor: '#82ffe0 !important',
  },
})

export default styles
