/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import PropTypes from 'prop-types'
import { Button } from '../button'

const makeStyles = () => ({
  buttonWrapper: css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 1.6rem;
  `,
  description: ({ colors: { grey } }) => css`
    line-height: 2.4rem;
    font-size: 1.6rem;
    color: ${grey[800]};
  `,
  root: css`
    display: flex;
    flex-direction: column;
    padding: 2.4rem;
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    width: calc(100% - 3.2rem);
    max-width: 40.6rem;
    gap: 1.6rem;
    box-sizing: border-box;
  `,
})

const DialogContent = ({ secondaryButton, primaryButton, description }) => {
  const styles = makeStyles()
  return (
    <div css={styles.root}>
      <div css={styles.description}>{description}</div>
      <div css={styles.buttonWrapper}>
        {secondaryButton.visible && (
          <Button
            boldText={false}
            onClick={secondaryButton.onClick}
            color="transparent"
          >
            {secondaryButton.text}
          </Button>
        )}
        {primaryButton.visible && (
          <Button onClick={primaryButton.onClick} color="green">
            {primaryButton.text}
          </Button>
        )}
      </div>
    </div>
  )
}

DialogContent.defaultProps = {
  secondaryButton: {
    onClick: () => {},
    text: '취소',
    visible: false,
  },
}

DialogContent.propTypes = {
  description: PropTypes.string.isRequired,
  primaryButton: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
  }).isRequired,
  secondaryButton: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
  }),
}

export default DialogContent
