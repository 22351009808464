/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import PropTypes from 'prop-types'

const makeStyles = ({ width }) => ({
  root: () => css`
    display: flex;
    width: ${width};
  `,
  textArea: ({ colors: { grey, green }, font, spacing }) => css`
    flex: 1;
    height: 16.8rem;
    border: 1px solid ${grey[400]};
    border-radius: 4px;
    color: ${grey[900]};
    font-size: ${font.body2.fontSize};
    line-height: ${font.body2.lineHeight};
    overflow: auto;
    box-shadow: none;
    resize: none;
    font-family: inherit;
    padding: ${spacing(2)}rem;

    :focus {
      border-color: ${green[100]};
    }

    ::placeholder {
      color: ${grey[400]};
    }
  `,
})

const TextArea = ({
  customStyles,
  name,
  onChange,
  placeholder,
  value,
  width,
}) => {
  const styles = makeStyles({ width })

  return (
    <div css={[styles.root, customStyles]}>
      <textarea
        name={name}
        css={styles.textArea}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  )
}

TextArea.defaultProps = {
  customStyles: '',
  width: '100%',
  placeholder: '',
}

TextArea.propTypes = {
  customStyles: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.array,
  ]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  width: PropTypes.string,
}

export default TextArea
