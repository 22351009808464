import React from 'react'
import PropTypes from 'prop-types'
import NotificationImagelinkAd from '@/features/preview/components/molecules/NotificationImagelinkAd'
import NotificationTextlinkAd from '@/features/preview/components/molecules/NotificationTextlinkAd'

const AdPreviewNotificationType = ({
  advertiserProfileImage,
  description,
  imageUrl,
  title,
  highlightColor,
  advertiserName,
}) => (
  <div style={{ width: 360 }}>
    {imageUrl ? (
      <NotificationImagelinkAd
        advertiserName={advertiserName}
        advertiserProfileImage={advertiserProfileImage}
        description={description}
        imageUrl={imageUrl}
        title={title}
      />
    ) : (
      <NotificationTextlinkAd
        advertiserName={advertiserName}
        advertiserProfileImage={advertiserProfileImage}
        description={description}
        title={title}
        highlightColor={highlightColor}
      />
    )}
  </div>
)

AdPreviewNotificationType.defaultProps = {
  highlightColor: '#fff',
  description: '',
  title: '',
  advertiserName: '',
}

AdPreviewNotificationType.propTypes = {
  highlightColor: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  advertiserProfileImage: PropTypes.string.isRequired,
  description: PropTypes.string,
  title: PropTypes.string,
  advertiserName: PropTypes.string,
}

export default AdPreviewNotificationType
