import React from 'react'
import PropTypes from 'prop-types'

import { Tooltip as MaterialUIToolTip } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'

const Tooltip = ({
  title, message, placement, iconStyle,
}) => (
  <MaterialUIToolTip title={message} placement={placement}>
    <span>
      {title}
      <InfoOutlined style={iconStyle} />
    </span>
  </MaterialUIToolTip>
)

Tooltip.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  iconStyle: PropTypes.shape({}),
  placement: PropTypes.string,
}

Tooltip.defaultProps = {
  iconStyle: {
    fontSize: 14,
  },
  placement: 'top',
}

export default Tooltip
