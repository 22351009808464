import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { ResponsiveLine } from '@nivo/line'
import moment from 'moment'

import {
  GRAPH_STATS,
  LOG_ZERO_VALUE,
  LEGEND_STYLE,
} from '@/shared/constants/graph'

import { getGraphColorByActivateLegend } from '@/features/campaign/utils'

import ReportTooltip from './ReportTooltip'

const DEFAULT_MAX_TICK_VAULE = 10

/**
 * @param {Object} data
 * @return {Number}
 * y축의 최대 값보다 크고 가장 가까운 10의 거듭제곱수
 * max값이 default min값과 같을 경우 DEFAULT_MAX_TICK_VAULE return
 */
const getMaxYValue = (data) => {
  const max = data.reduce((accYMaxInWholeData, value) => {
    const lineYMax = value.data.reduce((accYMax, line) => (
      Math.max(accYMax, line.y)
    ), LOG_ZERO_VALUE)

    return Math.max(accYMaxInWholeData, lineYMax)
  }, LOG_ZERO_VALUE)

  if (max === LOG_ZERO_VALUE) {
    return DEFAULT_MAX_TICK_VAULE
  }

  if (Number.isInteger(Math.log10(max))) {
    return max // max의 값이 10, 100, 1000, 10000... 일 때
  }

  return 10 ** Math.ceil(Math.log10(max)) // === 10 **
}

/**
 * y축의 최소 값
 * @param {Object} data
 * @param {Number} yScaleMax
 * @return {Number} min값이 default max값과 같을 경우 LOG_ZERO_VALUE return
 */
const getMinYValue = data => (yScaleMax) => {
  const min = data.reduce((accYMinInWholeData, value) => {
    const lineYMax = value.data.reduce((accYMin, line) => (
      Math.min(accYMin, line.y)
    ), yScaleMax)

    return Math.min(accYMinInWholeData, lineYMax)
  }, yScaleMax)

  if (min === DEFAULT_MAX_TICK_VAULE) {
    return LOG_ZERO_VALUE
  }

  return min
}

const legendItemsInit = value => (
  value.reduce((acc, { id, data }) => ({ ...acc, [id]: data.length > 0 }), {})
)

/**
 * 그래프의 grid 와 y축에 보이는 숫자를 customization
 * @param {Number} yScaleMin
 * @param {Number} yScaleMax
 * @return {Array} ex) [min, 10, 100, 1000, max]
 */
const getTickYValues = (yScaleMin, yScaleMax) => {
  if (yScaleMax === DEFAULT_MAX_TICK_VAULE) {
    return [yScaleMin, yScaleMax]
  }

  const yScaleMaxLen = Math.floor(yScaleMax).toString().length
  const yScaleMinLen = Math.floor(yScaleMin).toString().length

  let middleValues = []

  for (let i = yScaleMinLen; i < yScaleMaxLen - 1; i += 1) {
    middleValues = [...middleValues, 10 ** i]
  }

  const result = [yScaleMin, ...middleValues, yScaleMax]

  return result
}

const ResponsiveLineChart = ({ data }) => {
  if (data.length === 0) {
    return null
  }

  const [selectedItems, setSelectedItems] = useState({})

  useEffect(() => {
    setSelectedItems(legendItemsInit(data))
  }, [data])

  const filteredData = data.filter(({ id }) => !!selectedItems[id])

  const yScaleMax = getMaxYValue(filteredData)
  const yScaleMin = getMinYValue(filteredData)(yScaleMax)

  const tickYValues = getTickYValues(yScaleMin, yScaleMax)

  return (
    <div style={{ height: 400 }}>
      <ResponsiveLine
        data={filteredData}
        margin={{
          top: 50, right: 130, bottom: 50, left: 60,
        }}
        gridYValues={tickYValues}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'log',
          max: yScaleMax,
          min: yScaleMin,
        }}
        axisLeft={{
          tickValues: tickYValues,
          format: d => (d === LOG_ZERO_VALUE ? 0 : d),
        }}
        axisBottom={{
          format: d => moment(d).format('MM/DD'),
        }}
        colors={getGraphColorByActivateLegend(selectedItems)}
        pointSize={10}
        pointColor="white"
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabel="y"
        pointLabelYOffset={-12}
        useMesh
        theme={{
          legends: {
            text: {
              fontSize: 12,
            },
          },
        }}
        yFormat={value => (value === LOG_ZERO_VALUE ? 0 : value)}
        legends={[
          {
            ...LEGEND_STYLE,
            dataFrom: 'keys',
            data: data.map(value => ({
              color: selectedItems[value.id] ? GRAPH_STATS[value.id].color : 'darkgray',
              value,
              label: GRAPH_STATS[value.id].label,
            })),
            onClick: (d) => {
              setSelectedItems({ ...selectedItems, [d.value.id]: !selectedItems[d.value.id] })
            },
          },
        ]}
        // tooltip
        enableSlices="x"
        sliceTooltip={ReportTooltip}
      />
    </div>
  )
}

ResponsiveLineChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
}

ResponsiveLineChart.defaultProps = {
  data: [],
}

export default ResponsiveLineChart
