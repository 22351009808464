import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import { Text, Icon } from '../atoms'

const useStyles = makeStyles({
  root: {
    position: 'relative',
    width: '240px',
  },
  button: {
    display: 'flex',
    background: '#fff',
    width: '100%',
    height: '40px',
    cursor: 'pointer',
    alignItems: 'center',
    padding: '0px 7px 0 15px',
    border: '1px solid #bdbdbd',
    borderRadius: '4px',
  },
  buttonValue: {
    flex: '1',
  },
  menu: {
    position: 'absolute',
    top: '0',
    left: '0',
    background: '#fff',
    width: '100%',
    borderRadius: '4px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 14px 2px rgba(0, 0, 0, 0.12)',
  },
})

const useItemStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '48px',
    padding: '0 12px 0 20px',
    cursor: 'pointer',

    '&:active, &:hover': {
      background: '#eeeeee',
    },
  },
})

const Item = ({ name, changeValue, hideMenu }) => {
  const classes = useItemStyles()
  const handleItemClick = () => {
    hideMenu()
    changeValue(name)
  }

  return (
    <div className={classes.root} onClick={handleItemClick}>
      <Text color="darkGray">{name}</Text>
    </div>
  )
}

const Dropdown = ({ value, items, changeValue }) => {
  const dropdownEl = useRef()
  const [isShowMenu, setIsShowMenu] = useState(false)
  const classes = useStyles()

  const hideMenu = () => {
    setIsShowMenu(false)
  }

  const handleButtonClick = () => {
    setIsShowMenu(true)
  }

  const handleOutsideClick = ({ target }) => {
    if (isShowMenu && !dropdownEl.current.contains(target)) setIsShowMenu(false)
  }

  useEffect(() => {
    window.addEventListener('click', handleOutsideClick)

    return () => {
      window.removeEventListener('click', handleOutsideClick)
    }
  }, [isShowMenu])

  return (
    <div ref={dropdownEl} className={classes.root}>
      <div className={classes.button} onClick={handleButtonClick}>
        <Text className={classes.buttonValue}>{value}</Text>
        <Icon shape="downTriangle" />
      </div>
      {
        isShowMenu && (
        <div className={classes.menu}>
          {
            items.map(name => (
              <Item
                key={name}
                name={name}
                changeValue={changeValue}
                hideMenu={hideMenu}
              />
            ))
          }
        </div>
        )
      }
    </div>
  )
}

Item.propTypes = {
  name: PropTypes.string.isRequired,
  changeValue: PropTypes.func.isRequired,
  hideMenu: PropTypes.func.isRequired,
}

Dropdown.propTypes = {
  value: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  changeValue: PropTypes.func.isRequired,
}

export default Dropdown
