import { createAction } from 'redux-actions'
import { forEach } from 'lodash'
import { http } from '@/shared/utils'
import { CLASSTING_AD_API_URL } from '@/shared/constants'

export const SIGN_UP_RESET_STATE = 'signUp/SIGN_UP_RESET_STATE'
export const SIGN_UP_POST_PENDING = 'signUp/POST_PENDING'
export const SIGN_UP_POST_SUCCESS = 'signUp/POST_SUCCESS'
export const SIGN_UP_POST_FAILURE = 'signUp/POST_FAILURE'
export const RESET_PASSWORD_POST_PENDING = 'signUp/RESET_PASSWORD_POST_PENDING'
export const RESET_PASSWORD_POST_SUCCESS = 'signUp/RESET_PASSWORD_POST_SUCCESS'
export const RESET_PASSWORD_POST_FAILURE = 'signUp/RESET_PASSWORD_POST_FAILURE'
export const UPDATE_PASSWORD_POST_PENDING = 'signUp/UPDATE_PASSWORD_POST_PENDING'
export const UPDATE_PASSWORD_POST_SUCCESS = 'signUp/UPDATE_PASSWORD_POST_SUCCESS'
export const UPDATE_PASSWORD_POST_FAILURE = 'signUp/UPDATE_PASSWORD_POST_FAILURE'
export const CHECK_EMAIL_GET_PENDING = 'signUp/CHECK_EMAIL_GET_PENDING'
export const CHECK_EMAIL_GET_SUCCESS = 'signUp/CHECK_EMAIL_GET_SUCCESS'
export const CHECK_EMAIL_GET_FAILURE = 'signUp/CHECK_EMAIL_GET_FAILURE'
export const USER_CONFIRMATION_GET_PENDING = 'signUp/USER_CONFIRMATION_GET_PENDING'
export const USER_CONFIRMATION_GET_SUCCESS = 'signUp/USER_CONFIRMATION_GET_SUCCESS'
export const USER_CONFIRMATION_GET_FAILURE = 'signUp/USER_CONFIRMATION_GET_FAILURE'

export const signUpResetState = createAction(SIGN_UP_RESET_STATE)
export const signUpPending = createAction(SIGN_UP_POST_PENDING)
export const signUpSuccess = createAction(SIGN_UP_POST_SUCCESS)
export const signUpFailure = createAction(SIGN_UP_POST_FAILURE)
export const resetPasswordPending = createAction(RESET_PASSWORD_POST_PENDING)
export const resetPasswordSuccess = createAction(RESET_PASSWORD_POST_SUCCESS)
export const resetPasswordFailure = createAction(RESET_PASSWORD_POST_FAILURE)
export const updatePasswordPending = createAction(UPDATE_PASSWORD_POST_PENDING)
export const updatePasswordSuccess = createAction(UPDATE_PASSWORD_POST_SUCCESS)
export const updatePasswordFailure = createAction(UPDATE_PASSWORD_POST_FAILURE)
export const checkEmailPending = createAction(CHECK_EMAIL_GET_PENDING)
export const checkEmailSuccess = createAction(CHECK_EMAIL_GET_SUCCESS)
export const checkEmailFailure = createAction(CHECK_EMAIL_GET_FAILURE)
export const userConfirmationPending = createAction(USER_CONFIRMATION_GET_PENDING)
export const userConfirmationSuccess = createAction(USER_CONFIRMATION_GET_SUCCESS)
export const userConfirmationFailure = createAction(USER_CONFIRMATION_GET_FAILURE)

export const signUp = data => (dispatch) => {
  const formData = new FormData()
  forEach(data, (value, key) => {
    formData.append(key, value || '')
  })
  dispatch(signUpPending())
  return http.post(`${CLASSTING_AD_API_URL}/agencies/sign_up`,
    formData,
    { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(res => (
      dispatch(signUpSuccess(res))
    ))
    .catch(err => (
      dispatch(signUpFailure(err.response))
    ))
}

export const resetPassword = data => (dispatch) => {
  dispatch(resetPasswordPending())
  return http.post(`${CLASSTING_AD_API_URL}/agencies/reset_password_email`, { ...data })
    .then(res => (
      dispatch(resetPasswordSuccess(res))
    ))
    .catch(err => (
      dispatch(resetPasswordFailure(err.response))
    ))
}

export const updatePassword = data => (dispatch) => {
  dispatch(updatePasswordPending())
  return http.post(`${CLASSTING_AD_API_URL}/agencies/update_password`, { ...data })
    .then(res => (
      dispatch(updatePasswordSuccess(res))
    ))
    .catch(err => (
      dispatch(updatePasswordFailure(err.response))
    ))
}

export const fetchCheckEmail = params => (dispatch) => {
  dispatch(checkEmailPending())
  return http.get(`${CLASSTING_AD_API_URL}/agencies`, { params })
    .then(res => (
      dispatch(checkEmailSuccess(res))
    ))
    .catch(err => (
      dispatch(checkEmailFailure(err.response))
    ))
}

function setUserConfirmation(params) {
  return http.post(`${CLASSTING_AD_API_URL}/agencies/confirm`, { ...params })
}

export const fetchUserConfirmation = params => (dispatch) => {
  dispatch(userConfirmationPending())
  return http.get(`${CLASSTING_AD_API_URL}/agencies`, { params })
    .then(() => setUserConfirmation(params))
    .then(res => (
      dispatch(userConfirmationSuccess(res))
    ))
    .catch(err => (
      dispatch(userConfirmationFailure(err.response))
    ))
}
