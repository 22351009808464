/** @jsx jsx */
import { jsx } from '@emotion/react'
import PropTypes from 'prop-types'

import DownloadButton from './DownloadButton'

const ProductIntroductionButton = ({ fullWidth }) => (
  <DownloadButton
    action={process.env.REACT_APP_AD_PRODUCT_INFORMATION_URL}
    color="blue"
    fullWidth={fullWidth}
  >
    광고 상품소개서 다운로드
  </DownloadButton>
)

ProductIntroductionButton.defaultProps = {
  fullWidth: false,
}

ProductIntroductionButton.propTypes = {
  fullWidth: PropTypes.bool,
}

export default ProductIntroductionButton
