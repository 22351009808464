/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useContext, Fragment } from 'react'
import Home from '@landing/components/home'
import Introduce from '@landing/components/introduce'
import Guidance from '@landing/components/guidance'
import CaseOfExecution from '@landing/components/caseOfExecution'
import Process from '@landing/components/process'
import FAQ from '@landing/components/FAQ'
import Inquire from '@landing/components/inquire'
import { FloatButton, InquireButton } from '@landing/components/unit/button'
import { uiStore, VIEW_SIZE_MOBILE } from '@landing/contexts/ui'

const googleSurvey = {
  fields: {
    brandName: 'entry.177881569',
    businessClassification: 'entry.2100143488',
    companyName: 'entry.1358840273',
    contact: 'entry.1258022061',
    email: 'entry.351777409',
    goals: 'entry.1142074009',
    inquireContent: 'entry.1114766244',
    name: 'entry.770748672',
    targets: 'entry.978871537',
    title: 'entry.281066320',
    visitRoute: 'entry.1436162895',
  },
  url:
    'https://docs.google.com/forms/d/e/1FAIpQLSeF75t26lmp6CSEoPUX9exI1EwV-6SUkkITd_dc6U1LW3mDlQ/formResponse',
}

const Main = () => {
  const {
    state: {
      viewSize,
    },
  } = useContext(uiStore)

  const submitInquire = (inquireInfo) => {
    let params = ''

    Object.entries(googleSurvey.fields).forEach(([key, value]) => {
      params += `${value}=${inquireInfo[key]}&`
    })
    params = params.slice(0, -1)

    return fetch(googleSurvey.url, {
      body: encodeURI(params),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
    })
      .then(res => JSON.parse(res))
      .catch(e => e)
  }

  return (
    <Fragment>
      <Home />
      <Introduce />
      <Guidance />
      <CaseOfExecution />
      <Process />
      <FAQ />
      <Inquire submitInquire={submitInquire} />
      {viewSize === VIEW_SIZE_MOBILE && (
        <FloatButton>
          <InquireButton fullWidth isRounded={false} />
        </FloatButton>
      )}
    </Fragment>
  )
}

export default Main
