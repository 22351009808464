import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import DialogContentText from '@material-ui/core/DialogContentText'
import { isEmpty, map } from 'lodash'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import Pageview from '@material-ui/icons/Pageview'
import Button from '@material-ui/core/Button'
import queryString from 'query-string'
import {
  Layout, Pagination, EnhancedPaper, CircularUnderLoad, EnhancedSelect, EnhancedDialog,
} from '@/shared/components'
import { withTracker } from '@/shared/enhancers'
import { fetchAdvertisers, deleteAdvertiser } from '../actions'

const styles = ({
  header: {
    padding: '24px',
    position: 'relative',
  },
  toolbar: {
    padding: '24px',
    position: 'relative',
  },
  footer: {
    position: 'relative',
  },
  formButton: {
    position: 'absolute',
    right: '14px',
    bottom: '24px',
  },
  progressPosition: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  paper: {
    marginTop: '14px',
    marginBottom: '14px',
  },
})

const mapStateToProps = state => ({
  status: state.advertiser.status,
  advertisers: state.advertiser.advertisers,
  pending: state.advertiser.pending,
  pageTotal: state.advertiser.total,
})

const mapDispatchToProps = dispatch => ({
  onAdvertiserMount: (page, perPage, order) => (
    dispatch(fetchAdvertisers({ page, per_page: perPage, sort: order }))
  ),
})

class AdvertiserPage extends Component {
  state = {
    order: '+name',
    page: '1',
    perPage: '30',
    perPages: [
      { value: '10', label: '10개씩 보기' },
      { value: '20', label: '20개씩 보기' },
      { value: '30', label: '30개씩 보기' },
    ],
    orderList: [
      { value: '+name', label: '오름차순' },
      { value: '-name', label: '내림차순' },
    ],
    dense: false,
    isOpenModal: false,
    selectAdvertiserId: '',
  }

  componentDidMount() {
    const { onAdvertiserMount, history, location } = this.props
    const { perPage, order } = this.state
    const pageNumber = queryString.parse(location.search).page || '1'
    this.setState({ page: pageNumber })
    onAdvertiserMount(pageNumber, perPage, order)
    history.push({
      search: `?page=${pageNumber}&per_page=${perPage}&sort=${order}`,
    })
  }

  onPagination = (pageNumber) => {
    const { perPage, order } = this.state
    const { history, onAdvertiserMount } = this.props
    this.setState({ page: pageNumber })
    onAdvertiserMount(pageNumber, perPage, order)
    history.push({
      pathname: '/advertiser',
      search: `?page=${pageNumber}&per_page=${perPage}&sort=${order}`,
    })
  }

  onPerPageChange = (event) => {
    const { onAdvertiserMount, history } = this.props
    const { page, order } = this.state
    this.setState({ perPage: event.target.value })
    onAdvertiserMount(page, event.target.value, order)
    history.push({
      search: `?page=${page}&per_page=${event.target.value}&sort=${order}`,
    })
  }

  onOrderPageChange = (event) => {
    const { onAdvertiserMount, history } = this.props
    const { page, perPage } = this.state
    this.setState({ order: event.target.value })
    onAdvertiserMount(page, perPage, event.target.value)
    history.push({
      search: `?page=${page}&per_page=${perPage}&sort=${event.target.value}`,
    })
  }

  handleUpdateClick = id => () => {
    const { history } = this.props
    history.push({
      pathname: '/advertiser/form',
      search: `?advertiser_id=${id}`,
    })
  }

  handleFormClick = () => {
    const { history } = this.props
    history.push({
      pathname: '/advertiser/form',
    })
  }

  handleDeleteClick = id => () => {
    this.setState({ selectAdvertiserId: id, isOpenModal: true })
  }

  handleBrandClick = id => () => {
    const { history } = this.props
    const params = {
      advertiser_id: id,
    }

    history.push({
      pathname: '/campaign',
      search: queryString.stringify(params),
    })
  }

  render() {
    const {
      classes,
      pending,
      pageTotal,
      advertisers,
    } = this.props
    const {
      page,
      perPage,
      perPages,
      dense,
      order,
      orderList,
      isOpenModal,
      selectAdvertiserId,
    } = this.state
    const {
      onOrderPageChange,
      onPagination,
      onPerPageChange,
      handleFormClick,
      handleUpdateClick,
      handleDeleteClick,
      handleBrandClick,
    } = this

    return (
      <Layout pageTitle="브랜드 관리">
        {pending ? <CircularUnderLoad styles={classes.progressPosition} />
          : (
            <div>
              <Grid>
                <EnhancedPaper styles={classes.header}>
                  <Typography variant="h5" className={classes.title}>
                    새로운 브랜드 광고주를 등록해주세요
                  </Typography>
                  <Typography variant="h6" className={classes.title}>
                    Classting AD를 통해 다양한 브랜드를 등록 및 관리하실 수 있습니다.
                  </Typography>
                  <Button
                    variant="outlined"
                    className={classes.formButton}
                    onClick={handleFormClick}
                    size="large"
                    color="primary"
                  >
                    + 새로운 브랜드 등록하기
                  </Button>
                </EnhancedPaper>
                <div className={classes.toolbar}>
                  <EnhancedSelect
                    onChange={onOrderPageChange}
                    menuItem={orderList}
                    defaultItem={order}
                  />
                </div>
                {map(advertisers, item => (
                  <EnhancedPaper styles={classes.paper} key={item.id}>
                    <List dense={dense}>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar alt="profile_image" src={item.profile_image} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={item.name}
                          secondary={false}
                        />
                        <ListItemSecondaryAction>
                          <IconButton aria-label="Edit" onClick={handleBrandClick(item.id)}>
                            <Pageview />
                          </IconButton>
                          <IconButton aria-label="Edit" onClick={handleUpdateClick(item.id)}>
                            <EditIcon />
                          </IconButton>
                          <IconButton aria-label="Delete" onClick={handleDeleteClick(item.id)}>
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </List>
                  </EnhancedPaper>
                ))}
              </Grid>
              {isEmpty(advertisers)
                ? null
                : (
                  <div className={classes.footer}>
                    <Pagination
                      perPage={Number(perPage)}
                      pageNumber={Number(page)}
                      pageTotal={Number(pageTotal)}
                      onPagination={onPagination}
                    />
                    <EnhancedSelect
                      onChange={onPerPageChange}
                      menuItem={perPages}
                      defaultItem={perPage}
                    />
                  </div>
                )
            }
            </div>
          )
        }
        <EnhancedDialog
          isOpen={isOpenModal}
          modalSize="lg"
          modalTitle="해당 브랜드를 삭제하시겠습니까?"
          handleClose={() => { this.setState({ isOpenModal: false }) }}
          handleOpen={() => {
            deleteAdvertiser(selectAdvertiserId)
              .then(() => (this.setState({ selectAdvertiserId: '', isOpenModal: false })))
              .then(() => {
                const { history, onAdvertiserMount } = this.props
                onAdvertiserMount(page, perPage, order)
                  .then(() => (
                    history.push({
                      search: `?page=${page}&per_page=${perPage}&sort=${order}`,
                    })
                  ))
              })
          }}
        >
          <DialogContentText>
            해당 브랜드의 정보를 삭제하시겠습니까?
            <br />
            삭제 시 캠페인 기록이 사라집니다.
          </DialogContentText>
        </EnhancedDialog>
      </Layout>
    )
  }
}

AdvertiserPage.propTypes = {
  onAdvertiserMount: PropTypes.func.isRequired,
  advertisers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  classes: PropTypes.shape({
    title: PropTypes.string,
    formButton: PropTypes.string,
    progressButton: PropTypes.string,
    progressPosition: PropTypes.string,
    header: PropTypes.string,
    toolbar: PropTypes.string,
    paper: PropTypes.string,
    footer: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  pending: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  pageTotal: PropTypes.string.isRequired,
}

export default compose(
  withTracker,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(AdvertiserPage)
