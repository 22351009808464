import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { map } from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import { Typography, Button } from '@material-ui/core'
import { InlineDatePicker } from 'material-ui-pickers'

import { Layout, CircularUnderLoad } from '@/shared/components'
import { TodayTable } from '../components'

const styles = () => ({
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  paperToolbar: {
    marginBottom: '24px',
  },
  paperTitle: {
    display: 'inline-block',
    marginRight: '14px',
  },
  datePicker: {
    marginRight: '14px',
  },
  dateChangeButton: {
    color: '#ffffff',
  },
})

class LegacyTodayPage extends Component {
  state = {
    date: moment().format('YYYY-MM-DD'),
    loading: false,
    status: 'approved',
    page: 1,
    perPage: 500,
  }

  componentDidMount() {
    this.fetchAdsAndCount()
  }

  fetchAdsAndCount = async (params) => {
    const { onAd, onAdCount } = this.props
    const {
      date, status, page, perPage,
    } = this.state

    this.setState({ loading: true })

    const { payload } = await onAd({
      date,
      page,
      per_page: perPage,
      status,
      ...params,
    })

    await onAdCount(this.getAdCountParams(payload.data))

    this.setState({ loading: false })
  }

  getAdCountParams = (data) => {
    const advertisementIds = map(data, item => item.id)
    const { page, perPage, date } = this.state

    return {
      'by_period[end_date]': date,
      'by_period[start_date]': date,
      advertisement_ids: advertisementIds,
      page,
      per_page: perPage,
    }
  }

  handleDateChange = (date) => {
    this.setState({ date: date.format('YYYY-MM-DD') })
  }

  handleDateClick = () => {
    this.fetchAdsAndCount({
      ad_package_id: '',
    })
  }

  render() {
    const {
      classes,
      ad,
      onUpdateAdForcedStop,
      isExpanderLoading,
    } = this.props

    const {
      loading,
      date,
    } = this.state

    const {
      handleDateChange,
      handleDateClick,
    } = this

    return (
      <Layout pageTitle="투데이">
        {loading ? <CircularUnderLoad styles={classes.progress} />
          : (
            <>
              <div className={classes.paperToolbar}>
                <Typography
                  variant="h5"
                  gutterBottom
                  className={classes.paperTitle}
                >
                  일별 노출 현황 체크(커스텀)
                </Typography>
                <InlineDatePicker
                  className={classes.datePicker}
                  onlyCalendar
                  value={date}
                  onChange={handleDateChange}
                  format="YYYY-MM-DD"
                />
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  className={classes.dateChangeButton}
                  onClick={handleDateClick}
                >
                  조회하기
                </Button>
              </div>

              <TodayTable
                ad={ad}
                onUpdateAdForcedStop={onUpdateAdForcedStop}
                isExpanderLoading={isExpanderLoading}
              />
            </>
          )
        }
      </Layout>
    )
  }
}

LegacyTodayPage.propTypes = {
  classes: PropTypes.object.isRequired,
  onAd: PropTypes.func.isRequired,
  onAdCount: PropTypes.func.isRequired,
  onUpdateAdForcedStop: PropTypes.func.isRequired,
  ad: PropTypes.object.isRequired,
  isExpanderLoading: PropTypes.bool.isRequired,
}

export default withStyles(styles)(LegacyTodayPage)
