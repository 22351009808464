import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'

import Button from '@material-ui/core/Button'
import DialogContentText from '@material-ui/core/DialogContentText'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import CheckCircle from '@material-ui/icons/CheckCircle'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { CircularUnderLoad, EnhancedDialog } from '@/shared/components'
import {
  fetchReportToken,
  fetchReportExport,
} from '../actions'

const ShareButton = ({
  campaignId,
  classes,
  isPrivate,
  exportToken,
  accessToken,
  onReportToken,
}) => {
  const [copied, setCopied] = useState(false)
  const [isExportPublicModal, setIsExportPublicModal] = useState(false)

  const exportPublicUrl = window.location.origin

  const handleExportUrlClick = () => {
    setIsExportPublicModal(true)
    onReportToken(campaignId)
  }

  return (
    <>
      {isPrivate && (
        <Button
          variant="outlined"
          color="default"
          size="large"
          disable={`${exportToken}`}
          style={{ marginRight: '10px' }}
          onClick={handleExportUrlClick}
        >
          공유하기
          {exportToken && <CircularUnderLoad styles={classes.progressButton} size={20} />}
        </Button>
      )}
      <EnhancedDialog
        isOpen={isExportPublicModal}
        isCloseButton={false}
        modalTitle="리포트 주소 복사하기"
        handleOpen={() => {
          setIsExportPublicModal(false)
          setCopied(false)
        }}
      >
        <div className={classes.exportPublicUrl}>
          {exportToken
            ? <CircularUnderLoad />
            : `${exportPublicUrl}/campaign/${campaignId}/${accessToken}`
          }
        </div>
        <DialogContentText>
          <CopyToClipboard
            text={`${exportPublicUrl}/campaign/${campaignId}/${accessToken}`}
            onCopy={() => setCopied(true)}
          >
            <Button
              variant="outlined"
              color="primary"
              size="small"
            >
              복사하기
              {copied
                ? (
                  <CheckCircle className={
                      classNames(classes.rightIcon, classes.iconSmall)
                    }
                  />
                )
                : (
                  <CheckCircleOutline className={
                      classNames(classes.rightIcon, classes.iconSmall)
                    }
                  />
                )
              }
            </Button>
          </CopyToClipboard>
          <br />
          <p className={classes.copyToText}>
            Ctrl+V 혹은 붙여넣기를 통해 해당 페이지를 공유할 수 있습니다.
            <br />
            공유된 페이지는 보기만 가능합니다.
          </p>
        </DialogContentText>
      </EnhancedDialog>
    </>
  )
}

ShareButton.propTypes = {
  classes: PropTypes.any.isRequired,
  campaignId: PropTypes.string.isRequired,
  onReportToken: PropTypes.func.isRequired,
  isPrivate: PropTypes.bool.isRequired,
  exportToken: PropTypes.bool.isRequired,
  accessToken: PropTypes.string.isRequired,
}

export default connect(
  state => ({
    exportToken: state.campaign.exportToken,
    exportFile: state.campaign.exportFile,
    accessToken: state.campaign.accessToken,
  }),
  {
    onReportToken: fetchReportToken,
    onReportExport: fetchReportExport,
  },
)(ShareButton)
