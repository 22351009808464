/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import PropTypes from 'prop-types'

import { InquireButton, ProductIntroductionButton } from '../unit/button'

const makeStyles = () => ({
  root: ({ mq, spacing }) => css`
    ${mq({
    '> *:first-of-type': {
      marginBottom: [`${spacing()}rem`, '0'],
      marginRight: ['0', `${spacing(2)}rem`],
    },
    display: 'flex',
    flexDirection: ['column', 'row'],
    marginTop: ['0', `${spacing(2)}rem`],
  })}
  `,
})

const InquireButtonWrap = ({ closeModal, fullWidth }) => {
  const styles = makeStyles()

  return (
    <div css={styles.root}>
      <InquireButton clickCallBack={closeModal} fullWidth={fullWidth} />
      <ProductIntroductionButton fullWidth={fullWidth} />
    </div>
  )
}

InquireButtonWrap.defaultProps = {
  fullWidth: false,
  closeModal: () => {},
}

InquireButtonWrap.propTypes = {
  closeModal: PropTypes.func,
  fullWidth: PropTypes.bool,
}

export default InquireButtonWrap
