import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

import { TableHeader } from '@/shared/components'
import { getToCommas, getRatio } from '@/shared/utils'

import { adReportRows } from '@/features/campaign/containers/tableData'

const styles = ({
  tableCell: {
    border: '1px solid rgba(224, 224, 224, 1)',
    padding: '12px',
  },
})
const ReportTable = ({
  classes,
  selectRole,
  isHideWatchData,
  adReportData,
  adTypeTotalReport,
}) => {
  const adReportDataEntries = Object.entries(adReportData)
    .sort(([prev], [next]) => (new Date(prev) - new Date(next)))

  return (
    <TableHeader
      rows={adReportRows}
      isHideRow={isHideWatchData}
    >
      <TableRow>
        <TableCell component="th" scope="row" className={classes.tableCell}>
          전체기간
        </TableCell>
        <TableCell component="th" scope="row" className={classes.tableCell}>
          {getToCommas(adTypeTotalReport.view)}
        </TableCell>
        <TableCell component="th" scope="row" className={classes.tableCell}>
          {getToCommas(adTypeTotalReport.click)}
        </TableCell>
        <TableCell component="th" scope="row" className={classes.tableCell}>
          {getRatio(adTypeTotalReport.click, adTypeTotalReport.view)}
          %
        </TableCell>
        {!isHideWatchData && (
        <>
          <TableCell component="th" scope="row" className={classes.tableCell}>
            {getToCommas(adTypeTotalReport.watch)}
          </TableCell>
          <TableCell component="th" scope="row" className={classes.tableCell}>
            {adTypeTotalReport.vtr}
            %
          </TableCell>
        </>
        )}
      </TableRow>
      {
        adReportDataEntries.map(([key, value]) => (
          <TableRow key={key}>
            <TableCell component="th" scope="row" className={classes.tableCell}>
              {key}
            </TableCell>
            <TableCell component="th" scope="row" className={classes.tableCell}>
              {getToCommas(value[selectRole].view)}
            </TableCell>
            <TableCell component="th" scope="row" className={classes.tableCell}>
              {getToCommas(value[selectRole].click)}
            </TableCell>
            <TableCell component="th" scope="row" className={classes.tableCell}>
              {`${value[selectRole].ctr}%`}
            </TableCell>
            {!isHideWatchData && (
            <>
              <TableCell component="th" scope="row" className={classes.tableCell}>
                {getToCommas(value[selectRole].watch)}
              </TableCell>
              <TableCell component="th" scope="row" className={classes.tableCell}>
                {`${value[selectRole].vtr}%`}
              </TableCell>
            </>
            )}
          </TableRow>
        ))
      }
    </TableHeader>
  )
}
ReportTable.propTypes = {
  classes: PropTypes.shape({
    tableCell: PropTypes.string,
  }).isRequired,
  isHideWatchData: PropTypes.bool.isRequired,
  adTypeTotalReport: PropTypes.shape({
    view: PropTypes.number,
    click: PropTypes.number,
    watch: PropTypes.number,
    vtr: PropTypes.number,
  }).isRequired,
  selectRole: PropTypes.string.isRequired,
  adReportData: PropTypes.shape({}),
}

ReportTable.defaultProps = {
  adReportData: {},
}

export default withStyles(styles)(ReportTable)
