import React from 'react'
import PropTypes from 'prop-types'
import {
  BrowserRouter as Router, Switch, Route, Redirect,
} from 'react-router-dom'
import { map } from 'lodash'

import { getItem } from '@/shared/utils'
import { routes } from './features'

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      getItem('token')
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/sign_in' }} />
    )}
  />
)

const NotFound = () => <Redirect to="/campaign" />

const AppRouter = () => (
  <Router>
    <Switch>
      { map(routes, routeProps => !routeProps.requiresAuth && <Route {...routeProps} />)}
      { map(routes, routeProps => routeProps.requiresAuth && <PrivateRoute {...routeProps} />)}
      <Route component={NotFound} />
    </Switch>
  </Router>
)

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
}

export default AppRouter
