/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import PropTypes from 'prop-types'

import Remark from './Remark'
import { Dropdown, TextInput } from '../unit/form'

const makeStyles = () => ({
  field: ({ spacing, mq }) => css`
    ${mq({
    marginBottom: `${spacing(2)}rem`,
    marginRight: ['0', `${spacing(3.25)}rem`],
    width: ['100%', '38.4rem'],
  })}

    :nth-of-type(even) {
      margin-right: 0;
    }
  `,

  fieldWrap: () => css`
    display: flex;
    flex-wrap: wrap;
  `,
  root: ({ colors: { grey }, spacing }) => css`
    padding-bottom: ${spacing(5)}rem;
    border-bottom: 1px solid ${grey[200]};
  `,
  title: ({ colors: { grey }, font, spacing }) => css`
    color: ${grey[900]};
    font-size: ${font.title3.fontSize};
    font-weight: bold;
    line-height: ${font.title3.lineHeight};
    margin-bottom: ${spacing(3)}rem;
    margin-top: ${spacing(5)}rem;
  `,
})

const visitRoutes = [
  '클래스팅 사용자',
  '광고',
  '지인',
  '클래스팅 홈페이지',
  '클래스팅 블로그',
  '기타',
]

const ManagerInformation = ({ inquireInfo, setInquireInfo }) => {
  const styles = makeStyles()

  const handleTextInputChange = ({ target: { value, name } }) => {
    setInquireInfo(prev => ({ ...prev, [name]: value }))
  }

  const selectVisitRoute = (route) => {
    setInquireInfo(prev => ({ ...prev, visitRoute: route }))
  }

  return (
    <div css={styles.root}>
      <h3 css={styles.title}>담당자정보</h3>
      <div css={styles.fieldWrap}>
        <TextInput
          customStyles={styles.field}
          name="name"
          placeholder="이름을 입력하세요"
          label="이름"
          value={inquireInfo.name}
          isRequired
          onChange={handleTextInputChange}
        />
        <TextInput
          customStyles={styles.field}
          name="title"
          placeholder="직함을 입력하세요"
          label="직함"
          value={inquireInfo.title}
          isRequired
          onChange={handleTextInputChange}
        />
        <TextInput
          customStyles={styles.field}
          name="email"
          placeholder="이메일을 입력하세요"
          label="이메일"
          value={inquireInfo.email}
          isRequired
          onChange={handleTextInputChange}
        />
        <TextInput
          customStyles={styles.field}
          name="contact"
          placeholder="연락처을 입력하세요"
          label="연락처"
          value={inquireInfo.contact}
          isRequired
          onChange={handleTextInputChange}
        />
        <Dropdown
          customStyles={styles.field}
          items={visitRoutes}
          label="방문 경로"
          selectOption={selectVisitRoute}
          placeholder="방문 경로를 선택하세요"
          value={inquireInfo.visitRoute}
          isRequired
        />
      </div>
      <Remark text="* 필수 입력사항입니다." />
    </div>
  )
}

ManagerInformation.propTypes = {
  inquireInfo: PropTypes.shape({
    contact: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    name: PropTypes.string,
    title: PropTypes.string.isRequired,
    visitRoute: PropTypes.string.isRequired,
  }).isRequired,
  setInquireInfo: PropTypes.func.isRequired,
}

export default ManagerInformation
