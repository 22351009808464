/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import PropTypes from 'prop-types'

const makeStyles = () => ({
  root: ({ colors: { grey }, font, spacing }) => css`
    color: ${grey[600]};
    font-size: ${font.body2.fontSize};
    line-height: ${font.body2.lineHeight};
    padding: ${spacing(1.25)}rem ${spacing(3)}rem;

    :active {
      background-color: rgba(0, 200, 150, 0.1);
    }
  `,
})

const DropdownOption = ({ name, selectOption }) => {
  const styles = makeStyles()

  const handleOptionClick = () => {
    selectOption(name)
  }

  return (
    <div css={styles.root} onClick={handleOptionClick}>
      {name}
    </div>
  )
}

DropdownOption.propTypes = {
  name: PropTypes.string.isRequired,
  selectOption: PropTypes.func.isRequired,
}

export default DropdownOption
