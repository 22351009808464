import reduce from 'lodash/reduce'
import has from 'lodash/has'
import get from 'lodash/get'
import find from 'lodash/find'
import map from 'lodash/map'

import { getRatio } from '@/shared/utils'
import {
  LOG_ZERO_VALUE,
  GRAPH_STATS,
  STAT_CTR,
  STAT_CLICK,
  STAT_VIEW,
} from '@/shared/constants/graph'

export const setPostData = (stat, viewTemplate) => {
  if (!stat) {
    return {}
  }

  const hasWatch = has(stat, 'watch') && viewTemplate === 'post'
  const ctr = getRatio(stat.click, stat.view)

  const watchView = hasWatch ? stat.view : 0
  const vtr = getRatio(stat.watch, stat.view)

  return {
    ...stat,
    watchView,
    ctr,
    vtr,
  }
}

export const addAdStats = (aAdStat, bAdStat) => {
  const aView = get(aAdStat, 'view', 0)
  const aClick = get(aAdStat, 'click', 0)
  const aWatch = get(aAdStat, 'watch', 0)
  const aWatchView = get(aAdStat, 'watchView', 0)

  const bView = get(bAdStat, 'view', 0)
  const bClick = get(bAdStat, 'click', 0)
  const bWatch = get(bAdStat, 'watch', 0)
  const bWatchView = get(bAdStat, 'watchView', 0)

  return {
    view: bView + aView,
    click: bClick + aClick,
    watch: bWatch + aWatch,
    watchView: bWatchView + aWatchView,
    vtr: getRatio(bWatch + aWatch, bWatchView + aWatchView) || '0.00',
    ctr: getRatio(bClick + aClick, bView + aView) || '0.00',
  }
}

export const convertAdCountCacheArrayToStats = (groupByAdId) => {
  const result = {}
  Object.keys(groupByAdId).forEach((adId) => {
    const adReports = groupByAdId[adId]
    const statsByDate = {}
    adReports.forEach((adReport) => {
      if (!statsByDate[adReport.date]) {
        statsByDate[adReport.date] = {}
      }
      statsByDate[adReport.date][adReport.event_type] = adReport.count
    })
    result[adId] = []
    Object.keys(statsByDate).forEach((date) => {
      result[adId].push({
        advertisement_id: adId,
        date,
        stats: { all: statsByDate[date] },
      })
    })
  })
  return result
}
export const getAllAdStats = stats => reduce(stats, (result, stat) => {
  const resultView = get(result, 'view', 0)
  const resultClick = get(result, 'click', 0)
  const resultWatch = get(result, 'watch', 0)
  const resultWatchView = resultWatch ? get(result, 'watchView', 0) : 0

  const statView = get(stat, 'view', 0)
  const statClick = get(stat, 'click', 0)
  const statWatch = get(stat, 'watch', 0)
  const statWatchView = (resultWatch || statWatch) ? statView : 0

  const view = resultView + statView
  const click = resultClick + statClick
  const watch = resultWatch + statWatch
  const watchView = resultWatchView + statWatchView
  const ctr = getRatio(click, view)
  const vtr = getRatio(watch, watchView)

  return {
    view,
    click,
    watch,
    ctr,
    vtr,
    watchView,
  }
}, {
  click: 0,
  view: 0,
  ctr: '0.00',
  vtr: '0.00',
  watch: 0,
  watchView: 0,
})

export const isHideWatchData = adStats => (selectRole) => {
  const hasWatch = find(adStats, stat => (
    get(stat, [selectRole, 'watch']) !== 0 && has(stat, [selectRole, 'watch'])
  ))

  return !hasWatch
}

/**
 * @param {adStats} 광고 stats
 * @param {selectRole} 선택된 role (all, teacher, student, parent)
 * @return line 그래프 데이터
 * @example [
 *   { id: view, color: "#000", data: [{x: '2020-05-20', y: 1} ...] },
 *   ...
 * ]
 */
export const formatLineData = (adStats, selectRole) => {
  const isHideWatch = isHideWatchData(adStats)(selectRole)
  /*
   * 데이터의 순서는 Legend 순서와 일치
   * 재생 데이터가 없을 경우에는 노출 수, 클릭 수, CTR 순서로 정의
   * 재생 데이터가 있을 경우에는 노출 수, 클릭 수, 재생 수, VTR, CTR 순서로 정의
   */
  const statsKeys = isHideWatch ? [STAT_VIEW, STAT_CLICK, STAT_CTR] : Object.keys(GRAPH_STATS)

  const lineData = statsKeys.map((id) => {
    const data = reduce(adStats, (accLineData, stat, date) => {
      const selectedData = stat[selectRole]

      return [
        ...accLineData,
        { x: date, y: Number(selectedData[id]) === 0 ? LOG_ZERO_VALUE : selectedData[id] },
      ]
    }, [])

    data.sort((a, b) => new Date(a.x) - new Date(b.x))

    return {
      id,
      color: '#000',
      data,
    }
  })

  return lineData
}

export const getAdStatsById = (adReports = []) => (selectedAdId) => {
  const foundAdSet = adReports
    .map(({ advertisements }) => (
      advertisements.find(({ id }) => id === selectedAdId)
    ))
    .filter(Boolean)
  return get(foundAdSet, [0, 'adStats'])
}

export const getGraphColorByActivateLegend = (selectedItems = {}) => {
  const colors = map(GRAPH_STATS, (stat, key) => (
    selectedItems[key] ? stat.color : null
  )).filter(Boolean)

  return colors
}
