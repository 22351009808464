/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import PropTypes from 'prop-types'

const makeStyles = ({ isShow }) => ({
  image: () => css`
    display: block;
    width: 100%;
  `,
  imageWrap: () => css`
    width: 100%;
  `,
  root: () => css`
    transition: opacity 2s;
    width: 100%;
    opacity: ${isShow ? '1' : '0'};
  `,
})

const SliderItem = ({ isShow, url }) => {
  const styles = makeStyles({ isShow })

  return (
    <div css={styles.root}>
      <figure css={styles.imageWrap}>
        <img css={styles.imag} src={url} alt="" />
      </figure>
    </div>
  )
}

SliderItem.propTypes = {
  isShow: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
}

export default SliderItem
