import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'

class CircularUnderLoad extends Component {
  static defaultProps = {
    size: 36,
    styles: '',
  }

  componentDidMount() {
  }

  render() {
    const { size, styles } = this.props

    return (
      <CircularProgress disableShrink color="primary" size={size} className={styles} />
    )
  }
}

CircularUnderLoad.propTypes = {
  styles: PropTypes.string,
  size: PropTypes.number,
}

export default CircularUnderLoad
