/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import PropTypes from 'prop-types'

import Remark from './Remark'
import { TextInput, Dropdown } from '../unit/form'

const makeStyles = () => ({
  field: ({ spacing, mq }) => css`
    ${mq({
    marginBottom: `${spacing(2)}rem`,
    marginRight: ['0', `${spacing(3.25)}rem`],
    width: ['100%', '38.4rem'],
  })}

    :nth-of-type(even) {
      margin-right: 0;
    }
  `,
  fieldWrap: () => css`
    display: flex;
    flex-wrap: wrap;
  `,
  root: ({ colors: { grey }, spacing }) => css`
    padding-bottom: ${spacing(5)}rem;
    border-bottom: 1px solid ${grey[200]};
  `,
  title: ({ colors: { grey }, font, spacing }) => css`
    color: ${grey[900]};
    font-size: ${font.title3.fontSize};
    font-weight: bold;
    line-height: ${font.title3.lineHeight};
    margin-bottom: ${spacing(3)}rem;
  `,
})

const businessClassifications = ['광고주', '대행사', '미디어랩사']

const CompanyInformation = ({ inquireInfo, setInquireInfo }) => {
  const styles = makeStyles()

  const handleTextInputChange = ({ target: { value, name } }) => {
    setInquireInfo(prev => ({ ...prev, [name]: value }))
  }

  const selectBusinessClassification = (name) => {
    setInquireInfo(prev => ({ ...prev, businessClassification: name }))
  }

  return (
    <div css={styles.root}>
      <h3 css={styles.title}>기업정보</h3>
      <div css={styles.fieldWrap}>
        <TextInput
          customStyles={styles.field}
          name="companyName"
          placeholder="회사명을 입력하세요"
          label="회사명"
          value={inquireInfo.companyName}
          isRequired
          onChange={handleTextInputChange}
        />
        <Dropdown
          customStyles={styles.field}
          items={businessClassifications}
          label="사업자 분류"
          selectOption={selectBusinessClassification}
          placeholder="사업자 분류를 선택하세요"
          value={inquireInfo.businessClassification}
          isRequired
        />
        <TextInput
          customStyles={styles.field}
          name="brandName"
          placeholder="담당 브랜드를 입력하세요"
          label="담당 브랜드"
          value={inquireInfo.brandName}
          onChange={handleTextInputChange}
        />
      </div>
      <Remark text="* 필수 입력사항입니다." />
    </div>
  )
}

CompanyInformation.propTypes = {
  inquireInfo: PropTypes.shape({
    brandName: PropTypes.string,
    businessClassification: PropTypes.string.isRequired,
    companyName: PropTypes.string.isRequired,
  }).isRequired,
  setInquireInfo: PropTypes.func.isRequired,
}

export default CompanyInformation
