import React from 'react'
import { Provider } from 'react-redux'
import { MuiThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import MomentUtils from '@date-io/moment'
import { includes } from 'lodash'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import 'bootstrap/dist/css/bootstrap.css'
import GoogleAnalytics from 'react-ga'
import { LanguageProvider } from './shared/components'
import AppRouter from './Router'
import { theme } from './shared/styles'
import configureStore from './configureStore'

GoogleAnalytics.initialize(process.env.REACT_APP_GA_CODE)

const store = configureStore()

const language = includes((navigator.language || navigator.userLanguage), 'zh')
  ? navigator.language || navigator.userLanguage
  : (navigator.language || navigator.userLanguage).split(/[-_]/)[0]

const App = () => (
  <Provider store={store}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <LanguageProvider language={language}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <AppRouter />
        </MuiThemeProvider>
      </LanguageProvider>
    </MuiPickersUtilsProvider>
  </Provider>
)

export default App
