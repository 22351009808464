import { createMuiTheme } from '@material-ui/core/styles'

export default createMuiTheme({
  palette: {
    primary: {
      main: '#00c896',
    },
    secondary: {
      main: '#5aaafa',
    },
    tertiary: {
      main: '#E10050',
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      '"Apple SD Gothic Neo"',
      '"Nanum Gothic"',
      '"나눔고딕"',
      'NanumGothic',
      '"돋움"',
      'Dotum',
      '"굴림"',
      'Gulim',
      'Helvetica',
      'sans-serif',
    ].join(','),
  },
})
