import { connect } from 'react-redux'

import ImpressionRatePage from './ImpressionRatePage'
import { fetchImpressionRate, createImpressionRate } from '../actions'

const mapStateToProps = state => ({
  impressionRate: state.impression.impressionRate,
})

const mapDispatchToProps = dispatch => ({
  onFetchImpressionRate: params => dispatch(fetchImpressionRate(params)),
  onCreateImpressionRate: params => dispatch(createImpressionRate(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ImpressionRatePage)
