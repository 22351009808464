/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { useContext } from 'react'

import { uiStore, VIEW_SIZE_PC } from '@landing/contexts/ui'
import Logo from '../Logo'

const makeStyles = () => ({
  inquire: ({ colors: { grey }, font, spacing }) => css`
    color: ${grey[600]};
    margin-top: ${spacing()}rem;
    font-size: ${font.body3.fontSize};
    line-height: ${font.body3.lineHeight};
  `,
  root: ({ mq, spacing }) => css`
    ${mq({
    marginBottom: [`${spacing(4)}rem`, '0'],
  })}
  `,
  strong: () => css`
    font-weight: bold;
  `,
})

const LogoArea = () => {
  const {
    state: { viewSize },
  } = useContext(uiStore)
  const styles = makeStyles()

  return (
    <div css={styles.root}>
      <Logo />
      <p css={styles.inquire}>
        {viewSize === VIEW_SIZE_PC && (
          <span css={styles.strong}>광고 문의 </span>
        )}
        <a href="mailto:ad-sales-part@classting.com">ad-sales-part@classting.com</a>
      </p>
    </div>
  )
}

export default LogoArea
