import { handleActions } from 'redux-actions'
import {
  FETCH_IMPRESSION_RATE_PENDING,
  FETCH_IMPRESSION_RATE_SUCCESS,
  FETCH_IMPRESSION_RATE_FAILURE,
  CREATE_IMPRESSION_RATE_PENDING,
  CREATE_IMPRESSION_RATE_SUCCESS,
  CREATE_IMPRESSION_RATE_FAILURE,
} from './actions'

export const initialState = {
  impressionRate: {},
}

const ImpressionRateArrayToObject = (data) => {
  const result = {}

  data.forEach(({
    view_template: viewTemplate, hour, impression_rate: impressionRate, role,
  }) => {
    if (!result[viewTemplate]) {
      result[viewTemplate] = {}
    }
    if (!result[viewTemplate][role]) {
      result[viewTemplate][role] = {}
    }
    result[viewTemplate][role][hour] = impressionRate
  })

  return result
}

export default handleActions({
  [FETCH_IMPRESSION_RATE_PENDING]: state => ({
    ...state,
  }),

  [FETCH_IMPRESSION_RATE_SUCCESS]: (state, action) => {
    const { data } = action.payload

    return {
      ...state,
      impressionRate: ImpressionRateArrayToObject(data),
    }
  },

  [FETCH_IMPRESSION_RATE_FAILURE]: (state, action) => {
    const { data } = action.payload
    return {
      ...state,
      data,
    }
  },

  [CREATE_IMPRESSION_RATE_PENDING]: state => ({
    ...state,
  }),

  [CREATE_IMPRESSION_RATE_SUCCESS]: (state, action) => {
    const { data } = action.payload

    return {
      ...state,
      data,
    }
  },

  [CREATE_IMPRESSION_RATE_FAILURE]: (state, action) => {
    const { data } = action.payload
    return {
      ...state,
      data,
    }
  },

}, initialState)
