export const validateTextInput = value => (value ? undefined : 'Required!')

export const validateFile = value => (value ? undefined : 'Required')

export const validateTargetGroups = value => (value.length ? undefined : 'Required')

export const validateEmail = (value) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return !re.test(String(value).toLowerCase())
}

export const validateJSONString = (str) => {
  try {
    const json = JSON.parse(str)
    return typeof json === 'object'
  } catch (error) {
    return false
  }
}
