import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import { Text, Caution } from '../atoms'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: '94px',
    padding: '0 40px',
    background: '#fff',
  },
  title: {
    marginRight: '16px',
  },
}))

const TitleTemplate = ({ children, title, caution }) => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.root}>
        <Text className={classes.title} size="lg" color="darkGray">{title}</Text>
        {caution && <Caution text={caution} size="md" />}
      </div>
      {children}
    </>
  )
}

TitleTemplate.defaultProps = {
  caution: '',
}

TitleTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  caution: PropTypes.string,
}

export default TitleTemplate
