import {
  CampaignPage, ReportPage, PublicReportPage,
} from './page'

export default [
  {
    key: 'campaign',
    requiresAuth: true,
    exact: true,
    path: '/campaign',
    component: CampaignPage,
  },
  {
    key: 'report',
    requiresAuth: true,
    exact: false,
    path: '/campaign/:campaignId',
    component: ReportPage,
  },
  {
    key: 'publicReport',
    requiresAuth: false,
    exact: false,
    path: '/campaign/:campaignId/:guestToken',
    component: PublicReportPage,
  },
]
