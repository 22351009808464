import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import get from 'lodash/get'
import find from 'lodash/find'
import size from 'lodash/size'
import flatten from 'lodash/flatten'

import {
  getAdTotalSelector,
  getAdReportsSelector,
  getAdTotalByAdIdSelector,
  getAdTotalByDateSelector,
  getAdsTotalInOneAdTypeSelector,
  getAdsTotalDateInOneAdTypeSelector,
  getLineGraphAdsDateTotalInAdTypeSelector,
  getAdsTotalInOneAdSetSelector,
  getAdsTotalDateInOneAdSetSelector,
  getAdTotalOfDateForLineGraphSelector,
  getAdsTotalDateInOneAdSetForLineGraphSelector,
} from '@/features/campaign/selectors'
import {
  formatLineData,
  isHideWatchData,
  getAdStatsById,
} from '@/features/campaign/utils'
import ReportSelectGrouping from './ReportSelectGrouping'
import ReportTable from './ReportTable'
import LineChart from './ResponsiveLineChart'
import AdPreview from './AdPreview'

const AD_TOTAL_TYPE = 'all'
const AD_SET_TOTAL = 0
const AD_TOTAL = -1

const AdReportCharts = ({
  selectRole,
  adReports,
  getAdTotalReport,
  getAllAdsTotalDate,
  getAllAdsTotalDateForLine,
  getAdTotalByAdId,
  getAdsTotalInOneAdType,
  getAdsTotalDateInOneAdType,
  getLineGraphAdsDateTotalInAdType,
  getAdsTotalInOneAdSet,
  getAdsTotalDateInOneAdSet,
  getLineGraphAdsDateTotalInAdSet,
}) => {
  const [selectedAdType, setSelectedType] = useState(AD_TOTAL_TYPE)
  const [selectedAdSetId, setSelectedAdSetId] = useState(undefined)
  const [selectedAdId, setSelectedAdId] = useState(undefined)

  const totalAdsInOneAdType = getAdsTotalInOneAdType({ selectedAdType, selectRole })
  const totalAdsDateInOneAdType = getAdsTotalDateInOneAdType({ selectedAdType, selectRole })
  const totalAdsDateInAdTypeLine = getLineGraphAdsDateTotalInAdType({ selectedAdType, selectRole })

  const totalAdsInOneAdSet = getAdsTotalInOneAdSet({ selectedAdSetId, selectRole })
  const totalAdsDateInOneAdSet = getAdsTotalDateInOneAdSet({ selectedAdSetId, selectRole })
  const totalAdsDateInAdSetLine = getLineGraphAdsDateTotalInAdSet({ selectedAdSetId, selectRole })

  const allAdSetTotal = getAdTotalReport({ selectRole })
  const allAdsTotalDate = getAllAdsTotalDate({ selectRole })

  const allAdsTotalDateForLine = getAllAdsTotalDateForLine({ selectRole })

  const adReportList = flatten(Object.values(adReports))

  const handleAdSetSelectChange = (event) => {
    const { value } = event.target
    const filterAdSet = find(adReportList, ({ id }) => id === value)

    setSelectedAdSetId(value)
    const hasAdList = size(get(filterAdSet, 'advertisements')) > 1
    setSelectedAdId(hasAdList ? AD_TOTAL : get(filterAdSet, ['advertisements', [0], 'id']))
  }

  const handleAdSelectChange = event => setSelectedAdId(Number(event.target.value))

  const handleAdTypeChange = ({ target: { value } }) => {
    setSelectedType(value)
    setSelectedAdSetId(value === AD_TOTAL_TYPE ? undefined : AD_SET_TOTAL)
    setSelectedAdId(undefined)
  }

  const adStats = getAdStatsById(adReportList)(selectedAdId)

  const displayCharts = () => {
    if (selectedAdType === AD_TOTAL_TYPE) {
      return (
        <>
          <LineChart data={allAdsTotalDateForLine} />
          <ReportTable
            adTypeTotalReport={allAdSetTotal}
            adReportData={allAdsTotalDate}
            selectRole={selectRole}
            isAdSetTotalTab
            isHideWatchData={isHideWatchData(allAdsTotalDate)(selectRole)}
          />
        </>
      )
    }

    if (selectedAdSetId === AD_SET_TOTAL) {
      return (
        <>
          <LineChart data={totalAdsDateInAdTypeLine} />
          <ReportTable
            adTypeTotalReport={totalAdsInOneAdType}
            adReportData={totalAdsDateInOneAdType}
            selectRole={selectRole}
            isAdSetTotalTab
            isHideWatchData={isHideWatchData(totalAdsDateInOneAdType)(selectRole)}
          />
        </>
      )
    }

    if (selectedAdId === AD_TOTAL) {
      return (
        <>
          <LineChart data={totalAdsDateInAdSetLine} />
          <ReportTable
            adTypeTotalReport={totalAdsInOneAdSet}
            adReportData={totalAdsDateInOneAdSet}
            selectRole={selectRole}
            isHideWatchData={isHideWatchData(totalAdsDateInOneAdSet)(selectRole)}
          />
        </>
      )
    }

    return (
      <>
        <LineChart data={formatLineData(adStats, selectRole)} />
        <ReportTable
          adTypeTotalReport={getAdTotalByAdId({ selectedAdId, selectRole })}
          adReportData={adStats}
          selectRole={selectRole}
          isHideWatchData={isHideWatchData(adStats)(selectRole)}
        />
      </>
    )
  }

  return (
    <ReportSelectGrouping
      selectedAdSetId={selectedAdSetId}
      selectedType={selectedAdType}
      adReports={adReports}
      handleAdSetSelectChange={handleAdSetSelectChange}
      totalAdSetValue={AD_SET_TOTAL}
      totalAdValue={AD_TOTAL}
      totalAdType={AD_TOTAL_TYPE}
      selectedAdId={selectedAdId}
      handleAdSelectChange={handleAdSelectChange}
      handleAdTypeChange={handleAdTypeChange}
    >
      <>
        <AdPreview selectedAdType={selectedAdType} selectedAdSetId={selectedAdSetId} />
        {displayCharts()}
      </>
    </ReportSelectGrouping>
  )
}

AdReportCharts.propTypes = {
  selectRole: PropTypes.string.isRequired,
  getAdTotalReport: PropTypes.func.isRequired,
  adReports: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  // all of the ads total
  getAdTotalByAdId: PropTypes.func.isRequired,
  getAllAdsTotalDate: PropTypes.func.isRequired,
  getAllAdsTotalDateForLine: PropTypes.func.isRequired,
  getAdsTotalInOneAdType: PropTypes.func.isRequired,
  getAdsTotalDateInOneAdType: PropTypes.func.isRequired,
  getLineGraphAdsDateTotalInAdType: PropTypes.func.isRequired,
  // total ads in one ad set
  getAdsTotalInOneAdSet: PropTypes.func.isRequired,
  getAdsTotalDateInOneAdSet: PropTypes.func.isRequired,
  getLineGraphAdsDateTotalInAdSet: PropTypes.func.isRequired,
}

export default connect(
  state => ({
    getAdTotalReport: ({ selectRole }) => getAdTotalSelector(state, { selectRole }),
    adReports: getAdReportsSelector(state),
    // all of the ads total
    getAdTotalByAdId: ({ selectedAdId, selectRole }) => (
      getAdTotalByAdIdSelector(state, { selectedAdId, selectRole })
    ),
    getAllAdsTotalDate: ({ selectRole }) => getAdTotalByDateSelector(state, { selectRole }),
    getAllAdsTotalDateForLine: ({ selectRole }) => (
      getAdTotalOfDateForLineGraphSelector(state, { selectRole })
    ),
    getAdsTotalInOneAdType: ({ selectedAdType, selectRole }) => (
      getAdsTotalInOneAdTypeSelector(state, { selectedAdType, selectRole })
    ),
    getAdsTotalDateInOneAdType: ({ selectedAdType, selectRole }) => (
      getAdsTotalDateInOneAdTypeSelector(state, { selectedAdType, selectRole })
    ),
    getLineGraphAdsDateTotalInAdType: ({ selectedAdType, selectRole }) => (
      getLineGraphAdsDateTotalInAdTypeSelector(state, { selectedAdType, selectRole })
    ),
    // total ads in one ad set
    getAdsTotalInOneAdSet: ({ selectedAdSetId, selectRole }) => (
      getAdsTotalInOneAdSetSelector(state, { selectedAdSetId, selectRole })
    ),
    getAdsTotalDateInOneAdSet: ({ selectedAdSetId, selectRole }) => (
      getAdsTotalDateInOneAdSetSelector(state, { selectedAdSetId, selectRole })
    ),
    getLineGraphAdsDateTotalInAdSet: ({ selectedAdSetId, selectRole }) => (
      getAdsTotalDateInOneAdSetForLineGraphSelector(state, { selectedAdSetId, selectRole })
    ),
  }),
  null,
)(AdReportCharts)
