import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  Row, Col, Form, FormGroup, Label, Input,
} from 'reactstrap'
import { Formik } from 'formik'
import {
  isEmpty, find, map, head, every, isArray, isString,
} from 'lodash'
import DialogContentText from '@material-ui/core/DialogContentText'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Button from '@material-ui/core/Button'
import * as Yup from 'yup'
import Dropzone from 'react-dropzone'
import queryString from 'query-string'
import { CircularUnderLoad, EnhancedDialog } from '@/shared/components'
import { getItem } from '@/shared/utils'
import userProfile from '@/shared/assets/user-profile.png'
import {
  setImage, setVideo, deleteImage, updateCampaigns,
} from '../actions'

const styles = ({
  container: {
    padding: '24px',
  },
  formContainer: {
    paddingTop: '24px',
  },
  fieldError: {
    margin: '4px 0 4px 0',
    border: '1px solid #e85d7b',
  },
  validFeedback: {
    color: '#5aaafa',
    fontSize: '12px',
  },
  invalidFeedback: {
    color: '#e85d7b',
    fontSize: '12px',
  },
  productLabel: {
    color: '#00c896',
    border: '1px solid #00c896',
  },
  actionType: {
    width: '236px',
    height: '40px',
  },
  primary: {
    width: '100%',
  },
  post: {
    width: '360px',
    height: 'auto',
    margin: '0 auto',
  },
  postSlide: {
    width: '132px',
    height: 'auto',
    borderRadius: '2px',
    margin: '0 auto',
  },
  postSlideTitle: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, 0.87)',
    marginBottom: 0,
  },
  postSlideText: {
    padding: '14px',
  },
  postSlideImage: {
    width: '100%',
    height: '124px',
    backgroundColor: '#d8d8d8',
  },
  comment: {
    width: '360px',
    height: 'auto',
    margin: '0 auto',
  },
  submitButton: {
    width: '24%',
    float: 'right',
    marginRight: '14px',
  },
  nextButton: {
    color: '#ffffff',
    width: '24%',
    float: 'right',
  },
  profileContainer: {
    position: 'absolute',
    top: '16px',
  },
  profileImage: {
    width: '36px',
    height: '36px',
    border: 'solid 1px rgba(0, 0, 0, 0.1)',
    borderRadius: '36px',
  },
  postImage: {
    width: '360px',
    height: '360px',
  },
  postTitle: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#333333',
  },
  postText: {
    wordBreak: 'break-all',
  },
  postSubTitle: {
    fontSize: '12px',
    color: '#b3b3b3',
  },
  postActionButton: {
    marginLeft: 'auto',
  },
  notice: {
    width: '380px',
    height: 'auto',
    backgroundColor: '#1f344f',
    color: '#ffffff',
    margin: '0 auto',
  },
  noticeTitle: {
    color: '#ffffff',
  },
  noticeText: {
    color: '#ffffff',
  },
  noticeActionButton: {
    width: '180px',
    margin: '0 auto',
    color: '#ffffff',
    border: '1px solid #ffffff',
  },
  inline: {
    display: 'inline-block',
  },
  commentImage: {
    width: '110px',
    height: '82px',
    borderRadius: '4px',
  },
  profileName: {
    display: 'inline-block',
    fontSize: '14px',
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  profileSubName: {
    marginLeft: '10px',
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.38)',
  },
  loadingOverlay: {
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: 200,
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
  fieldSubLabel: {
    cursor: 'pointer',
    color: '#757575',
    fontSize: '14px',
    textAlign: 'right',
    marginTop: '8px',
  },
  modalBody: {
    widith: '400px',
  },
  modalLabel: {
    color: '#757575',
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: '6px',
  },
})

const mapStateToProps = state => ({
  pending: state.editing.pending,
})

const mapDispatchToProps = dispatch => ({
  onCampaignsUpdate: data => dispatch(updateCampaigns(data)),
})

class AdForm extends Component {
  state = {
    loading: false,
    selectedThemeId: 0,
    actionType: 'outer_browser',
    imageType: 'show',
    imageFile: null,
    isOpenModal: false,
  }

  componentWillReceiveProps(nextProps) {
    const { imageType } = this.state
    if (
      imageType === 'hide'
      && (nextProps.ad.advertisement_set && nextProps.ad.advertisement_set.view_template === 'comment')
      && isEmpty(nextProps.ad.image)
    ) {
      this.setState({ imageType: 'hide' })
    }
  }

  findAdSet = (adSets) => {
    const { adProduct } = this.props
    return find(adSets, { view_template: adProduct.view_template }) || {}
  }

  findAd = () => {
    const { findAdSet } = this
    const { adSets } = this.props
    return head(findAdSet(adSets).advertisements) || {}
  }

  findTheme = () => {
    const { themes } = this.props
    const { selectedThemeId } = this.state
    return find(themes, { id: selectedThemeId }) || {}
  }

  onThemeChange = setFieldValue => (event) => {
    setFieldValue('theme_id', event.target.value)
    this.setState({ selectedThemeId: event.target.value })
  }

  handleActionTypeChange = setFieldValue => (event) => {
    setFieldValue('action_type', event.target.value)
    this.setState({ actionType: event.target.value })
  }

  handleImageTypeChange = (event) => {
    this.setState({ imageType: event.target.value })
  }

  showNextButton = () => {
    const { adSets } = this.props
    return every(adSets, item => !isEmpty(head(item.advertisements) || {}))
  }

  handleNextButtonClick = () => {
    const {
      history, campaignId, adFormType, campaign, onCampaignsUpdate, unblock,
    } = this.props

    /* eslint-disable no-alert */
    if (adFormType === 'update') {
      if (!window.confirm('소재수정 버튼을 누르지 않으면 저장되지 않습니다. 다음단계로 이동하시겠습니까?')) {
        return true
      }
    }
    /* eslint-disable */

    unblock()
    const params = {
      campaign_id: campaignId,
      form_type: adFormType,
    }

    if (campaign.status === 'rejected' && window.confirm('재 심사 요청하시겠습니까?')) {
      const campaignData = {
        id: campaignId,
        event: 'request',
      }

      return onCampaignsUpdate(campaignData)
        .then(() => (
          history.replace({
            pathname: '/payment_form',
            search: queryString.stringify(params),
          })
        ))
    }

    if (campaign.status !== 'rejected') {
      return history.replace({
        pathname: '/payment_form',
        search: queryString.stringify(params),
      })
    }

    return true
  }

  renderAdPreview = (adProduct, values) => {
    const { classes, campaign, ad } = this.props
    const { imageFile } = this.state
    const { findTheme } = this
    const makeHtmlDescription = description => (
      description.split('\n').map(item => (<span>{item}<br/></span>))
    )

    if (adProduct.view_template === 'post' && (adProduct.product_type === 'image' || adProduct.product_type === 'video')) {
      return (
        <Card className={classes.post}>
          <CardHeader
            avatar={
              <img
                className={classes.profileImage}
                src={userProfile}
                alt="user_profile"
              />
            }
            classes={{ title: classes.profileName }}
            title={campaign.advertiser && campaign.advertiser.name}
            subheader="Sponsored"
          />
          <CardContent>
            <Typography component="p" className={classes.postText}>
              {makeHtmlDescription(values.description)}
            </Typography>
          </CardContent>
          {(!isEmpty(imageFile) || !isEmpty(ad.image)) &&
            <CardMedia
              className={classes.postImage}
              image={imageFile || ad.image.original_url}
            />
          }
          <CardActions className={classes.actions} disableActionSpacing>
            <Typography>{values.title}</Typography>
            <Button
              variant="outlined"
              color="primary"
              className={classes.postActionButton}
            >
              {findTheme().action_label || ad.theme && ad.theme.action_label || '버튼문구'}
            </Button>
          </CardActions>
        </Card>
      )
    }

    if (adProduct.view_template === 'notice' && adProduct.product_type === 'text') {
      return (
        <Card className={classes.notice}>
          <CardHeader
            classes={{
              title: classes.noticeTitle,
              subheader: classes.noticeTitle,
            }}
            title={campaign.advertiser && campaign.advertiser.name}
            subheader="Sponsored"
          />
          <CardContent>
            <Typography
              component="p"
              color="default"
              className={classes.noticeText}
            >
              {makeHtmlDescription(values.description)}
            </Typography>
          </CardContent>
          <CardActions className={classes.actions} disableActionSpacing>
            <Button
              variant="outlined"
              color="default"
              className={classes.noticeActionButton}
            >
              {findTheme().action_label || ad.theme && ad.theme.action_label || '버튼문구'}
            </Button>
          </CardActions>
        </Card>
      )
    }

    if (adProduct.view_template === 'comment') {
      return (
        <Card className={classes.comment}>
          <List>
            <ListItem>
              <div className={classes.profileContainer}>
                <img
                  className={classes.profileImage}
                  src={userProfile}
                  alt="user_profile"
                />
              </div>
              <ListItemText
                style={{ marginLeft: '42px' }}
                primary={
                  <Typography component="span" className={classes.profileName} color="default">
                    {values.title}<span className={classes.profileSubName}>Sponsored</span>
                  </Typography>
                }
                secondary={
                  <>
                    <Typography component="span" color="default" style={{ width: '166px' }}>
                      {makeHtmlDescription(values.description)}
                    </Typography>
                    {findTheme().action_label || ad.theme && ad.theme.action_label || '버튼문구'}
                  </>
                }
              />
              <ListItemSecondaryAction>
                {(!isEmpty(imageFile) || !isEmpty(ad.image)) &&
                  <img
                    className={classes.commentImage}
                    src={imageFile || ad.image && ad.image.original_url}
                    alt="ad_image"
                  />
                }
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Card>
      )
    }

    if (adProduct.view_template === 'post_slide' && adProduct.product_type === 'image') {
      return (
        <Card className={classes.postSlide}>
          {(!isEmpty(imageFile) || !isEmpty(ad.image)) ?
            <CardMedia
              className={classes.postSlideImage}
              image={imageFile || ad.image && ad.image.original_url}
            /> :
            <CardMedia className={classes.postSlideImage} image />
          }
          <CardContent className={classes.postSlideText}>
            <Typography paragraph className={classes.postSlideTitle}>{values.title}</Typography>
            <Typography component="p">
              {makeHtmlDescription(values.description)}
            </Typography>
          </CardContent>
        </Card>
      )
    }

    return true
  }

  isAdmin = () => JSON.parse(getItem('isAdmin'))

  getImageLabelText = adProduct => {
    if (adProduct.view_template === 'comment') {
      return '배너 이미지'
    }

    if (adProduct.view_template === 'post' && adProduct.product_type === 'video') {
      return '영상 썸네일'
    }

    if (adProduct.view_template === 'post_slide') {
      return '사진'
    }

    return '이미지'
  }

  getTitleLabelText = adProduct => {
    if (adProduct.view_template === 'comment') {
      return '광고주 이름'
    }

    if (adProduct.view_template === 'post_slide') {
      return '상호명'
    }

    return '광고글 제목'
  }

  getValidationForm = () => {
    const { adProduct } = this.props
    const { imageType } = this.state
    const isVideo = adProduct.view_template === 'post' && adProduct.product_type === 'video'

    if (adProduct.view_template === 'comment' && imageType === 'show') {
      return {
        title: Yup.string()
          .min('2', '광고주 이름은 2자리 이상 입력해주세요.')
          .max('8', '광고주 이름은 8자리 까지 입력해주세요.')
          .required('광고주 이름은 필수 값입니다.'),
        description: Yup.string()
          .required('소재 내용은 필수 값입니다.')
          .min('2', '소재 내용은 2자리 이상 입력해주세요.')
          .max('16', '소재 내용은 16자리 까지 입력해주세요.'),
        action_url: Yup.string()
          .required('랜딩은 필수 입력 값입니다.'),
        theme_id: Yup.string()
          .required('버튼 문구는 필수 선택 값입니다.'),
        image_file: Yup.string()
          .required('배너 이미지는 필수 값입니다.')
      }
    }

    if (adProduct.view_template === 'comment' && imageType === 'hide') {
      return {
        title: Yup.string()
          .min('2', '광고주 이름은 2자리 이상 입력해주세요.')
          .max('8', '광고주 이름은 8자리 까지 입력해주세요.')
          .required('광고주 이름은 필수 값입니다.'),
        description: Yup.string()
          .required('소재 내용은 필수 값입니다.')
          .min('2', '소재 내용은 2자리 이상 입력해주세요.')
          .max('24', '소재 내용은 24자리 까지 입력해주세요.'),
        action_url: Yup.string()
          .required('랜딩은 필수 입력 값입니다.'),
        theme_id: Yup.string()
          .required('버튼 문구는 필수 선택 값입니다.')
      }
    }

    if (adProduct.view_template === 'post_slide') {
      return {
        title: Yup.string()
          .min('2', '상호명은 2자리 이상 입력해주세요.')
          .max('8', '상호명은 8자리 까지 입력해주세요.')
          .required('상호명은 필수 값입니다.'),
        description: Yup.string()
          .required('광고 내용은 필수 값입니다.')
          .min('2', '광고 내용은 2자리 이상 입력해주세요.')
          .max('16', '광고 내용은 16자리 까지 입력해주세요.'),
        image_file: Yup.string()
          .required('사진은 필수 값입니다.')
      }
    }

    if (adProduct.view_template === 'notice') {
      return {
        description: Yup.string()
          .required('소재 내용은 필수 값입니다.')
          .min('2', '소재 내용은 2자리 이상 입력해주세요.')
          .max('100', '소재 내용은 100자리 까지 입력해주세요.'),
        action_url: Yup.string()
          .required('랜딩은 필수 입력값 입니다.'),
        theme_id: Yup.string()
          .required('버튼 문구는 필수 선택 값입니다.')
      }
    }

    return {
      title: Yup.string()
        .min('2', '광고글 제목은 2자리 이상 입력해주세요.')
        .max('18', '광고글 제목은 18자리 까지 입력해주세요.')
        .required('광고글 제목은 필수 값입니다.'),
      description: Yup.string()
        .required('소재 내용은 필수 값입니다.')
        .min('2', '소재 내용은 2자리 이상 입력해주세요.')
        .max('100', '소재 내용은 100자리 까지 입력해주세요.'),
      action_url: Yup.string()
        .required('랜딩은 필수 입력 값입니다.'),
      theme_id: Yup.string()
        .required('버튼 문구는 필수 선택 값입니다.'),
      image_file: Yup.string()
        .required(isVideo ? '영상 썸네일은 필수 값입니다.' : '사진은 필수 값입니다.')
    }
  }

  getFieldSubLabelText = () => {
    const { adProduct } = this.props
    if (adProduct.view_template === 'comment') {
      return '배너 이미지 상세 가이드라인 보기'
    }

    if (adProduct.view_template === 'post' && adProduct.product_type === 'image') {
      return '이미지 상세 가이드라인 보기'
    }

    if (adProduct.view_template === 'post' && adProduct.product_type === 'video') {
      return '동영상 상세 가이드라인 보기'
    }

    return ''
  }

  isImageField = adProduct => {
    const { imageType } = this.state

    if (adProduct.view_template === 'comment' && imageType === 'show') {
      return true
    }

    if (adProduct.view_template === 'comment' && imageType === 'hide') {
      return false
    }

    if (adProduct.view_template !== 'notice') {
      return true
    }

    return false
  }

  render() {
    const {
      classes,
      onAdSubmit,
      onAdUpdate,
      themes,
      adProduct,
      onAdSet,
      campaignId,
      ad,
      adSets,
      adPackageType,
      adFormType,
    } = this.props

    const {
      selectedThemeId,
      actionType,
      imageType,
      imageFile,
      isOpenModal,
      loading,
     } = this.state

     const {
      findAdSet,
      findAd,
      renderAdPreview,
      handleImageTypeChange,
      handleActionTypeChange,
      onThemeChange,
      showNextButton,
      handleNextButtonClick,
      getImageLabelText,
      isAdmin,
      getTitleLabelText,
      getValidationForm,
      getFieldSubLabelText,
      isImageField,
     } = this

    return (
      <Formik
        initialValues={!isEmpty(ad) &&
          (ad.advertisement_set && ad.advertisement_set.id === findAdSet(adSets).id) ? {
          title: ad.title,
          description: ad.description,
          theme_id: ad.theme && ad.theme.id,
          video_url: ad.video && ad.video.video_url || '',
          action_type: ad.advertisement_set && ad.advertisement_set.action_type,
          action_url: ad.advertisement_set && ad.advertisement_set.action_url,
          image_file: ad.image && ad.image.original_url || '',
        } : {
          title: '',
          description: '',
          theme_id: '',
          action_type: '',
          video_url: '',
          action_url: '',
          image_file: '',
        }}
        enableReinitialize
        onSubmit={(values, { setSubmitting }) => {
          this.setState({ loading: true })
          const adData = {
            title: values.title,
            description: values.description,
            theme_id: values.theme_id || '1',
          }

          const adSetData = {
            action_type: actionType,
            action_url: values.action_url,
          }

          Promise.resolve()
            .then(() => {
              if (!isEmpty(findAd())) {
                return onAdUpdate(adData, adSetData, findAdSet(adSets).id, findAd().id)
              }

              return onAdSubmit(adData, adSetData, findAdSet(adSets).id)
            })
            .then(data => {
              const advertisement = isArray(data) ? head(data) : data
              return Promise.resolve()
                .then(() => {
                  if (!isEmpty(advertisement.image) && imageType === 'hide') {
                    return deleteImage(ad.id)
                      .then(() => this.setState({ imageFile: null }))
                  }

                  return true
                })
                .then(() => {
                  if (!isEmpty(imageFile) && !isString(values.image_file)) {
                    return setImage({ image_file: values.image_file }, advertisement.id)
                  }

                  return true
                })
                .then(() => {
                  if (!isEmpty(values.video_url)) {
                    return setVideo({ video_url: values.video_url, width: 960, height: 540 }, advertisement.id)
                  }

                  return true
                })
            })
            .then(() => onAdSet(campaignId))
            .then(() => {
              setSubmitting(false)
              return this.setState({ loading: false })
            })
        }}
        validationSchema={Yup.object().shape({
            ...getValidationForm(),
          })
        }
      >
        {props => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          } = props

          return (
            <div className={classes.formContainer}>
              {loading &&
                <div className={classes.loadingOverlay}>
                  <CircularUnderLoad styles={classes.loading} />
                </div>
              }
              <Row>
                <Col sm={8}>
                  <Form onSubmit={handleSubmit}>
                    {adProduct.view_template === 'comment' &&
                      <FormGroup row>
                        <Label sm={2}>이미지 사용</Label>
                        <Col sm={9}>
                          <RadioGroup
                            aria-label="formType"
                            name="formType"
                            className={classes.group}
                            value={imageType}
                            onChange={handleImageTypeChange}
                            row
                          >
                            <FormControlLabel
                              value="show"
                              control={<Radio color="primary" />}
                              label="사용"
                              labelPlacement="end"
                            />
                            <FormControlLabel
                              value="hide"
                              control={<Radio color="primary" />}
                              label="미사용"
                              labelPlacement="end"
                            />
                          </RadioGroup>
                        </Col>
                      </FormGroup>
                    }

                    {adProduct.view_template !== 'notice' &&
                      <FormGroup row>
                        <Label sm={2}>
                          {getTitleLabelText(adProduct)}
                        </Label>
                        <Col sm={10}>
                          <Input
                            type="text"
                            name="title"
                            className={
                              (errors.title && touched.title) ?
                                classes.fieldError :
                                classes.field
                            }
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {(errors.title && touched.title) &&
                            <div className={classes.invalidFeedback}>
                              {errors.title}
                            </div>
                          }
                        </Col>
                      </FormGroup>
                    }

                    <FormGroup row>
                      <Label sm={2}>소재 내용</Label>
                      <Col sm={10}>
                        <Input
                          type="textarea"
                          name="description"
                          className={
                            (errors.description && touched.description) ?
                              classes.fieldError :
                              classes.field
                          }
                          value={values.description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {(errors.description && touched.description) &&
                          <div className={classes.invalidFeedback}>
                            {errors.description}
                          </div>
                        }
                      </Col>
                    </FormGroup>

                    {(adProduct.view_template === 'post' && adProduct.product_type === 'video') &&
                      <FormGroup row>
                        <Label sm={2}>동영상</Label>
                        <Col sm={10}>
                          {isAdmin() ?
                            <Input
                              type="text"
                              name="video_url"
                              className={
                                (errors.video_url && touched.video_url) ?
                                  classes.fieldError :
                                  classes.field
                              }
                              value={values.video_url}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="비메오 동영상 URL을 입력해주세요"
                            /> :
                            <div style={{ marginTop: '8px' }}>
                              동영상 소재는 withus@classting.com으로 전달 문의주시기 바랍니다.
                            </div>
                          }
                        </Col>
                      </FormGroup>
                    }

                    {isImageField(adProduct) &&
                      <FormGroup row>
                        <Label sm={2}>{getImageLabelText(adProduct)}</Label>
                        <Col sm={7}>
                          <Input
                            type="text"
                            name="image_file"
                            className={
                              (errors.image_file && touched.image_file) ?
                                classes.fieldError :
                                classes.field
                            }
                            value={(values.image_file && values.image_file.name) || values.image_file}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled
                          />
                          {(errors.image_file && touched.image_file) &&
                            <div className={classes.invalidFeedback}>
                              {errors.image_file}
                            </div>
                          }
                        </Col>
                        <Col sm={3}>
                          <Dropzone
                            accept=".jpg,.png"
                            onDrop={(file) => {
                              this.setState({ imageFile: URL.createObjectURL(file[0]) })
                              setFieldValue('image_file', file[0])
                            }}
                          >
                            {({getRootProps, getInputProps}) => (
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    className={classes.primary}
                                  >
                                    파일첨부
                                  </Button>
                              </div>
                            )}
                          </Dropzone>
                        </Col>
                        <Col sm={8} />
                        <Col
                          sm={4}
                          className={classes.fieldSubLabel}
                          onClick={() => this.setState({ isOpenModal: true })}
                        >
                          {getFieldSubLabelText()}
                        </Col>
                      </FormGroup>
                    }

                    {adPackageType !== 'region' &&
                      <FormGroup row>
                        <Label sm={2}>버튼 문구</Label>
                        <Col sm={10}>
                          <FormControl variant="outlined" className={classes.formControl}>
                            <Select
                              onChange={onThemeChange(setFieldValue)}
                              className={classes.actionType}
                              input={<OutlinedInput labelWidth={0} name="value" />}
                              value={values.theme_id || selectedThemeId}
                            >
                              {map(themes, item => (
                                <MenuItem value={item.id} key={item.id}>
                                  {item.action_label}
                                </MenuItem>
                              ))}
                            </Select>
                            {(errors.theme_id && touched.theme_id) &&
                              <div className={classes.invalidFeedback}>
                                {errors.theme_id}
                              </div>
                            }
                          </FormControl>
                        </Col>
                      </FormGroup>
                    }

                    {adPackageType !== 'region' &&
                      <FormGroup row>
                        <Label sm={2}>랜딩</Label>
                        <Col sm={10}>
                          <RadioGroup
                            aria-label="actionType"
                            name="actionType"
                            className={classes.group}
                            value={values.action_type || actionType}
                            onChange={handleActionTypeChange(setFieldValue)}
                            row
                          >
                            <FormControlLabel
                              value="outer_browser"
                              control={<Radio color="primary" />}
                              label="웹 URL"
                              labelPlacement="end"
                            />
                            <FormControlLabel
                              value="virtual_number"
                              control={<Radio color="primary" />}
                              label="전화번호"
                              labelPlacement="end"
                            />
                          </RadioGroup>
                        </Col>
                        <Col sm={2} />
                        <Col sm={10}>
                          {actionType === 'outer_browser' &&
                            <Input
                              type="text"
                              name="action_url"
                              className={
                                (errors.action_url && touched.action_url) ?
                                  classes.fieldError :
                                  classes.field
                              }
                              value={values.action_url}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="http://classting.com”와 같은 형식으로 입력해주세요"
                            />
                          }
                          {actionType === 'virtual_number' &&
                            <Input
                              type="tel"
                              name="action_url"
                              className={
                                (errors.action_url && touched.action_url) ?
                                  classes.fieldError :
                                  classes.field
                              }
                              value={values.action_url}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="- 없이 숫자만 입력해주세요"
                            />
                          }
                          {(errors.action_url && touched.action_url) &&
                            <div className={classes.invalidFeedback}>
                              {errors.action_url}
                            </div>
                          }
                        </Col>
                      </FormGroup>
                    }

                    <FormGroup row>
                      <Label sm={2} />
                      <Col sm={10}>
                      {showNextButton() &&
                          <Button
                            className={classes.nextButton}
                            onClick={handleNextButtonClick}
                            variant="contained"
                            color="primary"
                            type="button"
                          >
                            다음 단계
                          </Button>
                        }
                        <Button
                          className={classes.submitButton}
                          variant="outlined"
                          color="primary"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {adFormType === 'create' ? '소재 저장' : '소재 수정'}
                        </Button>
                      </Col>
                    </FormGroup>

                  </Form>
                </Col>
                <Col sm={4}>
                  {renderAdPreview(adProduct, values)}
                </Col>
              </Row>
              <EnhancedDialog isOpen={isOpenModal}
                isCloseButton={false}
                modalTitle={
                  <>
                    {adProduct.view_template === 'comment' && '배너 이미지 가이드 라인'}
                    {(adProduct.view_template === 'post' && adProduct.product_type === 'video') &&
                      '동영상 상세 가이드라인'
                    }
                    {(adProduct.view_template === 'post' && adProduct.product_type === 'image') &&
                      '이미지 상세 가이드 라인'
                    }
                  </>
                }
                handleOpen={() => {
                  this.setState({ isOpenModal: false })
                }}
                modalSize="xl"
              >
                <DialogContentText>
                  {(adProduct.view_template === 'post' && adProduct.product_type === 'image') &&
                    <div className={classes.modalBody}>
                      이미지 크기 : 1200*900px<br />
                      텍스트는 이미지의 20% 이내 (로고제외)<br />
                      메인 1~2줄, 글자 크기 72px 권장<br />
                      서브 1줄, 글자 크기 36px 권장<br />
                      저속한 도안, 사진, 합성 이미지 불가<br />
                      가독성이 떨어지는 이미지 불가<br />
                      - 이미지 잘림 현상으로 전달이 안되는경우
                    </div>
                  }
                  {adProduct.view_template === 'comment' &&
                    <div className={classes.modalBody}>
                      이미지 크기 : (최소 400*300px 이상)<br />
                      이미지 내 텍스트 불가<br />
                      메인 1~2줄, 글자크기 72px 권장<br />
                      저속한 도안, 사진, 합성 이미지 불가<br />
                      가독성이 떨어지는 이미지 불가<br />
                      - 이미지 잘림 현상으로 전달이 안되는 경우
                    </div>
                  }
                  {(adProduct.view_template === 'post' && adProduct.product_type === 'video') &&
                    <div className={classes.modalBody}>
                      <div className={classes.modalLabel}>영상 화면비율</div>
                      16:9 비율권장.<br />
                      16:9 이외에 4:3 또는 다른 비율의 영상도 사용할 수 있지만,<br/>
                      영상 재생시, 상/하 or 좌/우에 검은 띠(레터/필러박스)가 노출될 수 있습니다.<br /><br />

                      <div className={classes.modalLabel}>해상도</div>
                      제한 없음. (단, 너무 낮은 해상도는 권장하지 않음)<br /><br />

                      <div className={classes.modalLabel}>동영상 길이</div>
                      길이 제한 없음. (15-30초 권장)<br />
                      좋은 집행 결과를 위해, 15초(30초)내의<br />
                      동영상 사용을 권장해 드립니다.<br /><br />

                      <div className={classes.modalLabel}>파일 형식</div>
                      제한 없음.<br /><br />

                      <div className={classes.modalLabel}>섬네일(Thumbnail) 이미지크기</div>
                      영상 화면 비율과 동일한 사이즈<br /><br />

                      <div className={classes.modalLabel}>주의 사항</div>
                      •저속한영상, 표시, 음향불가 <br/>
                      •저작권 및 초상권이 확보된 영상만 사용 가능
                    </div>
                  }
                </DialogContentText>
              </EnhancedDialog>
            </div>
          )
        }}
      </Formik>
    )
  }
}

AdForm.propTypes = {
  classes: PropTypes.object.isRequired,
  ad: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  adSets: PropTypes.array.isRequired,
  themes: PropTypes.array.isRequired,
  campaign: PropTypes.object.isRequired,
  onAdSubmit: PropTypes.func.isRequired,
  onAdUpdate: PropTypes.func.isRequired,
  adProduct: PropTypes.object.isRequired,
  adFormType: PropTypes.string.isRequired,
  onAdSet: PropTypes.func.isRequired,
  campaignId: PropTypes.string.isRequired,
  adPackageType: PropTypes.string.isRequired,
  onCampaignsUpdate: PropTypes.func.isRequired,
  unblock: PropTypes.func.isRequired,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(AdForm)
