/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { useNavigationPointing } from '@landing/hooks/navigation'
import mauGraph from '@landing/assets/images/graph_mau.png'

import { AppearanceAnimation } from '../unit/animation'

const makeStyles = () => ({
  achievements: ({
    colors: { grey }, font, mq, spacing,
  }) => css`
    ${mq({
    color: grey[900],
    fontSize: [font.title3.fontSize, font.title1.fontSize],
    lineHeight: [font.title3.lineHeight, font.title1.lineHeight],
    paddingBottom: [`${spacing()}rem`, `${spacing(0.5)}rem`],
  })}
  `,
  describe: ({ spacing, mq }) => css`
    ${mq({
    marginBottom: [`${spacing(5)}rem`, '0'],
    marginRight: ['0', `${spacing(6.875)}rem`],
  })}
  `,
  root: ({ mq, spacing }) => css`
    ${mq({
    alignItems: 'center',
    display: 'flex',
    height: ['720px', '108rem'],
    justifyContent: 'center',
    paddingTop: [`${spacing(2.5)}rem`, '0'],
  })}
  `,
  service: ({
    colors: { grey }, font, mq, spacing,
  }) => css`
    ${mq({
    color: grey[700],
    fontSize: [font.body2.fontSize, font.body1.fontSize],
    lineHeight: [font.body2.lineHeight, font.body1.lineHeight],
    paddingBottom: [`${spacing(0.5)}rem`, '0'],
  })}
  `,
  statistics: ({ mq }) => css`
    ${mq({
    margin: ['0 auto', 'auto'],
    width: ['29rem', '38.4rem'],
  })}

    img {
      display: block;
      width: 100%;
    }
  `,
  strong: css`
    font-weight: bold;
  `,
  wrap: ({ mq }) => css`
    ${mq({
    alignItems: ['stretch', 'center'],
    display: 'flex',
    flexDirection: ['column', 'row'],
    textAlign: ['center', 'left'],
  })}
  `,
})

const navId = 'introduce'

const UsageStatus = () => {
  const styles = makeStyles()
  const [wrapEl] = useNavigationPointing(navId)

  return (
    <div ref={wrapEl} id={navId} css={styles.root}>
      <div css={styles.wrap}>
        <AppearanceAnimation>
          <div css={styles.describe}>
            <p css={styles.service}>대한민국 1등 클래스관리, 클래스팅</p>
            <p css={styles.achievements}>
              누적 회원수
              {' '}
              <strong css={styles.strong}>850만명</strong>
              <br />
              {' '}
              <strong css={styles.strong}>50만 개</strong>
              {' '}
              이상의 클래스
            </p>
          </div>
        </AppearanceAnimation>
        <AppearanceAnimation duration={700}>
          <figure css={styles.statistics}>
            <img src={mauGraph} alt="" />
          </figure>
        </AppearanceAnimation>
      </div>
    </div>
  )
}

export default UsageStatus
