import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'

import { getAdSetsSelector, getAdvertisementsSelector } from '@/features/campaign/selectors'
import { getViewTemplate } from '@/shared/utils'
import { fetchAdvertisement as fetchAdvertisementAction } from '../../actions'
import AdPreviewMenu from './AdPreviewMenu'
import AdPreviewMedia from './AdPreviewMedia'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#fafafa',
    borderRadius: '4px',
    boxShadow: 'none',
    border: 'solid 1px #e0e0e0',
    '& .MuiAccordionSummary-root.Mui-expanded': {
      borderBottom: '1px solid #e0e0e0',
    },
  },
  summary: {
    margin: 0,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#616161',
  },
  comment: {
    display: 'inline-block',
    color: '#9e9e9e',
    marginLeft: '16px',
  },
  detailWrap: {
    padding: '0',
  },
  detail: {
    display: 'flex',
    width: '100%',
    overflow: 'scroll',
  },
}))

const AdPrevView = ({
  selectedAdType,
  selectedAdSetId,
  adSets,
  advertisements,
  fetchAdvertisement,
}) => {
  const isEmptyAdSets = adSets.length <= 0
  if (isEmptyAdSets) return null

  const filteredAdSetByAdType = selectedAdType === 'all'
    ? adSets
    : adSets.filter(adSet => getViewTemplate(adSet) === selectedAdType)
  const filteredAdSetBySelectedAdSetId = selectedAdSetId
    ? filteredAdSetByAdType.filter(({ id }) => id === selectedAdSetId)
    : filteredAdSetByAdType
  const isEmptyAdvertisements = filteredAdSetBySelectedAdSetId[0] ? !filteredAdSetBySelectedAdSetId[0].advertisements.length : true
  if (isEmptyAdvertisements) return null

  const firstAdvertisementId = filteredAdSetBySelectedAdSetId[0].advertisements[0].id
  const [selectedAdId, setSelectedAdId] = useState(firstAdvertisementId)
  const classes = useStyles()
  const selectedAdvertisement = advertisements[selectedAdId]

  useEffect(() => {
    if (selectedAdId && !selectedAdvertisement) {
      fetchAdvertisement(selectedAdId)
    }
  }, [selectedAdId, selectedAdvertisement])

  useEffect(() => {
    if (firstAdvertisementId !== selectedAdId) {
      setSelectedAdId(firstAdvertisementId)
    }
  }, [firstAdvertisementId])

  return (
    <Accordion className={classes.root} expanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <p className={classes.summary}>
          광고 소재
          <span className={classes.comment}>
            ※ 실제 광고 노출 화면은 사용자의 기기 또는 환경 설정에 따라 차이가 발생할 수 있습니다.
          </span>
        </p>
      </AccordionSummary>
      <AccordionDetails className={classes.detailWrap}>
        <div className={classes.detail}>
          <AdPreviewMenu
            adSets={filteredAdSetBySelectedAdSetId}
            selectedAdId={selectedAdId}
            setSelectedAdId={setSelectedAdId}
          />
          <AdPreviewMedia advertisement={selectedAdvertisement} />
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

AdPrevView.propTypes = {
  selectedAdType: PropTypes.string.isRequired,
  selectedAdSetId: PropTypes.number.isRequired,
  adSets: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    view_template: PropTypes.string.isRequired,
    start_date: PropTypes.string.isRequired,
    advertisements: PropTypes.arrayOf(PropTypes.shape({
      action_label: PropTypes.string.isRequired,
      action_url: PropTypes.string.isRequired,
      created_at: PropTypes.string,
      description: PropTypes.string.isRequired,
      highlight_color: PropTypes.string,
      id: PropTypes.number.isRequired,
      title: PropTypes.string,
      memo: PropTypes.string,
    })).isRequired,
  })).isRequired,
  fetchAdvertisement: PropTypes.func.isRequired,
  advertisements: PropTypes.any.isRequired,
}

const mapStateToProps = state => ({
  adSets: getAdSetsSelector(state),
  advertisements: getAdvertisementsSelector(state),
})

const mapDispatchToProps = dispatch => ({
  fetchAdvertisement: advertisementId => dispatch(fetchAdvertisementAction(advertisementId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdPrevView)
