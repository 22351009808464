import facepaint from 'facepaint'

export const breakpoints = [1023]

export default {
  colors: {
    blue: {
      100: '#008EFF',
      200: '#415AD4',
      300: '#232E56',
    },
    green: {
      100: '#00C896',
    },
    grey: {
      100: '#F5F5F5',
      200: '#EEEEEE',
      300: '#E0E0E0',
      400: '#BDBDBD',
      50: '#FAFAFA',
      500: '#9E9E9E',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
    },
  },
  font: {
    body1: {
      fontSize: '2rem',
      lineHeight: 1.6,
    },
    body2: {
      fontSize: '1.6rem',
      lineHeight: 1.6,
    },
    body3: {
      fontSize: '1.4rem',
      lineHeight: 1.6,
    },
    body4: {
      fontSize: '1.2rem',
      lineHeight: 1.6,
    },
    title1: {
      fontSize: '4rem',
      lineHeight: 1.5,
    },
    title2: {
      fontSize: '3.2rem',
      lineHeight: 1.5,
    },
    title3: {
      fontSize: '2.4rem',
      lineHeight: 1.5,
    },
  },
  mq: facepaint(breakpoints.map(bp => `@media (min-width: ${bp}px)`)),
  spacing: (value = 1) => (value * 8) / 10,
}
