/* eslint-disable no-unused-vars */
import React from 'react'

import get from 'lodash/get'
import has from 'lodash/has'
import reduce from 'lodash/reduce'
import map from 'lodash/map'
import filter from 'lodash/filter'

import classesName from 'classnames'

import {
  getToCommas,
  getRatio,
} from '@/shared/utils'

import ADStatusToggle from './ADStatusToggle'

export const COLUMN_WIDTH_LOCAL_STORAGE_KEY = 'today_column_width'

export const sumObjectValue = (data, key) => reduce(data, (result, value) => (
  result + Number(get(value, key, 0))
), 0)

export const sumViewCountForVTR = ({ data, key, refKey }) => reduce(data, (result, value) => {
  if (has(value, refKey) && get(value, refKey) !== 0) {
    return (
      result + Number(get(value, key, 0))
    )
  }
  return result
}, 0)

export const sortNumberMethod = (a, b) => {
  const valueA = Number(a) || Number.MIN_VALUE
  const valueB = Number(b) || Number.MIN_VALUE

  if (valueA > valueB) {
    return 1
  }
  if (valueA < valueB) {
    return -1
  }
  return 0
}

export const sortSwitchMethod = (a, b) => {
  const forcedStopToNumber = (value) => {
    if (value.forced_stop === false) return 1
    if (value.forced_stop === true) return 0
    return -1
  }

  return sortNumberMethod(forcedStopToNumber(a), forcedStopToNumber(b))
}

export const stringIncludeFilter = (filtered, row) => {
  const id = filtered.pivotId || filtered.id
  return row[id] !== undefined ? String(row[id]).includes(filtered.value) : true
}

export const addColumnOptions = ({ classes, columnWidth, columns }) => (
  map(columns, (column) => {
    const option = {}
    option.headerClassName = classesName(classes.tableHeaderCell)

    if (column.type === 'number') {
      option.className = classesName(classes.tableCell, classes.tableNumberCell)
      option.sortMethod = sortNumberMethod
    } else if (column.type === 'switch') {
      option.sortMethod = sortSwitchMethod
      option.className = classesName(classes.tableCell, classes.tableSwitchCell)
    } else {
      option.className = classesName(classes.tableCell)
    }

    option.width = get(columnWidth, `${[column.id]}`) || column.width

    option.getFooterProps = () => ({
      className: classesName(classes.tableFooterCell),
    })

    return { ...column, ...option }
  })
)

export const getTotalColumns = () => [
  {
    id: 'advertiser_name',
    Header: '광고주 (광고주 ID)',
    accessor: row => `${row.advertiser.name}(${row.advertiser.id})`,
    width: 150,
  },
  {
    id: 'id',
    Header: 'ID',
    accessor: 'id',
    Cell: row => (
      <a href={`/campaign/${row.value}`}>
        {row.value}
      </a>
    ),
    width: 50,
    type: 'number',
  },
  {
    id: 'title',
    Header: '캠페인 이름',
    accessor: 'title',
    width: 250,
  },
]

export const getTypeTableColumns = ({ type, onUpdateAdForcedStop }) => {
  const findViewTemplate = (ads, value) => {
    const filterPackage = ({
      advertisement_set: { view_template: viewTemplate },
    }) => viewTemplate === 'post' || viewTemplate === 'notice'

    return filter(ads, value === 'package' ? filterPackage : {
      advertisement_set: { view_template: value },
    }) || {}
  }
  const getValueByType = (advertisements, key) => (
    get(findViewTemplate(advertisements, type), key)
  )

  return [
    {
      id: `${type}_status`,
      Header: '상태',
      accessor: ({ advertisements }) => findViewTemplate(advertisements, type),
      Cell: row => (<ADStatusToggle row={row} onUpdateAdForcedStop={onUpdateAdForcedStop} />),
      type: 'switch',
      width: 70,
    },
    {
      id: `${type}_id`,
      Header: 'ID',
      accessor: ({ advertisements }) => {
        const filteredAdsByType = findViewTemplate(advertisements, type)
        return filteredAdsByType.length > 1 ? '...' : (getValueByType(advertisements, '[0].id') || '-')
      },
      width: 50,
      type: 'number',
    },
    {
      id: `${type}_memo`,
      Header: '가이드 값',
      accessor: ({ advertisements }) => {
        const filteredAdsByType = findViewTemplate(advertisements, type)
        return sumObjectValue(filteredAdsByType, 'memo') || 0
      },
      Cell: row => getToCommas(row.value),
      Footer: column => getToCommas(sumObjectValue(column.data, `${type}_memo`)),
      type: 'number',
    },
    {
      id: `${type}_target_daily_impressions`,
      Header: '일별 최대 노출',
      accessor: ({ advertisements }) => {
        const filteredAdsByType = findViewTemplate(advertisements, type)
        return sumObjectValue(filteredAdsByType, 'target_daily_impressions') || 0
      },
      Cell: row => getToCommas(row.value),
      Footer: column => getToCommas(sumObjectValue(column.data, `${type}_target_daily_impressions`)),
      type: 'number',
    },
    {
      id: `${type}_impression`,
      Header: '현재 노출',
      accessor: ({ advertisements }) => {
        const filteredAdsByType = findViewTemplate(advertisements, type)
        return sumObjectValue(filteredAdsByType, 'view.count') || 0
      },
      Cell: row => getToCommas(row.value),
      Footer: column => getToCommas(sumObjectValue(column.data, `${type}_impression`)),
      type: 'number',
    },
    {
      id: `${type}_ratio`,
      Header: '달성률',
      accessor: ({ advertisements }) => {
        const filteredAdsByType = findViewTemplate(advertisements, type)
        const viewCount = sumObjectValue(filteredAdsByType, 'view.count') || 0
        const memo = sumObjectValue(filteredAdsByType, 'memo') || 0

        return getRatio(viewCount, memo)
      },
      Cell: row => `${row.value}%`,
      Footer: column => `${getRatio(
        sumObjectValue(column.data, `${type}_impression`),
        sumObjectValue(column.data, `${type}_memo`),
      )}%`,
      type: 'number',
    },
    {
      id: `${type}_cpm`,
      Header: 'CPM 값',
      accessor: ({ advertisements }) => {
        const filteredAdsByType = findViewTemplate(advertisements, type)
        return sumObjectValue(filteredAdsByType, 'advertisement_set.cpm') / filteredAdsByType.length || 0
      },
      Cell: row => getToCommas(row.value),
      type: 'number',
      show: type === 'post',
    },
    {
      id: `${type}_click_count`,
      Header: '클릭 수',
      accessor: ({ advertisements }) => {
        const filteredAdsByType = findViewTemplate(advertisements, type)
        return sumObjectValue(filteredAdsByType, 'click.count') || 0
      },
      Cell: row => getToCommas(row.value),
      Footer: column => getToCommas(sumObjectValue(column.data, `${type}_click_count`)),
      type: 'number',
    },
    {
      id: `${type}_ctr`,
      Header: 'CTR',
      accessor: ({ advertisements }) => {
        const filteredAdsByType = findViewTemplate(advertisements, type)
        const viewCount = sumObjectValue(filteredAdsByType, 'view.count')
        const clickCount = sumObjectValue(filteredAdsByType, 'click.count')
        return getRatio(clickCount, viewCount)
      },
      Cell: row => `${row.value}%`,
      Footer: column => `${getRatio(
        sumObjectValue(column.data, `${type}_click_count`),
        sumObjectValue(column.data, `${type}_impression`),
      )}%`,
      type: 'number',
    },
    {
      id: `${type}_watch_count`,
      Header: '재생 수',
      accessor: ({ advertisements }) => {
        const filteredAdsByType = findViewTemplate(advertisements, type)
        return sumObjectValue(filteredAdsByType, 'watch.count') || 0
      },
      Cell: row => getToCommas(row.value),
      Footer: column => getToCommas(sumObjectValue(column.data, `${type}_watch_count`)),
      type: 'number',
      show: type === 'post' || type === 'package',
    },
    {
      id: `${type}_watched_view_count`,
      Header: '재생 노출 수',
      accessor: ({ advertisements }) => {
        const filteredAdsByType = findViewTemplate(advertisements, type)
        return sumViewCountForVTR({
          data: filteredAdsByType, key: 'view.count', refKey: 'watch.count',
        })
      },
      // do not show this, this data is only for VTR total
      show: false,
    },
    {
      id: `${type}_vtr`,
      Header: 'VTR',
      accessor: ({ advertisements }) => {
        const filteredAdsByType = findViewTemplate(advertisements, type)
        const viewCount = sumViewCountForVTR({
          data: filteredAdsByType, key: 'view.count', refKey: 'watch.count',
        })
        const watchCount = sumObjectValue(filteredAdsByType, 'watch.count') || 0
        return getRatio(watchCount, viewCount)
      },
      Cell: row => `${row.value}%`,
      Footer: column => `${getRatio(
        sumObjectValue(column.data, `${type}_watch_count`),
        sumObjectValue(column.data, `${type}_watched_view_count`),
      )}%`,
      type: 'number',
      show: type === 'post' || type === 'package',
    },
  ]
}
