const extractFieldValueById = (fields) => {
  const values = {}
  fields.forEach(({ id, type, value }) => {
    if (type === 'file') {
      values[id] = value.url
    } else {
      values[id] = value
    }
  })
  return values
}

export default extractFieldValueById
