export const STAT_VTR = 'vtr'
export const STAT_CTR = 'ctr'
export const STAT_WATCH = 'watch'
export const STAT_CLICK = 'click'
export const STAT_VIEW = 'view'

export const GRAPH_COLORS = ['#e8c1a0', '#f47560', '#f1e15b', '#e8a838', '#61cdbb', '#97e3d5']

export const GRAPH_STATS = {
  [STAT_VIEW]: {
    label: '노출 수',
    color: GRAPH_COLORS[0],
  },
  [STAT_CLICK]: {
    label: '클릭 수',
    color: GRAPH_COLORS[1],
  },
  [STAT_WATCH]: {
    label: '재생 수',
    color: GRAPH_COLORS[2],
  },
  [STAT_VTR]: {
    label: 'VTR',
    color: GRAPH_COLORS[3],
  },
  [STAT_CTR]: {
    label: 'CTR',
    color: GRAPH_COLORS[4],
  },
}

/**
 * 큰 수의(대수의) 라인그래프를 사용하기 위해 log type의 line 그래프 사용: https://nivo.rocks/storybook/?path=/story/line--logarithmic-scale
 * LOG_ZERO_VALUE는 trick value: log type의 line graph는 0이 무한대 값이므로 쓰이지 않을 값으로 0 값을 대체
 * doc: https://github.com/d3/d3-scale/blob/master/README.md#log-scales
*/
export const LOG_ZERO_VALUE = 0.001

export const LEGEND_STYLE = {
  anchor: 'bottom-right',
  direction: 'column',
  justify: false,
  translateX: 100,
  translateY: 0,
  itemsSpacing: 0,
  itemDirection: 'left-to-right',
  itemWidth: 80,
  itemHeight: 20,
  itemOpacity: 0.75,
  symbolSize: 12,
  symbolShape: 'circle',
  symbolBorderColor: 'rgba(0, 0, 0, .5)',
  effects: [{
    on: 'hover',
    style: {
      itemBackground: 'rgba(0, 0, 0, .03)',
      itemOpacity: 1,
    },
  }],
}
