/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import PropTypes from 'prop-types'

import Line from './Line'
import Step from './Step'
import InquireButtonWrap from './InquireButtonWrap'
import ProcessGuideText from './ProcessGuideText'
import { AppearanceAnimation } from '../unit/animation'
import { LineBreak } from '../unit/string'

const makeStyles = () => ({
  item: ({ spacing, mq }) => css`
    ${mq({
    marginBottom: [`${spacing(3)}rem`, `${spacing(5)}rem`],
  })}

    :last-of-type {
      margin-bottom: 0;
    }
  `,
  list: () => css`
    position: relative;
    z-index: 10;
  `,
  root: ({ mq, spacing }) => css`
    ${mq({
    alignItems: ['center', 'normal'],
    display: ['flex', 'block'],
    flexDirection: ['column', 'raw'],
    justifyContent: ['center', 'normal'],
    padding: [`0 ${spacing(1.875)}rem`, '0'],
    width: ['100%', 'auto'],
  })}
  `,
  title: ({ font, spacing, mq }) => css`
    ${mq({
    fontSize: [font.title3.fontSize, font.title1.fontSize],
    fontWeight: 'bold',
    lineHeight: [font.title3.lineHeight, font.title1.lineHeight],
    marginBottom: [`${spacing()}rem`, `${spacing(6)}rem`],
    textAlign: ['center', 'left'],
  })}
  `,
  titleWrap: ({ mq, spacing }) => css`
    ${mq({
    marginBottom: [`${spacing(2)}rem`, '0'],
    width: ['100%', 'auto'],
  })}
  `,
  wrap: ({ spacing, mq }) => css`
    ${mq({
    padding: [`0 ${spacing(3)}rem 0 ${spacing(6.5)}rem`, '0'],
    position: 'relative',
  })}
  `,
})

const steps = [
  {
    Additional: InquireButtonWrap,
    description: '진행하고 싶은 광고에 대해 상담합니다.',
    description2: (
      <LineBreak
        text={
          '진행하고 싶은 광고에 대해 상담합니다.\n문의를 접수하시기 전, FAQ에서\n중복된 내용이 없는지 꼭 확인해주세요.'
        }
      />
    ),
    order: '01',
    title: '광고 문의',
  },
  {
    description: '기간, 예산, 타겟에 맞추어 광고 견적을 드립니다.',
    description2: (
      <LineBreak text={'기간, 예산, 타겟에 맞추어\n광고 견적을 드립니다.'} />
    ),
    order: '02',
    title: '광고상품 제안',
    title2: '견적 제안',
  },
  {
    description: '집행 확정 후 광고 게재신청서를 작성합니다.',
    description2: (
      <LineBreak text={'집행 확정 후\n광고 게재신청서를 작성합니다.'} />
    ),
    order: '03',
    title: '광고 계약',
  },
  {
    description: (
      <LineBreak
        text={
          '광고 시작일 기준 1~2일 전에\n게재될 광고 소재를 클래스팅에 전달합니다.'
        }
      />
    ),
    description2: (
      <LineBreak
        text={
          '광고 시작일 기준 1~2일 전에\n게재될 광고 소재를 클래스팅에 전달합니다.'
        }
      />
    ),
    order: '04',
    title: '소재 전달',
  },
  {
    description: (
      <LineBreak
        text={
          '클래스팅에서 검수 후 셋팅을 진행합니다.\n가이드에 맞지 않은 소재는 반려될 수 있습니다.'
        }
      />
    ),
    description2: (
      <LineBreak
        text={
          '클래스팅에서 검수 후 셋팅을 진행합니다.\n가이드에 맞지 않은 소재는\n반려될 수 있습니다.'
        }
      />
    ),
    order: '05',
    title: '광고 검수',
    title2: '소재 검수 및 셋팅',
  },
  {
    description: (
      <LineBreak
        text={
          '캠페인 시작일에 광고 라이브 이미지와\n성과 리포트 링크를 전달드립니다.'
        }
      />
    ),
    description2: (
      <LineBreak
        text={
          '캠페인 시작일에 광고 라이브 이미지와\n성과 리포트 링크를 전달드립니다.'
        }
      />
    ),
    order: '06',
    title: '완료·리포트',
    title2: '광고 집행 및 리포트',
  },
]

const Content = ({ isMobileViewSize }) => {
  const styles = makeStyles()

  return (
    <div css={styles.root}>
      <div css={styles.titleWrap}>
        <AppearanceAnimation>
          <h2 css={styles.title}>집행 프로세스</h2>
          {isMobileViewSize && <ProcessGuideText />}
        </AppearanceAnimation>
      </div>
      <div css={styles.wrap}>
        <AppearanceAnimation>
          <ul css={styles.list}>
            {steps.map(
              ({
                Additional,
                description,
                description2,
                order,
                title,
                title2,
              }) => (
                <li css={styles.item} key={order}>
                  <Step
                    Additional={Additional}
                    order={order}
                    description={description}
                    description2={description2}
                    title={title}
                    title2={title2}
                  />
                </li>
              ),
            )}
          </ul>
          <Line />
        </AppearanceAnimation>
      </div>
    </div>
  )
}

Content.propTypes = {
  isMobileViewSize: PropTypes.bool.isRequired,
}

export default Content
