import { PreviewPage } from './page'

export default [
  {
    key: 'preview',
    requiresAuth: false,
    exact: true,
    path: '/preview',
    component: PreviewPage,
  },
]
