import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import { Skeleton } from '../atoms'

const useStyles = makeStyles(() => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    width: '320px',
    height: '100px',
    background: '#fafafa',
    boxSizing: 'border-box',
    borderRadius: '8px',
  },
  adImage: {
    width: '100%',
    height: '100%',
    margin: '0',

    '& img': {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      color: 'transparent',
    },
  },
}))

const ClasstalkTabAd = ({ imageUrl }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Skeleton isShow={!!imageUrl} borderRadius="32px" width="96px" height="96px">
        <figure className={classes.adImage}>
          {imageUrl ? <img src={imageUrl} alt="광고 이미지" /> : null}
        </figure>
      </Skeleton>
    </div>
  )
}

ClasstalkTabAd.propTypes = {
  imageUrl: PropTypes.string.isRequired,
}

export default ClasstalkTabAd
