import { AdvertiserFormPage, AdvertiserPage } from './page'

export default [
  {
    key: 'advertiser',
    requiresAuth: true,
    exact: true,
    path: '/advertiser',
    component: AdvertiserPage,
  },
  {
    key: 'advertiser_form',
    requiresAuth: true,
    exact: true,
    path: '/advertiser/form',
    component: AdvertiserFormPage,
  },
]
