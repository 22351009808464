/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import { Skeleton, Text } from '../atoms'

const useStyles = makeStyles(() => ({
  root: {
    width: '360px',
    background: '#fff',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  image: ({ backgroundColor }) => ({
    width: '100%',
    objectFit: 'contain',
    backgroundColor,
  }),
}))


const SkeletonContent = () => (
  <div
    style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#EEEEEE',
    }}
  >
    <Text>파일을 첨부하시면 해당 이미지가 노출됩니다</Text>
  </div>
)

const PostBannerAd = ({
  imageUrl,
  backgroundColor,
}) => {
  const classes = useStyles({ backgroundColor })
  return (
    <div className={classes.root}>
      <Skeleton
        isShow={!!imageUrl}
        width="100%"
        height="120px"
        skeletonContent={<SkeletonContent />}
      >
        <img
          className={classes.image}
          src={imageUrl}
          alt="광고주 이미지"
        />
      </Skeleton>
    </div>
  )
}

PostBannerAd.defaultProps = {
  imageUrl: '',
  backgroundColor: '#F5F5F5',
}

PostBannerAd.propTypes = {
  imageUrl: PropTypes.string,
  backgroundColor: PropTypes.string,
}

export default PostBannerAd
