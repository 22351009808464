import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  Row, Col, Form, FormGroup, Label, Input,
} from 'reactstrap'
import { Formik } from 'formik'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { isEmpty } from 'lodash'
import Button from '@material-ui/core/Button'
import * as Yup from 'yup'
import Dropzone from 'react-dropzone'
import { fetchCheckEmail } from '../actions'
import { updateUser } from '../../user/actions'

const styles = ({
  container: {
    maxWidth: '846px',
    margin: '0 auto',
    padding: '64px 0 0 0',
  },
  title: {
    margin: '0 0 66px 0',
  },
  field: {
    '&:disabled': {
      background: '#ffffff',
    },
  },
  fieldError: {
    margin: '4px 0 4px 0',
    border: '1px solid #e85d7b',
  },
  validFeedback: {
    color: '#5aaafa',
    fontSize: '12px',
  },
  invalidFeedback: {
    color: '#e85d7b',
    fontSize: '12px',
  },
  formControl: {
    display: 'block',
  },
  button: {
    width: '100%',
  },
  failure: {
    color: '#e85d7b',
    fontSize: '14px',
    margin: '14px 0 14px 0',
    textAlign: 'left',
  },
  submitButton: {
    width: '24%',
    background: '#5aaafa',
    color: '#ffffff',
  },
})

const mapStateToProps = state => ({
  status: state.signUp.status,
  pending: state.signUp.pending,
  isValidEmail: state.signUp.isValidEmail,
  isInValidEmail: state.signUp.isInValidEmail,
})

const mapDispatchToProps = dispatch => ({
  onCheckEmail: params => dispatch(fetchCheckEmail(params)),
  onUpdateUser: (data, userId) => dispatch(updateUser(data, userId)),
})

class SignUpForm extends Component {
  static defaultProps = {
    isForUpdate: false,
  }

  handleUpdatePasswordClick = () => {
    const { history } = this.props
    history.push({
      pathname: '/update_user_password',
    })
  }

  render() {
    const {
      classes,
      history,
      onSignUp,
      onUpdateUser,
      status,
      container,
      userData,
      isForUpdate,
      onCheckEmail,
      isValidEmail,
      isInValidEmail,
    } = this.props

    const { handleUpdatePasswordClick } = this

    const validationPasswordForm = isForUpdate ? null : {
      password: Yup.string()
        .min('6', '비밀번호는 6자리 이상 입력해주세요.')
        .max('12', '비밀번호는 12자리 까지 입력해주세요.')
        .required('비밀번호 입력은 필수 값입니다.'),
      password_confirmation: Yup.string()
        .oneOf([Yup.ref('password')], '비밀번호가 일치하지 않습니다.')
        .required('비밀번호 확인 입력은 필수 값입니다.'),
    }

    return (
      <div className={container}>
        {!isForUpdate
            && (
            <Typography variant="h5" gutterBottom className={classes.title}>
              회원 정보입력
            </Typography>
            )}
        {status !== 200 && <div className={classes.failure}>회원정보 입력이 올바르지 않습니다.</div>}
        <Row>
          <Col sm={3}>
            <Typography variant="h6" gutterBottom color="secondary">
              기본정보
            </Typography>
          </Col>
          <Col sm={7}>필수 입력사항입니다</Col>
          <Col sm={2} />
        </Row>

        <Divider style={{ margin: '14px 0 24px 0' }} />

        <Formik
          initialValues={!isEmpty(userData) ? {
            email: userData.email,
            company_name: userData.company_name,
            name: userData.name,
            position: userData.position,
            tel: userData.tel,
            phone: userData.phone,
            business_registration_file: userData.business_registration_file || '',
            bankbook_file: userData.bankbook_file || '',
            email_for_tax: userData.email_for_tax || '',
          } : {
            email: '',
            password: '',
            password_confirmation: '',
            company_name: '',
            name: '',
            position: '',
            tel: '',
            phone: '',
            business_registration_file: '',
            bankbook_file: '',
            email_for_tax: '',
          }}
          onSubmit={(values, { setSubmitting }) => {
            if (isForUpdate) {
              return onUpdateUser(values, userData.id)
                .then(() => setSubmitting(false))
                .then(() => history.push('/campaign'))
            }

            return onSignUp(values)
              .then(() => setSubmitting(false))
              .then(() => history.push('/request_confirmation'))
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email('올바른 아이디(올바른 아이디(이메일 주소)형식이 아닙니다.')
              .required('아이디는 필수 입력 값입니다.'),
            ...validationPasswordForm,
            company_name: Yup.string()
              .min('2', '회사명은 최소 2자리 이상 입력해주세요.')
              .max('16', '회사명은 최대 16자리 까지 입력해주세요.')
              .required('회사명 필수 입력 값입니다.'),
            name: Yup.string()
              .min('2', '담당자 이름은 최소 2자리 이상 입력해주세요.')
              .max('16', '담당자 이름은 최대 16자리 까지 입력해주세요.')
              .required('담당자 이름은 필수 입력 값입니다.'),
            position: Yup.string()
              .min('2', '소속은 2자리 이상 입력해주세요.')
              .max('16', '소속은 16자리 까지 입력해주세요.')
              .required('소속은 필수 입력 값입니다.'),
            tel: Yup.string()
              .min('9', '- 없이 9자리 이상 입력해주세요')
              .max('11', '-없이 11자리 까지 입력해주세요')
              .required('전화번호는 필수 입력 값입니다.'),
            phone: Yup.string()
              .min('10', '-없이 10자리 이상 입력해주세요')
              .max('11', '-없이 11자리 까지 입력해주세요')
              .required('휴대폰번호는 필수 입력 값입니다.'),
            business_registration_file: Yup.string()
              .required('사업자 등록증은 필수 값입니다.'),
          })
            }
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            } = props

            const handleCheckEmailClick = () => {
              onCheckEmail({ email: values.email })
            }

            return (
              <Form onSubmit={handleSubmit}>
                <FormGroup row>
                  <Label sm={3}>아이디(이메일)</Label>
                  <Col sm={7}>
                    <Input
                      type="email"
                      name="email"
                      className={
                        errors.email && touched.email ? classes.fieldError : classes.field
                      }
                      style={{ backgroundColor: isForUpdate ? '#e9ecef' : '#ffffff' }}
                      disabled={isForUpdate && true}
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="이메일 형식으로 입력해주세요. 예) classting@classting.com"
                    />
                    {errors.email && touched.email
                      && <div className={classes.invalidFeedback}>{errors.email}</div>}
                    {isValidEmail
                      && <div className={classes.validFeedback}>사용가능한 이메일 입니다.</div>}
                    {isInValidEmail
                      && <div className={classes.invalidFeedback}>사용불가능한 이메일 입니다.</div>}
                  </Col>
                  <Col sm={2}>
                    {!isForUpdate
                      && (
                      <Button
                        variant="outlined"
                        color="secondary"
                        className={classes.button}
                        onClick={handleCheckEmailClick}
                      >
                        중복확인
                      </Button>
                      )
                    }
                  </Col>
                </FormGroup>
                {isForUpdate
                  ? (
                    <FormGroup row>
                      <Label sm={3}>비밀번호</Label>
                      <Col sm={7}>
                        <Input
                          type="password"
                          name="password"
                          disabled
                        />
                      </Col>
                      <Col sm={2}>
                        <Button
                          variant="outlined"
                          color="secondary"
                          className={classes.button}
                          onClick={handleUpdatePasswordClick}
                        >
                          비밀번호 수정
                        </Button>
                      </Col>
                    </FormGroup>
                  )
                  : (
                    <FormGroup row>
                      <Label sm={3}>비밀번호</Label>
                      <Col sm={7}>
                        <Input
                          type="password"
                          name="password"
                          className={
                          errors.password && touched.password ? classes.fieldError : classes.field
                        }
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="2자 이상, 12자 이내의 비밀번호를 입력해주세요"
                        />
                        {errors.password
                          && touched.password
                          && <div className={classes.invalidFeedback}>{errors.password}</div>}
                      </Col>
                      <Col sm={2} />
                    </FormGroup>
                  )
                }

                {isForUpdate
                  ? null
                  : (
                    <FormGroup row>
                      <Label sm={3}>비밀번호 확인</Label>
                      <Col sm={7}>
                        <Input
                          type="password"
                          name="password_confirmation"
                          className={errors.password_confirmation
                            && touched.password_confirmation ? classes.fieldError : classes.field
                          }
                          value={values.password_confirmation}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.password_confirmation
                          && touched.password_confirmation
                          && (
                            <div className={classes.invalidFeedback}>
                              {errors.password_confirmation}
                            </div>
                          )}
                      </Col>
                      <Col sm={2} />
                    </FormGroup>
                  )
                }

                <FormGroup row>
                  <Label sm={3}>회사명</Label>
                  <Col sm={7}>
                    <Input
                      type="text"
                      name="company_name"
                      className={errors.company_name
                        && touched.company_name ? classes.fieldError : classes.field
                      }
                      value={values.company_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="예) 클래스팅"
                    />
                    {errors.company_name
                      && touched.company_name
                      && <div className={classes.invalidFeedback}>{errors.company_name}</div>}
                  </Col>
                  <Col sm={2} />
                </FormGroup>

                <FormGroup row>
                  <Label sm={3}>담당자 이름</Label>
                  <Col sm={7}>
                    <Input
                      type="text"
                      name="name"
                      className={
                        errors.name && touched.name ? classes.fieldError : classes.field
                      }
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="예) 홍길동"
                    />
                    {errors.name && touched.name
                      && <div className={classes.invalidFeedback}>{errors.name}</div>}
                  </Col>
                  <Col sm={2} />
                </FormGroup>

                <FormGroup row>
                  <Label sm={3}>사업자 등록증</Label>
                  <Col sm={7}>
                    <Input
                      type="text"
                      name="business_registration_file"
                      className={errors.business_registration_file
                        && touched.business_registration_file
                        ? classes.fieldError
                        : classes.field
                      }
                      value={(values.business_registration_file
                        && values.business_registration_file.name)
                        || values.business_registration_file}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled
                    />
                    {(errors.business_registration_file && touched.business_registration_file)
                      && (
                      <div className={classes.invalidFeedback}>
                        {errors.business_registration_file}
                      </div>
                      )
                    }
                  </Col>
                  <Col sm={2}>
                    <Dropzone
                      accept=".jpg,.png,.pdf"
                      onDrop={(file) => {
                        setFieldValue('business_registration_file', file[0])
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.button}
                          >
                            등록하기
                          </Button>
                        </div>
                      )}
                    </Dropzone>
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label sm={3}>소속</Label>
                  <Col sm={7}>
                    <Input
                      type="text"
                      name="position"
                      className={
                        errors.position && touched.position ? classes.fieldError : classes.field
                      }
                      value={values.position}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="예) 마케팅팀"
                    />
                    {errors.position && touched.position
                      && <div className={classes.invalidFeedback}>{errors.position}</div>}
                  </Col>
                  <Col sm={2} />
                </FormGroup>

                <FormGroup row>
                  <Label sm={3}>전화번호</Label>
                  <Col sm={7}>
                    <Input
                      type="tell"
                      name="tel"
                      className={
                        errors.tel && touched.tel ? classes.fieldError : classes.field
                      }
                      value={values.tel}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="- 없이 숫자만 입력해주세요"
                    />
                    {errors.tel && touched.tel
                      && <div className={classes.invalidFeedback}>{errors.tel}</div>}
                  </Col>
                  <Col sm={2} />
                </FormGroup>

                <FormGroup row>
                  <Label sm={3}>휴대전화번호</Label>
                  <Col sm={7}>
                    <Input
                      type="tell"
                      name="phone"
                      className={
                        errors.phone && touched.phone ? classes.fieldError : classes.field
                      }
                      value={values.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="- 없이 숫자만 입력해주세요"
                    />
                    {errors.phone && touched.phone
                      && <div className={classes.invalidFeedback}>{errors.phone}</div>}
                  </Col>
                  <Col sm={2} />
                </FormGroup>
                <br />
                <br />
                <br />
                <br />
                <Row>
                  <Col sm={3}>
                    <Typography variant="h6" gutterBottom color="secondary">
                      선택정보
                    </Typography>
                  </Col>
                  <Col sm={7}>선택 입력사항입니다</Col>
                  <Col sm={2} />
                </Row>

                <Divider style={{ margin: '14px 0 24px 0' }} />

                <FormGroup row>
                  <Label sm={3}>통장사본</Label>
                  <Col sm={7}>
                    <Input
                      type="text"
                      name="bankbook_file"
                      className={errors.bankbook_file
                        && touched.business_registration_file
                        ? classes.fieldError : classes.field
                      }
                      value={(values.bankbook_file
                        && values.bankbook_file.name)
                        || values.bankbook_file}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled
                    />
                  </Col>
                  <Col sm={2}>
                    <Dropzone
                      accept=".jpg,.png,.pdf"
                      onDrop={(file) => {
                        setFieldValue('bankbook_file', file[0])
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.button}
                          >
                            등록하기
                          </Button>
                        </div>
                      )}
                    </Dropzone>
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label sm={3}>세금계산서 수령 이메일</Label>
                  <Col sm={7}>
                    <Input
                      type="email"
                      name="email_for_tax"
                      value={values.email_for_tax}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col sm={2} />
                </FormGroup>

                <FormGroup row>
                  <Label sm={3} />
                  <Col sm={9}>
                    <Button
                      className={classes.submitButton}
                      variant="contained"
                      color="secondary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isForUpdate ? '수정완료' : '회원가입'}
                    </Button>
                  </Col>
                </FormGroup>
              </Form>
            )
          }}
        </Formik>
      </div>
    )
  }
}

SignUpForm.propTypes = {
  container: PropTypes.shape({}).isRequired,
  onUpdateUser: PropTypes.func.isRequired,
  onSignUp: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  userData: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    company_name: PropTypes.string,
    name: PropTypes.string,
    position: PropTypes.string,
    tel: PropTypes.string,
    phone: PropTypes.string,
    business_registration_file: PropTypes.string,
    bankbook_file: PropTypes.string,
    email_for_tax: PropTypes.string,
  }).isRequired,
  onResetUser: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    fieldError: PropTypes.string,
    field: PropTypes.string,
    invalidFeedback: PropTypes.string,
    validFeedback: PropTypes.string,
    button: PropTypes.string,
    submitButton: PropTypes.string,
    title: PropTypes.string,
    failure: PropTypes.string,
  }).isRequired,
  isForUpdate: PropTypes.bool,
  onCheckEmail: PropTypes.func.isRequired,
  isValidEmail: PropTypes.bool.isRequired,
  isInValidEmail: PropTypes.bool.isRequired,
  status: PropTypes.bool.isRequired,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(SignUpForm)
