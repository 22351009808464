import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { map, range } from 'lodash'
import { Button } from '@material-ui/core'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'

const styles = ({
  container: {
    textAlign: 'center',
    padding: '24px',
    margin: '8px',
  },
  button: {
    width: '28px',
    minWidth: 0,
    height: '28px',
    linegHeight: '28px',
    minHeight: 0,
    padding: 0,
    color: '#212121',
    fontSize: '12px',
    fontWeight: 'normal',
    '&:hover': {
      color: '#00c896',
      borderRadius: '4px',
    },
  },
  active: {
    color: '#00c896',
    fontSize: '12px',
    fontWeight: 'bold',
    width: '28px',
    minWidth: 0,
    height: '28px',
    minHeight: 0,
    padding: 0,
    borderRadius: '2px',
    border: 'solid 1px #00c896',
    backgroundColor: '#c2f3e2',
  },
})

class Pagination extends Component {
  handleClick = pageNumber => () => {
    const { onPagination } = this.props
    onPagination(pageNumber)
  }

  startPageGroupNumber = () => {
    const { pageNumber, perPage } = this.props
    return (Math.ceil(pageNumber / perPage) * perPage) - perPage
  }

  lastPageGroupNumber = () => {
    const { pageNumber, perPage } = this.props
    return Math.ceil(pageNumber / perPage) * perPage
  }

  lastPageNumber = () => {
    const { perPage, pageTotal } = this.props
    return Math.ceil(pageTotal / perPage)
  }

  isDisabledPrev = () => {
    const { pageNumber } = this.props
    return pageNumber === 1
  }

  isDisabledNext = () => {
    const { pageNumber } = this.props
    return this.lastPageNumber() === pageNumber
  }


  render() {
    const {
      handleClick, isDisabledPrev, isDisabledNext, lastPageNumber,
      startPageGroupNumber, lastPageGroupNumber,
    } = this
    const { pageNumber, classes } = this.props

    return (
      <div className={classes.container}>
        <Button
          className={classes.button}
          onClick={handleClick(pageNumber - 1)}
          color="primary"
          disabled={isDisabledPrev()}
        >
          <ChevronLeft />
        </Button>
        {map(range(startPageGroupNumber(), lastPageGroupNumber()), number => (
          number + 1 <= lastPageNumber()
            ? (
              <Button
                className={number + 1 === pageNumber ? classes.active : classes.button}
                key={number}
                onClick={handleClick(number + 1)}
                color="primary"
              >
                {number + 1}
              </Button>
            )
            : null
        ))}
        <Button
          className={classes.button}
          onClick={handleClick(pageNumber + 1)}
          color="primary"
          disabled={isDisabledNext()}
        >
          <ChevronRight />
        </Button>
      </div>
    )
  }
}

Pagination.propTypes = {
  perPage: PropTypes.number.isRequired,
  pageTotal: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  onPagination: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    container: PropTypes.string,
    active: PropTypes.string,
    button: PropTypes.string,
  }).isRequired,
}

export default withStyles(styles)(Pagination)
