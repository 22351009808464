import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Table } from 'reactstrap'
import { map } from 'lodash'

const styles = ({})

class EnhancedTable extends React.Component {
  componentDidMount() {
  }

  render() {
    const { header, children } = this.props
    return (
      <Table bordered>
        <thead>
          <tr style={{ backgroundColor: '#f3f3f3' }}>
            {map(header, item => <th style={{ borderBottomWidth: '1px' }}>{item}</th>)}
          </tr>
        </thead>
        <tbody>
          {children}
        </tbody>
      </Table>
    )
  }
}

EnhancedTable.propTypes = {
  header: PropTypes.shape({}).isRequired,
  children: PropTypes.shape({}).isRequired,
}

export default withStyles(styles)(EnhancedTable)
