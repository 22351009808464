import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  root: ({ fullWidth, width }) => ({
    outline: 'none',
    appearance: 'none',
    border: '1px solid #bdbdbd',
    borderRadius: '4px',
    overflow: 'auto',
    boxShadow: 'none',
    resize: 'none',
    width: fullWidth ? '100%' : width,
    padding: '9px 15px',
    boxSizing: 'border-box',

    '&::placeholder': {
      color: '#bdbdbd',
    },
  }),
  sm: {
    height: '40px',
  },
  md: {
    height: '62px',
  },
  lg: {
    height: '146px',
  },
})

const TextArea = ({
  value, placeholder, size, fullWidth, width, onChange,
}) => {
  const classes = useStyles({ fullWidth, width })

  return (
    <textarea
      className={cn(classes.root, classes[size])}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
    />
  )
}

TextArea.defaultProps = {
  placeholder: '',
  size: 'md',
  width: 'auto',
  fullWidth: true,
}

TextArea.propTypes = {
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

export default TextArea
