import React from 'react'
import PropTypes from 'prop-types'

import PostBannerAd from '@/features/preview/components/molecules/PostBannerAd'

const AdPreviewPostBannerType = ({
  imageUrl,
  backgroundColor,
}) => (
  <PostBannerAd
    imageUrl={imageUrl}
    backgroundColor={backgroundColor}
  />
)

AdPreviewPostBannerType.defaultProps = {
  imageUrl: '',
  backgroundColor: '',
}

AdPreviewPostBannerType.propTypes = {
  imageUrl: PropTypes.string,
  backgroundColor: PropTypes.string,
}

export default AdPreviewPostBannerType
