import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { NavigationBar } from '@/shared/components'
import privacy from './privacy.html'

const privacyHtml = { __html: privacy }

const styles = ({
  root: {
    padding: '24px',
  },
})

const PrivacyPage = ({ classes }) => (
  <div>
    <NavigationBar />
    {/* eslint-disable-next-line react/no-danger */}
    <div className={classes.root} dangerouslySetInnerHTML={privacyHtml} />
    {/* eslint-disable */}
  </div>
)

PrivacyPage.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string
  }).isRequired,
}

export default withStyles(styles)(PrivacyPage)
