import get from 'lodash/get'

import { STAGE, gamAdUnitName } from '../constants'

const getViewTemplate = (info) => {
  let viewTemplate = get(info, 'view_template')
  if (viewTemplate === 'google_ad_manager') {
    const adUnitId = get(info, 'gam_ad_unit_id')
    viewTemplate = gamAdUnitName[STAGE][adUnitId]
  }

  return viewTemplate
}

export default getViewTemplate
