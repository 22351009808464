import React from 'react'
import PropTypes from 'prop-types'

const BaseComponent = ({ tableProps, className }) => (
  <div
    className={className}
    style={tableProps.style}
  >
    {tableProps.children}
  </div>
)

BaseComponent.propTypes = {
  tableProps: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
}

export default BaseComponent
