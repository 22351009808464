import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Form, FormGroup, Input } from 'reactstrap'
import { Formik } from 'formik'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import DialogContentText from '@material-ui/core/DialogContentText'
import * as Yup from 'yup'
import { withTracker } from '@/shared/enhancers'
import { NavigationBar, Linear, EnhancedDialog } from '@/shared/components'
import { resetPassword, signUpResetState } from '../actions'

const styles = ({
  container: {
    maxWidth: '846px',
    margin: '0 auto',
    padding: '64px 0 0 0',
  },
  formContainer: {
    margin: '0 auto',
    maxWidth: '424px',
  },
  fieldError: {
    margin: '4px 0 4px 0',
    border: '1px solid #e85d7b',
  },
  invalidFeedback: {
    color: '#e85d7b',
    fontSize: '12px',
  },
  formTitle: {
    textAlign: 'center',
    margin: '24px',
  },
  failure: {
    color: '#e85d7b',
    fontSize: '14px',
    margin: '14px 0 14px 0',
    textAlign: 'center',
  },
  button: {
    color: '#ffffff',
    width: '100%',
  },
})

const mapStateToProps = state => ({
  pending: state.signUp.pending,
  status: state.signUp.status,
  success: state.signUp.success,
  failure: state.signUp.failure,
})

const mapDispatchToProps = dispatch => ({
  onResetPassword: values => dispatch(resetPassword(values)),
  onSignUpResetState: () => dispatch(signUpResetState()),
})

class FindPasswordPage extends Component {
  state = {
    isOpenModal: true,
  }

  componentDidMount() {
    const { onSignUpResetState } = this.props
    onSignUpResetState()
  }

  render() {
    const {
      classes, onResetPassword, failure, success, pending, history,
    } = this.props
    const { isOpenModal } = this.state

    return (
      <div>
        {pending && <Linear />}
        <NavigationBar />
        <div className={classes.container}>
          <Typography variant="h5" gutterBottom>
            비밀번호 찾기
          </Typography>
          <br />
          <br />
          <br />
          <br />
          <Divider />
          <div className={classes.formContainer}>
            {failure && <div className={classes.failure}>유효하지 않는 이메일 입니다.</div>}
            {success
              && (
              <EnhancedDialog
                isOpen={isOpenModal}
                isCloseButton={false}
                modalTitle="이메일을 전송했습니다"
                handleOpen={() => {
                  this.setState({ isOpenModal: false }, async () => {
                    await history.push('/sign_in')
                  })
                }}
              >
                <DialogContentText>
                  이메일에서 주소를 확인 후 비밀번호를 변경해주세요.
                </DialogContentText>
              </EnhancedDialog>
              )
            }
            <Formik
              initalValues={{
                email: '',
              }}
              onSubmit={(values, { setSubmitting }) => {
                onResetPassword(values)
                  .then(() => setSubmitting(false))
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email('올바른 아이디(올바른 아이디(이메일 주소)형식이 아닙니다.')
                  .required('아이디는 필수 입력 값입니다.'),
              })}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                } = props

                return (
                  <Form onSubmit={handleSubmit}>
                    <div className={classes.formTitle}>
                      비밀번호를 찾고자 하는 이메일(아이디)을 입력해주세요
                    </div>
                    <FormGroup row>
                      <Input
                        type="email"
                        name="email"
                        className={
                          errors.email && touched.email ? classes.fieldError : classes.field
                        }
                        placeholder=""
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.email && touched.email
                        && <div className={classes.invalidFeedback}>{errors.email}</div>}
                    </FormGroup>
                    <FormGroup row>
                      <Button
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        비밀번호 재설정 메일 보내기
                      </Button>
                    </FormGroup>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </div>
      </div>
    )
  }
}

FindPasswordPage.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string,
    formContainer: PropTypes.string,
    failure: PropTypes.string,
    formTitle: PropTypes.string,
    field: PropTypes.string,
    fieldError: PropTypes.string,
    invalidFeedback: PropTypes.string,
    button: PropTypes.string,
  }).isRequired,
  onResetPassword: PropTypes.func.isRequired,
  onSignUpResetState: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  success: PropTypes.bool.isRequired,
  failure: PropTypes.bool.isRequired,
  pending: PropTypes.bool.isRequired,
}

export default compose(
  withTracker,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(FindPasswordPage)
