import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
  preview: {
    display: 'flex',
    padding: '42px 0',
    flex: '1',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#f5f5f5',
  },
  form: {
    flex: '1',
    padding: '24px 40px 40px 40px',
    background: '#fff',
    maxWidth: '820px',
  },
})

const PreviewFormTemplate = ({ form, preview }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.form}>
        {form}
      </div>
      <div className={classes.preview}>
        {preview}
      </div>
    </div>
  )
}

PreviewFormTemplate.propTypes = {
  form: PropTypes.element.isRequired,
  preview: PropTypes.element.isRequired,
}

export default PreviewFormTemplate
