import { route as landing } from './landing'
import { route as signUp } from './signUp'
import { route as signIn } from './signIn'
import { route as campaign } from './campaign'
import { route as policy } from './policy'
import { route as user } from './user'
import { route as advertiser } from './advertiser'
import { route as editing } from './editing'
import { route as payment } from './payment'
import { route as today } from './today'
import { route as impression } from './impression'
import { route as preview } from './preview'
import { route as legacyToday } from './legacy-today'

export default [
  landing,
  ...signUp,
  ...signIn,
  ...campaign,
  ...policy,
  ...user,
  ...advertiser,
  ...editing,
  ...payment,
  ...today,
  ...impression,
  ...preview,
  ...legacyToday,
]
