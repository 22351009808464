import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import icChevronRight from '@/shared/assets/gray-right-arrow.svg'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '360px',
    padding: '12px 12px 16px 12px',
    backgroundColor: '#1f344f',
  },
  header: {
    marginTop: '12px',
  },
  advertiserName: {
    color: '#FFF',
    fontSize: '14px',
    lineHeight: '21px',
  },
  sponsored: {
    fontSize: '10px',
    marginTop: '4px',
    color: '#ffffff',
  },
  content: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
  },
  description: {
    margin: '0',
    color: 'rgba(255, 255, 255, 0.8)',
    marginBottom: '12px',
    wordBreak: 'break-all',
    whiteSpace: 'break-spaces',
  },
  actionButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#424242',
    backgroundColor: '#fff',
    fontWeight: '600',
    borderRadius: '8px',
    height: '44px',
    fontSize: '14px',
    lineHeight: '21px',
    marginTop: 'auto',
  },
}))

const AdPreviewNoticeType = ({ advertiserName, actionLabel, description }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.advertiserName}>{advertiserName}</div>
        <span className={classes.sponsored}>Sponsored</span>
      </div>
      <div className={classes.content}>
        <p className={classes.description}>
          {description}
        </p>
        <div className={classes.actionButton}>
          {actionLabel}
          <img src={icChevronRight} alt="화살표 아이콘" />
        </div>
      </div>
    </div>
  )
}

AdPreviewNoticeType.propTypes = {
  advertiserName: PropTypes.string.isRequired,
  actionLabel: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default AdPreviewNoticeType
