import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'

import { Switch } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import {
  getToggleInfo,
  handleAdsForceStop,
} from './utils'

import * as switchTypes from './types'

const baseSwitchStyle = {
  switchBase: {
    width: 30,
    height: 30,
  },
  root: {
    width: 46,
    marginTop: -4,
  },
}

export const BaseSwitch = withStyles(baseSwitchStyle)(Switch)

const WarningSwitch = withStyles({
  switchBase: {
    ...baseSwitchStyle.switchBase,
    color: '#bdbdbd',
    '&$checked': {
      color: '#ffbb00',
    },
    '&$checked + $bar': {
      backgroundColor: '#ffbb00',
    },
  },
  root: {
    ...baseSwitchStyle.root,
  },
  bar: {},
  checked: {},
})(Switch)

const StatusToggle = ({ row, onUpdateAdForcedStop }) => {
  if (isEmpty(row.value)) {
    return '-'
  }

  const toggleStyles = getToggleInfo(row.value)
  const style = { height: '30px', padding: '0px' }
  const isStatusPartialOn = toggleStyles.type === switchTypes.STATUS_SWITCH_PARTIAL_ON
  const onChange = () => handleAdsForceStop({
    ads: row.value, onUpdateAdForcedStop, isForceStopShouldBeTrue: isStatusPartialOn,
  })

  if (isStatusPartialOn) {
    return (
      <WarningSwitch
        checked={toggleStyles.checked}
        onChange={onChange}
        style={style}
      />
    )
  }

  return (
    <BaseSwitch
      checked={toggleStyles.checked}
      onChange={onChange}
    />
  )
}

StatusToggle.propTypes = {
  row: PropTypes.object.isRequired,
  onUpdateAdForcedStop: PropTypes.func.isRequired,
}

export default StatusToggle
