import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import classNames from 'classnames'

const defaultStyles = {
  container: {
    padding: '40px',
    margin: '14px 0 44px 0',
    minWidth: '1140px',
    position: 'relative',
  },
}

function EnhancedPaper(props) {
  const { classes, children, styles } = props
  return (
    <Paper className={classNames(classes.container, styles)}>
      {children}
    </Paper>
  )
}

EnhancedPaper.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string,
  }).isRequired,
  children: PropTypes.node.isRequired,
  styles: PropTypes.string,
}

EnhancedPaper.defaultProps = {
  styles: '',
}

export default withStyles(defaultStyles)(EnhancedPaper)
