import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Form, FormGroup, Input } from 'reactstrap'
import { Formik } from 'formik'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import DialogContentText from '@material-ui/core/DialogContentText'
import * as Yup from 'yup'
import { withTracker } from '@/shared/enhancers'
import { NavigationBar, Linear, EnhancedDialog } from '@/shared/components'
import { queryString } from '@/shared/utils'
import { updatePassword, signUpResetState } from '../actions'

const styles = ({
  container: {
    maxWidth: '846px',
    margin: '0 auto',
    padding: '64px 0 0 0',
  },
  formContainer: {
    margin: '0 auto',
    maxWidth: '424px',
  },
  fieldError: {
    margin: '4px 0 4px 0',
    border: '1px solid #e85d7b',
  },
  invalidFeedback: {
    color: '#e85d7b',
    fontSize: '12px',
  },
  formTitle: {
    textAlign: 'center',
    margin: '24px',
  },
  failure: {
    color: '#e85d7b',
    fontSize: '14px',
    margin: '14px 0 14px 0',
    textAlign: 'center',
  },
  button: {
    color: '#ffffff',
    width: '100%',
  },
})

const mapStateToProps = state => ({
  pending: state.signUp.pending,
  status: state.signUp.status,
  success: state.signUp.success,
  failure: state.signUp.failure,
})

const mapDispatchToProps = dispatch => ({
  onUpdatePassword: values => dispatch(updatePassword(values)),
  onSignUpResetState: () => dispatch(signUpResetState()),
})

class UpdatePasswordPage extends Component {
  state = {
    token: '',
    isOpenModal: true,
  }

  componentDidMount() {
    const { location, onSignUpResetState } = this.props
    onSignUpResetState()
    this.setState({ token: queryString.parse(location.search).token || '' })
  }

  render() {
    const {
      classes, onUpdatePassword, failure, success, pending, history,
    } = this.props
    const { token, isOpenModal } = this.state

    return (
      <div>
        {pending && <Linear />}
        <NavigationBar />
        <div className={classes.container}>
          <Typography variant="h5" gutterBottom>
            비밀번호 변경
          </Typography>
          <br />
          <br />
          <br />
          <br />
          <Divider />
          <div className={classes.formContainer}>
            { failure && <div className={classes.failure}>입력하신 비밀번호가 유효하지 않습니다.</div>}
            { success
              && (
              <EnhancedDialog
                isOpen={isOpenModal}
                isCloseButton={false}
                modalTitle="비밀번호 변경성공"
                handleOpen={() => {
                  this.setState({ isOpenModal: false }, async () => {
                    await history.push('/sign_in')
                  })
                }}
              >
                <DialogContentText>
                  변경하신 비밀번호로 다시 로그인해주세요.
                </DialogContentText>
              </EnhancedDialog>
              )
            }
            <Formik
              initalValues={{
                password: '',
                password_confirmation: '',
              }}
              onSubmit={(values, { setSubmitting }) => {
                onUpdatePassword({ ...values, reset_password_token: token })
                  .then(() => setSubmitting(false))
              }}
              validationSchema={Yup.object().shape({
                password: Yup.string()
                  .min('6', '비밀번호는 6자리 이상 입력해주세요.')
                  .max('12', '비밀번호는 12자리 까지 입력해주세요.')
                  .required('비밀번호 입력은 필수 값입니다.'),
                password_confirmation: Yup.string()
                  .oneOf([Yup.ref('password')], '비밀번호가 일치하지 않습니다.')
                  .required('비밀번호 확인 입력은 필수 값입니다.'),
              })}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                } = props

                return (
                  <Form onSubmit={handleSubmit}>
                    <div className={classes.formTitle}>
                      새로운 비밀번호를 입력하세요
                    </div>
                    <FormGroup row>
                      <Input
                        type="password"
                        name="password"
                        className={
                        errors.password && touched.password ? `${classes.fieldError} ` : `${classes.field}`
                      }
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="새 비밀번호 입력"
                      />
                      {errors.password && touched.password
                        && <div className={classes.invalidFeedback}>{errors.password}</div>}
                    </FormGroup>
                    <FormGroup row>
                      <Input
                        type="password"
                        name="password_confirmation"
                        className={
                        errors.password_confirmation && touched.password_confirmation ? `${classes.fieldError} ` : `${classes.field}`
                      }
                        value={values.password_confirmation}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="새 비밀번호 확인"
                      />
                      {errors.password_confirmation
                        && touched.password_confirmation
                        && (
                          <div className={classes.invalidFeedback}>
                            {errors.password_confirmation}
                          </div>
                        )}
                    </FormGroup>
                    <FormGroup row>
                      <Button
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        확인
                      </Button>
                    </FormGroup>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </div>
      </div>
    )
  }
}

UpdatePasswordPage.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string,
    formContainer: PropTypes.string,
    failure: PropTypes.string,
    formTitle: PropTypes.string,
    fieldError: PropTypes.string,
    field: PropTypes.string,
    invalidFeedback: PropTypes.string,
    button: PropTypes.string,
  }).isRequired,
  onUpdatePassword: PropTypes.func.isRequired,
  onSignUpResetState: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  success: PropTypes.bool.isRequired,
  failure: PropTypes.bool.isRequired,
  pending: PropTypes.bool.isRequired,
}

export default compose(
  withTracker,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(UpdatePasswordPage)
