import React from 'react'
import PropTypes from 'prop-types'
import { ClassTabAd } from '@/features/preview/components'

const AdPreviewClassTabType = ({
  imageUrl,
}) => (
  <ClassTabAd
    imageUrl={imageUrl}
  />


)

AdPreviewClassTabType.propTypes = {
  imageUrl: PropTypes.string.isRequired,
}

export default AdPreviewClassTabType
