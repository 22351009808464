/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import PropTypes from 'prop-types'

import { CheckBox, TextInput } from '@landing/components/unit/form'
import Remark from './Remark'

const makeStyles = () => ({
  checkBox: ({ spacing }) => css`
    margin-right: ${spacing(3)}rem;

    :last-of-type {
      margin-right: 0;
    }
  `,
  checkBoxWrap: () => css`
    display: flex;
    flex-wrap: wrap;
  `,
  etcInput: ({ spacing, mq }) => css`
    ${mq({
    marginTop: `${spacing(2)}rem`,
    width: ['100%', '38.4rem'],
  })}
  `,
  field: ({ colors: { grey }, spacing }) => css`
    background-color: ${grey[50]};
    border-radius: 4px;
    padding: ${spacing(2.5)}rem ${spacing(3)}rem;
  `,
  fieldWrap: ({ spacing }) => css`
    margin-bottom: ${spacing(2)}rem;
  `,
  goalsCheckBox: ({ mq, spacing }) => css`
    ${mq({
    marginBottom: `${spacing()}rem`,
  })}

    :nth-of-type(n + 6) {
      margin-bottom: 0;
    }
  `,
  remark: ({ spacing }) => css`
    margin-left: ${spacing(2)}rem;
  `,
  root: ({ colors: { grey }, spacing }) => css`
    margin-top: ${spacing(5)}rem;
    padding-bottom: ${spacing(5)}rem;
    border-bottom: 1px solid ${grey[200]};
  `,
  subTitle: ({ colors: { grey }, font, mq }) => css`
    ${mq({
    color: grey[800],
    fontSize: font.body2.fontSize,
    fontWeight: 'bold',
    lineHeight: font.body2.lineHeight,
  })}
  `,
  subTitleWrap: ({ spacing }) => css`
    display: flex;
    align-items: center;
    margin-bottom: ${spacing()}rem;
  `,
  targetsCheckBox: ({ mq, spacing }) => css`
    ${mq({
    marginBottom: [`${spacing()}rem`, '0'],
  })}

    :last-of-type {
      margin-bottom: 0;
    }
  `,
  title: ({ colors: { grey }, font, spacing }) => css`
    color: ${grey[900]};
    font-size: ${font.title3.fontSize};
    font-weight: bold;
    line-height: ${font.title3.lineHeight};
    margin-bottom: ${spacing(3)}rem;
  `,
})

const AdvertisementInformation = ({
  inquireInfo: { targets, goals },
  setInquireInfo,
}) => {
  const styles = makeStyles()

  const handleTargetChange = ({ target: { checked, name } }) => {
    setInquireInfo(prev => ({
      ...prev,
      targets: prev.targets.map(target => (target.label === name ? { ...target, checked } : target)),
    }))
  }

  const handleGoalChange = ({ target: { checked, name } }) => {
    setInquireInfo(prev => ({
      ...prev,
      goals: prev.goals.map(goal => (goal.label === name ? { ...goal, checked } : goal)),
    }))
  }

  const handleGoalEtcTextChange = ({ target: { value, name } }) => {
    setInquireInfo(prev => ({
      ...prev,
      goals: prev.goals.map(goal => (goal.label === name ? { ...goal, value } : goal)),
    }))
  }

  const etcGoal = goals.find(({ label }) => label === '기타')

  return (
    <div css={styles.root}>
      <h3 css={styles.title}>광고정보</h3>
      <div css={styles.fieldWrap}>
        <div css={styles.subTitleWrap}>
          <span css={styles.subTitle}>광고 타겟</span>
          <Remark customStyles={styles.remark} text="복수 선택이 가능합니다." />
        </div>
        <div css={styles.field}>
          <div css={styles.checkBoxWrap}>
            {targets.map(({ label, checked }) => (
              <CheckBox
                customStyles={[styles.checkBox, styles.targetsCheckBox]}
                key={label}
                checked={checked}
                label={label}
                onChange={handleTargetChange}
              />
            ))}
          </div>
        </div>
      </div>
      <div css={styles.fieldWrap}>
        <div css={styles.subTitleWrap}>
          <span css={styles.subTitle}>광고 KPI(목표)</span>
          <Remark customStyles={styles.remark} text="복수 선택이 가능합니다." />
        </div>
      </div>
      <div css={styles.field}>
        <div css={styles.checkBoxWrap}>
          {goals.map(({ label, checked }) => (
            <CheckBox
              customStyles={[styles.checkBox, styles.goalsCheckBox]}
              key={label}
              checked={checked}
              label={label}
              onChange={handleGoalChange}
            />
          ))}
        </div>
        {etcGoal.checked && (
          <TextInput
            customStyles={styles.etcInput}
            label="직접 입력"
            name={etcGoal.label}
            placeholder="광고 KPI (목표)를 입력하세요"
            value={etcGoal.value}
            onChange={handleGoalEtcTextChange}
          />
        )}
      </div>
    </div>
  )
}

AdvertisementInformation.propTypes = {
  inquireInfo: PropTypes.shape({
    goals: PropTypes.arrayOf(
      PropTypes.shape({
        checked: PropTypes.bool,
        label: PropTypes.string,
      }),
    ),
    targets: PropTypes.arrayOf(
      PropTypes.shape({
        checked: PropTypes.bool,
        label: PropTypes.string,
      }),
    ),
  }).isRequired,
  setInquireInfo: PropTypes.func.isRequired,
}

export default AdvertisementInformation
