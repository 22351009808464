import {
  CampaignTypeFormPage,
  CampaignFormPage,
  AdSetFormPage,
  AdFormPage,
} from './page'

export default [
  {
    key: 'campaign_type_form',
    requiresAuth: true,
    exact: true,
    path: '/campaign_type_form',
    component: CampaignTypeFormPage,
  },
  {
    key: 'campaign_form',
    requiresAuth: true,
    exact: true,
    path: '/campaign_form',
    component: CampaignFormPage,
  },
  {
    key: 'adset_form',
    requiresAuth: true,
    exact: true,
    path: '/adset_form',
    component: AdSetFormPage,
  },
  {
    key: 'ad_form',
    requiresAuth: true,
    exact: true,
    path: '/ad_form',
    component: AdFormPage,
  },

]
