import {
  UpdateUserPasswordPage,
  AccountPage,
} from './page'

export default [
  {
    key: 'account',
    requiresAuth: true,
    exact: true,
    path: '/account',
    component: AccountPage,
  },
  {
    key: 'update_user_password',
    requiresAuth: true,
    exact: true,
    path: '/update_user_password',
    component: UpdateUserPasswordPage,
  },
]
