/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import PropTypes from 'prop-types'

const makeStyles = () => ({
  root: () => css`
    bottom: 0;
    position: fixed;
    width: 100%;
    z-index: 10;
  `,
})

const FloatButton = ({ children }) => {
  const styles = makeStyles()

  return <div css={styles.root}>{children}</div>
}

FloatButton.defaultProps = {
  children: [],
}

FloatButton.propTypes = {
  children: PropTypes.node,
}

export default FloatButton
