import React, { useState } from 'react'

import { previewFormFieldFactory } from '../data'
import { mockupImages } from '../constant'
import {
  TitleTemplate, NavigationTemplate, PreviewFormTemplate, PreviewForm,
  Preivew,
} from '../components'
import { extractFieldValueById } from '../utils'

const UNUSED_ADS = ['commentText', 'commentImage']

const TABS_IN_ORDER = [
  'postImage',
  'postVideo',
  'closePopup',
  'classTab',
  'notificationTextlink',
  'notificationImagelink',
  'postBanner',
]

const PREVIEW_MOCKUP_IMAGE_MAP = {
  postImage: [mockupImages.postImage],
  postVideo: [mockupImages.postVideo],
  commentImage: [mockupImages.commentImage],
  commentText: [mockupImages.commentText],
  closePopup: [mockupImages.closePopup],
  notificationTextlink: [mockupImages.notificationTextlink],
  notificationImagelink: [mockupImages.notificationImagelink],
  classTab: [mockupImages.classTab],
  postBanner: [mockupImages.postBannerOnList, mockupImages.postBannerOnDetail],
}

const PreviewPage = () => {
  const [previewFormField, setPreviewFormField] = useState(previewFormFieldFactory)
  const navigationItems = Object.entries(previewFormField)
    .map(([key, { name }]) => ({ id: key, name }))
    .filter((item) => {
      if (UNUSED_ADS.includes(item.id)) {
        return false
      }

      return true
    })
    .sort((item1, item2) => TABS_IN_ORDER.indexOf(item1.id) - TABS_IN_ORDER.indexOf(item2.id))
  const [selectedFormType, setSelectedFormType] = useState(navigationItems[0].id)
  const advertisement = extractFieldValueById(previewFormField[selectedFormType].fields)

  const changeInputValue = (fieldId, value) => {
    setPreviewFormField((prev) => {
      const targetFieldIdx = prev[selectedFormType].fields.findIndex(({ id }) => id === fieldId)
      const targetField = prev[selectedFormType].fields[targetFieldIdx]
      targetField.value = value

      return ({
        ...prev,
        [selectedFormType]: {
          ...prev[selectedFormType],
          fields: prev[selectedFormType].fields,
        },
      })
    })
  }

  return (
    <TitleTemplate title="광고 소재 사전 검수" caution="※ 광고 소재 사전 검수 화면은 참고용으로, 실제 광고 소재 검수 시에 반려될 수 있습니다">
      <NavigationTemplate
        selectedItem={selectedFormType}
        items={navigationItems}
        setSelectedItem={setSelectedFormType}
      >
        <PreviewFormTemplate
          form={(
            <PreviewForm
              fields={previewFormField[selectedFormType].fields}
              changeInputValue={changeInputValue}
            />
          )}
          preview={(
            <Preivew
              advertisement={advertisement}
              formType={selectedFormType}
              mockupImages={PREVIEW_MOCKUP_IMAGE_MAP[selectedFormType]}
            />
          )}
        />
      </NavigationTemplate>
    </TitleTemplate>
  )
}

export default PreviewPage
