import keyBy from 'lodash/keyBy'
import get from 'lodash/get'
import reduce from 'lodash/reduce'
import map from 'lodash/map'
import groupBy from 'lodash/groupBy'
import values from 'lodash/values'
import sortBy from 'lodash/sortBy'

import moment from 'moment'

import { handleActions } from 'redux-actions'
import { validateJSONString, getViewTemplate } from '@/shared/utils'
import { ADVERTISEMENT_NAME_MAP } from '@/shared/constants'
import {
  RESET_CAMPAIGN,
  FETCH_CAMPAIGNS_PENDING,
  FETCH_CAMPAIGNS_SUCCESS,
  FETCH_CAMPAIGNS_FAILURE,
  FETCH_CAMPAIGN_PENDING,
  FETCH_CAMPAIGN_SUCCESS,
  FETCH_CAMPAIGN_FAILURE,
  FETCH_AD_SETS_PENDING,
  FETCH_AD_SETS_SUCCESS,
  FETCH_AD_SETS_FAILURE,
  FETCH_AD_REPORTS_PENDING,
  FETCH_AD_REPORTS_SUCCESS,
  FETCH_AD_REPORTS_FAILURE,
  FETCH_REPORT_TOKEN_PENDING,
  FETCH_REPORT_TOKEN_SUCCESS,
  FETCH_REPORT_TOKEN_FAILURE,
  FETCH_REPORT_FILE_PENDING,
  FETCH_REPORT_FILE_SUCCESS,
  FETCH_REPORT_FILE_FAILURE,
  FETCH_ADVERTISEMENT_SUCCESS,
  FETCH_ADVERTISEMENT_FAILURE,
} from './actions'

import { convertAdCountCacheArrayToStats, getAllAdStats } from './utils'

export const initialState = {
  pending: false,
  refresh: false,
  exportToken: false,
  exportFile: false,
  status: 0,
  items: [],
  campaignReport: {},
  adReports: [],
  adSets: [],
  advertisements: {},
  total: '0',
  accessToken: '',
}

export default handleActions({
  [RESET_CAMPAIGN]: state => ({
    ...state,
    campaignReport: {},
    pending: false,
  }),

  [FETCH_CAMPAIGNS_PENDING]: state => ({
    ...state,
    pending: true,
  }),

  [FETCH_CAMPAIGNS_SUCCESS]: (state, action) => {
    const { data, status, headers } = action.payload
    return {
      ...state,
      pending: false,
      items: data,
      ...headers,
      status,
    }
  },

  [FETCH_CAMPAIGNS_FAILURE]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      pending: false,
      data,
      status,
    }
  },

  [FETCH_CAMPAIGN_PENDING]: state => ({
    ...state,
    pending: true,
  }
  ),

  [FETCH_CAMPAIGN_SUCCESS]: (state, action) => {
    const { data, status } = action.payload
    let memo = []

    if (validateJSONString(data.memo)) {
      const parsedMemo = JSON.parse(data.memo)

      if (Array.isArray(parsedMemo)) {
        memo = parsedMemo
      }
    }

    return {
      ...state,
      campaignReport: {
        ...data,
        memo,
      },
      status,
    }
  },

  [FETCH_CAMPAIGN_FAILURE]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      data,
      status,
    }
  },

  [FETCH_REPORT_TOKEN_PENDING]: state => ({
    ...state,
    exportToken: true,
  }
  ),

  [FETCH_REPORT_TOKEN_SUCCESS]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      exportToken: false,
      accessToken: data.access_token,
      status,
    }
  },

  [FETCH_REPORT_TOKEN_FAILURE]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      exportToken: false,
      data,
      status,
    }
  },

  [FETCH_REPORT_FILE_PENDING]: state => ({
    ...state,
    exportFile: true,
  }
  ),

  [FETCH_REPORT_FILE_SUCCESS]: state => ({
    ...state,
    exportFile: false,
  }),

  [FETCH_REPORT_FILE_FAILURE]: (state, action) => {
    const { data } = action.payload
    return {
      ...state,
      exportFile: false,
      data,
    }
  },

  [FETCH_AD_REPORTS_PENDING]: state => ({
    ...state,
    refresh: true,
  }),

  [FETCH_AD_REPORTS_SUCCESS]: (state, { payload }) => {
    const data = sortBy(get(payload, 'data'), ['date'])
    const status = get(payload, 'status')

    const isValidDate = date => startDate => endDate => (
      moment(date).isSameOrAfter(startDate)
      && moment(date).isSameOrBefore(endDate)
      && moment(date).isBefore(moment().startOf('day'))
    )

    const adSetList = keyBy(state.adSets, 'id')
    const groupByAdId = convertAdCountCacheArrayToStats(groupBy(data, 'advertisement_id'))

    const adReports = map(adSetList, (adSet) => {
      const viewTemplate = getViewTemplate(adSet)
      const advertisements = get(adSet, 'advertisements')
      const adSetStartDate = get(adSet, 'start_date')
      const adSetEndDate = get(adSet, 'end_date')

      const mergedAdReportsToAds = map(advertisements, (ad) => {
        const adId = get(ad, 'id')
        const selectedAdReport = groupByAdId[adId]

        const redefinedStats = reduce(selectedAdReport, (result, { stats, date }) => {
          if (isValidDate(date)(adSetStartDate)(adSetEndDate)) {
            return {
              ...result,
              [date]: {
                all: getAllAdStats(values(stats)),
              },
            }
          }
          return result
        }, {})

        return {
          id: ad.id,
          memo: ad.memo,
          adStats: redefinedStats,
        }
      }, [])
      return {
        id: get(adSet, 'id'),
        viewTemplate: viewTemplate === ADVERTISEMENT_NAME_MAP.GAM_POST_BANNER ? ADVERTISEMENT_NAME_MAP.POST_BANNER : viewTemplate,
        name: get(adSet, 'name'),
        advertisements: mergedAdReportsToAds,
      }
    })

    return {
      ...state,
      refresh: false,
      adReports: groupBy(adReports, 'viewTemplate'),
      status,
    }
  },

  [FETCH_AD_REPORTS_FAILURE]: (state, { payload }) => {
    const data = get(payload, 'data')
    const status = get(payload, 'status')

    return {
      ...state,
      refresh: false,
      data,
      status,
    }
  },

  [FETCH_AD_SETS_PENDING]: state => ({
    ...state,
    pending: true,
  }),

  [FETCH_AD_SETS_SUCCESS]: (state, action) => {
    const data = sortBy(get(action.payload, 'data'), ['date'])
    const status = get(action.payload, 'status')
    return {
      ...state,
      pending: false,
      adSets: data,
      status,
    }
  },

  [FETCH_AD_SETS_FAILURE]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      pending: false,
      data,
      status,
    }
  },
  [FETCH_ADVERTISEMENT_SUCCESS]: (state, action) => {
    const { data, status } = action.payload

    return {
      ...state,
      advertisements: {
        ...state.advertisements,
        [data.id]: data,
      },
      status,
    }
  },
  [FETCH_ADVERTISEMENT_FAILURE]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      pending: false,
      data,
      status,
    }
  },
}, initialState)
