import { useContext, useEffect, useRef } from 'react'

import {
  selectNavigationItemAction,
  unSelectNavigationItemAction,
  uiStore,
} from '@landing/contexts/ui'

export const useNavigationPointing = (id) => {
  const wrapEl = useRef(null)
  const {
    dispatch,
    state: { headerHeight, navigations },
  } = useContext(uiStore)
  const navItem = navigations.find(({ id: nId }) => id === nId)
  const pointNaviItem = () => {
    if (window.scrollY === 0) {
      if (navItem.isSelected) {
        dispatch(unSelectNavigationItemAction(id))
      }
      return
    }
    if (!navItem.isSelected) {
      const { bottom, height } = wrapEl.current.getBoundingClientRect()

      if (bottom - headerHeight > 0 && bottom - headerHeight < height) {
        dispatch(selectNavigationItemAction(id))
      }
    }
  }

  const handleScroll = () => {
    pointNaviItem()
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    pointNaviItem()

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [headerHeight, navItem.isSelected])

  return [wrapEl]
}
