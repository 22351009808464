/** @jsx jsx */
import { css, jsx } from '@emotion/react'

import LogoArea from './LogoArea'

const makeStyles = () => ({
  root: ({ spacing, mq }) => css`
    ${mq({
    display: 'flex',
    flexDirection: ['column', 'row'],
    marginBottom: `${spacing(5)}rem`,
  })}
  `,
})

const Sitemap = () => {
  const styles = makeStyles()

  return (
    <div css={styles.root}>
      <LogoArea />
    </div>
  )
}

export default Sitemap
