import { handleActions } from 'redux-actions'
import { isEmpty } from 'lodash'
import {
  SIGN_UP_RESET_STATE,
  SIGN_UP_POST_PENDING,
  SIGN_UP_POST_SUCCESS,
  SIGN_UP_POST_FAILURE,
  RESET_PASSWORD_POST_PENDING,
  RESET_PASSWORD_POST_SUCCESS,
  RESET_PASSWORD_POST_FAILURE,
  UPDATE_PASSWORD_POST_PENDING,
  UPDATE_PASSWORD_POST_SUCCESS,
  UPDATE_PASSWORD_POST_FAILURE,
  CHECK_EMAIL_GET_SUCCESS,
  CHECK_EMAIL_GET_PENDING,
  CHECK_EMAIL_GET_FAILURE,
  USER_CONFIRMATION_GET_PENDING,
  USER_CONFIRMATION_GET_SUCCESS,
  USER_CONFIRMATION_GET_FAILURE,
} from './actions'

export const initialState = {
  pending: false,
  status: 200,
  failure: false,
  success: false,
  isValidEmail: false,
  isInValidEmail: false,
  isValidConfirm: false,
  isInValidConfirm: false,
  errors: [],
}

export default handleActions({
  [SIGN_UP_RESET_STATE]: state => ({
    ...state,
    pending: false,
    status: 200,
    failure: false,
    success: false,
    isValidEmail: false,
    isInValidEmail: false,
    userData: {},
  }),

  [SIGN_UP_POST_PENDING]: state => ({
    ...state,
    pending: true,
  }
  ),

  [SIGN_UP_POST_SUCCESS]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      pending: false,
      status,
      data,
    }
  },

  [SIGN_UP_POST_FAILURE]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      pending: false,
      data,
      status,
    }
  },

  [RESET_PASSWORD_POST_PENDING]: state => ({
    ...state,
    pending: true,
  }
  ),

  [RESET_PASSWORD_POST_SUCCESS]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      pending: false,
      success: true,
      status,
      data,
    }
  },

  [RESET_PASSWORD_POST_FAILURE]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      pending: false,
      failure: true,
      data,
      status,
    }
  },

  [UPDATE_PASSWORD_POST_PENDING]: state => ({
    ...state,
    pending: true,
  }
  ),

  [UPDATE_PASSWORD_POST_SUCCESS]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      pending: false,
      success: true,
      status,
      data,
    }
  },

  [UPDATE_PASSWORD_POST_FAILURE]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      pending: false,
      failure: true,
      data,
      status,
    }
  },

  [CHECK_EMAIL_GET_PENDING]: state => ({
    ...state,
  }
  ),

  [CHECK_EMAIL_GET_SUCCESS]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      isValidEmail: isEmpty(data),
      isInValidEmail: !isEmpty(data),
      status,
    }
  },

  [CHECK_EMAIL_GET_FAILURE]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      isValidEmail: !isEmpty(data),
      isInValidEmail: isEmpty(data),
      data,
      status,
    }
  },

  [USER_CONFIRMATION_GET_PENDING]: state => ({
    ...state,
    pending: true,
  }
  ),

  [USER_CONFIRMATION_GET_SUCCESS]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      isValidConfirm: !isEmpty(data),
      isInValidConfirm: isEmpty(data),
      status,
      pending: false,
    }
  },

  [USER_CONFIRMATION_GET_FAILURE]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      isValidConfirm: isEmpty(data),
      isInValidConfirm: !isEmpty(data),
      errors: data.errors,
      status,
      pending: false,
    }
  },
}, initialState)
