import React from 'react'
import PropTypes from 'prop-types'

import Text from './Text'

const TextLimitIndicator = ({ curLen, maxLen }) => (
  <Text color="lightGray">
    {`${curLen}/${maxLen}`}
  </Text>
)

TextLimitIndicator.propTypes = {
  curLen: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  maxLen: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default TextLimitIndicator
