/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import PropTypes from 'prop-types'

import { useNavigationPointing } from '@landing/hooks/navigation'
import Content from './Content'
import SectionHeader from './SectionHeader'

const makeStyles = () => ({
  root: ({ colors: { grey }, spacing, mq }) => css`
    ${mq({
    background: [grey[50], '#fff'],
    paddingTop: [`${spacing(5)}rem`, '0'],
  })}
  `,
  wrap: ({ spacing, mq }) => css`
    ${mq({
    background: '#fff',
    boxSizing: 'border-box',
    margin: '0 auto',
    maxWidth: ['none', '102.4rem'],
    padding: [
      `${spacing(5)}rem ${spacing(1.875)}rem ${spacing(10)}rem`,
      `${spacing(10)}rem ${spacing(14.375)}rem ${spacing(15)}rem`,
    ],
  })}
  `,
})

const navId = 'inquire'

const Inquire = ({ submitInquire }) => {
  const styles = makeStyles()
  const [wrapEl] = useNavigationPointing(navId)

  return (
    <section css={styles.root} ref={wrapEl} id={navId}>
      <SectionHeader />
      <div css={styles.wrap}>
        <Content submitInquire={submitInquire} />
      </div>
    </section>
  )
}

Inquire.propTypes = {
  submitInquire: PropTypes.func.isRequired,
}

export default Inquire
