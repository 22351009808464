import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import {
  PostAd, CommentAd, ClassTabAd, ClosePopupAd,
  NotificationTextlinkAd,
  NotificationImagelinkAd,
} from '../molecules'
import PostBannerAd from '../molecules/PostBannerAd'

const useStyles = makeStyles({
  preview: {
    display: 'flex',
    flexGrow: 0,
    flexDirection: 'column',
    background: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '16px',
  },
  img: {
    width: '360px',
  },
  noneImageClosePopup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '360px',
    height: '640px',
  },
})

const getAdPreviewByType = (type, {
  advertiserName,
  advertiserProfileImage,
  actionLabel,
  highlightColor,
  description,
  imageUrl,
  title,
}) => {
  switch (type) {
    case 'postImage':
    case 'postVideo':
      return (
        <PostAd
          advertiserName={advertiserName}
          advertiserProfileImage={advertiserProfileImage}
          actionLabel={actionLabel}
          highlightColor={highlightColor}
          description={description}
          imageUrl={imageUrl}
          isVideoType={type === 'postVideo'}
          title={title}
        />
      )
    case 'commentImage':
    case 'commentText':
      return (
        <CommentAd
          title={title}
          advertiserProfileImage={advertiserProfileImage}
          actionLabel={actionLabel}
          description={description}
          imageUrl={imageUrl}
          isImageType={type === 'commentImage'}
        />
      )
    case 'classTab':
      return (
        <ClassTabAd title={title} description={description} imageUrl={imageUrl} />
      )
    case 'closePopup':
      return (
        <ClosePopupAd imageUrl={imageUrl} backgroundColor={highlightColor} />
      )

    case 'notificationTextlink':
      return (
        <NotificationTextlinkAd
          title={title}
          description={description}
          advertiserName={advertiserName}
          advertiserProfileImage={advertiserProfileImage}
        />
      )
    case 'notificationImagelink':
      return (
        <NotificationImagelinkAd
          advertiserName={advertiserName}
          description={description}
          advertiserProfileImage={advertiserProfileImage}
          imageUrl={imageUrl}
        />
      )
    case 'postBanner':
      return (
        <PostBannerAd
          title={title}
          description={description}
          imageUrl={imageUrl}
        />
      )
    default: return null
  }
}

const AdMockup = ({
  type,
  mockupImage,
  advertisement,
}) => {
  const adPreview = getAdPreviewByType(type, advertisement)
  const classes = useStyles()

  return (
    <div>
      <div className={classes.preview}>
        {
          mockupImage
          && mockupImage.upperImage
          && <img className={classes.img} src={mockupImage.upperImage} alt="상단 이미지" />
        }
        {adPreview}
        {
          mockupImage
          && mockupImage.belowImage
          && <img className={classes.img} src={mockupImage.belowImage} alt="하단 이미지" />
        }
      </div>
    </div>
  )
}

AdMockup.defaultProps = {
  mockupImage: null,
}

AdMockup.propTypes = {
  type: PropTypes.oneOf([
    'postImage',
    'postVideo',
    'notice',
    'commentImage',
    'commentText',
    'classTab',
    'closePopup',
    'notificationFirstSlot',
    'notificationSecondSlot',
    'postBanner',
  ]).isRequired,
  mockupImage: PropTypes.shape(PropTypes.arrayOf({
    upperImage: PropTypes.string,
    belowImage: PropTypes.string,
  })),
  advertisement: PropTypes.shape({
    advertiserName: PropTypes.string,
    advertiserProfileImage: PropTypes.string,
    actionLabel: PropTypes.string,
    highlightColor: PropTypes.string,
    description: PropTypes.string,
    imageUrl: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
}

export default AdMockup
