import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import FlagIcon from '@material-ui/icons/Flag'
import AddIcon from '@material-ui/icons/Add'
import LabelIcon from '@material-ui/icons/Label'
import TableChartIcon from '@material-ui/icons/TableChart'
import ExposureIcon from '@material-ui/icons/Exposure'
import SaveAlt from '@material-ui/icons/SaveAlt'
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew'
import PermIdentity from '@material-ui/icons/PermIdentity'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { withRouter, Link } from 'react-router-dom'
import { isEmpty, map } from 'lodash'
import { removeItem, getItem } from '@/shared/utils'
import logo from '@/shared/assets/logo-ct-dashboard.svg'
import { fetchUser } from '../../features/user/actions'

const drawerWidth = 240

const styles = theme => ({
  root: {
    display: 'flex',
  },
  logo: {
    marginTop: '20px',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    background: '#f8f8f8',
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    boxShadow: 'none',
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
    textAlign: 'center',
  },
  bottomDrawer: {
    position: 'absolute',
    left: '0',
    right: '0',
    bottom: '180px',
  },
  policy: {
    position: 'absolute',
    left: '0',
    right: '0',
    bottom: '160px',
  },
  policyLink: {
    fontSize: '12px',
    color: '#bdbdbd',
    margin: '0 0 0 24px',
    '&:hover': {
      color: '#00c896',
      textDecoration: 'none',
    },
  },
  contact: {
    position: 'absolute',
    left: '0',
    right: '0',
    bottom: '18px',
    marginLeft: '24px',
  },
  contactLabel: {
    color: '#bdbdbd',
    fontWeight: 500,
    fontSize: '12px',
  },
  contactText: {
    color: '#757575',
    fontWeight: 500,
    fontSize: '12px',
    marginBottom: '4px',
  },
  menuList: {
    '&:hover': {
      background: '#00c896',
      textDecoration: 'none',
    },
  },
  menuLink: {
    color: '#ffffff',
    textDecoration: 'none',
    '&:hover': {
      color: '#ffffff',
      textDecoration: 'none',
    },
  },
  activeLink: {
    background: '#00c896',
    '&:hover': {
      background: '#00c896',
      textDecoration: 'none',
    },
  },
  icon: {
    color: '#FFFFFF',
  },
  drawerPaper: {
    width: drawerWidth,
    background: '#424242',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  container: {
    background: '#ffffff',
    padding: '24px',
    borderRadius: '2px',
    boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12)',
  },
})

const mapStateToProps = state => ({
  userData: state.user.userData,
})

const mapDispatchToProps = dispatch => ({
  onMount: () => dispatch(fetchUser()),
})

class ResponsiveDrawer extends React.Component {
  static defaultProps = {
    isPrivate: true,
  }

  state = {
    mobileOpen: false,
    topDrawerItems: [
      {
        name: '광고 만들기',
        path: '/campaign_type_form',
        adminExclusive: true,
        icon: <AddIcon />,
      },
      {
        name: '투데이(레거시)',
        path: '/legacy-today',
        adminExclusive: true,
        icon: <TableChartIcon />,
      },
      {
        name: '투데이',
        path: '/today',
        adminExclusive: true,
        icon: <TableChartIcon />,
      },
      {
        name: '캠페인',
        path: '/campaign',
        adminExclusive: false,
        icon: <FlagIcon />,
      },
      {
        name: '브랜드 관리',
        path: '/advertiser',
        adminExclusive: false,
        icon: <LabelIcon />,
      },
      {
        name: '시간별 노출 관리',
        path: '/impression',
        adminExclusive: true,
        icon: <ExposureIcon />,
      },
    ],
    bottomDrawerItems: [
      {
        name: '내 정보관리',
        path: '/account',
        icon: <PermIdentity />,
      },
    ],
    contact: [
      {
        title: '광고 문의',
        email: 'E. ad-sales-part@classting.com',
        phone: 'T. 02-6928-3488',
      },
    ],
  }

  componentDidMount() {
    const { userData, onMount } = this.props
    if (isEmpty(userData) && !this.isAdmin()) {
      onMount()
    }
  }

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }))
  }

  handleLinkClick = path => () => {
    const { history } = this.props
    history.push(path)
  }

  handleSignInClick = () => {
    const { history } = this.props
    return history.push('/sign_in')
  }

  handleSignOutClick = () => {
    const { history } = this.props
    removeItem('token')
    return history.push('/sign_in')
  }

  isAdmin = () => JSON.parse(getItem('isAdmin'))

  handleFileClick = () => {
    window.open('https://www.dropbox.com/s/zjjwe73vmipg5l8/Classting_AD.zip?dl=0')
  }

  renderListItem = (item) => {
    const { classes, location } = this.props
    const { isAdmin, handleLinkClick } = this

    if (!isAdmin() && item.adminExclusive) {
      return null
    }

    return (
      <ListItem
        button
        key={item.name}
        onClick={handleLinkClick(item.path)}
        className={location.pathname === item.path ? classes.activeLink : ''}
      >
        <ListItemIcon className={classes.icon}>{item.icon}</ListItemIcon>
        <ListItemText primary={(
          <span to={item.path} className={classes.menuLink}>
            {item.name}
          </span>
          )}
        />
      </ListItem>
    )
  }

  render() {
    const {
      classes, theme, children, pageTitle, location, isPrivate,
    } = this.props
    const {
      mobileOpen, topDrawerItems, bottomDrawerItems, contact,
    } = this.state
    const {
      handleSignOutClick,
      handleLinkClick,
      isAdmin,
      handleFileClick,
      renderListItem,
    } = this

    const TopDrawer = (
      <div>
        <div className={classes.toolbar}>
          <Link to="/campaign">
            <img src={logo} className={classes.logo} alt="logo" />
          </Link>
        </div>
        {isPrivate
          ? (
            <List>
              {topDrawerItems.map(item => (
                renderListItem(item)
              ))}
            </List>
          )
          : null
        }
      </div>
    )

    const BottomDrawer = (
      <div>
        {isPrivate
          ? (
            <List className={classes.bottomDrawer}>
              <ListItem button onClick={handleSignOutClick}>
                <ListItemIcon className={classes.icon}><PowerSettingsNew /></ListItemIcon>
                <ListItemText primary={(
                  <span className={classes.menuLink}>
                    로그아웃
                  </span>
                )}
                />
              </ListItem>
              {bottomDrawerItems.map(item => (
                !isAdmin()
                  ? (
                    <ListItem
                      button
                      key={item.name}
                      onClick={handleLinkClick(item.path)}
                      className={location.pathname === item.path ? classes.activeLink : ''}
                    >
                      <ListItemIcon className={classes.icon}>{item.icon}</ListItemIcon>
                      <ListItemText primary={(
                        <span className={classes.menuLink}>
                          {item.name}
                        </span>
                      )}
                      />
                    </ListItem>
                  )
                  : null
              ))}
              <ListItem button onClick={handleFileClick}>
                <ListItemIcon className={classes.icon}><SaveAlt /></ListItemIcon>
                <ListItemText primary={
                  <span className={classes.menuLink}>메뉴얼 다운로드</span>
                }
                />
              </ListItem>
            </List>
          ) : null
        }
        <div className={classes.policy}>
          <a className={classes.policyLink} target="_brank_" href="https://ad.classting.com/policy/terms">이용약관</a>
          <a className={classes.policyLink} target="_brank_" href="https://policies.classting.com/4d069000-eb99-48d1-b07f-350b89f79cd2">개인정보처리방침</a>
        </div>
        <div className={classes.contact}>
          {map(contact, (item, index) => (
            <div key={index}>
              <div className={classes.contactLabel}>{item.title}</div>
              <div className={classes.contactText}>{item.email}</div>
              <div className={classes.contactText}>{item.phone}</div>
            </div>
          ))}
        </div>
      </div>
    )

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" noWrap>{pageTitle}</Typography>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer}>
          <Hidden smUp implementation="css">
            <Drawer
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              {TopDrawer}
              {BottomDrawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {TopDrawer}
              {BottomDrawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {children}
        </main>
      </div>
    )
  }
}

ResponsiveDrawer.propTypes = {
  onMount: PropTypes.func.isRequired,
  userData: PropTypes.shape({}).isRequired,
  classes: PropTypes.shape({
    activeLink: PropTypes.string,
    icon: PropTypes.string,
    menuLink: PropTypes.string,
    toolbar: PropTypes.string,
    logo: PropTypes.string,
    bottomDrawer: PropTypes.string,
    contact: PropTypes.string,
    contactLabel: PropTypes.string,
    contactText: PropTypes.string,
    root: PropTypes.string,
    policy: PropTypes.string,
    policyLink: PropTypes.string,
    appBar: PropTypes.string,
    menuButton: PropTypes.string,
    drawer: PropTypes.string,
    drawerPaper: PropTypes.string,
    content: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  theme: PropTypes.shape({
    direction: PropTypes.string,
  }).isRequired,
  children: PropTypes.node.isRequired,
  pageTitle: PropTypes.string.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  isPrivate: PropTypes.bool,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles, { withTheme: true }),
  withRouter,
)(ResponsiveDrawer)
