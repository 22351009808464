import React from 'react'
import PropTypes from 'prop-types'
import { ClosePopupAd } from '@/features/preview/components'

const AdPreviewClosePopupType = ({ backgroundColor, imageUrl }) => (
  <ClosePopupAd
    backgroundColor={backgroundColor}
    imageUrl={imageUrl}
  />
)

AdPreviewClosePopupType.defaultProps = {
  backgroundColor: '#fff',
}

AdPreviewClosePopupType.propTypes = {
  backgroundColor: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
}

export default AdPreviewClosePopupType
