import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import {
  withStyles, Button, TableCell, TableRow, Avatar, List, ListItemAvatar, ListItem, ListItemText,
} from '@material-ui/core'
import {
  reduce, map, isEmpty, isEqual, cloneDeep, head,
} from 'lodash'
import queryString from 'query-string'
import classNames from 'classnames'
import moment from 'moment'
import {
  Layout, Pagination, TableHeader, EnhancedPaper, CircularUnderLoad, EnhancedSelect,
} from '@/shared/components'
import { getToCommas, getItem } from '@/shared/utils'
import { withTracker } from '@/shared/enhancers'
import { CLASSTING_AD_ADMIN_URL } from '@/shared/constants'
import { fetchCampaigns } from '../actions'
import { fetchAdvertiser, resetAdvertiser } from '../../advertiser/actions'

const LOCAL_STORAGE_CAMPAIGN_PER_PAGE_KEY = 'campaignPerPage'

const styles = ({
  buttonGroup: {
    paddingBottom: '24px',
  },
  filterBtn: {
    minWidth: '80px',
    minHeight: '36px',
    padding: '5px',
    fontSize: '14px',
    border: '1px solid #bdbdbd',
    color: '#bdbdbd',
  },
  filterBtnAct: {
    border: '1px solid #4a90e2',
    color: '#4a90e2',
  },
  tableCell: {
    borderTop: '1px solid #dbdbdb',
    borderBottom: '1px solid #dbdbdb',
    padding: '8px 24px',
    color: '#212121',
  },
  tableCellCenter: {
    textAlign: 'center',
  },
  agencyIdBtn: {
    margin: '0 2px',
    cursor: 'pointer',
  },
  sortableCell: {
    color: '#757575',
  },
  bottomContainer: {
    position: 'relative',
  },
  progressPosition: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  advertiserProfile: {
    margin: 10,
    width: 60,
    height: 60,
  },
  advertiserName: {
    fontSize: '24px',
    fontWeight: 'bold',
  },
  errorButton: {
    border: 'solid 1px #e91e63',
    color: '#e91e63',
    width: '100%',
  },
  editingButton: {
    width: '100%',
  },
  campaignTitle: {
    width: '240px',
  },
})

const mapStateToProps = state => ({
  status: state.campaign.status,
  campaigns: state.campaign.items,
  pending: state.campaign.pending,
  pageTotal: state.campaign.total,
  advertiser: state.advertiser.advertiserData,
})

const mapDispatchToProps = dispatch => ({
  onCampaignMount: query => dispatch(fetchCampaigns(query)),
  onAdvertiserMount: advertiserId => dispatch(fetchAdvertiser(advertiserId)),
  onResetAdvertser: () => dispatch(resetAdvertiser()),
})

class CampaignPage extends Component {
  state = {
    order: {
      order_start_date: 'desc',
    },
    page: '1',
    perPage: '30',
    perPages: [
      { value: '10', label: '10개씩 보기' },
      { value: '20', label: '20개씩 보기' },
      { value: '30', label: '30개씩 보기' },
      { value: '50', label: '50개씩 보기' },
      { value: '70', label: '70개씩 보기' },
      { value: '100', label: '100개씩 보기' },
    ],
    rows: [
      {
        id: 'state', alignCenter: true, numeric: false, disablePadding: false, isOrder: false, label: '상태',
      },
      {
        id: 'advertiser', alignCenter: false, numeric: false, disablePadding: false, isOrder: false, label: '브랜드',
      },
      {
        id: 'title', alignCenter: false, numeric: false, disablePadding: false, isOrder: false, label: '캠페인 이름',
      },
      {
        id: 'agency_ids', alignCenter: true, numeric: false, disablePadding: false, isOrder: false, label: '에이전시 ID',
      },
      {
        id: 'package', alignCenter: false, numeric: false, disablePadding: false, isOrder: false, label: '상품',
      },
      {
        id: 'order_start_date', alignCenter: false, numeric: false, disablePadding: false, isOrder: true, label: '실행 기간',
      },
      {
        id: 'budget', alignCenter: false, numeric: false, disablePadding: false, isOrder: false, label: '예산',
      },
      {
        id: 'action', alignCenter: true, numeric: false, disablePadding: false, isOrder: false, label: '보고서',
      },
    ],
    campaignState: {
      ad_package_type: [
        { id: 'all', label: '전체' },
        { id: 'impression', label: '일반 광고' },
        { id: 'region', label: '지역 광고' },
        { id: 'ad_package_id', label: '커스텀 광고' },
      ],
      statuses: [
        { id: 'all', label: '전체' },
        { id: 'draft', label: '작성중' },
        { id: 'under_review', label: '심사 중' },
        { id: 'rejected', label: '수정 요청' },
        { id: 'approved', label: '심사 완료' },
      ],
    },
    filter: {
      ad_package_type: 'all',
      statuses: ['all'],
    },
  }

  componentDidMount() {
    const {
      updateCampaignList, getAdvertiserId, getPageNumber, getPerPage, getOrder, getFilter,
    } = this
    const { onAdvertiserMount, onResetAdvertser } = this.props
    onResetAdvertser()
    const advertiserId = getAdvertiserId()
    if (!isEmpty(advertiserId)) {
      onAdvertiserMount(advertiserId)
    }
    const initPage = getPageNumber()
    const initPerPage = getPerPage()
    const initOrder = getOrder()
    const initFilter = getFilter()
    this.setState({
      page: initPage,
      perPage: initPerPage,
      order: initOrder,
      filter: initFilter,
    })
    updateCampaignList({
      fromParams: true,
      useSetState: true,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { updateCampaignList } = this
    const { location } = this.props
    if (location.search !== prevProps.location.search && isEqual(this.state, prevState)) {
      updateCampaignList({
        fromParams: true,
        useSetState: true,
      })
    }
  }

  updateCampaignList = (flag, baseData = this.state) => {
    const { fromParams, useSetState } = flag
    const {
      getAdvertiserId, getPageNumber, getPerPage, getOrder, getFilter, getQueryFilter,
    } = this
    const { onCampaignMount } = this.props
    const {
      page, perPage, order, filter,
    } = baseData
    const advertiserId = getAdvertiserId()
    const initPage = fromParams ? getPageNumber() : page
    const initPerPage = fromParams ? getPerPage() : perPage
    const initOrder = fromParams ? getOrder() : order
    const initFilter = fromParams ? getFilter() : filter
    const queryFilter = getQueryFilter(initFilter)
    if (useSetState) {
      this.setState({
        page: initPage,
        perPage: initPerPage,
        order: initOrder,
        filter: initFilter,
      })
    }
    onCampaignMount({
      ...initOrder,
      ...queryFilter,
      page: initPage,
      per_page: initPerPage,
      advertiser_id: advertiserId,
    })
  }

  isAdmin = () => JSON.parse(getItem('isAdmin'))

  getPageNumber = () => {
    const { location } = this.props
    return queryString.parse(location.search).page || '1'
  }

  getPerPage = () => {
    const { location } = this.props
    return queryString.parse(location.search).per_page || localStorage.getItem(LOCAL_STORAGE_CAMPAIGN_PER_PAGE_KEY) || '30'
  }

  getOrder = () => {
    const { location } = this.props
    const { order } = this.state
    const paramsObj = queryString.parse(location.search)
    return reduce(order, (result, value, key) => {
      const obj = {}
      if (paramsObj[key]) obj[key] = paramsObj[key]
      return { ...result, ...obj }
    }, { order_start_date: 'desc' })
  }

  getFilter = () => {
    const { location } = this.props
    const { filter } = this.state
    const paramsObj = queryString.parse(location.search)
    const initObj = reduce(filter, (result, value, key) => {
      const obj = {}
      let paramsValue = paramsObj[key]
      if (key === 'statuses' && !paramsValue) paramsValue = paramsObj.status
      if (!paramsValue) return result
      if (key === 'statuses' && typeof paramsValue === 'string') obj[key] = [paramsValue]
      else obj[key] = paramsValue
      return { ...result, ...obj }
    }, {
      ad_package_type: 'all',
      statuses: ['all'],
    })
    if (paramsObj.ad_package_id !== undefined) initObj.ad_package_type = 'ad_package_id'
    return initObj
  }

  getQueryFilter = filter => reduce(filter, (result, value, key) => {
    const obj = {}
    if (value === 'all' || (value.length === 1 && value[0] === 'all')) return result
    if (key === 'statuses' && value.length === 1) obj.status = head(value)
    else if (value === 'ad_package_id') obj.ad_package_id = ''
    else obj[key] = value
    return { ...result, ...obj }
  }, {})

  getAdvertiserId = () => {
    const { location } = this.props
    return queryString.parse(location.search).advertiser_id
  }

  onPagination = (pageNumber) => {
    const { getQueryFilter, updateCampaignList } = this
    const { perPage, order, filter } = this.state
    const { history } = this.props
    const queryFilter = getQueryFilter(filter)
    const params = {
      page: pageNumber,
      per_page: perPage,
      ...order,
      ...queryFilter,
    }
    history.push({
      pathname: '/campaign',
      search: queryString.stringify(params),
    })
    this.setState({ page: pageNumber })
    updateCampaignList({
      fromParams: false,
      useSetState: false,
    },
    {
      perPage,
      order,
      filter,
      page: pageNumber,
    })
  }

  onPerPageChange = (event) => {
    const { getQueryFilter, updateCampaignList } = this
    const { history } = this.props
    const { page, order, filter } = this.state
    const queryFilter = getQueryFilter(filter)
    const params = {
      page,
      per_page: event.target.value,
      ...order,
      ...queryFilter,
    }
    history.push({
      search: queryString.stringify(params),
    })
    this.setState({ perPage: event.target.value })
    localStorage.setItem(LOCAL_STORAGE_CAMPAIGN_PER_PAGE_KEY, event.target.value)
    updateCampaignList({
      fromParams: false,
      useSetState: false,
    },
    {
      page,
      order,
      filter,
      perPage: event.target.value,
    })
  }

  handleCampaignStateClick = (itemKey, id) => () => {
    const { getQueryFilter, updateCampaignList } = this
    const { history } = this.props
    const {
      perPage, order, filter, campaignState,
    } = this.state
    const newFilter = cloneDeep(filter)
    if (id === 'all' && (newFilter[itemKey] === 'all' || newFilter[itemKey][0] === 'all')) return
    if (itemKey === 'statuses') {
      const actIdx = newFilter[itemKey].indexOf(id)
      if (actIdx !== -1) {
        newFilter[itemKey].splice(actIdx, 1)
        if (!newFilter[itemKey].length) newFilter[itemKey] = ['all']
      } else if (id !== 'all') {
        newFilter[itemKey].push(id)
        if (newFilter[itemKey][0] === 'all') newFilter[itemKey].shift()
        if (newFilter[itemKey].length === campaignState[itemKey].length - 1) newFilter[itemKey] = ['all']
      } else {
        newFilter[itemKey] = ['all']
      }
    } else if (itemKey === 'ad_package_type') {
      newFilter[itemKey] = id
    }
    const queryFilter = getQueryFilter(newFilter)
    const params = {
      page: '1',
      per_page: perPage,
      ...order,
      ...queryFilter,
    }
    history.push({
      search: queryString.stringify(params),
    })
    this.setState({
      filter: newFilter,
      page: '1',
    })
    updateCampaignList({
      fromParams: false,
      useSetState: false,
    },
    {
      perPage,
      order,
      page: '1',
      filter: newFilter,
    })
  }

  handleRequestSort = (event, property) => {
    const { getQueryFilter, updateCampaignList } = this
    const {
      page, perPage, order, filter,
    } = this.state
    const { history } = this.props
    const isDesc = order[property] === 'desc'
    const newOrder = { ...order, [property]: isDesc ? 'asc' : 'desc' }
    const queryFilter = getQueryFilter(filter)
    const params = {
      page,
      per_page: perPage,
      ...newOrder,
      ...queryFilter,
    }
    history.push({
      search: queryString.stringify(params),
    })
    this.setState({ order: newOrder })
    updateCampaignList({
      fromParams: false,
      useSetState: false,
    },
    {
      page,
      perPage,
      filter,
      order: newOrder,
    })
  }

  renderValidStatusLabel = (startDate, endDate) => {
    if (moment(moment().format('YYYY-MM-DD')).isBefore(startDate)) {
      return <span style={{ color: '#17a27e' }}>승인</span>
    }

    if (moment(moment().format('YYYY-MM-DD')).isSameOrAfter(startDate)
      && moment(moment().format('YYYY-MM-DD')).isSameOrBefore(endDate)) {
      return <span style={{ color: '#17a27e' }}>진행 중</span>
    }

    return <span style={{ color: '#757575' }}>종료</span>
  }

  renderStatusLabel = (item) => {
    const { renderValidStatusLabel } = this

    switch (item.status) {
      case 'draft':
        return <span style={{ color: '#4a90e2' }}>작성 중</span>
      case 'approved':
        return renderValidStatusLabel(item.start_date, item.end_date)
      case 'rejected':
        return <span style={{ color: '#e91e63' }}>수정 요청</span>
      case 'under_review':
        return <span style={{ color: '#4a90e2' }}>심사 중</span>
      default:
        return ''
    }
  }

  handleReportClick = id => () => {
    const { history } = this.props
    history.push({
      pathname: `/campaign/${id}`,
    })
  }

  handleEditingClick = item => () => {
    const { history } = this.props
    const params = {
      campaign_id: item.id,
    }

    if (!isEmpty(item.ad_package)) {
      params.form_type = 'update'
      return history.push({
        pathname: '/campaign_form',
        search: queryString.stringify(params),
      })
    }

    return history.push({
      pathname: '/campaign_type_form',
      search: queryString.stringify(params),
    })
  }

  handlePayFormClick = id => () => {
    const { history } = this.props
    const params = {
      campaign_id: id,
      form_type: 'update',
    }

    history.push({
      pathname: '/payment_form',
      search: queryString.stringify(params),
    })
  }

  handleAdFormClick = id => () => {
    const { history } = this.props
    const params = {
      campaign_id: id,
      form_type: 'update',
    }

    history.push({
      pathname: '/ad_form',
      search: queryString.stringify(params),
    })
  }

  adAdminLink = id => () => {
    window.open(`${CLASSTING_AD_ADMIN_URL}/agency/${id}`)
  }

  renderStatusButton = (item) => {
    const { classes } = this.props
    const { handleReportClick, handleEditingClick, handlePayFormClick } = this

    if (item.status === 'draft') {
      return (
        <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          style={{ width: '100%' }}
          onClick={handleEditingClick(item)}
        >
          작성 중
        </Button>
      )
    }

    if (item.status === 'rejected') {
      return (
        <Button
          variant="outlined"
          className={classes.errorButton}
          onClick={handlePayFormClick(item.id)}
        >
          피드백 보기
        </Button>
      )
    }

    if (item.status === 'under_review') {
      return (
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          style={{ width: '100%', color: '#ffffff' }}
          onClick={handlePayFormClick(item.id)}
        >
          견적서 보기
        </Button>
      )
    }

    return (
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        style={{ width: '100%', color: '#ffffff' }}
        onClick={handleReportClick(item.id)}
      >
        보고서 보기
      </Button>
    )
  }

  render() {
    const {
      classes, campaigns, pending, pageTotal, advertiser,
    } = this.props
    const {
      rows,
      order,
      page,
      perPage,
      campaignState,
      filter,
      perPages,
    } = this.state
    const {
      handleRequestSort,
      renderStatusButton,
      onPagination,
      onPerPageChange,
      handleCampaignStateClick,
      renderStatusLabel,
      handleEditingClick,
      isAdmin,
      adAdminLink,
    } = this

    return (
      <Layout pageTitle="캠페인">
        {pending ? <CircularUnderLoad styles={classes.progressPosition} />
          : (
            <div>
              {!isEmpty(advertiser)
              && (
              <List className={classes.root}>
                <ListItem>
                  {!isEmpty(advertiser.profile_image)
                    && (
                    <ListItemAvatar>
                      <Avatar
                        className={classes.advertiserProfile}
                        alt="profile"
                        src={advertiser.profile_image}
                      />
                    </ListItemAvatar>
                    )
                  }
                  <ListItemText
                    classes={{ primary: classes.advertiserName }}
                    primary={advertiser.name}
                  />
                </ListItem>
              </List>
              )
            }
              <EnhancedPaper>
                {!isEmpty(campaignState)
                && map(campaignState, (itemArr, itemKey) => (
                  <div
                    className={classes.buttonGroup}
                    key={itemKey}
                  >
                    {itemArr.map((item) => {
                      if (!isAdmin() && itemKey === 'ad_package_type' && item.id === 'ad_package_id') return null
                      return (
                        <Button
                          variant="outlined"
                          className={
                            filter[itemKey].indexOf(item.id) !== -1
                              ? classNames(classes.filterBtn, classes.filterBtnAct)
                              : classes.filterBtn
                          }
                          color={filter[itemKey].indexOf(item.id) !== -1 ? 'secondary' : 'default'}
                          size="large"
                          style={{ marginRight: '10px' }}
                          key={item.id}
                          onClick={handleCampaignStateClick(itemKey, item.id)}
                        >
                          {item.label}
                        </Button>
                      )
                    })}
                  </div>
                ))
              }
                {isEmpty(campaigns)
                  ? (
                    <TableHeader rows={rows}>
                      <TableRow>
                        <TableCell>
                          -
                        </TableCell>
                        <TableCell>
                          -
                        </TableCell>
                        <TableCell>
                          -
                        </TableCell>
                        <TableCell>
                          -
                        </TableCell>
                        <TableCell>
                          -
                        </TableCell>
                        <TableCell>
                          -
                        </TableCell>
                        <TableCell>
                          -
                        </TableCell>
                      </TableRow>
                    </TableHeader>
                  )
                  : (
                    <TableHeader
                      isCampaignList
                      rows={rows}
                      order={order}
                      onRequestSort={handleRequestSort}
                    >
                      {map(campaigns, item => (
                        <TableRow key={item.id}>
                          <TableCell component="td" scope="row" className={classes.tableCell} style={{ textAlign: 'center' }}>
                            {renderStatusLabel(item)}
                          </TableCell>
                          <TableCell component="td" scope="row" className={classes.tableCell}>
                            {item.advertiser.name || '-'}
                            {isAdmin() && `(${item.advertiser.id})`}
                          </TableCell>
                          <TableCell component="td" scope="row" className={classNames(classes.tableCell, classes.campaignTitle)}>
                            {item.title || '-'}
                            {isAdmin() && `(${item.id})`}
                          </TableCell>
                          {isAdmin()
                        && (
                        <TableCell component="td" scope="row" className={classNames(classes.tableCell, classes.tableCellCenter)}>
                          {isEmpty(item.advertiser.agency_ids)
                            ? '-'
                            : (
                              <>
                                {map(item.advertiser.agency_ids, (id, idx) => (
                                  <span
                                    key={id}
                                    className={classes.agencyIdBtn}
                                    onClick={adAdminLink(id)}
                                    onKeyPress={adAdminLink(id)}
                                    role="button"
                                    tabIndex="0"
                                  >
                                    {id}
                                    {idx !== item.advertiser.agency_ids.length - 1 && ','}
                                  </span>
                                ))}
                              </>
                            )
                          }
                        </TableCell>
                        )
                      }
                          <TableCell component="td" scope="row" className={classes.tableCell}>
                            {(item.ad_package && item.ad_package.title) || '-'}
                          </TableCell>
                          <TableCell component="td" scope="row" className={classNames(classes.tableCell, classes.sortableCell)}>
                            {(item.start_date && moment(item.start_date).format('YYYY.MM.DD')) || ''}
                            {' '}
                            -
                            {' '}
                            {(item.end_date && moment(item.end_date).format('YYYY.MM.DD')) || ''}
                          </TableCell>
                          <TableCell component="td" scope="row" className={classNames(classes.tableCell, classes.sortableCell)}>
                            {(item.budget && `${getToCommas(String(item.budget))} 원`) || '-'}
                          </TableCell>
                          <TableCell component="td" scope="row" className={classes.tableCell}>
                            {renderStatusButton(item)}
                          </TableCell>
                          {isAdmin()
                        && (
                        <TableCell component="td" scope="row" className={classes.tableCell}>
                          <Button
                            variant="outlined"
                            color="primary"
                            className={classes.editingButton}
                            onClick={handleEditingClick(item)}
                          >
                            소재 수정
                          </Button>
                        </TableCell>
                        )
                      }
                        </TableRow>
                      ))}
                    </TableHeader>
                  )
              }
                {isEmpty(campaigns)
                  ? null
                  : (
                    <div className={classes.bottomContainer}>
                      <Pagination
                        perPage={Number(perPage)}
                        pageNumber={Number(page)}
                        pageTotal={Number(pageTotal)}
                        onPagination={onPagination}
                      />
                      <EnhancedSelect
                        onChange={onPerPageChange}
                        menuItem={perPages}
                        defaultItem={perPage}
                      />
                    </div>
                  )
              }
              </EnhancedPaper>
            </div>
          )
        }
      </Layout>
    )
  }
}

CampaignPage.propTypes = {
  onCampaignMount: PropTypes.func.isRequired,
  onAdvertiserMount: PropTypes.func.isRequired,
  onResetAdvertser: PropTypes.func.isRequired,
  campaigns: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  advertiser: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  pending: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  pageTotal: PropTypes.string.isRequired,
}

export default compose(
  withTracker,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(CampaignPage)
