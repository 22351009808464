/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { useState } from 'react'
import PropTypes from 'prop-types'

import arrowDownIcon from '@landing/assets/images/arrow_down.svg'

const makeStyles = ({ isShowContent }) => ({
  content: ({ colors: { grey }, font, mq }) => css`
    ${mq({
    color: grey[600],
    fontSize: [font.body4.fontSize, font.body2.fontSize],
    lineHeight: [font.body4.lineHeight, font.body2.lineHeight],
  })}
  `,
  downIcon: ({ mq }) => css`
    ${mq({
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    height: ['2.4rem', '2rem'],
    justifyContent: 'center',
    marginLeft: 'auto',
    transform: `${isShowContent ? 'rotate(180deg)' : 'rotate(0deg)'}`,
    width: ['2.4rem', '2rem'],
  })}

    svg {
      width: 1.2rem;
    }
  `,
  header: ({ spacing, mq }) => css`
    ${mq({
    alignItems: ['flex-start', 'center'],
    display: 'flex',
    marginBottom: isShowContent ? `${spacing()}rem` : '0',
  })}
  `,
  root: ({ colors: { grey }, spacing, mq }) => css`
    ${mq({
    borderBottom: `1px solid ${grey[300]}`,
    padding: `${spacing(2)}rem 0`,
  })}
  `,
  title: ({ colors: { grey }, font, mq }) => css`
    ${mq({
    color: grey[800],
    fontSize: font.body2.fontSize,
    fontWeight: 'bold',
    lineHeight: font.body2.lineHeight,
  })}
  `,
})

const Accordion = ({ title, content }) => {
  const [isShowContent, setIsShowContent] = useState(false)
  const styles = makeStyles({ isShowContent })

  const handleArrowClick = () => {
    setIsShowContent(!isShowContent)
  }

  return (
    <div css={styles.root}>
      <div css={styles.header}>
        <p css={styles.title}>{title}</p>
        <div css={styles.downIcon} onClick={handleArrowClick}>
          <img src={arrowDownIcon} alt="" />
        </div>
      </div>
      {isShowContent && <p css={styles.content}>{content}</p>}
    </div>
  )
}

Accordion.defaultProps = {
  content: '',
  title: '',
}

Accordion.propTypes = {
  content: PropTypes.node,
  title: PropTypes.node,
}

export default Accordion
