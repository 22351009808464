import React from 'react'
import classesName from 'classnames'

import filter from 'lodash/filter'
import get from 'lodash/get'

import { getToCommas, getRatio } from '@/shared/utils'
import { templateTitle } from '@/shared/constants'

import Tooltip from '@/shared/components/Tooltip'
import { sumObjectValue, sumViewCountForVTR } from '../utils'
import { BaseSwitch } from '../ADStatusToggle/Toggle'

export const getTotalColumns = classes => [
  {
    Header: '전체',
    headerClassName: classesName(classes.totalHeader),
    columns: [
      {
        id: 'advertiser_name',
        Header: '광고주 (광고주 ID)',
        headerClassName: classesName(classes.tableHeaderCell),
        accessor: row => `${row.advertiser_name}`,
        width: 150,
        className: classesName(classes.tableCell),
        style: { paddingLeft: '10px' },
      },
      {
        id: 'memo',
        Header: '가이드 값',
        headerClassName: classesName(classes.tableHeaderCell),
        accessor: row => `${row.memo}`,
        Cell: row => getToCommas(row.value),
        type: 'number',
        className: classesName(classes.tableNumberCell),
      },
      {
        id: 'target_daily_impressions',
        Header: '일별 최대 노출',
        headerClassName: classesName(classes.tableHeaderCell),
        accessor: row => `${row.target_daily_impressions}`,
        Cell: row => getToCommas(row.value),
        type: 'number',
        className: classesName(classes.tableNumberCell),
      },
      {
        id: 'impression',
        Header: '현재 노출',
        headerClassName: classesName(classes.tableHeaderCell),
        accessor: row => `${row.impression}`,
        Cell: row => getToCommas(row.value),
        type: 'number',
        className: classesName(classes.tableNumberCell),
      },
      {
        id: 'ratio',
        Header: '달성률',
        headerClassName: classesName(classes.tableHeaderCellLast),
        accessor: row => `${row.ratio}`,
        Cell: row => `${row.value}%`,
        type: 'number',
        className: classesName(classes.tableNumberCell),
      },
      {
        id: 'click_count',
        Header: '클릭 수',
        headerClassName: classesName(classes.tableHeaderCell),
        accessor: row => `${row.click_count}`,
        Cell: row => getToCommas(row.value),
        type: 'number',
        className: classesName(classes.tableNumberCell),
      },
      {
        id: 'ctr',
        Header: 'CTR',
        headerClassName: classesName(classes.tableHeaderCellLast),
        accessor: row => `${row.ctr}`,
        Cell: row => `${row.value}%`,
        type: 'number',
        className: classesName(classes.tableNumberCell),
      },
      {
        id: 'watch_count',
        Header: '재생 수',
        headerClassName: classesName(classes.tableHeaderCell),
        accessor: row => `${row.watch_count}`,
        Cell: row => getToCommas(row.value),
        type: 'number',
        className: classesName(classes.tableNumberCell),
      },
      {
        id: 'vtr',
        Header: 'VTR',
        headerClassName: classesName(classes.tableHeaderCellLast),
        accessor: row => `${row.vtr}`,
        Cell: row => `${row.value}%`,
        type: 'number',
        className: classesName(classes.tableNumberCell),
      },
    ],
  },
]

const getBaseTypeTableColumns = ({ classes, type, onUpdateAdForcedStop }) => [
  {
    id: 'status',
    Header: '상태',
    headerClassName: classesName(classes.tableHeaderCell),
    accessor: row => row.forced_stop,
    Cell: row => (
      <BaseSwitch
        checked={!row.value}
        onChange={() => onUpdateAdForcedStop({ id: row.row.id, forced_stop: !row.value })}
      />
    ),
    className: classesName(classes.tableSwitchCell),
    width: 80,
    Footer: '총합',
  },
  {
    id: 'id',
    Header: 'ID',
    headerClassName: classesName(classes.tableHeaderCell),
    accessor: row => row.id,
    type: 'number',
    className: classesName(classes.tableNumberCell),
    width: 50,
  },
  {
    id: 'advertisement_set_name',
    Header: '광고셋 이름',
    headerClassName: classesName(classes.tableHeaderCell),
    accessor: row => row.advertisement_set.name || '-',
    className: classesName(classes.tableCell),
  },
  {
    id: 'type',
    Header: '광고 타입',
    headerClassName: classesName(classes.tableHeaderCell),
    accessor: () => `${templateTitle[type]}`,
    className: classesName(classes.tableCell),
    width: 300,
    style: { textAlign: 'center' },
  },
  {
    id: 'title',
    Header: '광고 이름',
    headerClassName: classesName(classes.tableHeaderCell),
    accessor: row => row.name,
    className: classesName(classes.tableCell),
  },
  {
    id: 'weight',
    Header: '노출 비율',
    headerClassName: classesName(classes.tableHeaderCell),
    accessor: row => row.weight,
    className: classesName(classes.tableCell),
  },
  {
    id: 'memo',
    Header: '가이드 값',
    headerClassName: classesName(classes.tableHeaderCell),
    accessor: row => row.memo,
    Cell: row => getToCommas(row.value),
    type: 'number',
    className: classesName(classes.tableNumberCell),
    Footer: column => getToCommas(sumObjectValue(column.data, 'memo')),
    width: 100,
  },
  {
    id: 'target_daily_impressions',
    Header: '일별 최대 노출',
    headerClassName: classesName(classes.tableHeaderCell),
    accessor: row => row.target_daily_impressions,
    Cell: row => getToCommas(row.value),
    type: 'number',
    className: classesName(classes.tableNumberCell),
    Footer: column => getToCommas(sumObjectValue(column.data, 'target_daily_impressions')),
    width: 100,
  },
  {
    id: 'view_count',
    Header: '현재 노출',
    headerClassName: classesName(classes.tableHeaderCell),
    accessor: row => get(row, 'view.count', 0),
    Cell: row => getToCommas(row.value),
    type: 'number',
    className: classesName(classes.tableNumberCell),
    Footer: column => getToCommas(sumObjectValue(column.data, 'view_count')),
    width: 70,
  },
  {
    id: 'ratio',
    Header: () => <Tooltip title="달성률" message="현재 노출 / 가이드 값" placement="top" />,
    headerClassName: classesName(classes.tableHeaderCell),
    accessor: row => getRatio(
      get(row, 'view.count', 0), row.memo || 0,
    ),
    Cell: row => `${row.value}%`,
    type: 'number',
    className: classesName(classes.tableNumberCell),
    Footer: column => `${getRatio(
      sumObjectValue(column.data, 'view_count'),
      sumObjectValue(column.data, 'memo'),
    )}%`,
    width: 70,
  },
  {
    id: 'cpm',
    Header: 'CPM 값',
    headerClassName: classesName(classes.tableHeaderCell),
    accessor: row => get(row, 'advertisement_set.cpm', 0),
    Cell: row => getToCommas(row.value),
    type: 'number',
    className: classesName(classes.tableNumberCell),
    show: type === 'post',
  },
  {
    id: 'click_count',
    Header: '클릭 수',
    headerClassName: classesName(classes.tableHeaderCell),
    accessor: row => get(row, 'click.count', 0),
    Cell: row => getToCommas(row.value),
    type: 'number',
    className: classesName(classes.tableNumberCell),
    Footer: column => getToCommas(sumObjectValue(column.data, 'click_count')),
    width: 100,
  },
  {
    id: 'ctr',
    Header: () => <Tooltip title="CTR" message="클릭 수 / 현재 노출" placement="top" />,
    headerClassName: classesName(type !== 'post' ? classes.tableHeaderCellLast : classes.tableHeaderCell),
    accessor: row => getRatio(
      get(row, 'click.count', 0), get(row, 'view.count', 0),
    ),
    Cell: row => `${row.value}%`,
    type: 'number',
    className: classesName(classes.tableNumberCell),
    Footer: column => `${getRatio(
      sumObjectValue(column.data, 'click_count'),
      sumObjectValue(column.data, 'view_count'),
    )}%`,
    width: 70,
  },
  {
    id: 'watch_count',
    Header: '재생 수',
    headerClassName: classesName(classes.tableHeaderCell),
    accessor: row => get(row, 'watch.count', 0),
    Cell: row => row.value,
    type: 'number',
    className: classesName(classes.tableNumberCell),
    Footer: column => getToCommas(sumObjectValue(column.data, 'watch_count')),
    width: 100,
  },
  {
    id: 'vtr',
    Header: () => <Tooltip title="VTR" message="재생 수 / 현재 노출" placement="top" />,
    headerClassName: classesName(classes.tableHeaderCellLast),
    accessor: row => getRatio(
      get(row, 'watch.count', 0), get(row, 'view.count', 0),
    ),
    Cell: row => `${row.value}%`,
    type: 'number',
    className: classesName(classes.tableNumberCell),
    Footer: (column) => {
      const watchCount = sumObjectValue(column.data, 'watch_count')
      const viewCount = sumViewCountForVTR({ data: column.data, key: 'view_count', refKey: 'watch_count' })

      return `${getRatio(watchCount, viewCount)}%`
    },
    width: 70,
  },
]

export const getTypeTableColumns = ({ classes, type, onUpdateAdForcedStop }) => {
  const columns = getBaseTypeTableColumns({ classes, type, onUpdateAdForcedStop })
  const header = type === 'package' ? `${templateTitle[type]} 전체` : `${templateTitle[type]} 타입`

  return [
    {
      Header: header,
      headerClassName: classesName(classes[`${type}Header`]),
      columns: type === 'post' || type === 'package'
        ? columns
        : filter(columns, ({ id }) => id !== 'watch_count' && id !== 'vtr'),
    },
  ]
}
