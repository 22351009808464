import { createSelector } from 'reselect'

import reduce from 'lodash/reduce'
import get from 'lodash/get'
import find from 'lodash/find'
import flatten from 'lodash/flatten'

import flow from 'lodash/fp/flow'
import eq from 'lodash/fp/eq'
import fpGet from 'lodash/fp/get'

import { addAdStats, formatLineData } from './utils'

export const getCampaign = state => state.campaign

export const getCampaignReportSelector = createSelector(
  getCampaign,
  campagin => get(campagin, 'campaignReport', {}),
)

export const getCampaignReportTitleSelector = createSelector(
  getCampaignReportSelector,
  campaignReport => get(campaignReport, 'title'),
)

export const getCampaignReportStartDateSelector = createSelector(
  getCampaignReportSelector,
  campaignReport => get(campaignReport, 'start_date'),
)

export const getCampaignReportEndDateSelector = createSelector(
  getCampaignReportSelector,
  campaignReport => get(campaignReport, 'end_date'),
)

export const getAdReportsSelector = createSelector(
  getCampaign,
  campagin => get(campagin, 'adReports', []),
)

export const getAdSetsSelector = createSelector(
  getCampaign,
  campagin => get(campagin, 'adSets', []),
)

export const getAdvertisementsSelector = createSelector(
  getCampaign,
  campagin => get(campagin, 'advertisements', {}),
)

/**
 * @param {selectRole} 선택된 role (all, teacher, student, parent)
 * @return 모든 광고의 총합
 * @example { view: 184000, click: 1700, (watch: 300)}
 */
export const getAdTotalSelector = createSelector(
  getAdReportsSelector,
  (_state, { selectRole }) => selectRole,
  (adReports, selectRole) => reduce(flatten(Object.values(adReports)), (accAllOfAds, value) => {
    const advertisements = get(value, 'advertisements')

    const allOfAdAdded = reduce(advertisements, (accAllOfAd, ad) => {
      const adStats = get(ad, 'adStats')

      const allStatsAdded = reduce(adStats, (accAllOfAdStats, stats) => (
        addAdStats(accAllOfAdStats, stats[selectRole])
      ), {})

      return addAdStats(accAllOfAd, allStatsAdded)
    }, {})

    return addAdStats(accAllOfAds, allOfAdAdded)
  }, {}),
)

export const isEmptyAdStatsSelector = createSelector(
  getAdTotalSelector,
  adTotal => flow(
    fpGet('view'),
    eq(0),
  )(adTotal),
)

const initAdStat = {
  view: 0,
  click: 0,
}

/**
 * @param {selectedAdId} 선택된 광고 id
 * @param {selectRole} 선택된 role (all, teacher, student, parent)
 * @return 선택된 광고의 총합 (선택된 광고의 날짜 데이터 총합)
 * @example { view: 1, click: 1, (watch: 1)}
 */
export const getAdTotalByAdIdSelector = createSelector(
  getAdReportsSelector,
  (_state, { selectedAdId }) => selectedAdId,
  (_state, { selectRole }) => selectRole,
  (adReports, selectedAdId, selectRole) => (
    reduce(flatten(Object.values(adReports)), (accAllOfAds, value) => {
      const advertisements = get(value, 'advertisements')
      const selectedAd = find(advertisements, ({ id }) => id === selectedAdId)

      if (selectedAd) {
        const adStats = get(selectedAd, 'adStats')

        return reduce(adStats, (accAllOfAdStats, stat) => (
          addAdStats(accAllOfAdStats, stat[selectRole])
        ), initAdStat)
      }

      return accAllOfAds
    }, initAdStat)
  ),
)

/**
 * @param {selectRole} 선택된 role (all, teacher, student, parent)
 * @return 날짜별 모든 광고 총합
 * @example {2020-05-20: { all: { view, click, (watch) }}, 2020-05-21: { ... }, ...}
 */
export const getAdTotalByDateSelector = createSelector(
  getAdReportsSelector,
  (_state, { selectRole }) => selectRole,
  (adReports, selectRole) => reduce(flatten(Object.values(adReports)), (accAdsTotal, adReport) => {
    const advertisements = get(adReport, 'advertisements')

    const mergedAdsToTotal = reduce(advertisements, (accAd, ad) => {
      const adStats = get(ad, 'adStats')

      const adStatsAdded = reduce(adStats, (accAdStat, stat, date) => {
        const accAdStatDate = (
          get(accAd, [date, selectRole]) // 하나의 광고셋에 대한 지금까지 광고 총 합 중 해당 date의 총 합
          || get(accAdsTotal, [date, selectRole]) // 모든 광고에 대한 지금까지 광고 총 합 중 해당 date의 총 합
        )

        return ({
          ...accAdStat,
          [date]: {
            [selectRole]: addAdStats(stat[selectRole], accAdStatDate),
          },
        })
      }, {})

      return {
        ...accAd,
        ...adStatsAdded,
      }
    }, {})

    return {
      ...accAdsTotal,
      ...mergedAdsToTotal,
    }
  }, {}),
)

/**
 * @param {selectRole} 선택된 role (all, teacher, student, parent)
 * @return line 그래프의 날짜별 모든 광고 총합 데이터
 * @example [
 *   { id: view, color: "#000", data: [{x: '2020-05-20', y: 1} ...] },
 *   ...
 * ]
 */
export const getAdTotalOfDateForLineGraphSelector = createSelector(
  getAdTotalByDateSelector,
  (_state, { selectRole }) => selectRole,
  (adTotalOfDate, selectRole) => (
    formatLineData(adTotalOfDate, selectRole)
  ),
)

export const getAdsTotalInOneAdTypeSelector = createSelector(
  getAdReportsSelector,
  (_state, { selectedAdType }) => selectedAdType,
  (_state, { selectRole }) => selectRole,
  (adReports, selectedAdType, selectRole) => {
    const adReport = adReports[selectedAdType] || []
    return adReport
      .reduce((acc, { advertisements }) => acc.concat(advertisements), [])
      .reduce((accAdTotal, ad) => {
        const adStats = get(ad, 'adStats')
        const mergedAdStats = reduce(adStats, (accAdStats, stats) => (
          addAdStats(accAdStats, stats[selectRole])
        ), {})
        return addAdStats(accAdTotal, mergedAdStats)
      }, {})
  },
)

export const getAdsTotalDateInOneAdTypeSelector = createSelector(
  getAdReportsSelector,
  (_state, { selectedAdType }) => selectedAdType,
  (_state, { selectRole }) => selectRole,
  (adReports, selectedAdType, selectRole) => {
    const adReport = adReports[selectedAdType] || []
    return adReport
      .reduce((acc, { advertisements }) => acc.concat(advertisements), [])
      .reduce((accAdTotal, ad) => {
        const adStats = get(ad, 'adStats')

        const mergedAdStats = reduce(adStats, (accAdStats, stats, date) => ({
          ...accAdStats,
          [date]: {
            [selectRole]: addAdStats(stats[selectRole], get(accAdTotal, [date, selectRole])),
          },
        }), {})

        return {
          ...accAdTotal,
          ...mergedAdStats,
        }
      }, {})
  },
)

export const getLineGraphAdsDateTotalInAdTypeSelector = createSelector(
  getAdsTotalDateInOneAdTypeSelector,
  (_state, { selectRole }) => selectRole,
  (adsTotalInOneAdSet, selectRole) => (
    formatLineData(adsTotalInOneAdSet, selectRole)
  ),
)

/**
 * @param {selectedAdSetId} 선택된 광고셋 id
 * @param {selectRole} 선택된 role (all, teacher, student, parent)
 * @return 하나의 광고셋의 광고 총합(전체) 계산
 * @example { view: 1, click: 1, (watch: 1) }
 */
export const getAdsTotalInOneAdSetSelector = createSelector(
  getAdReportsSelector,
  (_state, { selectedAdSetId }) => selectedAdSetId,
  (_state, { selectRole }) => selectRole,
  (adReports, selectedAdSetId, selectRole) => {
    const adSet = find(flatten(Object.values(adReports)), ({ id }) => id === selectedAdSetId)
    const advertisements = get(adSet, 'advertisements')

    return reduce(advertisements, (accAdTotal, ad) => {
      const adStats = get(ad, 'adStats')

      const mergedAdStats = reduce(adStats, (accAdStats, stats) => (
        addAdStats(accAdStats, stats[selectRole])
      ), {})

      return addAdStats(accAdTotal, mergedAdStats)
    }, {})
  },
)

/**
 * @param {selectedAdSetId} 선택된 광고셋 id
 * @param {selectRole} 선택된 role (all, teacher, student, parent)
 * @return 날짜에 대한 한 광고셋의 광고 총합
 * @example {2020-05-20: { all: { view, click, (watch) }}, 2020-05-21: { ... } ...}
 */
export const getAdsTotalDateInOneAdSetSelector = createSelector(
  getAdReportsSelector,
  (_state, { selectedAdSetId }) => selectedAdSetId,
  (_state, { selectRole }) => selectRole,
  (adReports, selectedAdSetId, selectRole) => {
    const adSet = find(flatten(Object.values(adReports)), ({ id }) => id === selectedAdSetId)
    const advertisements = get(adSet, 'advertisements')
    return reduce(advertisements, (accAdTotal, ad) => {
      const adStats = get(ad, 'adStats')

      const mergedAdStats = reduce(adStats, (accAdStats, stats, date) => ({
        ...accAdStats,
        [date]: {
          [selectRole]: addAdStats(stats[selectRole], get(accAdTotal, [date, selectRole])),
        },
      }), {})

      return {
        ...accAdTotal,
        ...mergedAdStats,
      }
    }, {})
  },
)

/**
 * @param {selectRole} 선택된 role (all, teacher, student, parent)
 * @return line 그래프의 날짜별 하나의 광고셋의 광고 총합(전체) 계산
 * @example [
 *   { id: view, color: "#000", data: [{x: '2020-05-20', y: 1} ...] },
 *   ...
 * ]
 */
export const getAdsTotalDateInOneAdSetForLineGraphSelector = createSelector(
  getAdsTotalDateInOneAdSetSelector,
  (_state, { selectRole }) => selectRole,
  (adsTotalInOneAdSet, selectRole) => (
    formatLineData(adsTotalInOneAdSet, selectRole)
  ),
)
