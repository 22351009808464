import React from 'react'
import PropTypes from 'prop-types'
import SplashBannerAd from '@/features/preview/components/molecules/SplashBannerAd'

const AdPreviewSplashBannerType = ({ imageUrl }) => (
  <SplashBannerAd
    imageUrl={imageUrl}
  />
)

AdPreviewSplashBannerType.propTypes = {
  imageUrl: PropTypes.string.isRequired,
}

export default AdPreviewSplashBannerType
