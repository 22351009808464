/** @jsx jsx */
import { jsx } from '@emotion/react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import ogImage from '@landing/assets/images/og_image.png'

const HOST = process.env.REACT_APP_HOST_URL

function SEO({ lang, meta, title }) {
  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={title}
      meta={[
        {
          content: '79953e18ac734dce6a1cc96ce84edbcd6f96ec89',
          name: 'naver-site-verification',
        },
        {
          content: title,
          name: 'title',
        },
        {
          content:
            '누적 회원 수 800만 명, 대한민국 1등 클래스관리 도구 \'클래스팅\'에 광고를 집행해보세요. 사용자 데이터를 기반으로 학부모, 학생, 선생님에게 직접 광고를 노출하여, 정교하고 효과적인 광고 집행이 가능합니다!',
          name: 'description',
        },
        {
          content: 'website',
          property: 'og:type',
        },
        {
          content: '클래스팅 광고',
          property: 'og:title',
        },
        {
          content: '학부모, 학생, 교사 타겟 매체',
          property: 'og:description',
        },
        {
          content: '클래스팅 광고',
          property: 'og:site_name',
        },
        {
          content: HOST,
          property: 'og:url',
        },
        {
          content: ogImage,
          property: 'og:image',
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: 'ko',
  meta: [],
}

SEO.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
