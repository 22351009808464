import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    backgroundColor: '#00c896',
    outline: 'none',
    border: 'none',
    borderRadius: '4px',
    padding: '0 16px',
  },
}))

const Button = ({ children, className, onClick }) => {
  const classes = useStyles()

  return (
    <button type="button" className={cn(classes.root, className)} onClick={onClick}>
      {children}
    </button>
  )
}

Button.defaultProps = {
  children: null,
  className: '',
  onClick: null,
}

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default Button
