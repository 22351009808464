/** @jsx jsx */
import { jsx } from '@emotion/react'
import PropTypes from 'prop-types'

import Button from './Button'

const InquireButton = ({ fullWidth, clickCallBack, isRounded }) => {
  const handleClick = () => {
    window.location = '#inquire'
    if (typeof clickCallBack === 'function') clickCallBack()
  }

  return (
    <Button fullWidth={fullWidth} isRounded={isRounded} onClick={handleClick}>
      광고 문의하기
    </Button>
  )
}

InquireButton.defaultProps = {
  fullWidth: false,
  isRounded: true,
  clickCallBack: () => {},
}

InquireButton.propTypes = {
  clickCallBack: PropTypes.func,
  fullWidth: PropTypes.bool,
  isRounded: PropTypes.bool,
}

export default InquireButton
