import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { NavigationBar } from '@/shared/components'
import policy from './terms.html'

const policyHtml = { __html: policy }

const styles = ({
  root: {
    padding: '24px',
  },
})

const TermsPage = ({ classes }) => (
  <div>
    <NavigationBar />
    {/* eslint-disable-next-line react/no-danger */}
    <div className={classes.root} dangerouslySetInnerHTML={policyHtml} />
    {/* eslint-disable */}
  </div>
)

TermsPage.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }).isRequired,
}

export default withStyles(styles)(TermsPage)
