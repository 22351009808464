// 이거 없애야함
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  padStart,
  keys,
  clone,
  toNumber,
} from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import {
  Typography,
  Button,
  TableCell,
  TableRow,
  OutlinedInput,
  Table,
  TableHead,
  TableBody,
  DialogContentText,
} from '@material-ui/core'
import {
  Layout, CircularUnderLoad, EnhancedPaper, EnhancedDialog,
} from '@/shared/components'
import { templateTitle, roleMap } from '@/shared/constants'
import {
  CREATE_IMPRESSION_RATE_FAILURE,
} from '../actions'

const ROLES = ['teacher', 'parent', 'student']
const VIEW_TEMPLATES = ['post', 'notice', 'comment']
const MAX_IMPRESSION_RATE = 100
const DEFAULT_IMPRESSION_RATE = 100

const styles = () => ({
  header: {
    display: 'inline-block',
  },
  saveButton: {
    float: 'right',
    color: '#fff',
  },
  table: {
    tableLayout: 'fixed',
  },
  paper: {
    width: '2000px',
  },
  tableHead: {
    backgroundColor: '#f5f5f5',
    '& th': {
      textAlign: 'center',
      fontSize: '14px',
      fontWeight: '600',
      color: '#212121',
      padding: '0',

      '&:last-child': {
        paddingRight: '0',
      },
    },
  },
  tableBody: { },
  tableCell: {
    padding: '8px 24px 8px 24px',
    border: '1px solid #e0e0e0',
    fontSize: '12px',
    color: '#212121',
    '& input': {
      fontSize: '12px',
    },
  },
  hourCell: {
    textAlign: 'center',
    backgroundColor: '#fafafa',
    fontSize: '14px',
    color: '#212121',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  outlinedInput: {
    width: '95px',
    height: '32px',
    '& fieldset': {
      backgroundColor: '#fff',
    },
    '& input': {
      zIndex: '1',
    },
  },
})

class ImpressionRatePage extends Component {
  state = {
    loading: false,
    isResultDialogOpen: false,
    resultDialogMessage: '',
    viewTemplates: VIEW_TEMPLATES,
    hours: [...Array(24).keys()],
    impressionRate: {},
    changedImpressionRate: {},
  };

  componentDidMount() {
    this.fetchImpressionRate()
  }

  fetchImpressionRate = async (params) => {
    const { onFetchImpressionRate } = this.props

    this.setState({ loading: true })

    await onFetchImpressionRate(params)
    const { impressionRate } = this.props

    this.setState({
      impressionRate,
      loading: false,
    })
  };

  getImpressionRate = (viewTemplate, role, hour) => {
    const changedValue = (
      this.state.changedImpressionRate[viewTemplate]
        && this.state.changedImpressionRate[viewTemplate][role]
    )
      ? this.state.changedImpressionRate[viewTemplate][role][hour]
      : undefined
    if (changedValue) {
      return changedValue
    }

    const originalValue = (
      this.state.impressionRate[viewTemplate]
        && this.state.impressionRate[viewTemplate][role]
    )
      ? this.state.impressionRate[viewTemplate][role][hour]
      : undefined

    return originalValue || DEFAULT_IMPRESSION_RATE
  };

  handleImpressionRateChange = (event) => {
    const viewTemplate = event.target.getAttribute('view_template')
    const hour = event.target.getAttribute('hour')
    const role = event.target.getAttribute('role')
    const { value } = event.target

    if (value > 100) {
      // eslint-disable-next-line no-param-reassign
      event.target.value = 100
    }
    if (value < 0) {
      // eslint-disable-next-line no-param-reassign
      event.target.value = 0
    }

    this.setState((prevState) => {
      const cPrevChangedRate = clone(prevState.changedImpressionRate)

      if (!cPrevChangedRate[viewTemplate]) {
        cPrevChangedRate[viewTemplate] = {}
      }

      if (!cPrevChangedRate[viewTemplate][role]) {
        cPrevChangedRate[viewTemplate][role] = {}
      }

      return {
        changedImpressionRate: {
          ...cPrevChangedRate,
          [viewTemplate]: {
            ...cPrevChangedRate[viewTemplate],
            [role]: {
              ...cPrevChangedRate[viewTemplate][role],
              [hour]: value,
            },
          },
        },
      }
    })
  };

  handleSaveButtonClick = async () => {
    const createMessageFromResults = (results) => {
      const errorMessages = []

      let message = ''
      results.forEach((result) => {
        if (result === CREATE_IMPRESSION_RATE_FAILURE) {
          errorMessages.push(JSON.stringify(result.payload.data))
        }
      })
      if (errorMessages.length === 0) {
        message = '저장되었습니다.'
      } else {
        message = errorMessages.join('\n')
      }
      return message
    }

    const { onCreateImpressionRate } = this.props
    const { impressionRate: _impressionRate, changedImpressionRate } = this.state
    const impressionRate = clone(_impressionRate)
    const onCreateImpressionRates = []

    keys(changedImpressionRate).forEach((viewTemplate) => {
      keys(changedImpressionRate[viewTemplate]).forEach((role) => {
        keys(changedImpressionRate[viewTemplate][role]).forEach((hour) => {
          const value = changedImpressionRate[viewTemplate][role][hour]
          // NOTE: create CreatingImpressionRate promise
          onCreateImpressionRates.push(onCreateImpressionRate({
            view_template: viewTemplate,
            hour: toNumber(hour),
            impression_rate: value,
            role,
          }))

          // NOTE: update impressionRate of Global State
          if (!impressionRate[viewTemplate]) {
            impressionRate[viewTemplate] = {}
          }
          if (!impressionRate[viewTemplate][role]) {
            impressionRate[viewTemplate][role] = {}
          }

          impressionRate[viewTemplate][role][hour] = value
        })
      })
    })

    const results = await Promise.all(onCreateImpressionRates)
    const message = createMessageFromResults(results)

    this.setState({
      impressionRate,
      changedImpressionRate: {},
      isResultDialogOpen: true,
      resultDialogMessage: message,
    })
  }

  render() {
    const { classes } = this.props

    const {
      loading, viewTemplates, hours, isResultDialogOpen, resultDialogMessage,
    } = this.state

    const {
      handleImpressionRateChange,
      handleSaveButtonClick,
      getImpressionRate,
    } = this

    return (
      <Layout pageTitle="시간별 노출 관리">
        {loading ? (
          <EnhancedPaper styles={classes.paper}>
            <CircularUnderLoad styles={classes.progress} />
          </EnhancedPaper>
        ) : (
          <EnhancedPaper styles={classes.paper}>
            <Typography variant="h5" gutterBottom className={classes.header}>
              시간별 노출 비율 조정
            </Typography>

            <Button variant="contained" size="large" color="primary" onClick={handleSaveButtonClick} className={classes.saveButton}>
              저장하기
            </Button>

            <Table className={classes.table}>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell rowSpan="2" component="th" scope="row" className={classes.tableCell}>
                    시간
                  </TableCell>
                  {viewTemplates.map(viewTemplate => (
                    <TableCell
                      component="th"
                      scope="row"
                      colSpan="3"
                      className={classes.tableCell}
                    >
                      {templateTitle[viewTemplate]}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  {
                    viewTemplates.map(() => (
                      ROLES.map(role => (
                        <TableCell className={classes.tableCell}>{roleMap[role]}</TableCell>
                      ))))
                  }
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBody}>
                {hours.map(hour => (
                  <TableRow>
                    <TableCell component="th" scope="row" className={`${classes.tableCell} ${classes.hourCell}`}>
                      {`${padStart(hour, 2, 0)}:00~${padStart(hour + 1, 2, 0)}:00`}
                    </TableCell>

                    {viewTemplates.map(viewTemplate => (
                      ROLES.map(role => (
                        <TableCell
                          component="td"
                          scope="row"
                          className={classes.tableCell}
                          style={{
                            backgroundColor: `rgba(0,200,150,${1
                            - getImpressionRate(viewTemplate, role, hour)
                              / MAX_IMPRESSION_RATE})`,
                          }}
                        >
                          <OutlinedInput
                            type="number"
                            name="title"
                            labelWidth={0}
                            max="100"
                            className={classes.outlinedInput}
                            inputProps={{
                              min: 0,
                              max: 100,
                              hour,
                              view_template: viewTemplate,
                              role,
                            }}
                            defaultValue={getImpressionRate(
                              viewTemplate,
                              role,
                              hour,
                            )}
                            onChange={handleImpressionRateChange}
                          />
                          {' %'}
                        </TableCell>
                      ))))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <EnhancedDialog
              isOpen={isResultDialogOpen}
              isCloseButton={false}
              modalTitle="저장 완료"
              handleOpen={() => {
                this.setState({ isResultDialogOpen: false })
              }}
            >
              <DialogContentText>
                <div>{ resultDialogMessage }</div>
              </DialogContentText>
            </EnhancedDialog>
          </EnhancedPaper>
        )}
      </Layout>
    )
  }
}

ImpressionRatePage.propTypes = {
  classes: PropTypes.object.isRequired,
  onFetchImpressionRate: PropTypes.func.isRequired,
  onCreateImpressionRate: PropTypes.func.isRequired,
  impressionRate: PropTypes.object.isRequired,
}

export default withStyles(styles)(ImpressionRatePage)
