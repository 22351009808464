import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { get } from 'lodash'
import { queryString } from '@/shared/utils'
import { withTracker } from '@/shared/enhancers'
import { NavigationBar, CircularUnderLoad } from '@/shared/components'
import { fetchUserConfirmation } from '../actions'

const styles = ({
  container: {
    maxWidth: '846px',
    margin: '44px auto',
    padding: '64px 0 0 0',
    position: 'relative',
  },
  title: {
    color: '#00c896',
  },
  subTitle: {
    color: '#757575',
    marginTop: '14px',
    marginBottom: '14px',
  },
  button: {
    color: '#ffffff',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
})

const mapStateToProps = state => ({
  isValidConfirm: state.signUp.isValidConfirm,
  isInValidConfirm: state.signUp.isInValidConfirm,
  errors: state.signUp.errors,
  pending: state.signUp.pending,
})

const mapDispatchToProps = dispatch => ({
  onUserConfirmationMount: confirmationToken => (
    dispatch(fetchUserConfirmation({ confirmation_token: confirmationToken }))
  ),
})

class ConfirmationPage extends Component {
  componentDidMount() {
    const {
      location,
      onUserConfirmationMount,
    } = this.props

    const { token } = queryString.parse(location.search)
    onUserConfirmationMount(token)
  }

  handleSignInClick = () => {
    const { history } = this.props
    return history.push({
      pathname: '/sign_in',
    })
  }

  inValidFeedback = () => {
    const { errors } = this.props
    const errrorMessage = get(errors && errors[0], 'message')

    return errrorMessage || '관리자에게 문의해주세요.'
  }


  render() {
    const {
      classes, isValidConfirm, isInValidConfirm, pending,
    } = this.props
    const { inValidFeedback, handleSignInClick } = this

    return (
      <div>
        <NavigationBar />
        {pending ? <CircularUnderLoad styles={classes.progress} />
          : (
            <div className={classes.container}>
              <Typography variant="h4" gutterBottom className={classes.title}>
                {isValidConfirm && '회원가입 성공'}
                {isInValidConfirm && '회원가입 실패'}
              </Typography>
              <Typography variant="h6" gutterBottom className={classes.subTitle}>
                {isValidConfirm && '서비스를 이용하기 위해서 로그인을 해주세요.'}
                {isInValidConfirm && inValidFeedback()}
              </Typography>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                type="submit"
                onClick={handleSignInClick}
              >
                로그인 하기
              </Button>
            </div>
          )
        }
      </div>
    )
  }
}

ConfirmationPage.propTypes = {
  onUserConfirmationMount: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  classes: PropTypes.shape({
    progress: PropTypes.string,
    container: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    button: PropTypes.string,
  }).isRequired,
  isValidConfirm: PropTypes.bool.isRequired,
  isInValidConfirm: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  errors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pending: PropTypes.bool.isRequired,
}

export default compose(
  withTracker,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(ConfirmationPage)
