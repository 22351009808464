/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import PropTypes from 'prop-types'
import DialogContent from './DialogContent'

const makeStyles = () => ({
  root: css`
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 100;
  `,
  wrap: css`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  `,
})

const Dialog = ({ secondaryButton, primaryButton, description }) => {
  const styles = makeStyles()

  return (
    <div css={styles.root}>
      <div css={styles.wrap}>
        <DialogContent
          primaryButton={primaryButton}
          secondaryButton={secondaryButton}
          description={description}
        />
      </div>
    </div>
  )
}

Dialog.defaultProps = {
  secondaryButton: {
    onClick: () => {},
    text: '취소',
    visible: false,
  },
}

Dialog.propTypes = {
  description: PropTypes.string.isRequired,
  primaryButton: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
  secondaryButton: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
  }),
}

export default Dialog
