import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { ChromePicker } from 'react-color'
import { makeStyles } from '@material-ui/core'

import { Input } from '../atoms'

const useStyles = makeStyles({
  root: {
    position: 'relative',
    display: 'flex',
  },
  colorPicker: {
    position: 'absolute',
    top: '40px',
    left: '0',
    zIndex: '100',
  },
  previewColor: ({ value }) => ({
    width: '40px',
    height: '40px',
    borderRadius: '4px',
    background: value,
    marginLeft: '8px',
  }),
})

const ColorPicker = ({
  id, value, changeValue, placeholder,
}) => {
  const colorPickerEl = useRef()
  const [displayColorPicker, setDisplayColorPicker] = useState(false)
  const classes = useStyles({ value })
  const handleInputChange = ({ target: { value: newValue } }) => {
    changeValue(newValue)
  }

  const handleInputClick = () => {
    setDisplayColorPicker(prev => !prev)
  }

  const handleColorPickerChange = (color) => {
    changeValue(color.hex)
  }

  const handleOutsideClick = ({ target }) => {
    if (displayColorPicker && !colorPickerEl.current.contains(target)) setDisplayColorPicker(false)
  }

  useEffect(() => {
    window.addEventListener('click', handleOutsideClick)

    return () => {
      window.removeEventListener('click', handleOutsideClick)
    }
  }, [displayColorPicker])

  return (
    <div className={classes.root}>
      <Input
        width="240px"
        id={id}
        value={value}
        placeholder={placeholder}
        onChange={handleInputChange}
        onClick={handleInputClick}
      />
      {
        displayColorPicker
        && (
          <div ref={colorPickerEl}>
            <ChromePicker
              color={value}
              className={classes.colorPicker}
              onChange={handleColorPickerChange}
            />
          </div>
        )
      }
      <div className={classes.previewColor} />
    </div>
  )
}

ColorPicker.defaultProps = {
  placeholder: '',
}

ColorPicker.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  changeValue: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
}

export default ColorPicker
