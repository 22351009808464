/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import PropTypes from 'prop-types'

import checkIcon from '@landing/assets/images/check_icon.svg'

const makeStyles = ({ checked }) => ({
  boxWrap: ({ colors: { grey, green } }) => css`
    display: flex;
    box-sizing: border-box;
    width: 2rem;
    height: 2rem;
    box-sizing: border-box;
    border: ${checked ? 'none' : `2px solid ${grey[400]}`};
    border-radius: 4px;
    background-color: ${checked ? green[100] : '#fff'};
    align-items: center;
    justify-content: center;
  `,
  checkIconWrap: css``,
  label: ({ colors: { grey }, font, spacing }) => css`
    color: ${grey[900]};
    font-size: ${font.body2.fontSize};
    line-height: ${font.body2.lineHeight};
    margin-left: ${spacing()}rem;
  `,
  wrap: css`
    display: flex;
    align-items: center;
    cursor: pointer;
  `,
})

const CheckBox = ({
  checked, customStyles, label, onChange,
}) => {
  const styles = makeStyles({ checked })

  return (
    <div css={customStyles}>
      <label htmlFor={label} css={styles.wrap}>
        <input
          hidden
          id={label}
          name={label}
          checked={checked}
          type="checkbox"
          onChange={onChange}
        />
        <div css={styles.boxWrap}>
          {checked && (
            <div css={styles.checkIconWrap}>
              <img src={checkIcon} alt="" />
            </div>
          )}
        </div>
        <span css={styles.label}>{label}</span>
      </label>
    </div>
  )
}

CheckBox.defaultProps = {
  checked: false,
  label: '',
  customStyles: '',
}

CheckBox.propTypes = {
  checked: PropTypes.bool,
  customStyles: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.array,
  ]),
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default CheckBox
