/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { useState } from 'react'
import PropTypes from 'prop-types'

import { useInterval } from '@landing/hooks/utils'
import SliderItem from './SliderItem'

const makeStyles = () => ({
  itemWrap: () => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  `,
  root: () => css`
    position: relative;
    width: 100%;
  `,
})

const Slider = ({ items }) => {
  const [currentOrder, setOrder] = useState(0)
  const styles = makeStyles()

  useInterval(() => {
    setOrder((prev) => {
      if (prev === items.length - 1) return 0
      return prev + 1
    })
  }, 2000)

  return (
    <div css={styles.root}>
      {items.map((url, order) => (
        <div css={styles.itemWrap} key={url}>
          <SliderItem
            isShow={order === currentOrder}
            url={url}
            currentOrder={currentOrder}
            setOrder={setOrder}
          />
        </div>
      ))}
    </div>
  )
}

Slider.defaultProps = {
  items: [],
}

Slider.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
}

export default Slider
