/**
 * 값이 추가되려면 아래 파일들이 같이 바뀌어야 함
 * 파일 나열은 우선순위 별로 나열되어 있습니다.
 * @see src/shared/constants/advertisements/AdvertisementNameMap.js
 * @see src/shared/constants/gamAdUnitName.js
 * @see src/shared/constants/templateTitle.js
 * @see src/features/campaign/xlsxExport/sections.js
 * @see src/features/today/components/TodayTable/TodayTable.styles.js
 * @see src/features/today/components/TableExpander/TableExpander.styles.js
 * @see src/shared/utils/getViewTemplate.js
 */
export const templateTitle = {
  package: '패키지',
  post: '게시글',
  notice: '학급공지',
  banner: '배너',
  class_brief: '클래스탭',
  benefit_banner: '혜택리스트 상단 배너',
  play_banner: '플레이탭',
  comment: '댓글',
  post_slide: '포스트 슬라이드',
  store_home: '스토어 홈',
  store_book: '스토어 도서',
  store_stationery: '스토어 문구',
  store_babylife: '스토어 아이생활',
  notification_text_banner: '알림탭 텍스트 배너',
  notification_image_banner: '알림탭 이미지 배너',
  post_banner: '포스트 배너',
  start_popup: '시작 팝업',
  end_popup: '종료 팝업',
  class_tab_banner: '클래스탭 배너 네이티브',
  gam_class_tab: 'GAM클래스탭 배너(MO)',
  gam_my_tab: 'MY탭',
  gam_close_popup: '종료 팝업',
  gam_ai_reward: 'AI 리워드',
  gam_post_banner: 'GAM포스트 배너',
  gam_post_detail: 'GAM포스트 배너 (게시글 상세)',
  gam_classroom_post_list: 'GAM포스트 배너 (클래스 게시글 목록)',
  gam_classroom_post_detail: 'GAM포스트 배너 (클래스 게시글 상세)',
  gam_classroom_navigation: '클래스룸 네비게이션',
  gam_home_top_banner: '홈 탭 배너',
  gam_home_left_top_banner1: '홈 좌측 상단 배너1',
  gam_home_left_top_banner2: '홈 좌측 상단 배너2',
  gam_home_middle_banner: '홈 탭 포스트',
  gam_home_bottom_banner: '홈 하단 배너',
  gam_notification_first_slot: '알림 탭 상단 배너',
  gam_notification_second_slot: '알림 탭 하단 배너',
  classtalk_tab_banner: '클래스톡 배너',
  splash_banner: '스플래시',
}
