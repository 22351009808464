import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'

import {
  EnhancedPaper,
  CircularUnderLoad,
  DatePicker,
} from '@/shared/components'
import AdReportCharts from './AdReportCharts/AdReportCharts'

import {
  getCampaignReportStartDateSelector,
  getCampaignReportEndDateSelector,
} from '../selectors'

import {
  fetchCampaign,
  handleAdCountFromNewPipeline,
  fetctAdSets,
} from '../actions'

const AdReportsDetail = ({
  campaignReportStartDate,
  campaignReportEndDate,
  onAdCountCacheMount,
  classes,
  refresh,
  pending,
  adSetIds,
  campaignId,
}) => {
  const initialStartDate = moment().add(-8, 'days').format('YYYY-MM-DD')
  const initialEndDate = moment().add(-1, 'days').format('YYYY-MM-DD')
  const [startDate, setStartDate] = useState(initialStartDate)
  const [endDate, setEndDate] = useState(initialEndDate)
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = (_, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  useEffect(() => {
    if (startDate !== campaignReportStartDate) {
      setStartDate(campaignReportStartDate)
    }

    if (endDate !== campaignReportEndDate) {
      const nowMoment = moment().add(-1, 'days')
      const endDateMoment = moment(campaignReportEndDate)

      if (endDateMoment.isAfter(nowMoment)) {
        setEndDate(nowMoment.format('YYYY-MM-DD'))
      } else {
        setEndDate(campaignReportEndDate)
      }
    }
  }, [campaignReportStartDate, campaignReportEndDate])

  const handleStartDateChange = date => setStartDate(date.format('YYYY-MM-DD'))

  const handleEndDateChange = date => setEndDate(date.format('YYYY-MM-DD'))

  const handleDateChange = () => {
    if (moment(initialEndDate).isBefore(endDate)) {
      handleOpen()
      return
    }

    const params = {
      adIds: adSetIds,
      startDate,
      endDate,
      campaignId,
    }

    onAdCountCacheMount(params)
  }

  return (
    refresh || pending
      ? (
        <EnhancedPaper styles={classes.progressContent}>
          <CircularUnderLoad styles={classes.progressPosition} />
        </EnhancedPaper>
      )
      : (
        <EnhancedPaper>
          <div className={classes.reportToolbar}>
            <Typography variant="h5" gutterBottom className={classes.reportTitle}>
              상세 보고서
            </Typography>
            <DatePicker
              value={startDate}
              onChange={handleStartDateChange}
            />
            <DatePicker
              value={endDate}
              onChange={handleEndDateChange}
            />
            <Button
              variant="contained"
              size="large"
              color="primary"
              className={classes.dateChangeButton}
              disable={`${refresh}`}
              onClick={handleDateChange}
            >
              조회하기
              {refresh && <CircularUnderLoad styles={classes.progressButton} size={20} />}
            </Button>
          </div>
          <>
            <Typography variant="caption" gutterBottom style={{ color: '#708090' }}>
              * 모바일 기기 특성 상 해당 일자 기준 1~2일 후 수치가 1~10 정도 오를 수 있습니다.
            </Typography>
            <AdReportCharts selectRole="all" />
          </>
          <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="warning">
              전일 데이터 이후의 데이터는 조회가 불가능합니다.
            </Alert>
          </Snackbar>
        </EnhancedPaper>
      )
  )
}

AdReportsDetail.propTypes = {
  classes: PropTypes.any.isRequired,
  refresh: PropTypes.bool.isRequired,
  pending: PropTypes.bool.isRequired,
  campaignReportStartDate: PropTypes.string,
  campaignReportEndDate: PropTypes.string,
  onAdCountCacheMount: PropTypes.func.isRequired,
  adSetIds: PropTypes.array.isRequired,
  campaignId: PropTypes.string,
}

AdReportsDetail.defaultProps = {
  campaignReportStartDate: '',
  campaignReportEndDate: '',
  campaignId: '',
}

export default connect(
  state => ({
    refresh: state.campaign.refresh,
    pending: state.campaign.pending,
    adSetIds: state.campaign.adSets.flatMap(adSet => adSet.advertisements.map(advertisement => advertisement.id)),
    campaignReportStartDate: getCampaignReportStartDateSelector(state),
    campaignReportEndDate: getCampaignReportEndDateSelector(state),
  }),
  {
    onMount: fetchCampaign,
    onAdSetMount: fetctAdSets,
    onAdCountCacheMount: handleAdCountFromNewPipeline,
  },
)(AdReportsDetail)
