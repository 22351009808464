import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import AdMockup from './AdMockup'
import { Caution } from '../atoms'

const useStyles = makeStyles(() => ({
  mockupContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '80px',
    padding: '20px',
  },
  cautionContainer: {
    textAlign: 'center',
  },
}))

const PreviewForm = ({ formType, mockupImages, advertisement }) => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.mockupContainer}>
        {mockupImages.map(images => (
          <AdMockup
            type={formType}
            mockupImage={images}
            advertisement={advertisement}
          />
        ))}
      </div>
      <div className={classes.cautionContainer}>
        {(formType === 'postImage' || formType === 'postVideo')
          ? (<Caution text="※ 게시글 형태의 광고는 소식 피드와 공지 피드에 노출되게 됩니다." />)
          : null
        }
        <Caution text="※ 실제 광고 노출 화면은 사용자의 기기 또는 환경 설정에 따라 차이가 발생할 수 있습니다." />
      </div>
    </>
  )
}

PreviewForm.propTypes = {
  formType: PropTypes.string.isRequired,
  mockupImages: PropTypes.arrayOf(PropTypes.shape({
    upperImage: PropTypes.string.isRequired,
    belowImage: PropTypes.string.isRequired,
  })).isRequired,
  advertisement: PropTypes.object.isRequired,
}

export default PreviewForm
