import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '360px',
    padding: '16px',
    backgroundColor: '#fafafa',
  },
  profile: {
    width: '28px',
    height: '28px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '50%',
    marginRight: '8px',
    overflow: 'hidden',

    '& img': {
      display: 'block',
      width: '100%',
    },
  },
  content: ({ hasImage }) => ({
    width: `${hasImage ? '174px' : '100%'}`,
    marginRight: '8px',
  }),
  title: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '1.43',
    color: 'rgba(0, 0, 0, 0.87)',
    marginBottom: '4px',
  },
  sponsored: {
    color: '#9e9e9e',
    fontSize: '10px',
    lineHeight: '16px',
    marginLeft: '4px',
  },
  description: {
    fontSize: '14px',
    lineHeight: '20px',
    color: '#757575',
    marginBottom: '4px',
    wordBreak: 'break-all',
    whiteSpace: 'break-spaces',
  },
  actionLabel: {
    fontSize: '12px',
    lineHeight: '16px',
    color: '#9e9e9e',
  },
  adImage: {
    width: '110px',
    borderRadius: '4px',
    overflow: 'hidden',

    '& img': {
      display: 'block',
      width: '100%',
    },
  },
}))

const AdPreviewCommentType = ({
  title,
  advertiserProfileImage,
  actionLabel,
  description,
  imageUrl,
  isImageType,
}) => {
  const styles = useStyles({ hasImage: isImageType })

  const nl2br = string => string.split('\n').map(line => (
    <span>
      {line}
      <br />
    </span>
  ))

  return (
    <div className={styles.root}>
      <figure className={styles.profile}>
        <img src={advertiserProfileImage} alt="광고주 이미지" />
      </figure>
      <div className={styles.content}>
        <div className={styles.title}>
          {title}
          <span className={styles.sponsored}>Sponsored</span>
        </div>
        <div className={styles.description}>
          {nl2br(description)}
        </div>
        <div className={styles.actionLabel}>
          {`${actionLabel} >`}
        </div>
      </div>
      {
        isImageType
        && (
        <figure className={styles.adImage}>
          <img src={imageUrl} alt="광고 이미지" />
        </figure>
        )
      }
    </div>
  )
}

AdPreviewCommentType.defaultProps = {
  imageUrl: '',
}

AdPreviewCommentType.propTypes = {
  title: PropTypes.string.isRequired,
  advertiserProfileImage: PropTypes.string.isRequired,
  actionLabel: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  isImageType: PropTypes.bool.isRequired,
}

export default AdPreviewCommentType
