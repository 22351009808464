import React from 'react'
import PropTypes from 'prop-types'

import { PostAd } from '@/features/preview/components'

const AdPreviewPostType = ({
  advertiserName,
  advertiserProfileImage,
  actionLabel,
  highlightColor,
  description,
  imageUrl,
  isVideoType,
  title,
}) => (
  <PostAd
    actionLabel={actionLabel}
    advertiserName={advertiserName}
    advertiserProfileImage={advertiserProfileImage}
    description={description}
    imageUrl={imageUrl}
    highlightColor={highlightColor}
    isVideoType={isVideoType}
    title={title}
  />
)

AdPreviewPostType.defaultProps = {
  advertiserProfileImage: '',
  actionLabel: '',
  highlightColor: '',
  imageUrl: '',
  title: '',
}

AdPreviewPostType.propTypes = {
  advertiserName: PropTypes.string.isRequired,
  advertiserProfileImage: PropTypes.string,
  actionLabel: PropTypes.string,
  highlightColor: PropTypes.string,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  isVideoType: PropTypes.bool.isRequired,
  title: PropTypes.string,
}

export default AdPreviewPostType
