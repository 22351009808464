import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    margin: '0',
    width: '193.75px',

    '& img': {
      display: 'block',
      width: '100%',
    },
  },
}))

const AdPreviewPcBannerType = ({ imageUrl }) => {
  const styles = useStyles()

  return (
    <figure className={styles.root}>
      <img src={imageUrl} alt="광고 이미지" />
    </figure>
  )
}

AdPreviewPcBannerType.propTypes = {
  imageUrl: PropTypes.string.isRequired,
}

export default AdPreviewPcBannerType
