import React from 'react'
import PropTypes from 'prop-types'
import classesName from 'classnames'

import BaseComponent from './BaseComponent'

const ThComponent = ({ thProps, classes }) => (
  <BaseComponent
    tableProps={thProps}
    className={`${classesName(classes.th)} ${thProps.className}`}
  />
)

ThComponent.propTypes = {
  thProps: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
}

export default ThComponent
