import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import { templateTitle } from '@/shared/constants'
import { getViewTemplate } from '@/shared/utils'
import AdPreviewMenuItem from './AdPreviewMenuItem'

const useStyles = makeStyles(() => ({
  root: {
    minWidth: '320px',
    width: '320px',
    padding: '24px',
    paddingRight: '40px',
    boxSizing: 'border-box',
  },
  menuItem: {
    marginBottom: '8px',

    '&:last-child': {
      marginBottom: '0',
    },
  },
}))

const AdPrevViewMenu = ({ adSets, selectedAdId, setSelectedAdId }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {
        adSets.map(({
          advertisements, name, view_template: originalViewTemplate, gam_ad_unit_id: gamAdUnitId,
        }) => {
          const viewTemplate = getViewTemplate({
            view_template: originalViewTemplate,
            gam_ad_unit_id: gamAdUnitId,
          })

          return (
            <>
              {
              advertisements.map(({ id, memo }) => (
                <div className={classes.menuItem} key={id}>
                  <AdPreviewMenuItem
                    id={id}
                    name={`${name || `${templateTitle[viewTemplate]} 타입`} > ${memo || `광고 (${id})`}`}
                    selectedAdId={selectedAdId}
                    setSelectedAdId={setSelectedAdId}
                  />
                </div>
              ))
            }
            </>
          )
        })
      }
    </div>
  )
}

AdPrevViewMenu.propTypes = {
  adSets: PropTypes.arrayOf(PropTypes.shape({
    advertiser: PropTypes.shape({
      name: PropTypes.string.isRequired,
      profile_image: PropTypes.string,
    }).isRequired,
    name: PropTypes.string,
    view_template: PropTypes.string.isRequired,
    start_date: PropTypes.string.isRequired,
    advertisements: PropTypes.arrayOf(PropTypes.shape({
      action_label: PropTypes.string.isRequired,
      action_url: PropTypes.string.isRequired,
      created_at: PropTypes.string,
      description: PropTypes.string.isRequired,
      highlight_color: PropTypes.string,
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    })).isRequired,
  })).isRequired,
  selectedAdId: PropTypes.number.isRequired,
  setSelectedAdId: PropTypes.func.isRequired,
}

export default AdPrevViewMenu
