/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { useContext, useState } from 'react'
import PropTypes from 'prop-types'

import {
  uiStore,
  VIEW_SIZE_MOBILE,
  openDialogAction,
  closeDialogAction,
} from '@landing/contexts/ui'
import Agreement from './Agreement'
import CompanyInformation from './CompanyInformation'
import ManagerInformation from './ManagerInformation'
import AdvertisementInformation from './AdvertisementInformation'
import InquireContent from './InquireContent'
import { AppearanceAnimation } from '../unit/animation'
import { Button } from '../unit/button'

const makeStyles = () => ({
  highLayer: () => css`
    position: relative;
    z-index: 10;
  `,
  submitBtn: ({ mq, spacing }) => css`
    ${mq({
    padding: [`${spacing(2)}rem 0`, `${spacing(2)}rem ${spacing(3.25)}rem`],
  })}
  `,
  submitBtnWrap: ({ spacing }) => css`
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: ${spacing(5)}rem;
  `,
})

const initialInquireInfo = {
  brandName: '',
  businessClassification: '',
  companyName: '',
  contact: '',
  email: '',
  goals: [
    { checked: false, label: '단순홍보' },
    { checked: false, label: 'DB(신청자)모집' },
    { checked: false, label: '구매' },
    { checked: false, label: '이벤트 등 참여자 모집 및 독려' },
    { checked: false, label: '동영상 시청' },
    { checked: false, label: '광고 클릭' },
    { checked: false, label: '기타', value: '' },
  ],
  inquireContent: '',
  name: '',
  targets: [
    { checked: false, label: '학부모' },
    { checked: false, label: '학생' },
    { checked: false, label: '선생님' },
  ],
  title: '',
  visitRoute: '',
}

const Content = ({ submitInquire }) => {
  const {
    dispatch,
    state: { viewSize },
  } = useContext(uiStore)
  const [inquireInfo, setInquireInfo] = useState(initialInquireInfo)

  const styles = makeStyles()

  const handleSubmit = async (e) => {
    e.preventDefault()
    const checkedGoals = inquireInfo.goals.filter(({ checked }) => checked)
    const checkedTargets = inquireInfo.targets.filter(({ checked }) => checked)
    const goals = checkedGoals
      .map(({ label, value }) => (value || label))
      .join()
    const targets = checkedTargets.map(({ label }) => label).join()

    const closeDialog = () => {
      dispatch(closeDialogAction())
    }

    const onConfirmClick = async () => {
      await submitInquire({ ...inquireInfo, goals, targets })
      setInquireInfo(initialInquireInfo)

      dispatch(
        openDialogAction({
          description: '문의가 제출되었습니다.',
          primaryButton: {
            onClick: () => {
              closeDialog()
              window.location.replace('/')
            },
            text: '확인',
            visible: true,
          },
        }),
      )
    }

    dispatch(
      openDialogAction({
        description: '클래스팅에 광고 문의를 등록하시겠습니까?',
        primaryButton: {
          onClick: onConfirmClick,
          text: '확인',
          visible: true,
        },
        secondaryButton: {
          onClick: closeDialog,
          text: '취소',
          visible: true,
        },
      }),
    )
  }

  return (
    <form onSubmit={handleSubmit}>
      <div css={styles.highLayer}>
        <AppearanceAnimation>
          <CompanyInformation
            inquireInfo={inquireInfo}
            setInquireInfo={setInquireInfo}
          />
        </AppearanceAnimation>
      </div>
      <div css={styles.highLayer}>
        <AppearanceAnimation>
          <ManagerInformation
            inquireInfo={inquireInfo}
            setInquireInfo={setInquireInfo}
          />
        </AppearanceAnimation>
      </div>
      <AppearanceAnimation>
        <AdvertisementInformation
          inquireInfo={inquireInfo}
          setInquireInfo={setInquireInfo}
        />
      </AppearanceAnimation>
      <AppearanceAnimation>
        <InquireContent
          inquireInfo={inquireInfo}
          setInquireInfo={setInquireInfo}
        />
        <Agreement />
        <div css={styles.submitBtnWrap}>
          <Button
            type="submit"
            fullWidth={viewSize === VIEW_SIZE_MOBILE}
            color={viewSize === VIEW_SIZE_MOBILE ? 'blue' : 'green'}
            customStyles={styles.submitBtn}
          >
            문의 등록하기
          </Button>
        </div>
      </AppearanceAnimation>
    </form>
  )
}

Content.propTypes = {
  submitInquire: PropTypes.func.isRequired,
}

export default Content
