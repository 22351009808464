/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { useContext, Fragment } from 'react'
import PropTypes from 'prop-types'

import {
  uiStore,
  openModalAction,
  VIEW_SIZE_MOBILE,
  VIEW_SIZE_PC,
} from '@landing/contexts/ui'

import arrowRightIcon from '@landing/assets/images/arrow_right.svg'

import Description from './Description'

const makeStyles = () => ({
  arrowIconWrap: ({ spacing }) => css`
    display: flex;
    align-self: center;
    margin-left: ${spacing()}rem;
  `,
  describe: ({ colors: { grey }, font }) => css`
    color: ${grey[600]};
    font-size: ${font.body2.fontSize};
    line-height: ${font.body2.lineHeight};
  `,
  order: ({ mq }) => css`
    ${mq({
    width: ['auto', '3rem'],
  })}
  `,
  orderBox: ({
    colors: { blue, grey }, font, spacing, mq,
  }) => css`
    ${mq({
    alignItems: 'center',
    color: blue[200],
    display: 'flex',
    fontSize: [font.body2.fontSize, font.title3.fontSize],
    fontWeight: 'bold',
    lineHeight: [font.body2.lineHeight, font.title3.lineHeight],
  })}

    ::after {
      ${mq({
    background: grey[400],
    border: '3px solid #fff',
    borderRadius: '50%',
    color: 'transparent',
    content: '""',
    display: 'block',
    height: '0.8rem',
    margin: [`0 ${spacing(2)}rem`, `0 ${spacing(4)}rem 0 ${spacing(3)}rem`],
    textIndent: '-9999px',
    width: '0.8rem',
  })}
    }
  `,
  orderWrap: () => css`
    display: flex;
    align-items: flex-start;
  `,
  root: () => css`
    align-items: "flex-start";
    display: flex;
  `,
  title: ({
    colors: { grey }, font, spacing, mq,
  }) => css`
    ${mq({
    color: grey[800],
    cursor: ['pointer', 'auto'],
    display: 'flex',
    fontSize: [font.body2.fontSize, font.title3.fontSize],
    fontWeight: 'bold',
    lineHeight: [font.body2.lineHeight, font.title3.lineHeight],
    paddingBottom: ['0', `${spacing()}rem`],
  })}
  `,
})

const Step = ({
  Additional,
  description,
  description2,
  order,
  title,
  title2,
}) => {
  const styles = makeStyles()
  const {
    dispatch,
    state: { viewSize },
  } = useContext(uiStore)

  const handleTitleClick = () => {
    if (viewSize === VIEW_SIZE_MOBILE) {
      dispatch(
        openModalAction(({ onClose }) => (
          <Description
            Additional={Additional}
            title={viewSize === VIEW_SIZE_MOBILE && title2 ? title2 : title}
            description={
              viewSize === VIEW_SIZE_MOBILE ? description2 : description
            }
            onClose={onClose}
          />
        )),
      )
    }
  }

  return (
    <div css={styles.root}>
      <div css={styles.orderWrap}>
        <span css={styles.orderBox}>
          <div css={styles.order}>{order}</div>
        </span>
      </div>
      <div>
        <div css={styles.title} onClick={handleTitleClick}>
          <p>{viewSize === VIEW_SIZE_MOBILE && title2 ? title2 : title}</p>
          {viewSize === VIEW_SIZE_MOBILE && (
            <div css={styles.arrowIconWrap}>
              <img src={arrowRightIcon} alt="" />
            </div>
          )}
        </div>

        {viewSize === VIEW_SIZE_PC && (
          <Fragment>
            <p css={styles.describe}>{description}</p>
            {Additional && <Additional />}
          </Fragment>
        )}
      </div>
    </div>
  )
}

Step.defaultProps = {
  description2: '',
  title2: '',
  Additional: () => <Fragment />,
}

Step.propTypes = {
  Additional: PropTypes.elementType,
  description: PropTypes.node.isRequired,
  description2: PropTypes.node,
  order: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  title2: PropTypes.node,
}

export default Step
