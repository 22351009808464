import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import {
  Col, Form, FormGroup, Label, Input,
} from 'reactstrap'
import Button from '@material-ui/core/Button'
import { isEmpty, isNil } from 'lodash'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Typography from '@material-ui/core/Typography'
import Dropzone from 'react-dropzone'
import emptyLogo from '@/shared/assets/ic-empty-logo.svg'
import { queryString } from '@/shared/utils'
import { withTracker } from '@/shared/enhancers'
import { CircularUnderLoad, Layout, EnhancedPaper } from '@/shared/components'
import {
  setAdvertiser, fetchAdvertiser, updateAdvertiser, resetAdvertiser,
} from '../actions'

const styles = ({
  container: {
    maxWidth: '946px',
    padding: '24px',
  },
  title: {
    margin: '24px 0 24px 0',
    textAlign: 'center',
  },
  field: {
    margin: '4px 0 4px 0',
  },
  text: {
    color: '#757575',
  },
  fieldError: {
    margin: '4px 0 4px 0',
    border: '1px solid #e85d7b',
  },
  actions: {
    margin: '12px 0 14px 0',
    fontSize: '14px',
    position: 'relative',
    height: '18px',
  },
  button: {
    color: '#ffffff',
    width: '24%',
    position: 'absolute',
    right: 0,
  },
  outlined: {
    width: '100%',
    color: '#00c896',
    marginBottom: '14px',
  },
  uploadButtonContainer: {
    paddingLeft: '14px',
  },
  failure: {
    color: '#e85d7b',
    fontSize: '14px',
    margin: '14px 0 14px 0',
    textAlign: 'center',
  },
  emptyProfile: {
    background: '#f5f5f5',
    width: '236px',
    height: '236px',
    display: 'inlin-block',
    color: 'rgba(0, 0, 0, 0.26)',
    padding: '14px',
    textAlign: 'center',
    fontSize: '14px',
  },
  profile: {
    width: '236px',
    height: '236px',
    display: 'inlin-block',
  },
  uploadButton: {
    marginBottom: '14px',
  },
  instituteField: {
    borderRadius: '4px',
    border: 'solid 1px #dbdbdb',
    padding: '24px',
    marginTop: '14px',
  },
  instituteLabel: {
    fontSize: '14px',
    color: '#757575',
    marginBottom: '14px',
  },
})

const mapStateToProps = state => ({
  pending: state.advertiser.pending,
  status: state.advertiser.status,
  advertiser: state.advertiser.advertiserData,
})

const mapDispatchToProps = dispatch => ({
  onSubmit: values => dispatch(setAdvertiser(values)),
  onMount: advertiserId => dispatch(fetchAdvertiser(advertiserId)),
  onUpdate: advertiserId => dispatch(updateAdvertiser(advertiserId)),
  onResetAdvertiser: () => dispatch(resetAdvertiser()),
})

class AdvertiserFormPage extends Component {
  state = {
    file: null,
  }

  componentDidMount() {
    const { onMount, onResetAdvertiser } = this.props
    const { getAdvertiserId } = this

    onResetAdvertiser()
    if (!isEmpty(getAdvertiserId())) {
      onMount(getAdvertiserId())
    }
  }

  getAdvertiserId = () => {
    const { location } = this.props
    return queryString.parse(location.search).advertiser_id
  }

  renderPreviewImage = (file, profileImage) => {
    const { classes } = this.props

    if (isEmpty(file) && isEmpty(profileImage)) {
      return (
        <div className={classes.emptyProfile}>
          <img src={emptyLogo} style={{ display: 'block', margin: '44px auto 8px' }} alt="empty-logo" />
          로고 이미지를 업로드 해주세요
        </div>
      )
    }

    if (!isEmpty(file)) {
      return <img className={classes.profile} src={file} alt="profile_image" />
    }

    return <img className={classes.profile} src={profileImage} alt="profile_image" />
  }

  handleInstituteFormClick = () => {
    window.open('http://naver.me/5obnK5wo')
  }

  render() {
    const {
      classes, onSubmit, pending, advertiser, history, onUpdate,
    } = this.props
    const { file } = this.state
    const { renderPreviewImage, handleInstituteFormClick, getAdvertiserId } = this

    return (
      <Layout pageTitle={!isEmpty(getAdvertiserId()) ? '브랜드 정보 수정' : '브랜드 정보 생성'}>
        {pending ? <CircularUnderLoad />
          : (
            <EnhancedPaper>
              <div className={classes.container}>
                <Typography variant="h6" gutterBottom>
                  브랜드 정보
                </Typography>
                <Formik
                  initialValues={!isEmpty(advertiser) ? {
                    name: advertiser.name,
                    profile_image: advertiser.profile_image,
                    institute_id: advertiser.institute_id,
                  } : {
                    name: '',
                    profile_image: '',
                    institute_id: '',
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    Promise.resolve()
                      .then(() => {
                        if (!isEmpty(advertiser)) {
                          const data = {
                            id: getAdvertiserId(),
                            ...values,
                          }
                          return onUpdate(data)
                        }
                        return onSubmit(values)
                      })
                      .then(() => setSubmitting(false))
                      .then(() => history.push('/advertiser'))
                  }}
                  validationSchema={Yup.object().shape({
                    name: Yup.string()
                      .min('2')
                      .max('24')
                      .required('브랜드 이름은 필수 입력값 입니다.'),
                    profile_image: Yup.string()
                      .required('브랜드 사진은 필수 입니다.'),
                  })}
                >
                  {(props) => {
                    const {
                      values,
                      touched,
                      errors,
                      isSubmitting,
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      setFieldValue,
                    } = props

                    return (
                      <Form onSubmit={handleSubmit}>
                        <FormGroup row>
                          <Label sm={2}>브랜드 이름</Label>
                          <Col sm={10}>
                            <Input
                              type="text"
                              name="name"
                              className={
                                errors.name && touched.name ? classes.fieldError : classes.field
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="광고 이름을 입력해주세요"
                              value={values.name}
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label sm={2}>로고</Label>
                          <Col sm={10} style={{ display: 'flex' }}>
                            {renderPreviewImage(file, values.profile_image)}
                            <div className={classes.uploadButtonContainer}>
                              <Dropzone
                                accept=".jpg,.png"
                                onDrop={(acceptedFiles) => {
                                  const fileUrl = URL.createObjectURL(acceptedFiles[0])
                                  this.setState({ file: fileUrl })
                                  setFieldValue('profile_image', acceptedFiles[0])
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <Button
                                      className={classes.uploadButton}
                                      variant="outlined"
                                      color="primary"
                                    >
                                      로고 등록하기
                                    </Button>
                                  </div>
                                )}
                              </Dropzone>
                              <div className={classes.text}>
                                로고 이미지 등록 시 정사각형 규격, 용량 10MB 이하,
                                <br />
                                최소 300*300px, 확장자 jpg, gif, png 파일만 등록 가능합니다.
                              </div>
                              {(errors.profile_image && touched.profile_image)
                              && (
                              <div className={classes.invalidFeedback}>
                                {errors.profile_image}
                              </div>
                              )
                            }
                            </div>
                          </Col>
                          <Col sm={2} />
                          <Col sm={10}>
                            {!isNil(values.institute_id)
                            && (
                            <div className={classes.instituteField}>
                              <div className={classes.instituteLabel}>
                                학원에 대한 추가적인 정보를 입력하시려면
                                <br />
                                상단 ‘학원 정보 추가 입력’ 버튼을 클릭해 양식을 입력해주세요.
                              </div>
                              <Button
                                onClick={handleInstituteFormClick}
                                variant="contained"
                                color="secondary"
                                style={{ color: '#ffffff' }}
                                type="button"
                              >
                                학원 정보 수정
                              </Button>
                            </div>
                            )
                          }
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label sm={2} />
                          <Col sm={10}>
                            <Button
                              className={classes.button}
                              variant="contained"
                              color="primary"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              {!isEmpty(getAdvertiserId()) ? '수정' : '저장'}
                            </Button>
                          </Col>
                        </FormGroup>

                      </Form>
                    )
                  }}
                </Formik>
              </div>
            </EnhancedPaper>
          )
        }
      </Layout>
    )
  }
}

AdvertiserFormPage.propTypes = {
  classes: PropTypes.shape({
    emptyProfile: PropTypes.string,
    profile: PropTypes.string,
    container: PropTypes.string,
    fieldError: PropTypes.string,
    field: PropTypes.string,
    uploadButtonContainer: PropTypes.string,
    uploadButton: PropTypes.string,
    text: PropTypes.string,
    invalidFeedback: PropTypes.string,
    invalidField: PropTypes.string,
    invalidLabel: PropTypes.string,
    instituteField: PropTypes.string,
    instituteLabel: PropTypes.string,
    button: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  advertiser: PropTypes.shape({
    name: PropTypes.string,
    profile_image: PropTypes.string,
    institute_id: PropTypes.string,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onMount: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onResetAdvertiser: PropTypes.func.isRequired,
  pending: PropTypes.bool.isRequired,
}

export default compose(
  withTracker,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(AdvertiserFormPage)
