import React from 'react'
import PropTypes from 'prop-types'

const LineBreak = ({ children }) => {
  if (typeof children === 'string') {
    return (
      <>
        {children.split('\n').map((line, idx, texts) => {
          const isLast = idx === texts.length

          return (
            <React.Fragment key={line}>
              {line}
              {!isLast && <br />}
            </React.Fragment>
          )
        })}
      </>
    )
  }
  return children
}

LineBreak.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LineBreak
