/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { useContext } from 'react'
import PropTypes from 'prop-types'

import { uiStore } from '@landing/contexts/ui'

const makeStyles = () => ({
  item: ({ colors: { grey }, font }) => css`
    display: flex;
    width: 100%;
    height: 5.6rem;
    color: ${grey[700]};
    font-size: ${font.body2.fontSize};
    font-weight: bold;
    align-items: center;
    justify-content: center;

    :active {
      background-color: rgba(0, 0, 0, 0.08);
    }
  `,
  root: css`
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
})

const MobileNavigation = ({ closeModal }) => {
  const styles = makeStyles()
  const {
    state: { navigations },
  } = useContext(uiStore)

  return (
    <div css={styles.root}>
      {navigations.map(({ id, name }) => (
        <a css={styles.item} key={id} href={`#${id}`} onClick={closeModal}>
          {name}
        </a>
      ))}
    </div>
  )
}

MobileNavigation.propTypes = {
  closeModal: PropTypes.func.isRequired,
}

export default MobileNavigation
