import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import {
  Form, Row, FormGroup, Col, Label, Input,
} from 'reactstrap'
import {
  map, filter, find, forEach, isEmpty, round, isNil, head, reduce, has, get, findIndex, cloneDeep,
} from 'lodash'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import DialogContentText from '@material-ui/core/DialogContentText'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import ClearIcon from '@material-ui/icons/Clear'
import { InlineDatePicker } from 'material-ui-pickers'
import { Range } from 'rc-slider'
import 'rc-slider/assets/index.css'
import moment from 'moment'
import queryString from 'query-string'
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Polygon,
} from 'react-google-maps'
import classNames from 'classnames'
import { getToCommas, getItem } from '@/shared/utils'
import { withTracker } from '@/shared/enhancers'
import {
  CircularUnderLoad, Layout, Stepper, EnhancedDialog,
} from '@/shared/components'
import { fetchCampaign } from '../../campaign/actions'
import {
  updateCampaigns,
  updateAdSet,
  setAdSet,
  fetchAdPackage,
  fetchRegions,
  fetchCities,
  fetchZones,
  fetchAdLocations,
  fetchAdSet,
  deleteAdLocation,
  fetchCoordinates,
  resetAdSets,
} from '../actions'

const styles = ({
  container: {
    padding: 0,
  },
  title: {
    margin: '24px 0 24px 0',
    textAlign: 'center',
  },
  field: {
    margin: '4px 0 4px 0',
  },
  productField: {
    display: 'inline-block',
    marginRight: '6px',
    width: '286px',
  },
  text: {
    color: '#757575',
  },
  invalidFeedback: {
    color: '#e85d7b',
    fontSize: '12px',
  },
  productFieldLabel: {
    width: '168px',
    marginRight: '14px',
    display: 'inline-block',
  },
  productValue: {
    marginLeft: '14px',
    marginBottom: '6px',
    color: '#757575',
  },
  itemText: {
    fontSize: '14px',
    color: '#bdbdbd',
  },
  itemLabel: {
    color: '#00c896',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  fieldError: {
    margin: '4px 0 4px 0',
    border: '1px solid #e85d7b',
  },
  actions: {
    margin: '12px 0 14px 0',
    fontSize: '14px',
    position: 'relative',
    height: '18px',
  },
  submitButton: {
    width: '18%',
    float: 'right',
    marginRight: '14px',
  },
  outlined: {
    width: '100%',
    color: '#00c896',
    marginBottom: '14px',
  },
  failure: {
    color: '#e85d7b',
    fontSize: '14px',
    margin: '14px 0 14px 0',
    textAlign: 'center',
  },
  rangeGrade: {
    margin: '44px auto',
    width: '97%',
  },
  tabsRoot: {
    borderBottom: '1px solid #e8e8e8',
  },
  tabsIndicator: {
    backgroundColor: '#1890ff',
  },
  formLabel: {
    marginLeft: '32px',
    marginBottom: '14px',
  },
  productImage: {
    marginLeft: '32px',
  },
  datePicker: {
    marginRight: '14px',
  },
  regionList: {
    border: '1px solid #bdbdbd',
    width: '100%',
    position: 'relative',
    overflow: 'auto',
    maxHeight: '300px',
    minHeight: '300px',
    marginRight: '14px',
    marginTop: '14px',
    display: 'inline-block',
  },
  selectedLocation: {
    marginTop: '14px',
    marginBottom: '14px',
    maxHeight: '300px',
    minHeight: '300px',
  },
  inlineLabel: {
    marginRight: '12px',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  paper: {
    padding: '24px',
    margin: '24px 0 24px 0',
    position: 'relative',
  },
  productLabel: {
    fontSize: '13px',
  },
  nextButton: {
    color: '#ffffff',
    width: '18%',
    float: 'right',
  },
  map: {
    marginTop: '14px',
    marginBottom: '14px',
    height: '340px',
  },
  selectedLocationLabel: {
    marginTop: '14px',
  },
  locationLabel: {
    marginRight: '18px',
  },
  emptyLocationLabel: {
    color: '#bdbdbd',
    marginTop: '14px',
  },
  disabledText: {
    color: '#dbdbdb',
  },
  subLabel: {
    color: '#757575',
    fontSize: '14px',
  },
  priceText: {
    fontSize: '24px',
    fontWeight: 'bold',
  },
  priceLabel: {
    color: '#bdbdbd',
    fontSize: '12px',
    marginBottom: '4px',
    marginTop: '4px',
  },
  listItem: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
})

const mapStateToProps = state => ({
  pending: state.editing.pending,
  status: state.editing.status,
  advertisers: state.advertiser.advertisers,
  advertiser: state.advertiser.advertiserData,
  campaign: state.campaign.campaignReport,
  adPackages: state.editing.adPackages,
  regions: state.editing.regions,
  cities: state.editing.cities,
  zones: state.editing.zones,
  adLocations: state.editing.adLocations,
  adSets: state.editing.adSets,
  adSet: head(state.editing.adSets),
})

const mapDispatchToProps = dispatch => ({
  onAdSetSubmit: data => dispatch(setAdSet(data)),
  onAdSetUpdate: data => dispatch(updateAdSet(data)),
  onAdSet: campaignId => dispatch(fetchAdSet(campaignId)),
  onCampaignsUpdate: data => dispatch(updateCampaigns(data)),
  onCampaign: campaignId => dispatch(fetchCampaign(campaignId)),
  onAdPackage: adPackageType => dispatch(fetchAdPackage({ package_type: adPackageType })),
  onRegions: params => dispatch(fetchRegions(params)),
  onCities: data => dispatch(fetchCities(data)),
  onZones: params => dispatch(fetchZones(params)),
  onAdLocations: adSetId => dispatch(fetchAdLocations(adSetId)),
  onResetAdSets: () => dispatch(resetAdSets()),
})


const PolygonMap = withScriptjs(withGoogleMap(props => (
  <GoogleMap
    defaultZoom={14}
    defaultCenter={{ lat: 37.5665, lng: 126.9780 }}
    center={props.center}
  >
    <Polygon
      path={props.path}
      options={{
        fillColor: '#A2FF99',
        fillOpacity: 0.4,
        strokeColor: '#39DE2A',
        strokeOpacity: 1,
        strokeWeight: 1,
      }}
    />
  </GoogleMap>
)))

class AdSetFormPage extends Component {
  state = {
    loading: false,
    isOpenModal: false,
    path: [],
    center: { lat: 37.5665, lng: 126.9780 },
    packageType: {
      region: '우리 동네 지역 광고',
      impression: '노출 보장형 광고',
    },
    budget: 0,
    selectedDateRangeType: '2',
    selectedRegionId: 0,
    selectedCityId: 0,
    selectedZoneId: 0,
    selectedLocationItems: [],
    selectedLocation: [],
    selectedBillingType: 'cpm',
    selectedPacakageType: '0',
    selectedRegionType: 'country',
    roles: [
      { label: '학부모', checked: true, value: 'parent' },
      { label: '학생', checked: false, value: 'student' },
      { label: '선생님', checked: false, value: 'teacher' },
    ],
    marks: {
      0: { style: { display: 'inline-table' }, label: '유치원' },
      1: { style: { display: 'inline-table' }, label: '초1' },
      2: { style: { display: 'inline-table' }, label: '초2' },
      3: { style: { display: 'inline-table' }, label: '초3' },
      4: { style: { display: 'inline-table' }, label: '초4' },
      5: { style: { display: 'inline-table' }, label: '초5' },
      6: { style: { display: 'inline-table' }, label: '초6' },
      7: { style: { display: 'inline-table' }, label: '중1' },
      8: { style: { display: 'inline-table' }, label: '중2' },
      9: { style: { display: 'inline-table' }, label: '중3' },
      10: { style: { display: 'inline-table' }, label: '고1' },
      11: { style: { display: 'inline-table' }, label: '고2' },
      12: { style: { display: 'inline-table' }, label: '고3' },
      13: { style: { display: 'inline-table' }, label: '대학생' },
    },
    handleStyle: [
      { backgroundColor: '#00c896', border: '1px solid #00c896' },
      { backgroundColor: '#00c896', border: '1px solid #00c896' },
    ],
    trackStyle: [{ backgroundColor: '#85f4cc' }],
    activeDotStyle: { borderColor: '#85f4cc', backgroundColor: '#85f4cc' },
    startDate: null,
    endDate: null,
    page: 1,
    perPage: 100,
    gradeFrom: 0,
    gradeTo: 13,
    citiesLoading: false,
    zonesLoading: false,
    adPackage: {},
    checkedAllAudience: false,
  }

  componentDidMount() {
    const {
      onCampaign,
      onAdPackage,
      onRegions,
      onAdSet,
      onAdLocations,
      onResetAdSets,
      history,
    } = this.props
    const { page, perPage, roles } = this.state
    const {
      getCampaignId, getAdPackageType, getAdFormType, isAdmin,
    } = this

    /* eslint-disable no-alert */
    this.unblock = history.block(() => {
      if (window.confirm('생성 중인 해당 캠페인이 저장되지 않습니다. 그래도 나가시겠습니까?')) {
        return true
      }
      return false
    })
    /* eslint-disable */

    onResetAdSets()
    if (getAdPackageType() === 'region') {
      this.setState({ selectedBillingType: 'cpp' })
    }

    if (!isAdmin()) {
      this.setState({ checkedAllAudience: true })
    }

    this.setState({ loading: true })
    onCampaign(getCampaignId())
      .then((campaign) => {
        if (!isEmpty(campaign.ad_package)) {
          this.setState({
            budget: campaign.budget,
            adPackage: campaign.ad_package,
          })

          return onAdPackage(campaign.ad_package.package_type)
            .then(() => this.setState({ selectedPacakageType: String(campaign.ad_package.id) }))
        }

        return onAdPackage(getAdPackageType())
          .then(adPackages => this.setState({ selectedPacakageType: String(head(adPackages).id) }))
      })
      .then(() => {
        if (getAdFormType() === 'update') {
          return onAdSet(getCampaignId())
            .then(adSets => head(adSets))
            .then(adSet => {
              if (!isEmpty(adSet)) {
                this.setState({
                  gradeFrom: adSet.audience_grade_from,
                  gradeTo: adSet.audience_grade_to,
                  startDate: adSet.start_date,
                  endDate: adSet.end_date,
                  roles: map(roles, role => ({
                    ...role,
                    checked : adSet.audience_roles.indexOf(role.value) !== -1
                  })),
                  selectedDateRangeType: String(moment(adSet.end_date).week() - moment(adSet.start_date).week()),
                })
                return onAdLocations(adSet.id)
                  .then((adLocations) => {
                    if (!isEmpty(adLocations)) {
                      this.setState(prevState => ({
                        selectedRegionType: 'region',
                        selectedLocationItems: [
                          ...prevState.selectedLocationItems,
                          ...reduce(adLocations, (acc, location) => [
                            ...acc,
                            !isEmpty(location.region) ? { ...location.region } : { ...location.zone },
                          ], []),
                        ],
                      }))
                    }
                  })
              }
            })
        }
      })
      .then(() => {
        const parmas = {
          page,
          per_page: perPage,
        }

        return onRegions(parmas)
      })
      .then(() => this.setState({ loading: false }))
  }

  componentWillUnmount() {
    this.unblock()
 }

 onCoordinates = (regionName) => {
   if (isEmpty(regionName)) {
     return true
   }

  const regionNameIndex = []
   forEach(regionName, (item, index) => {
      if (item === ' ') {
        regionNameIndex.push(index)
      }
   })

   if (regionNameIndex.length > 2) {
    const formatRegionName = regionName.split('')
    formatRegionName[regionNameIndex[1]] = ''
    /* eslint-disable no-param-reassign */
    regionName = formatRegionName.join('')
    /* eslint-enable */
   }

   return fetchCoordinates(regionName)
     .then((res) => {
       if (!has(res.data, 'response.result')) {
         return true
       }

       const coordinates = res.data.response
         .result.featureCollection.features[0].geometry.coordinates[0][0]
       const path = []
       const lat = []
       const lng = []

       forEach(coordinates, (item) => {
         path.push({
           lat: item[1],
           lng: item[0],
         })
         lat.push(item[1])
         lng.push(item[0])
       })

       const center = {
         lat: (Math.min(...lat) + (Math.max(...lat) - Math.min(...lat)) / 2),
         lng: (Math.min(...lng) + (Math.max(...lng) - Math.min(...lng)) / 2),
       }

       return this.setState({
         path,
         center,
       })
     })
 }

  getCampaignId = () => {
    const { location } = this.props
    return queryString.parse(location.search).campaign_id
  }

  getAdPackageType = () => {
    const { location } = this.props
    return queryString.parse(location.search).ad_package_type
  }

  getAdFormType = () => {
    const { location } = this.props
    return queryString.parse(location.search).form_type || 'create'
  }

  filterAdPackageType = () => {
    const { adPackages } = this.props
    const { getAdPackageType } = this
    return filter(adPackages, item => item.package_type === getAdPackageType())
  }

  findAdPackageType = () => {
    const { filterAdPackageType } = this
    const { selectedPacakageType } = this.state

    return find(filterAdPackageType(), { id: Number(selectedPacakageType) }) || {}
  }

  getGuaranteedExposure = () => {
    const { findAdPackageType } = this
    const { campaign } = this.props
    const { budget } = this.state
    const { ad_products: adProducts } = findAdPackageType()

    const guaranteedExposure = reduce(adProducts, (acc, cur) => {
      const actualBudget = !isEmpty(budget) ? budget : campaign.budget
      return ((actualBudget / cur.cpm)) * 1000
    }, 0)

    return round(guaranteedExposure) || 0
  }

  findLocationName = (selectedId, items) => find(items, { id: selectedId }) || {}

  handlePackagesChange = setFieldValue => (event) => {
    setFieldValue('ad_package_id', event.target.value)
    this.setState({ selectedPacakageType: event.target.value })
  }

  handleRegionChange = (event) => {
    this.setState({ selectedRegionType: event.target.value })
  }

  onGradeChange = setFieldValue => (event) => {
    this.setState({
      gradeFrom: event[0],
      gradeTo: event[1],
    })
    setFieldValue('audience_grade_from', event[0])
    setFieldValue('audience_grade_to', event[1])
  }

  handleDateRangeChange = setFieldValue => (event) => {
    const { startDate } = this.state
    const selectedDateRangeType = event.target.value

    this.setState({ selectedDateRangeType })

    if (isEmpty(startDate)) {
      return true
    }

    const endDate = moment(startDate).add(Number(selectedDateRangeType), 'week').format('YYYY-MM-DD')
    this.setState({ endDate })
    setFieldValue('end_date', endDate)
    return true
  }

  handleMinStartDate = () => moment().startOf('day')

  handleMinEndDate = () => {
    const { startDate } = this.state
    return moment(startDate)
  }

  handleMaxEndDate = () => {
    const { getAdPackageType } = this
    const { startDate } = this.state
    if (getAdPackageType() !== 'region') {
      return moment(startDate).add(1, 'month')
    }

    return moment(startDate).add(2, 'month')
  }

  handleStartDateChange = setFieldValue => (date) => {
    const { getAdPackageType } = this
    const { selectedDateRangeType } = this.state
    const startDate = date.format('YYYY-MM-DD')
    setFieldValue('start_date', startDate)
    this.setState({ startDate })

    if (getAdPackageType() === 'region') {
      const endDate = moment(date).add(Number(selectedDateRangeType), 'week').format('YYYY-MM-DD')
      this.setState({ endDate })
      setFieldValue('end_date', endDate)
    }
  }

  handleEndDateChange = setFieldValue => (date) => {
    const endDate = date.format('YYYY-MM-DD')
    setFieldValue('end_date', endDate)
    this.setState({ endDate })
  }

  adProductLabel = (product) => {
    const productLabel = []
    forEach(product, (item) => {
      if (item.view_template === 'post' && item.product_type === 'video') {
        productLabel.push('동영상 게시글')
      }

      if (item.view_template === 'post' && item.product_type === 'image') {
        productLabel.push('이미지 게시글')
      }

      if (item.view_template === 'notice' && item.product_type === 'text') {
        productLabel.push('공지글')
      }

      if (item.view_template === 'comment' && item.product_type === 'text') {
        productLabel.push('댓글 형식')
      }

      if (item.view_template === 'comment' && item.product_type === 'image') {
        productLabel.push('댓글 형식')
      }
    })

    return productLabel
  }

  renderAdPackageRadioGroup = (setFieldValue) => {
    const { classes } = this.props
    const { selectedPacakageType, adPackage } = this.state
    const { handlePackagesChange, filterAdPackageType, adProductLabel } = this
    return (
      <Row>
        {map(filterAdPackageType(), item => (
          <Col sm={4} key={item.id}>
            <RadioGroup
              aria-label="packageType"
              name="pacakageType"
              className={classes.group}
              value={selectedPacakageType}
              onChange={handlePackagesChange(setFieldValue)}
              row
            >
              <FormControlLabel
                value={String(item.id)}
                control={<Radio color="primary" disabled={!isEmpty(adPackage)} />}
                label={item.title}
                labelPlacement="end"
              />
            </RadioGroup>
            <FormLabel>
              <div className={classes.productLabel}>
                {adProductLabel(item.ad_products).join(' + ')}
              </div>
            </FormLabel>
          </Col>
        ))}
      </Row>
    )
  }

  handleRegionItemClick = (region, selectedIdx) => () => {
    const { getAdPackageType } = this
    const { onCities } = this.props
    const { page, perPage } = this.state
    this.setState({ selectedRegionId: Number(region.id) })

    if (getAdPackageType() === 'impression') {
      this.setState((prevState) => {
        const newState = {
          selectedLocationItems: [...prevState.selectedLocationItems],
        }
        if (selectedIdx !== -1) {
          newState.selectedLocationItems.splice(selectedIdx, 1)
          if (!newState.selectedLocationItems.length) newState.selectedRegionType = 'country'
        } else {
          newState.selectedLocationItems.push({ ...region })
        }
        return newState
      })
    }

    if (getAdPackageType() === 'region') {
      const params = {
        region_id: region.id,
        page,
        per_page: perPage,
      }
      this.setState({
        citiesLoading: true,
        selectedRegionId: Number(region.id),
      })
      onCities(params)
        .then(() => this.setState({ citiesLoading: false }))
    }
  }

  handleCityItemClick = city => () => {
    const { onZones } = this.props
    const { page, perPage } = this.state
    const params = {
      city_id: city.id,
      page,
      per_page: perPage,
    }

    this.setState({
      zonesLoading: true,
      selectedCityId: Number(city.id),
    })
    onZones(params)
      .then(() => this.setState({ zonesLoading: false }))
  }

  handleZoneItemClick = zone => () => {
    const { onCoordinates } = this
    const { selectedLocationItems } = this.state
    if (!isEmpty(filter(selectedLocationItems, item => item.id === zone.id))) {
      return true
    }

    this.setState({
      selectedZoneId: Number(zone.id),
    })

    onCoordinates(zone.description)
    return this.setState(prevState => ({
      selectedLocationItems: [
        ...prevState.selectedLocationItems,
        {
          ...zone,
        },
      ],
      selectedLocation: zone,
    }))
  }

  handleLocationRemoveClick = location => () => {
    const { selectedLocationItems } = this.state
    this.setState({
      selectedLocationItems: filter(selectedLocationItems, item => item.id !== location.id),
    })
  }

  handleAudienceRoles = (setFieldValue, index) => (event) => {
    const { roles } = this.state
    const newRoles = cloneDeep(roles)
    newRoles[index].checked = event.target.checked
    this.setState({ roles: newRoles })
    setFieldValue('audience_roles', reduce(newRoles, (acc, role) => {
      if (role.checked) acc.push(role.value)
      return acc
    }, []))
  }

  getEstimatedImpression = () => {
    const { selectedLocationItems, selectedDateRangeType } = this.state
    const estimatedImpression = reduce(
      selectedLocationItems,
      (acc, cur) => (acc + cur.estimated_impression),
      0,
    )

    if (Number(selectedDateRangeType) === 2) {
      return (estimatedImpression * Number(selectedDateRangeType / 2))
    }

    return (estimatedImpression * Number(selectedDateRangeType - 2))
  }

  getRegionPrice = () => {
    const { selectedLocationItems, selectedDateRangeType } = this.state
    const regionPrice = reduce(selectedLocationItems, (acc, cur) => (acc + cur.price), 0)
    if (Number(selectedDateRangeType) === 2) {
      return (regionPrice * Number(selectedDateRangeType / 2))
    }

    return (regionPrice * Number(selectedDateRangeType - 2))
  }

  renderRegionTypeField = () => {
    const {
      getAdPackageType,
      handleRegionItemClick,
      handleRegionChange,
      handleCityItemClick,
      handleZoneItemClick,
      isAdmin,
    } = this
    const {
      classes, cities, regions, zones,
    } = this.props
    const {
      selectedRegionType,
      selectedRegionId,
      selectedLocationItems,
      selectedCityId,
      selectedZoneId,
      zonesLoading,
      citiesLoading,
    } = this.state
    if (getAdPackageType() === 'region') {
      return (
        <Row>
          <Col>
            <List component="nav" className={classes.regionList}>
              <ListItem className={classes.listItem}>
                <ListItemText primary="특별시/광역시/도" />
              </ListItem>
              {map(regions, item => (
                <ListItem
                  key={item.id}
                  button
                  selected={selectedRegionId === item.id}
                  onClick={handleRegionItemClick(item)}
                  className={classes.listItem}
                >
                  <ListItemText primary={item.name} />
                </ListItem>
              ))}
            </List>
          </Col>
          <Col>
            {citiesLoading
              ? (
                <List component="nav" className={classes.regionList}>
                  <CircularUnderLoad styles={classes.progress} />
                </List>
              )
              : !isEmpty(cities)
            && (
            <List component="nav" className={classes.regionList}>
              <ListItem className={classes.listItem}>
                <ListItemText primary="시/군/구" />
              </ListItem>
              {map(cities, item => (
                <ListItem
                  key={item.id}
                  button
                  selected={selectedCityId === item.id}
                  onClick={handleCityItemClick(item)}
                  className={classes.listItem}
                >
                  <ListItemText primary={isEmpty(item.name) ? item.description === '세종특별자치시' && '세종시' : item.name} />
                </ListItem>
              ))}
            </List>
            )
          }
          </Col>
          <Col>
            {zonesLoading
              ? (
                <List component="nav" className={classes.regionList}>
                  <CircularUnderLoad styles={classes.progress} />
                </List>
              )
              : !isEmpty(zones)
            && (
            <List component="nav" className={classes.regionList}>
              <ListItem className={classes.listItem}>
                <ListItemText primary="읍/면/동" />
              </ListItem>
              {map(zones, item => (
                item.price > 0
                  ? (
                    <ListItem
                      key={item.id}
                      button
                      selected={selectedZoneId === item.id}
                      onClick={handleZoneItemClick(item)}
                      className={classes.listItem}
                    >
                      <ListItemText primary={item.name} />
                    </ListItem>
                  )
                  : (
                    <ListItem
                      key={item.id}
                    >
                      <ListItemText
                        primary={item.name}
                        classes={{ root: classes.disabledText }}
                        disableTypography
                        className={classes.listItem}
                      />
                    </ListItem>
                  )
              ))}
            </List>
            )
          }
          </Col>
        </Row>
      )
    }

    return (
      <>
        {!isAdmin()
          ? (
            <>
              <div style={{
                paddingTop: 'calc(0.375rem + 1px)',
                paddingBottom: 'calc(0.375rem + 1px)',
              }}
              >
                {isEmpty(selectedLocationItems)
                  ? '전국'
                  : map(selectedLocationItems, item => item.name).join(', ')
            }
              </div>
              <div className={classes.subLabel} style={{ paddingTop: '6px' }}>
                타겟 지역 설정을 원하시면 별도 문의바랍니다.
              </div>
            </>
          )
          : (
            <>
              <RadioGroup
                aria-label="regionType"
                name="regionType"
                className={classes.group}
                value={selectedRegionType}
                onChange={handleRegionChange}
                row
              >
                <FormControlLabel
                  value="country"
                  control={<Radio color="primary" />}
                  label="전국"
                  labelPlacement="end"
                  disabled={(!isAdmin() && (getAdPackageType() !== 'region'))}
                />
                <FormControlLabel
                  value="region"
                  control={<Radio color="primary" />}
                  label="특정지역"
                  labelPlacement="end"
                  disabled={(!isAdmin() && (getAdPackageType() !== 'region'))}
                />
              </RadioGroup>
              {selectedRegionType === 'region'
              && (
              <List component="nav" className={classes.regionList}>
                <ListItem className={classes.listItem}>
                  <ListItemText primary="특별시/광역시/도" />
                </ListItem>
                {map(regions, (item, index) => {
                  const selectedIdx = !isEmpty(selectedLocationItems) ? findIndex(selectedLocationItems, ['id', item.id]) : -1
                  return (
                    <ListItem
                      key={index}
                      button
                      selected={selectedIdx !== -1}
                      onClick={handleRegionItemClick(item, selectedIdx)}
                      className={classes.listItem}
                    >
                      <ListItemText primary={item.name} />
                    </ListItem>
                  )
                })}
              </List>
              )
            }
            </>
          )
        }
      </>
    )
  }

  handleBillingChange = (event) => {
    this.setState({ selectedBillingType: event.target.value })
  }

  handleBudgetChange = (event) => {
    this.setState({ budget: event.target.value })
  }

  renderBillingType = () => {
    const { classes } = this.props
    const { selectedBillingType } = this.state
    const {
      renderAdProductsTypeField,
      findAdPackageType,
      getAdPackageType,
      handleBillingChange,
    } = this

    return (
      <Col sm={7}>
        <RadioGroup
          aria-label="billingType"
          name="billingType"
          className={classes.group}
          value={selectedBillingType}
          onChange={handleBillingChange}
          row
        >
          {getAdPackageType() === 'region'
            ? (
              <FormControlLabel
                value="cpp"
                control={<Radio color="primary" />}
                label="CPP"
                labelPlacement="end"
              />
            )
            : (
              <FormControlLabel
                value="cpm"
                control={<Radio color="primary" />}
                label="CPM"
                labelPlacement="end"
              />
            )
          }
        </RadioGroup>

        {selectedBillingType === 'cpm'
          && map(findAdPackageType().ad_products, (item, index) => (
            renderAdProductsTypeField(item, index)
          ))
        }
      </Col>
    )
  }

  renderAdProductsTypeRateText = (item, index) => {
    const { findAdPackageType } = this
    const { classes } = this.props
    const { budget } = this.state

    let sumImpressionRate = 0
    const { ad_products: adProducts } = findAdPackageType()

    forEach(adProducts, (product) => {
      sumImpressionRate += product.impression_rate
    })

    if (item.view_template === 'post' && item.product_type === 'video') {
      return (
        <div key={index} style={{ marginBottom: '6px' }}>
          <span className={classes.productFieldLabel}>
            게시글 타입(동영상) 단가
          </span>
          <span className={classes.productValue}>
            {`${getToCommas(round(((item.impression_rate / sumImpressionRate) * budget)))}원`}
          </span>
        </div>
      )
    }

    if (item.view_template === 'post' && item.product_type === 'image') {
      return (
        <div key={index} style={{ marginBottom: '6px' }}>
          <span className={classes.productFieldLabel}>
            게시글 타입(이미지) 단가
          </span>
          <span className={classes.productValue}>
            {`${getToCommas(round(((item.impression_rate / sumImpressionRate) * budget)))}원`}
          </span>
        </div>
      )
    }

    if (item.view_template === 'notice') {
      return (
        <div key={index} style={{ marginBottom: '6px' }}>
          <span className={classes.productFieldLabel}>
            학급 공지 타입 단가
          </span>
          <span className={classes.productValue}>
            {`${getToCommas(round(((item.impression_rate / sumImpressionRate) * budget)))}원`}
          </span>
        </div>
      )
    }

    if (item.view_template === 'comment') {
      return (
        <div key={index} style={{ marginBottom: '6px' }}>
          <span className={classes.productFieldLabel}>
            댓글 타입 단가
          </span>
          <span className={classes.productValue}>
            {`${getToCommas(round(((item.impression_rate / sumImpressionRate) * budget)))}원`}
          </span>
        </div>
      )
    }

    return true
  }

  renderAdProductsTypeField = (item, index) => {
    const { classes } = this.props

    if (item.view_template === 'post' && item.product_type === 'video') {
      return (
        <Row key={index} style={{ marginBottom: '6px' }}>
          <Col sm={12}>
            <Label className={classes.productFieldLabel}>
              게시글 타입(동영상) 단가
            </Label>
            <Input
              type="text"
              className={classes.productField}
              value={getToCommas(item.cpm) || 0}
              disabled
            />
            원
          </Col>
        </Row>
      )
    }

    if (item.view_template === 'post' && item.product_type === 'image') {
      return (
        <Row key={index} style={{ marginBottom: '6px' }}>
          <Col sm={12}>
            <Label className={classes.productFieldLabel}>
              게시글 타입(이미지) 단가
            </Label>
            <Input
              type="text"
              className={classes.productField}
              value={getToCommas(item.cpm) || 0}
              disabled
            />
            원
          </Col>
        </Row>
      )
    }

    if (item.view_template === 'notice') {
      return (
        <Row key={index} style={{ marginBottom: '6px' }}>
          <Col sm={12}>
            <Label className={classes.productFieldLabel}>
              학급 공지 타입 단가
            </Label>
            <Input
              type="text"
              className={classes.productField}
              value={getToCommas(item.cpm) || 0}
              disabled
            />
            원
          </Col>
        </Row>
      )
    }

    if (item.view_template === 'comment') {
      return (
        <Row key={index} style={{ marginBottom: '6px' }}>
          <Col sm={12}>
            <Label className={classes.productFieldLabel}>
              댓글 타입 단가
            </Label>
            <Input
              type="text"
              className={classes.productField}
              value={getToCommas(item.cpm) || 0}
              disabled
            />
            원
          </Col>
        </Row>
      )
    }

    return true
  }

  exposeView = (item) => {
    if (item.view_template === 'post' && item.product_type === 'video') {
      return 'newsfeed'
    }

    if (item.view_template === 'post' && item.product_type === 'image') {
      return 'newsfeed'
    }

    if (item.view_template === 'notice' && item.product_type === 'text') {
      return 'noticeboard'
    }

    if (item.view_template === 'comment' && item.product_type === 'image') {
      return ''
    }

    return ''
  }

  getValidationForm = () => {
    const { getAdPackageType } = this
    if (getAdPackageType() !== 'region') {
      return {
        budget: Yup.number()
          .min('5000000', '5,000,000원 이상의 금액을 입력하세요.')
          .max('2000000000', '2,000,000,000원 이하의 금액을 입력하세요.')
          .required('예산은 필수 입력 값입니다.'),
      }
    }
    return {}
  }

  handleNextButtonClick = () => {
    /* eslint-disable no-alert */
    if (!window.confirm('적용버튼을 누르지 않으면 저장되지 않습니다. 다음단계로 이동하시겠습니까?')) {
      return true
    }
    /* eslint-disable */

    this.unblock()
    const { history } = this.props
    const { getCampaignId, getAdPackageType } = this

    const params = {
      campaign_id: getCampaignId(),
      form_type: 'update',
      ad_package_type: getAdPackageType(),
    }

    return history.push({
      pathname: '/ad_form',
      search: queryString.stringify(params),
    })
  }

  isAdmin = () => JSON.parse(getItem('isAdmin'))

  getLocationItemsType = () => {
    const { selectedLocationItems } = this.state
    const { getAdPackageType } = this

    return map(selectedLocationItems, item => {
      if (getAdPackageType() === 'region') {
        return {
          type: 'zone_id',
          ...item,
        }
      }

      return {
        type: 'region_id',
        ...item,
      }
    })
  }

  handleAllAudienceChange = (e) => {
    this.setState({ checkedAllAudience: e.target.checked })
  }

  render() {
    const {
      classes, history, campaign, onAdSetSubmit,
      adSet, adSets, adLocations, onAdSetUpdate,
      onCampaignsUpdate,
    } = this.props
    const {
      isOpenModal, packageType, marks, handleStyle,
      trackStyle, activeDotStyle, startDate, endDate,
      roles,
      selectedRegionType,
      budget,
      selectedDateRangeType,
      gradeFrom,
      gradeTo,
      selectedLocationItems,
      center,
      path,
      selectedLocation,
      selectedPacakageType,
      loading,
      checkedAllAudience,
    } = this.state
    const {
      onGradeChange,
      renderAdPackageRadioGroup,
      handleEndDateChange,
      handleStartDateChange,
      findAdPackageType,
      getGuaranteedExposure,
      getAdPackageType,
      renderRegionTypeField,
      renderBillingType,
      handleMinStartDate,
      handleMinEndDate,
      handleMaxEndDate,
      handleDateRangeChange,
      handleLocationRemoveClick,
      handleAudienceRoles,
      renderAdProductsTypeRateText,
      getAdFormType,
      exposeView,
      handleNextButtonClick,
      getEstimatedImpression,
      getRegionPrice,
      isAdmin,
      getLocationItemsType,
      getValidationForm,
      handleAllAudienceChange,
    } = this

    return (
      <Layout pageTitle={packageType[getAdPackageType()]}>
        {loading ? <CircularUnderLoad styles={classes.progress} /> :
          <>
            <Stepper activeStep={1} />
            <Row className={classes.container}>
              <Col sm={9}>
                <Formik
                  initialValues={{
                    campaign_id: '',
                    advertiser_id: '',
                    title: campaign.title,
                    channel: 'mobile',
                    start_date: !isNil(adSet && adSet.start_date) ? adSet.start_date : '',
                    end_date: !isNil(adSet && adSet.end_date) ? adSet.end_date : '',
                    ad_package_id: !isNil(campaign.ad_package && campaign.ad_package.id) ? campaign.ad_package.id : '',
                    package_name: '',
                    audience_grade_to: !isNil(adSet && adSet.audience_grade_to) ? adSet.audience_grade_to : 13,
                    audience_grade_from	: !isNil(adSet && adSet.audience_grade_from) ? adSet.audience_grade_from : 0,
                    audience_roles: !isNil(adSet && adSet.audience_roles) ? adSet.audience_roles : ['parent'],
                    is_sponsored: true,
                    budget: !isNil(campaign && campaign.budget) ? campaign.budget : '',
                    action_url: '',
                    action_type: 'outer_browser',
                    country_code: 'KR',
                    audience_language: 'ko',
                    expose_view: '',
                    view_template: '',
                    guaranteed_expose_view: '',
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    if (getRegionPrice() === 0 && getAdPackageType() === 'region') {
                      return setSubmitting(false)
                    }
                    this.setState({ loading: true })
                    this.unblock()
                    const campaignData = {
                      id: campaign.id,
                      ad_package_id: selectedPacakageType,
                      advertiser_id: campaign.advertiser.id,
                      title: campaign.title,
                      budget: getAdPackageType() === 'region' ? getRegionPrice() : values.budget,
                      guaranteed_expose_view: getAdPackageType() === 'region' ?  getEstimatedImpression() : getGuaranteedExposure(),
                      package_name: findAdPackageType().title,
                      tag: !isEmpty(selectedLocationItems) ? map(selectedLocationItems, item => item.name).join() : '전국',
                    }

                    const adSetData = {
                      campaign_id: campaign.id,
                      channel: values.channel,
                      country_code: values.country_code,
                      audience_language: values.audience_language,
                      audience_grade_from: values.audience_grade_from,
                      audience_grade_to: values.audience_grade_to,
                      audience_roles: values.audience_roles,
                      action_type: values.action_type,
                      action_url: values.action_url,
                      cpm: campaign.cpm || 1,
                      start_date: values.start_date,
                      end_date: values.end_date,
                      is_sponsored: values.is_sponsored,
                      cpm: 9000,
                    }

                    if (checkedAllAudience) {
                      adSetData.audience_grade_from = -1
                      adSetData.audience_grade_to = 13
                    }

                    return Promise.resolve()
                      .then(() => onCampaignsUpdate(campaignData))
                      .then(() => {
                        if (
                          (getAdFormType() === 'update' || !isEmpty(campaign.ad_package))
                          && !isEmpty(adLocations)
                        ) {
                          return Promise.all(map(adLocations, item => deleteAdLocation(item.id)))
                        }
                        return true
                      })
                      .then(() => {
                        if (getAdFormType() === 'update' || !isEmpty(campaign.ad_package)) {
                          return Promise.all(map(adSets,
                            adSet => onAdSetUpdate({
                              adSet,
                              adSetData,
                              selectedRegionType,
                              setAdLocationData : getLocationItemsType(),
                              packageType : getAdPackageType(),
                            })
                          ))
                        }

                        return Promise.all(map(findAdPackageType().ad_products,
                          item => onAdSetSubmit({
                            selectedRegionType,
                            adLocationData : getLocationItemsType(),
                            packageType : getAdPackageType(),
                            adSetData : {
                              ...adSetData,
                              view_template: item.view_template,
                              expose_view: exposeView(item),
                            },
                          })
                        ))

                      }).then(() => {
                        setSubmitting(false)
                        this.setState({ loading: false })
                        const params = {
                          campaign_id: campaign.id,
                          form_type: getAdFormType(),
                          ad_package_type: getAdPackageType(),
                        }
                        return history.replace({
                          pathname: '/ad_form',
                          search: queryString.stringify(params),
                        })
                      })
                  }}
                  validationSchema={Yup.lazy(values => {
                    return Yup.object().shape({
                      audience_grade_from: Yup.number()
                        .required('학년은 필수 값입니다.'),
                      audience_grade_to: Yup.number()
                        .required('학년은 필수 값입니다.'),
                      start_date: Yup.string()
                        .test(
                          'start_date',
                          '시작일 설정을 확인해주세요.',
                          function (st) {
                            return moment(st).diff(moment().startOf('day'), 'days') >= 0
                          }
                        )
                        .required('시작일 설정은 필수 입니다.'),
                      end_date: Yup.string()
                        .test(
                          'end_date',
                          '종료일 설정을 확인해주세요.',
                          function (et) {
                            return moment(et).diff(moment(values.start_date), 'days') >= 0
                          }
                        )
                        .required('종료일 설정은 필수 입니다.'),
                      audience_roles: Yup.array()
                        .required('타겟 대상은 1개 이상 선택 필수 입니다.'),
                      ...getValidationForm()
                    })
                  })}
                >
                  {props => {
                    const {
                      values,
                      touched,
                      errors,
                      isSubmitting,
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      setFieldValue,
                    } = props
                    return (
                      <div>
                        <Form onSubmit={handleSubmit}>
                          <Paper className={classes.paper}>
                            <FormGroup row>
                              <Label sm={2}>캠페인 이름</Label>
                              <Col sm={6}>
                                <Input
                                  type="text"
                                  name="title"
                                  className={
                                    errors.title && touched.title ? classes.fieldError : classes.field
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.title}
                                  disabled
                                />
                              </Col>
                            </FormGroup>
                          </Paper>

                          <Paper className={classes.paper}>
                            <FormGroup row>
                              <Label sm={2}>상품 선택</Label>
                              <Col sm={10}>
                                {renderAdPackageRadioGroup(setFieldValue)}
                              </Col>
                            </FormGroup>
                          </Paper>

                          <Paper className={classes.paper}>
                            <FormGroup row>
                              <Label sm={2}>
                                <Typography variant="h5" gutterBottom>
                                  타겟
                                </Typography>
                              </Label>
                              <Col sm={10} />
                              <Label sm={2}>타겟 대상</Label>
                              <Col sm={10}>
                                <FormGroup style={{marginBottom:0}}>
                                  {map(roles, (item, index) => (
                                    <FormControlLabel
                                      key={item.value}
                                      control={
                                        <Checkbox
                                          color="primary"
                                          checked={item.checked}
                                          value={item.value}
                                          onChange={handleAudienceRoles(setFieldValue, index)}
                                          disabled={!isAdmin()}
                                        />
                                      }
                                      label={item.label}
                                    />
                                  ))}
                                </FormGroup>
                                {errors.audience_roles &&
                                  <div className={classes.invalidFeedback}>
                                    {errors.audience_roles}
                                  </div>
                                }
                              </Col>
                              <Label sm={2}>타겟 학년</Label>
                              <Col sm={8}>
                                <FormGroup style={{ marginBottom:0 }}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        color="primary"
                                        checked={checkedAllAudience}
                                        onChange={handleAllAudienceChange}
                                        disabled={!isAdmin()}
                                      />
                                    }
                                    label={'전체 학년'}
                                  />
                                </FormGroup>
                                {!checkedAllAudience &&
                                  <Range
                                    marks={marks}
                                    className={classes.rangeGrade}
                                    min={0}
                                    max={13}
                                    handleStyle={handleStyle}
                                    trackStyle={trackStyle}
                                    onChange={onGradeChange(setFieldValue)}
                                    activeDotStyle={activeDotStyle}
                                    defaultValue={[gradeFrom, gradeTo]}
                                    disabled={!isAdmin()}
                                  />
                                }
                                {!isAdmin() &&
                                  <div style={{ paddingTop: '6px' }}>
                                    <span className={classes.subLabel}>타겟 대상 선택 또는 학년 설정을 원하시면 별도 문의바랍니다.</span>
                                  </div>
                                }
                                {(errors.audience_grade_from && touched.audience_grade_from) &&
                                  <div className={classes.invalidFeedback}>
                                    {errors.audience_grade_from}
                                  </div>
                                }
                              </Col>
                              <Col sm={2} />
                            </FormGroup>

                            <FormGroup row>
                              <Label sm={2}>타겟 지역</Label>
                              <Col sm={10}>
                                {getAdPackageType() === 'region' &&
                                  <>
                                    {!isEmpty(selectedLocation) &&
                                      <div style={{ paddingTop: '14px' }}>
                                        <div className={classes.itemLabel}>
                                          현재 지도 위치
                                        </div>
                                        <div className={classes.inlineLabel}>
                                          {selectedLocation.description}
                                        </div>
                                      </div>
                                    }
                                    <PolygonMap
                                      center={center}
                                      path={path}
                                      googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyA0_YXjNhuCq3NZKM_T1BUvqxtCQPYlZ2M"
                                      loadingElement={
                                        <div style={{ height: '100%' }}>
                                          <CircularUnderLoad styles={classes.progress} />
                                        </div>
                                      }
                                      containerElement={<div className={classes.map} />}
                                      mapElement={<div style={{ height: '100%' }} />}
                                    />
                                  </>
                                }
                                {renderRegionTypeField()}
                                {getAdPackageType() === 'region' &&
                                  <>
                                    <div className={classNames(classes.itemLabel, classes.selectedLocationLabel)}>
                                      설정지역
                                    </div>
                                    {!isEmpty(selectedLocationItems) ?
                                      <div className={classes.selectedLocationLabel} style={{ marginBottom: '14px' }}>
                                        {map(selectedLocationItems, (item, index) => (
                                          item.id &&
                                            <span className={classes.locationLabel} key={index}>
                                              {item.description} <ClearIcon onClick={handleLocationRemoveClick(item)} style={{ verticalAlign: 'top', cursor: 'pointer' }} />
                                            </span>
                                        ))}
                                      </div> :
                                      <div className={classes.emptyLocationLabel}>
                                        홍보하고자 하시는 지역을 선택해주세요.
                                      </div>
                                    }
                                  </>
                                }
                              </Col>
                            </FormGroup>
                          </Paper>

                          <Paper className={classes.paper}>
                            <Typography variant="h5" gutterBottom>
                              예산 및 일정 설정
                            </Typography>
                            <FormGroup row>
                              <Label sm={2}>
                                과금방식
                              </Label>
                              {renderBillingType()}
                            </FormGroup>

                            {getAdPackageType() === 'impression' &&
                              <FormGroup row>
                                <Label sm={2}>
                                  전체 예산
                                </Label>
                                <Col sm={5}>
                                  <Input
                                    type="number"
                                    name="budget"
                                    className={
                                      errors.budget && touched.budget ? classes.fieldError : classes.field
                                    }
                                    value={values.budget}
                                    onChange={e => {
                                      this.setState({ budget: e.target.value })
                                      handleChange(e)
                                    }}
                                    onBlur={handleBlur}
                                    placeholder="예산을 입력해주세요"
                                  />
                                  {(errors.budget && touched.budget) &&
                                    <div className={classes.invalidFeedback}>
                                      {errors.budget}
                                    </div>
                                  }
                                  <Row>
                                    <Col sm={12}>
                                      {map(findAdPackageType().ad_products, (item, index) => (
                                        (item.impression_rate !== 0) && renderAdProductsTypeRateText(item, index)
                                      ))}
                                    </Col>
                                  </Row>
                                </Col>
                              </FormGroup>
                            }

                            <FormGroup row>
                              <Label sm={2}>
                                집행 기간
                              </Label>
                              <Col sm={6}>
                                {getAdPackageType() === 'region' &&
                                  <RadioGroup
                                    aria-label="dateRangeType"
                                    name="dateRangeType"
                                    className={classes.group}
                                    value={selectedDateRangeType}
                                    onChange={handleDateRangeChange(setFieldValue)}
                                    row
                                  >
                                    <FormControlLabel
                                      value="2"
                                      control={<Radio color="primary" />}
                                      label="2주"
                                      labelPlacement="end"
                                    />
                                    <FormControlLabel
                                      value="4"
                                      control={<Radio color="primary" />}
                                      label="4주"
                                      labelPlacement="end"
                                    />
                                  </RadioGroup>
                                }
                                <InlineDatePicker
                                  label="시작일"
                                  className={classes.datePicker}
                                  minDate={handleMinStartDate()}
                                  onlyCalendar
                                  value={startDate}
                                  onChange={handleStartDateChange(setFieldValue)}
                                  format="YYYY-MM-DD"
                                  helperText={null}
                                />
                                {errors.start_date &&
                                  <div className={classes.invalidFeedback}>
                                    {errors.start_date}
                                  </div>
                                }
                                <InlineDatePicker
                                  label="종료일"
                                  className={classes.datePicker}
                                  minDate={handleMinEndDate()}
                                  maxDate={handleMaxEndDate()}
                                  onlyCalendar
                                  disabled={getAdPackageType() === 'region' && true}
                                  value={endDate}
                                  onChange={handleEndDateChange(setFieldValue)}
                                  format="YYYY-MM-DD"
                                  helperText={null}
                                />
                                {errors.end_date &&
                                  <div className={classes.invalidFeedback}>
                                    {errors.end_date}
                                  </div>
                                }
                              </Col>
                            </FormGroup>

                            <FormGroup row>
                              <Label sm={2} />
                              <Col sm={10}>
                                {getAdFormType() === 'update' &&
                                  <Button
                                    className={classes.nextButton}
                                    onClick={handleNextButtonClick}
                                    variant="contained"
                                    color="primary"
                                    type="button"
                                  >
                                    다음 단계
                                  </Button>
                                }
                                <Button
                                  className={classes.submitButton}
                                  variant="outlined"
                                  color="primary"
                                  type="submit"
                                  disabled={isSubmitting}
                                >
                                  {getAdFormType() === 'create' ? '저장' : '적용'}
                                </Button>
                              </Col>
                            </FormGroup>

                          </Paper>
                        </Form>
                      </div>
                    )
                  }}
                </Formik>
              </Col>

              <Col sm={3}>
                <Paper className={classes.paper}>
                  <Typography variant="h5" gutterBottom>
                    {getAdPackageType() === 'region' ? '예상 노출 수' : '보장 노출 수'}
                  </Typography>
                  <Typography variant="h4" gutterBottom>
                    {getAdPackageType() === 'region' ?
                      getToCommas(getEstimatedImpression()) :
                      getToCommas(getGuaranteedExposure())
                    }
                  </Typography>
                  <div className={classes.itemText}>
                    소재에 따라 클릭율은 달라질 수 있습니다.
                  </div>
                  <Divider style={{ margin: '14px 0 24px 0' }} />
                  <Typography variant="h5" gutterBottom>
                    타겟
                  </Typography>
                  <Row>
                    <Col sm={4}>
                      <span className={classes.itemLabel}>
                        대상
                      </span>
                    </Col>
                    <Col sm={8}>
                      {map(roles, item => (
                        item.checked &&
                        <span className={classes.text} key={item.value}>
                          {item.label}<br />
                        </span>
                      ))}
                    </Col>
                  </Row><br />
                  <Row>
                    <Col sm={4}>
                      <span className={classes.itemLabel}>
                        학년
                      </span>
                    </Col>
                    <Col sm={8}>
                      <span className={classes.text}>
                        {get(marks[gradeFrom], 'label')} ~ {get(marks[gradeTo], 'label')}
                      </span>
                    </Col>
                  </Row><br />
                  <Row>
                    <Col sm={4}>
                      <span className={classes.itemLabel}>
                        지역
                      </span>
                    </Col>
                    <Col sm={8}>
                    {isEmpty(selectedLocationItems) ?
                      <span className={classes.text}>전국</span> :
                      <>
                        {map(selectedLocationItems, item => (
                          <span className={classes.text} key={item.id}>
                            {item.description}<br />
                          </span>
                        ))}
                      </>
                    }
                    </Col>
                  </Row>
                  <Divider style={{ margin: '14px 0 24px 0' }} />
                  <Typography variant="h5" gutterBottom>
                    상품
                  </Typography>
                  <div className={classes.text}>
                    {findAdPackageType().title || '-'}
                  </div><br/>
                  <Typography variant="h5" gutterBottom>
                    예산
                  </Typography>
                  <div className={classNames(classes.text, classes.priceText)}>
                    <span className={classNames(classes.itemLabel, classes.priceText)}>
                      {getAdPackageType() === 'region' ?
                        getToCommas(getRegionPrice()) :
                        (getToCommas(budget) || 0)
                      }
                    </span>원
                  </div>
                  <div className={classes.priceLabel}>
                    VAT가 포함되지 않은 가격이며 결제시 VAT 10%가 포함됩니다.
                  </div>
                  {(getRegionPrice() === 0 && getAdPackageType() === 'region') &&
                    <div className={classes.invalidFeedback}>
                      광고지역 및 기간 설정을 해주세요
                    </div>
                  }
                  <br/>
                  <Typography variant="h5" gutterBottom>
                    일정
                  </Typography>
                  <div className={classes.text}>
                    <span className={classes.text}>{startDate}</span> ~
                    <span className={classes.text}>{endDate}</span>
                  </div>
                </Paper>
              </Col>
            </Row>
            <EnhancedDialog
              isOpen={isOpenModal}
              isCloseButton={false}
              modalTitle=""
              handleOpen={() => {
                this.setState({ isOpenModal: false })
              }}
            >
              <DialogContentText>
                예산은 0원 이상이어야 합니다.
              </DialogContentText>
            </EnhancedDialog>
          </>
        }
      </Layout>
    )
  }
}

AdSetFormPage.propTypes = {
  adSet: PropTypes.object,
  adLocations: PropTypes.array,
  adPackages: PropTypes.array,
  cities: PropTypes.array,
  regions: PropTypes.array,
  zones: PropTypes.array,
  campaign: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  adSets: PropTypes.array.isRequired,
  onCampaign: PropTypes.func.isRequired,
  onAdPackage: PropTypes.func.isRequired,
  onRegions: PropTypes.func.isRequired,
  onAdSetSubmit: PropTypes.func.isRequired,
  onAdSetUpdate: PropTypes.func.isRequired,
  onZones: PropTypes.func.isRequired,
  onCities: PropTypes.func.isRequired,
  onAdSet: PropTypes.func.isRequired,
  onAdLocations: PropTypes.func.isRequired,
  onCampaignsUpdate: PropTypes.func.isRequired,
  onResetAdSets: PropTypes.func.isRequired,
}
AdSetFormPage.defaultProps = {
  adSet: {},
  adLocations: [],
  adPackages: [],
  cities: [],
  regions: [],
  zones: [],
}

export default compose(
  withTracker,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(AdSetFormPage)
