import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import DialogContentText from '@material-ui/core/DialogContentText'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import queryString from 'query-string'
import classNames from 'classnames'
import {
  map, head, isEmpty, find, floor, last,
} from 'lodash'
import { Input } from 'reactstrap'
import moment from 'moment'
import {
  Layout, CircularUnderLoad, Stepper, EnhancedDialog,
} from '@/shared/components'
import { CLASSTING_PAY_API_URL } from '@/shared/constants'
import { getToCommas, getItem } from '@/shared/utils'
import { PaymentInformation } from '../components'
import { fetchCampaign } from '../../campaign/actions'
import {
  fetchAdSet, fetchAds, fetchAdPackage, updateCampaigns, fetchAdLocations, resetAdList,
} from '../../editing/actions'
import {
  setAdPayment, fetchAdPayment, fetchAdPayments, resetAdPayment,
} from '../actions'

const styles = ({
  buttonGroup: {
    paddingBottom: '14px',
  },
  tableCell: {
    border: '1px solid rgba(224, 224, 224, 1)',
  },
  bottomContainer: {
    position: 'relative',
  },
  progressPosition: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  advertiserProfile: {
    margin: 10,
    width: 60,
    height: 60,
  },
  advertiserName: {
    fontSize: '24px',
    fontWeight: 'bold',
  },
  paper: {
    padding: '24px',
    margin: '24px 0 24px 0',
    position: 'relative',
  },
  divider: {
    marginTop: '24px',
    marginBottom: '24px',
  },
  label: {
    color: '#212121',
  },
  subLabel: {
    color: '#757575',
  },
  listLabel: {
    color: '#00c896',
  },
  amount: {
    color: '#17a27e',
  },
  group: {
    margin: '14px 0',
  },
  submitButton: {
    color: '#ffffff',
    width: '100%',
  },
  requestButton: {
    color: '#ffffff',
    marginLeft: '14px',
  },
  radio: {
    padding: '4px 12px 4px 12px',
  },
  panel: {
    marginTop: '18px',
    marginBottom: '18px',
  },
  panelSummary: {
    paddingTop: '18px',
    paddingBottom: '18px',
  },
  panelDetails: {
    backgroundColor: '#fafafa',
    paddingTop: '18px',
    paddingBottom: '18px',
  },
  panelContent: {
    display: 'block',
  },
  spacing: {
    paddingTop: '18px',
    paddingBottom: '6px',
  },
  preparing: {
    color: '#4a90e2',
  },
  salesCodeField: {
    marginTop: '12px',
    marginBottom: '28px',
  },
  feedbackLabel: {
    color: '#e91e63',
  },
  feedbackButton: {
    color: '#e91e63',
    marginRight: '14px',
    border: 'solid 1px #e91e63',
  },
  reasonLabel: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.54)',
    marginBottom: '14px',
  },
  reason: {
    padding: '14px',
    border: '1px solid #cccccc',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
})


const mapStateToProps = state => ({
  pending: state.editing.pending,
  campaign: state.campaign.campaignReport,
  adSet: head(state.editing.adSets),
  adList: state.editing.adList,
  user: state.user.userData,
  adPackages: state.editing.adPackages,
  payments: find(state.payment.payments, { status: 'approved' }) || last(state.payment.payments) || {},
  adLocations: state.editing.adLocations,
})

const mapDispatchToProps = dispatch => ({
  onCampaignMount: campaignId => dispatch(fetchCampaign(campaignId)),
  onAdSetMount: campaignId => dispatch(fetchAdSet(campaignId)),
  onAds: params => dispatch(fetchAds(params)),
  onAdPaymentSubmit: data => dispatch(setAdPayment(data)),
  onAdPayment: paymentId => dispatch(fetchAdPayment(paymentId)),
  onAdPackage: adPackageType => dispatch(fetchAdPackage({ package_type: adPackageType })),
  onAdPayments: params => dispatch(fetchAdPayments(params)),
  onCampaignsUpdate: data => dispatch(updateCampaigns(data)),
  onAdLocations: adSetId => dispatch(fetchAdLocations(adSetId)),
  onResetAdList: () => dispatch(resetAdList()),
  onResetAdPayment: () => dispatch(resetAdPayment()),
})

window.onPageLoad = (params) => {
  window.location.href = `/payment_result?${queryString.stringify(params)}`
}

class PaymentFormPage extends Component {
  static defaultProps = {
    adSet: {},
  }

  state = {
    loading: false,
    selectedPayType: 'card',
    expanded: null,
    roles: {
      parent: '학부모',
      student: '학생',
      teacher: '선생님',
    },
    grades: {
      0: '유치원',
      1: '초1',
      2: '초2',
      3: '초3',
      4: '초4',
      5: '초5',
      6: '초6',
      7: '중1',
      8: '중2',
      9: '중3',
      10: '고1',
      11: '고2',
      12: '고3',
    },
    salesCode: '',
    isOpenModal: false,
  }

  componentDidMount() {
    const { getCampaignId, getFormType } = this
    const {
      onCampaignMount,
      onAdSetMount,
      onAds,
      onAdPackage,
      onAdPayments,
      onAdLocations,
      onResetAdList,
      onResetAdPayment,
    } = this.props

    onResetAdList()
    this.setState({ loading: true })
    onCampaignMount(getCampaignId())
      .then((campaign) => {
        this.setState({ pacakageTypeId: campaign.ad_package.id })
        if (getFormType() === 'update') {
          onResetAdPayment()
          return onAdPayments({ campaign_id: campaign.id })
        }

        return true
      })
      .then(() => onAdPackage())
      .then(() => onAdSetMount(getCampaignId()))
      .then((adSets) => {
        onAdLocations(head(adSets).id)
        return Promise.all(
          map(adSets, (item) => {
            const params = {
              advertisement_set_id: item.id,
            }
            return onAds(params)
          }),
        )
      })
      .then(() => this.setState({ loading: false }))
  }

  getCampaignId = () => {
    const { location } = this.props
    return queryString.parse(location.search).campaign_id
  }

  getFormType = () => {
    const { location } = this.props
    return queryString.parse(location.search).form_type || 'create'
  }

  getPackageType = () => {
    const { campaign } = this.props
    return campaign.ad_package && campaign.ad_package.package_type
  }

  findAdPackageType = () => {
    const { adPackages } = this.props
    const { pacakageTypeId } = this.state

    return find(adPackages, { id: Number(pacakageTypeId) }) || {}
  }

  handlePayTypeChange = (event) => {
    this.setState({ selectedPayType: event.target.value })
  }

  handlePaymentClick = () => {
    const { getCampaignId, getPackageType, getPayAmount } = this
    const { selectedPayType, salesCode } = this.state
    const {
      user, campaign, onAdPaymentSubmit, history, onCampaignsUpdate,
    } = this.props

    const order = {
      campaign_id: getCampaignId(),
      quantity: 1,
      pay_method: selectedPayType,
      payer_name: user.name,
      payer_tel: user.phone,
      vendor: 'classting_pay',
    }

    onAdPaymentSubmit(order)
      .then((payment) => {
        const params = {
          campaign_id: getCampaignId(),
          payment_id: payment.id,
        }

        if (selectedPayType !== 'transfer') {
          window.open('about:blank', 'viewer', 'width=660, height=520, scrollbars=no, resizable=no')
          const form = document.pay
          form.action = `${CLASSTING_PAY_API_URL}/payments`
          form.target = 'viewer'
          form.method = 'post'
          form.elements.token.value = getItem('token')
          form.elements.product_name.value = campaign.title
          form.elements.pay_amount.value = getPayAmount(campaign.budget)
          form.elements.service_name.value = 'classting_ad'
          form.elements.user_id.value = user.id
          form.elements.user_name.value = user.name
          form.elements.user_email.value = user.email
          form.elements.user_tel.value = user.phone
          form.elements.pay_method.value = selectedPayType
          form.elements.service_payment_id.value = payment.id
          form.elements.success_url.value = `${window.location.origin}/payment_response?page_type=success&${queryString.stringify(params)}`
          form.elements.fail_url.value = `${window.location.origin}/payment_response?page_type=failure&${queryString.stringify(params)}`
          return form.submit()
        }

        return params
      })
      .then((params) => {
        if (getPackageType() === 'region' && !isEmpty(salesCode)) {
          const campaignData = {
            id: getCampaignId(),
            sales_code: salesCode,
          }
          return onCampaignsUpdate(campaignData)
            .then(() => params)
        }

        return params
      })
      .then((params) => {
        if (selectedPayType === 'transfer') {
          return history.replace({
            pathname: 'payment_result',
            search: queryString.stringify({ ...params, page_type: 'success' }),
          })
        }

        return true
      })
  }

  handleSalesCode = (event) => {
    this.setState({ salesCode: event.target.value })
  }

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    })
  }

  getPayAmount = () => {
    const { campaign } = this.props
    const valueAddedTaxRate = 10
    return floor(campaign.budget * (1 + (valueAddedTaxRate / 100)))
  }

  getMethodType = () => {
    const { payments } = this.props
    return payments && payments.pay_method
  }

  getMethodTypeLabel = () => {
    const { getMethodType } = this

    switch (getMethodType()) {
      case 'transfer':
        return '무통장 입금'
      case 'card':
        return '카드 결제'
      case 'vbank':
        return '가상 계좌'
      default:
        return ''
    }
  }

  renderPaymentStatus = () => {
    const { payments } = this.props
    const { getMethodTypeLabel } = this

    if (payments && payments.status === 'approved') {
      return (
        <>
          <Typography variant="title" color="default">
            결제 정보
          </Typography>
          <Typography variant="subtitle1" color="primary">
            {getMethodTypeLabel()}
          </Typography>
          <Typography variant="title" color="default">
            결제 현황
          </Typography>
          <Typography variant="subtitle1" color="secondary">
            결제 완료
          </Typography>
        </>
      )
    }

    if (
      payments && payments.pay_method === 'vbank'
      && payments && payments.status === 'preparing'
      && !isEmpty(payments && payments.vbank_expire_at)
      && moment(payments && payments.vbank_expire_at).format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD')
    ) {
      return (
        <>
          <PaymentInformation
            payment={payments}
            methodType={getMethodTypeLabel()}
          />
          <Typography variant="title" color="default">
            결제 현황
          </Typography>
          <Typography variant="subtitle1" color="secondary">
            입금 대기
          </Typography>
        </>
      )
    }

    if (payments && payments.status === 'preparing' && payments && payments.pay_method === 'transfer') {
      return (
        <>
          <PaymentInformation
            payment={payments}
            methodType={getMethodTypeLabel()}
          />
          <Typography variant="title" color="default">
            결제 현황
          </Typography>
          <Typography variant="subtitle1" color="secondary">
            입금 대기
          </Typography>
        </>
      )
    }

    if (
      payments && payments.pay_method === 'vbank'
      && payments && payments.status === 'preparing'
      && !isEmpty(payments && payments.vbank_expire_at)
      && moment(payments && payments.vbank_expire_at).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD')
    ) {
      return (
        <Typography variant="title" color="default">
          결제 기한 만료
        </Typography>
      )
    }

    if (payments && payments.status === 'refunded') {
      return (
        <Typography variant="title" color="error">
          환불
        </Typography>
      )
    }

    if (payments && payments.status === 'preparing' && payments && payments.pay_method === 'card') {
      return (
        <>
          <Typography variant="title" color="default">
            결제 현황
          </Typography>
          <Typography variant="subtitle1" color="error">
            카드결제 오류
          </Typography>
        </>
      )
    }

    return (
      <Typography variant="subtitle1" color="primary">
        결제현황을 알 수 없으니 관리자에게 문의 부탁 드립니다.
      </Typography>
    )
  }

  handleFeedbackClick = () => {
    this.setState({ isOpenModal: true })
  }

  handleAdFormClick = () => {
    const { history } = this.props
    const { campaign } = this.props
    const params = {
      campaign_id: campaign.id,
      form_type: 'update',
      ad_package_type: campaign.ad_package && campaign.ad_package.package_type,
    }
    history.push({
      pathname: 'ad_form',
      search: queryString.stringify(params),
    })
  }

  renderCampaignStatus = () => {
    const { campaign, classes } = this.props
    const { handleFeedbackClick, handleAdFormClick } = this

    if (campaign && campaign.status === 'approved') {
      return (
        <>
          <Typography variant="subtitle1" color="primary">
            승인
          </Typography>
          <Typography variant="subtitle1" color="default">
            클래스팅에서 캠페인을 승인했습니다.
          </Typography>
        </>
      )
    }

    if (campaign && campaign.status === 'rejected') {
      return (
        <>
          <Typography variant="subtitle1" color="error" className={classes.feedbackLabel}>
            수정 요청
          </Typography>
          <Typography variant="subtitle1" color="default" gutterBottom>
            클래스팅에서 견적을 검토 중입니다.
          </Typography>
          <Button
            className={classes.feedbackButton}
            variant="outlined"
            type="button"
            onClick={handleFeedbackClick}
          >
            피드백 보기
          </Button>
          <Button
            variant="outlined"
            color="primary"
            type="button"
            onClick={handleAdFormClick}
          >
            광고소재 수정
          </Button>
        </>
      )
    }

    if (campaign && campaign.status === 'under_review') {
      return (
        <>
          <Typography variant="subtitle1" color="primary">
            심사 중
          </Typography>
          <Typography variant="subtitle1" color="default">
            클래스팅에서 견적을 검토 중입니다.
          </Typography>
        </>
      )
    }

    return ''
  }

  isForm = () => {
    const { payments } = this.props

    if (payments && payments.status === 'approved') {
      return true
    }

    if (
      payments && payments.pay_method === 'card'
      && payments && payments.status === 'preparing'
    ) {
      return false
    }

    if (
      payments && payments.pay_method === 'vbank'
      && payments && payments.status === 'preparing'
      && !isEmpty(payments && payments.vbank_number)
    ) {
      return true
    }

    if (
      payments && payments.pay_method === 'transfer'
      && payments && payments.status === 'preparing'
    ) {
      return true
    }

    return false
  }

  renderAdProductLabel = (item) => {
    if (item.advertisement_set.view_template === 'post' && !isEmpty(item.video)) {
      return '동영상 게시글'
    }

    if (item.advertisement_set.view_template === 'post' && !isEmpty(item.image)) {
      return '이미지 게시글'
    }

    if (item.advertisement_set.view_template === 'notice') {
      return '공지글'
    }

    if (item.advertisement_set.view_template === 'comment') {
      return '댓글 형식'
    }

    if (item.advertisement_set.view_template === 'post_slide') {
      return '뉴스피드 썸네일'
    }

    return '지원하지 않는 상품'
  }

  isAdmin = () => JSON.parse(getItem('isAdmin'))

  render() {
    const {
      classes,
      campaign,
      adSet,
      adList,
      adLocations,
    } = this.props

    const {
      selectedPayType,
      expanded,
      roles,
      grades,
      salesCode,
      isOpenModal,
      loading,
    } = this.state

    const {
      handlePaymentClick,
      handlePayTypeChange,
      handleChange,
      renderPaymentStatus,
      renderCampaignStatus,
      isForm,
      getFormType,
      getPackageType,
      handleSalesCode,
      getPayAmount,
      renderAdProductLabel,
      isAdmin,
    } = this

    return (
      <Layout pageTitle="캠페인 만들기">
        {loading ? <CircularUnderLoad styles={classes.progress} />
          : (
            <Grid container spacing={24}>
              <Stepper activeStep={3} />
              <Grid item xs={9}>
                <Paper className={classes.paper}>
                  <Typography variant="title" gutterBottom>
                    {campaign.title}
                  </Typography>

                  <Divider className={classes.divider} />

                  <Grid container spacing={24}>
                    <Grid item xs={4}>
                      <Typography variant="title" gutterBottom>
                        상품
                      </Typography>

                      <Typography variant="subtitle1" gutterBottom className={classes.label}>
                        {campaign.ad_package && campaign.ad_package.title}
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <Typography variant="title" gutterBottom>
                        타겟
                      </Typography>

                      <Typography variant="subtitle1" gutterBottom color="primary">
                        대상
                      </Typography>

                      {map(adSet && adSet.audience_roles, (item, index) => (
                        <Typography variant="subtitle1" gutterBottom className={classes.label} key={index}>
                          {roles[item]}
                        </Typography>
                      ))}

                      <Typography variant="subtitle2" gutterBottom className={classes.subLabel}>
                        {grades[adSet && adSet.audience_grade_from]}
                        ~
                        {grades[adSet && adSet.audience_grade_to]}
                      </Typography>

                      {!isEmpty(adLocations)
                      && (
                      <>
                        <Typography variant="subtitle1" gutterBottom color="primary">
                          지역
                        </Typography>
                        {map(adLocations, item => (
                          <Typography variant="subtitle1" gutterBottom className={classes.label} key={item.id}>
                            {item.description}
                          </Typography>
                        ))}
                      </>
                      )
                    }
                    </Grid>

                    <Grid item xs={4}>
                      <Typography variant="title" gutterBottom>
                        일정
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom className={classes.label}>
                        {moment(adSet && adSet.start_date).format('YYYY-MM-DD')}
                        ~
                        <br />
                        {moment(adSet && adSet.end_date).format('YYYY-MM-DD')}
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <Typography variant="title" gutterBottom>
                        상태
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom className={classes.label}>
                        {renderCampaignStatus()}
                      </Typography>
                    </Grid>

                  </Grid>
                  <div className={classes.panelCotainer}>
                    {map(adList, (item, index) => (
                      !isEmpty(item)
                    && (
                    <ExpansionPanel
                      className={classes.panel}
                      key={index}
                      expanded={expanded === (item && item.id)}
                      onChange={handleChange(item && item.id)}
                    >
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        className={classes.panelSummary}
                        classes={{ content: classes.panelContent }}
                      >
                        <Typography className={classes.heading}>{item && item.title}</Typography>
                        <Typography className={classes.subLabel}>
                          {renderAdProductLabel(item)}
                        </Typography>
                      </ExpansionPanelSummary>

                      <ExpansionPanelDetails className={classes.panelDetails}>
                        <Grid container spacing={24}>
                          {!isEmpty(item && item.title)
                            && (
                            <>
                              <Grid item xs={1}>
                                <Typography variant="subtitle1" className={classes.label}>
                                  제목
                                </Typography>
                              </Grid>

                              <Grid item xs={11}>
                                <Typography variant="subtitle1" className={classes.subLabel}>
                                  {item.title}
                                </Typography>
                              </Grid>
                            </>
                            )
                          }

                          <Grid item xs={1}>
                            <Typography variant="subtitle1" className={classes.label}>
                              소재내용
                            </Typography>
                          </Grid>

                          <Grid item xs={11}>
                            <Typography variant="subtitle1" className={classes.subLabel}>
                              {item && item.description}
                            </Typography>
                          </Grid>

                          <Grid item xs={1}>
                            <Typography variant="subtitle1" className={classes.label}>
                              광고주
                            </Typography>
                          </Grid>

                          <Grid item xs={11}>
                            <Typography variant="subtitle1" className={classes.subLabel}>
                              {item && item.advertiser.name}
                            </Typography>
                          </Grid>

                          {getPackageType() !== 'region'
                            && (
                            <>
                              <Grid item xs={1}>
                                <Typography variant="subtitle1" className={classes.label}>
                                  버튼문구
                                </Typography>
                              </Grid>

                              <Grid item xs={11}>
                                <Typography variant="subtitle1" className={classes.subLabel}>
                                  {item && item.theme.action_label}
                                </Typography>
                              </Grid>
                            </>
                            )
                          }

                          {!isEmpty(item && item.video)
                            && (
                            <>
                              <Grid item xs={1}>
                                <Typography variant="subtitle1" className={classes.label}>
                                  동영상
                                </Typography>
                              </Grid>

                              <Grid item xs={11}>
                                <Typography variant="subtitle1" color="primary">
                                  {item.video && item.video.video_url}
                                </Typography>
                              </Grid>
                            </>
                            )
                          }

                          {getPackageType() !== 'region'
                          && (
                          <>
                            <Grid item xs={1}>
                              <Typography variant="subtitle1" className={classes.label}>
                                랜딩
                              </Typography>
                            </Grid>

                            <Grid item xs={11}>
                              <Typography variant="subtitle1" color="primary">
                                {item.advertisement_set && item.advertisement_set.action_url}
                              </Typography>
                            </Grid>
                          </>
                          )
                        }

                        </Grid>
                      </ExpansionPanelDetails>

                    </ExpansionPanel>
                    )
                    ))}
                  </div>
                </Paper>
              </Grid>

              <Grid item xs={3}>
                <Paper className={classes.paper}>
                  <Typography variant="title" gutterBottom>
                    최종 결제 금액
                  </Typography>

                  <Typography variant="h4" gutterBottom>
                    <span className={classes.amount}>
                      {getToCommas(getPayAmount(campaign.budget))}
                    </span>
                    원
                  </Typography>
                  <span className={classes.subLabel}>VAT 10% 포함</span>

                  {false
                  && (
                  <>
                    <Typography variant="title" gutterBottom>
                      CPM 단가
                    </Typography>

                    <Typography variant="subtitle1" gutterBottom className={classes.label}>
                      {getToCommas(campaign.cpm)}
                      원
                    </Typography>
                  </>
                  )
                }

                  <Divider className={classes.divider} />
                  {(!isForm() || (getFormType()) === 'create')
                  && (
                  <>
                    <Typography variant="title" gutterBottom className={classes.label}>
                      결제 방법
                    </Typography>

                    <RadioGroup
                      aria-label="payType"
                      name="payType"
                      value={selectedPayType}
                      onChange={handlePayTypeChange}
                    >
                      <FormControlLabel
                        value="transfer"
                        control={<Radio color="primary" classes={{ root: classes.radio }} />}
                        label="무통장 입금"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="vbank"
                        control={<Radio color="primary" classes={{ root: classes.radio }} />}
                        label="가상 계좌"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="card"
                        control={<Radio color="primary" classes={{ root: classes.radio }} />}
                        label="카드"
                        labelPlacement="end"
                      />
                    </RadioGroup>

                    <Typography variant="subtitle1" className={classNames(classes.sublabel, classes.spacing)}>
                      무통장 입금은 견적
                      <strong>300만원 이상</strong>
                      일 때 가능합니다.
                    </Typography>

                    <Typography variant="subtitle1" style={{ paddingBottom: '18px' }} color="error">
                      결제를 진행하면 수정이 불가능합니다.
                    </Typography>

                    <form name="pay" action="" method="post">
                      <input type="hidden" name="token" value="" />
                      <input type="hidden" name="product_name" value="" />
                      <input type="hidden" name="pay_amount" value="" />
                      <input type="hidden" name="user_id" value="" />
                      <input type="hidden" name="user_name" value="" />
                      <input type="hidden" name="user_email" value="" />
                      <input type="hidden" name="user_tel" value="" />
                      <input type="hidden" name="service_name" value="" />
                      <input type="hidden" name="pay_method" value="" />
                      <input type="hidden" name="service_payment_id" value="" />
                      <input type="hidden" name="app_schema" value="" />
                      <input type="hidden" name="success_url" value="" />
                      <input type="hidden" name="fail_url" value="" />
                    </form>

                    {getPackageType() === 'region'
                      && (
                      <>
                        <Typography variant="title" className={classes.label}>
                          추천인 코드
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom className={classes.sublabel}>
                          추천인의 코드가 있다면 입력해주세요.
                        </Typography>
                        <Input
                          type="text"
                          onChange={handleSalesCode}
                          className={classes.salesCodeField}
                          value={salesCode}
                          placeholder="코드를 입력해주세요"
                        />
                      </>
                      )
                    }

                    <Button
                      className={classes.submitButton}
                      variant="contained"
                      color="primary"
                      type="submit"
                      onClick={handlePaymentClick}
                    >
                      결제 하기
                    </Button>
                  </>
                  )
                }
                  {isForm() && renderPaymentStatus()}
                </Paper>
              </Grid>
            </Grid>
          )
        }
        <EnhancedDialog
          isOpen={isOpenModal}
          isCloseButton={false}
          modalTitle="해당 캠페인 견적 피드백"
          handleOpen={() => {
            this.setState({ isOpenModal: false })
          }}
        >
          <DialogContentText>
            <div className={classes.reasonLabel}>
              {isAdmin() ? '견적에 대한 피드백 사항을 메모해주세요.' : '견적에 대한 피드백 사항을 확인해주세요.'}
            </div>
            <div className={classes.reason}>
              {!isEmpty(campaign.reason) && campaign.reason.split('\n').map(item => (
                <span>
                  {item}
                  <br />
                </span>
              ))}
            </div>
          </DialogContentText>
        </EnhancedDialog>
      </Layout>
    )
  }
}

PaymentFormPage.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  campaign: PropTypes.object.isRequired,
  payments: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  adPackages: PropTypes.array.isRequired,
  adSet: PropTypes.object,
  adList: PropTypes.array.isRequired,
  onCampaignMount: PropTypes.func.isRequired,
  onAdSetMount: PropTypes.func.isRequired,
  onAds: PropTypes.func.isRequired,
  onAdPaymentSubmit: PropTypes.func.isRequired,
  onAdPayments: PropTypes.func.isRequired,
  onAdPackage: PropTypes.func.isRequired,
  onCampaignsUpdate: PropTypes.func.isRequired,
  onAdLocations: PropTypes.func.isRequired,
  adLocations: PropTypes.array.isRequired,
  onResetAdList: PropTypes.func.isRequired,
  onResetAdPayment: PropTypes.func.isRequired,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(PaymentFormPage)
