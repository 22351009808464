/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { useContext } from 'react'
import PropTypes from 'prop-types'

import { uiStore, VIEW_SIZE_PC, VIEW_SIZE_MOBILE } from '@landing/contexts/ui'

const makeStyles = ({ direction, interval }) => ({
  content: ({ mq }) => css`
    ${mq({
    marginLeft: ['0', direction === 'row' ? '0' : interval],
    marginRight: ['0', direction === 'row' ? interval : 'auto'],
  })}
  `,

  imgWrap: ({ mq, spacing }) => css`
    ${mq({
    marginBottom: [`${spacing(2)}rem`, '0'],
    width: ['24.6rem', '38.4rem'],
  })}

    img {
      display: block;
      width: 100%;
    }
  `,
  root: ({ mq }) => css`
    ${mq({
    alignItems: 'center',
    display: 'flex',
    flexDirection: ['column', direction],
  })}
  `,
  text: ({ colors: { grey }, font, mq }) => css`
    ${mq({
    color: grey[700],
    fontSize: [font.body2.fontSize, font.title3.fontSize],
    fontWeight: '300',
    lineHeight: [font.body2.lineHeight, '1.6'],
  })}
  `,
})

const Description = ({
  direction, image, interval, text, text2,
}) => {
  const {
    state: { viewSize },
  } = useContext(uiStore)
  const styles = makeStyles({ direction, interval })

  return (
    <div css={styles.root}>
      {VIEW_SIZE_PC === viewSize && (
        <div css={styles.content}>
          <p css={styles.text}>{text}</p>
        </div>
      )}
      <figure css={styles.imgWrap}>
        <img src={image} alt="" />
      </figure>
      {VIEW_SIZE_MOBILE === viewSize && (
        <div css={styles.content}>
          <p css={styles.text}>{text2 || text}</p>
        </div>
      )}
    </div>
  )
}

Description.defaultProps = {
  direction: 'row',
  text: '',
  text2: '',
}

Description.propTypes = {
  direction: PropTypes.oneOf(['row', 'row-reverse']),
  image: PropTypes.string.isRequired,
  interval: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  text2: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
}

export default Description
