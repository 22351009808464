import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import { Skeleton } from '../atoms'
import SplashBannerBackgroundImage from '../../../../shared/assets/splash-banner-background.png'

const useStyles = makeStyles(() => ({
  root: {
    width: '375px',
    height: '667px',
    position: 'relative',
  },
  content: {
    width: '100%',
    height: '100%',
    marginRight: '8px',
    backgroundImage: `url(${SplashBannerBackgroundImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  adImage: {
    position: 'absolute',
    bottom: '0',
    width: '100%',
    height: '40%',
    margin: '0',
    objectFit: 'fill',

    '& img': {
      display: 'block',
      width: '100%',
      height: '100%',
      color: 'transparent',
    },
  },
}))

const SplashBannerAd = ({ imageUrl }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Skeleton isShow={!!imageUrl} borderRadius="32px" width="96px" height="96px">
          <figure className={classes.adImage}>
            {imageUrl ? <img src={imageUrl} alt="광고 이미지" /> : null}
          </figure>
        </Skeleton>
      </div>
    </div>
  )
}

SplashBannerAd.propTypes = {

  imageUrl: PropTypes.string.isRequired,
}

export default SplashBannerAd
