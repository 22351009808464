import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Button from 'reactstrap/lib/Button'

import { Drawer } from '@/shared/components'
import { templateTitle } from '@/shared/constants'

const SUBMIT_HEIGHT = '80px'

const useStyles = makeStyles(() => ({
  form: {
    width: '100%',
    height: '100%',
  },
  content: {
    padding: '1rem',
    height: `calc(100% - ${SUBMIT_HEIGHT})`,
    overflow: 'auto',
  },
  formItem: {
    display: 'flex',
    alignItems: 'center',
  },
  formItemLabel: {
    margin: '0 0 0 8px',
  },
  submitWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: SUBMIT_HEIGHT,
    padding: '1rem',
  },
  cancelButton: {
    marginLeft: '8px',
  },
}))

const TodayTableOptionDrawer = ({
  columnOptions,
  isOpen,
  onClose,
  onSubmit,
}) => {
  const styles = useStyles()
  const [items, setItems] = React.useState(columnOptions)

  const onCloseDrawer = React.useCallback(() => {
    if (onClose) {
      onClose()
    }
  }, [onClose])

  const onChangeOption = (e) => {
    e.stopPropagation()

    const newItems = items.map((item) => {
      if (item.key === e.target.name) {
        return {
          ...item,
          checked: e.target.checked,
        }
      }
      return item
    })
    setItems(newItems)
  }

  const onSubmitForm = (e) => {
    e.preventDefault()

    if (onSubmit) {
      onSubmit(items)
    }
    if (onClose) {
      onClose()
    }
  }

  return (
    <Drawer isOpen={isOpen} onClose={onCloseDrawer}>
      <form className={styles.form} onSubmit={onSubmitForm}>
        <div className={styles.content}>
          {items.map(item => (
            <div className={styles.formItem} key={item.key}>
              <input
                type="checkbox"
                id={item.key}
                name={item.key}
                checked={item.checked}
                onChange={onChangeOption}
              />
              <label
                className={styles.formItemLabel}
                htmlFor={item.key}
              >
                {templateTitle[item.label]}
              </label>
            </div>
          ))}
        </div>
        <div className={styles.submitWrapper}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
          >
            저장
          </Button>
          <Button className={styles.cancelButton} type="button" onClick={onCloseDrawer}>
            취소
          </Button>
        </div>
      </form>
    </Drawer>
  )
}

TodayTableOptionDrawer.propTypes = {
  columnOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
  })).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
}

export { TodayTableOptionDrawer }
export default TodayTableOptionDrawer
