/* eslint-disable no-param-reassign */
import { saveAs } from 'file-saver'
import XLSX from 'xlsx-style-cli'
import { summarySection, totalSection, typeSection } from './sections'

const todayYYYYMMDD = () => new Date().toISOString().slice(0, 10).replace(/-/g, '')
const setIsVideo = (adReports) => {
  Object.keys(adReports).forEach((type) => {
    adReports[type].forEach((advertisementSet) => {
      advertisementSet.hasVideoAd = false

      advertisementSet.advertisements.forEach((advertisement) => {
        advertisement.isVideoAd = false

        // eslint-disable-next-line no-restricted-syntax
        for (const date of Object.keys(advertisement.adStats)) {
          const watchView = advertisement.adStats[date].all.watchView || 0
          if (watchView > 0) {
            advertisement.isVideoAd = true
            advertisementSet.hasVideoAd = true

            break
          }
        }
      })
    })
  })
}

const getWorksheetRef = (data) => {
  const maxRow = data.length
  let maxColumn = 0
  data.forEach((rows) => {
    if (rows.length > maxColumn) {
      maxColumn = rows.length
    }
  })

  return `${XLSX.utils.encode_cell({ c: 0, r: 0 })}:${XLSX.utils.encode_cell({ c: maxColumn, r: maxRow })}`
}

const sheetbookToArrayBuffer = (s) => {
  const buf = new ArrayBuffer(s.length)
  const view = new Uint8Array(buf)
  for (let i = 0; i < s.length; i += 1) {
    // eslint-disable-next-line no-bitwise
    view[i] = s.charCodeAt(i) & 0xFF
  }
  return buf
}

const applyCellStyle = (cell) => {
  if (typeof cell !== 'object') {
    cell = { v: cell }
  } else if (cell === null) {
    cell = { v: '-' }
  }
  cell.s = {
    // NOTE: 기본 외곽선
    border: {
      top: { style: 'thin', color: { rgb: '00000000' } },
      bottom: { style: 'thin', color: { rgb: '00000000' } },
      right: { style: 'thin', color: { rgb: '00000000' } },
      left: { style: 'thin', color: { rgb: '00000000' } },
    },

    ...cell.s,
  }

  // NOTE: 포맷
  if (typeof cell.v === 'number') {
    cell.s = {
      alignment: {
        horizontal: 'right',
      },
      ...cell.s,
    }
    if (cell.t === 'f') {
      cell.s.numFmt = '0.00%'
      cell.v = Number(cell.v.toFixed(4))
    } else {
      cell.t = 'n'
      cell.s.numFmt = '#,##0'
    }
  } else {
    cell.t = 's'
    cell.s = {
      alignment: {
        horizontal: 'center',
      },
      ...cell.s,
    }
  }

  return cell
}


const applyFormularToCell = (cell, row, column) => {
  if (cell.formular) {
    if (cell.formular.type === 'divide') {
      const dividend = XLSX.utils.encode_cell(
        {
          c: column + cell.formular.dividend.c,
          r: row + cell.formular.dividend.r,
        },
      )
      let divisor
      if (cell.formular.divisor.formular) {
        divisor = applyFormularToCell(cell.formular.divisor, row, column)
      } else {
        divisor = XLSX.utils.encode_cell(
          {
            c: column + cell.formular.divisor.c,
            r: row + cell.formular.divisor.r,
          },
        )
      }

      return `${dividend}/${divisor}`
    }
    if (cell.formular.type === 'sumRange') {
      const start = XLSX.utils.encode_cell(
        {
          c: column + cell.formular.s.c,
          r: row + cell.formular.s.r,
        },
      )
      const end = XLSX.utils.encode_cell(
        {
          c: column + cell.formular.e.c,
          r: row + cell.formular.e.r,
        },
      )
      return `SUM(${start}:${end})`
    }
    if (cell.formular.type === 'sumCells') {
      const sumCells = []
      cell.formular.sumCells.forEach((sumCell) => {
        sumCells.push(XLSX.utils.encode_cell(
          {
            c: column + sumCell.c,
            r: row + sumCell.r,
          },
        ))
      })
      return `SUM(${sumCells.join(',')})`
    }
  }
  return undefined
}

export const exportXlsx = (campaign, adSets, adSummary, adReports) => {
  // adReposts = {
  //     post: [{
  //         name: '게시글 타입',
  //         advertisements: [
  //             {
  //                 memo: '클래스팅 동영상 광고A',
  //                 adStats: {
  //                     ['2020-01-01']: {
  //                         all: { view: 100, click: 20, watch: 30, watchView: 100 }
  //                     },
  //                     ...
  //                 }
  //             },
  //             ...
  //         ]
  //     }],
  //     notice: ...
  // }

  let data = []

  setIsVideo(adReports)

  const summary = summarySection(campaign, adSummary)
  const total = totalSection(adReports)
  const sections = [summary, total]
  const types = Object.keys(adReports)
  types.forEach((type) => {
    sections.push(typeSection(type, adReports))
  })
  sections.forEach((section) => {
    data = data.concat(section)
    data.push([])
  })

  // NOTE: 상하좌우 여백 추가
  data.unshift([])
  data.forEach((_rows, row) => {
    data[row].unshift({ v: '', s: { border: {} } })
    data[row].push({ v: '', s: { border: {} } })
  })

  const worksheet = {}

  const merges = []
  const colWidths = []

  data.forEach((rows, row) => {
    rows.forEach((_cell, column) => {
      const cell = applyCellStyle(_cell)
      cell.f = applyFormularToCell(cell, row, column)

      // NOTE: 셀 병합 계산
      if (cell.merge) {
        const { merge } = cell
        merges.push({
          s: { r: row, c: column },
          e: { r: row + (merge.r - 1 || 0), c: column + (merge.c - 1 || 0) },
        })
      }

      // NOTE: 열 너비 계산
      if (!colWidths[column]) {
        colWidths[column] = { wch: 12 }
      }

      const cellRef = XLSX.utils.encode_cell({ c: column, r: row })
      worksheet[cellRef] = cell
    })
  })

  const sheetName = '성과보고서'
  const workbook = {
    SheetNames: [sheetName],
    Sheets: {
      [sheetName]: {
        ...worksheet,
        '!ref': getWorksheetRef(data),
        '!merges': merges,
        '!cols': colWidths,
      },
    },
  }
  const options = { bookType: 'xlsx', bookSST: false, type: 'binary' }

  const output = XLSX.write(workbook, options)
  saveAs(new Blob([sheetbookToArrayBuffer(output)], { type: 'xlsx' }), `${todayYYYYMMDD()} ${campaign.title}.xlsx`)
}
