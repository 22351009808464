import { css } from 'styled-components'

const clearfix = css`
  &::after {
    content: '';
    display: block;
    clear: both;
  }
`

export default clearfix
