/** @jsx jsx */
import { jsx, Global, ThemeProvider } from '@emotion/react'
import { useEffect } from 'react'
import PropTypes from 'prop-types'
import ReactPixel from 'react-facebook-pixel'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { CssBaseline } from '@material-ui/core'

import Main from '@landing/containers/main'
import SEO from '@landing/components/seo'
import Layout from '@landing/components/layout'
import { UiProvider } from '@landing/contexts/ui'
import { reset, theme } from '@landing/styles'
import '@landing/assets/fonts/fonts.css'


const FB_PIXEL_ID = process.env.REACT_APP_LANDING_FB_PIXEL_ID
const STAGE = process.env.REACT_APP_STAGE

const IndexPage = () => {
  useEffect(() => {
    ReactPixel.init(
      FB_PIXEL_ID,
      undefined,
      { autoConfig: true, debug: STAGE.match(/dev/i) },
    )

    ReactPixel.pageView()
  }, [])

  return (
    <UiProvider>
      <OverrideMuiTheme>
        <ThemeProvider theme={theme}>
          <Global styles={reset} />
          <SEO title="클래스팅 광고 - 학부모, 학생, 교사 타겟 매체" />
          <Layout>
            <Main />
          </Layout>
        </ThemeProvider>
      </OverrideMuiTheme>
    </UiProvider>
  )
}

const overridenMuiTheme = createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: 'transparent',
          letterSpacing: 'none',
        },
      },
    },
  },
})

const OverrideMuiTheme = ({ children }) => (
  <MuiThemeProvider theme={overridenMuiTheme}>
    <CssBaseline />
    {children}
  </MuiThemeProvider>
)

OverrideMuiTheme.propTypes = {
  children: PropTypes.node.isRequired,
}

export default IndexPage
