import React, { Component } from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import queryString from 'query-string'
import { CircularUnderLoad } from '@/shared/components'

const styles = ({
  container: {
    position: 'relative',
  },
})

class PaymentResponsePage extends Component {
  componentDidMount() {
    const { getPageType, getCampaignId, getPaymentId } = this
    const params = {
      payment_id: getPaymentId(),
      campaign_id: getCampaignId(),
      page_type: getPageType(),
    }

    window.opener.onPageLoad(params)
    return window.close()
  }

  getQueryString = () => {
    const { location } = this.props
    return queryString.parse(location.search.replace(/&amp;/g, '&'))
  }

  getPageType = () => {
    const { getQueryString } = this
    return getQueryString().page_type
  }

  getCampaignId = () => {
    const { getQueryString } = this
    return getQueryString().campaign_id
  }

  getPaymentId = () => {
    const { getQueryString } = this
    return getQueryString().payment_id
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.conatiner}>
        <CircularUnderLoad />
      </div>
    )
  }
}

PaymentResponsePage.propTypes = {
  location: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
}

export default compose(
  withStyles(styles),
)(PaymentResponsePage)
