import { createAction } from 'redux-actions'
import { http, setItem } from '@/shared/utils'

import { CLASSTING_AD_API_URL } from '@/shared/constants'

export const SIGN_IN_POST_PENDING = 'signIn/POST_PENDING'
export const SIGN_IN_POST_SUCCESS = 'signIn/POST_SUCCESS'
export const SIGN_IN_POST_FAILURE = 'signIn/POST_FAILURE'
export const ADMIN_SIGN_IN_POST_PENDING = 'signIn/ADMIN_POST_PENDING'
export const ADMIN_SIGN_IN_POST_SUCCESS = 'signIn/ADMIN_POST_SUCCESS'
export const ADMIN_SIGN_IN_POST_FAILURE = 'signIn/ADMIN_POST_FAILURE'

export const signInPostPending = createAction(SIGN_IN_POST_PENDING)
export const signInPostSuccess = createAction(SIGN_IN_POST_SUCCESS)
export const signInPostFailure = createAction(SIGN_IN_POST_FAILURE)
export const adminSignInPostPending = createAction(ADMIN_SIGN_IN_POST_PENDING)
export const adminSignInPostSuccess = createAction(ADMIN_SIGN_IN_POST_SUCCESS)
export const adminSignInPostFailure = createAction(ADMIN_SIGN_IN_POST_FAILURE)

function fetchUser() {
  return http.get(`${CLASSTING_AD_API_URL}/agencies/me`)
    .then(res => (res.data))
}

export const signIn = userData => (dispatch) => {
  dispatch(signInPostPending())
  return http.post(`${CLASSTING_AD_API_URL}/agencies/sign_in`, { ...userData })
    .then((res) => {
      setItem('token', res.data.token)
      setItem('isAdmin', false)
      return dispatch(signInPostSuccess(res))
    })
    .then(() => (fetchUser()))
    .catch(err => (
      dispatch(signInPostFailure(err.response))
    ))
}

export const adminSignIn = userData => (dispatch) => {
  dispatch(adminSignInPostPending())
  return http.post(`${CLASSTING_AD_API_URL}/admins/sign_in`, { ...userData })
    .then((res) => {
      setItem('token', res.data.token)
      setItem('isAdmin', true)
      return dispatch(adminSignInPostSuccess(res))
    })
    .catch(err => (
      dispatch(adminSignInPostFailure(err.response))
    ))
}
