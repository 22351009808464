import { handleActions } from 'redux-actions'
import {
  RESET_ADVERTISER,
  FETCH_ADVERTISERS_PENDING,
  FETCH_ADVERTISERS_SUCCESS,
  FETCH_ADVERTISERS_FAILURE,
  FETCH_ADVERTISER_PENDING,
  FETCH_ADVERTISER_SUCCESS,
  FETCH_ADVERTISER_FAILURE,
  SET_ADVERTISER_PENDING,
  SET_ADVERTISER_SUCCESS,
  SET_ADVERTISER_FAILURE,
  UPDATE_ADVERTISER_PENDING,
  UPDATE_ADVERTISER_SUCCESS,
  UPDATE_ADVERTISER_FAILURE,
} from './actions'

export const initialState = {
  pending: false,
  success: false,
  failure: false,
  status: 200,
  advertisers: [],
  advertiserData: {},
  total: '0',
}

export default handleActions({
  [RESET_ADVERTISER]: state => ({
    ...state,
    advertiserData: {},
  }),

  [FETCH_ADVERTISERS_PENDING]: state => ({
    ...state,
    pending: true,
  }),

  [FETCH_ADVERTISERS_PENDING]: state => ({
    ...state,
    pending: true,
  }),

  [FETCH_ADVERTISERS_SUCCESS]: (state, action) => {
    const { data, status, headers } = action.payload
    return {
      ...state,
      pending: false,
      advertisers: data,
      ...headers,
      status,
    }
  },

  [FETCH_ADVERTISERS_FAILURE]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      pending: false,
      data,
      status,
    }
  },

  [FETCH_ADVERTISER_PENDING]: state => ({
    ...state,
    pending: true,
  }),

  [FETCH_ADVERTISER_SUCCESS]: (state, action) => {
    const { data, status, headers } = action.payload
    return {
      ...state,
      pending: false,
      advertiserData: data,
      ...headers,
      status,
    }
  },

  [FETCH_ADVERTISER_FAILURE]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      pending: false,
      data,
      status,
    }
  },

  [SET_ADVERTISER_PENDING]: state => ({
    ...state,
    pending: true,
  }),

  [SET_ADVERTISER_SUCCESS]: (state, action) => {
    const { data, status, headers } = action.payload
    return {
      ...state,
      pending: false,
      success: true,
      failure: false,
      advertiserData: data,
      ...headers,
      status,
    }
  },

  [SET_ADVERTISER_FAILURE]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      pending: false,
      success: false,
      failure: true,
      data,
      status,
    }
  },

  [UPDATE_ADVERTISER_PENDING]: state => ({
    ...state,
    pending: true,
  }),

  [UPDATE_ADVERTISER_SUCCESS]: (state, action) => {
    const { data, status, headers } = action.payload
    return {
      ...state,
      pending: false,
      success: true,
      failure: false,
      data,
      ...headers,
      status,
    }
  },

  [UPDATE_ADVERTISER_FAILURE]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      pending: false,
      success: false,
      failure: true,
      data,
      status,
    }
  },
}, initialState)
