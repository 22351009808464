import {
  SignUpPage,
  PolicyPage,
  FindPasswordPage,
  UpdatePasswordPage,
  RequestConfirmationPage,
  ConfirmationPage,
} from './page'

export default [
  {
    key: 'sign_up',
    requiresAuth: false,
    exact: true,
    path: '/sign_up',
    component: SignUpPage,
  },
  {
    key: 'policy',
    requiresAuth: false,
    exact: true,
    path: '/policy',
    component: PolicyPage,
  },
  {
    key: 'find_password',
    requiresAuth: false,
    exact: true,
    path: '/find_password',
    component: FindPasswordPage,
  },
  {
    key: 'update_password',
    requiresAuth: false,
    exact: true,
    path: '/update_password',
    component: UpdatePasswordPage,
  },
  {
    key: 'request_confirmation',
    requiresAuth: false,
    exact: true,
    path: '/request_confirmation',
    component: RequestConfirmationPage,
  },
  {
    key: 'confirmation',
    requiresAuth: false,
    exact: true,
    path: '/confirmation',
    component: ConfirmationPage,
  },
]
