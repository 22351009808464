import { handleActions } from 'redux-actions'
import { head } from 'lodash'
import {
  RESET_AD_SETS,
  RESET_AD_LIST,
  SET_CAMPAIGNS_PENDING,
  SET_CAMPAIGNS_SUCCESS,
  SET_CAMPAIGNS_FAILURE,
  UPDATE_CAMPAIGNS_PENDING,
  UPDATE_CAMPAIGNS_SUCCESS,
  UPDATE_CAMPAIGNS_FAILURE,
  SET_AD_SET_PENDING,
  SET_AD_SET_SUCCESS,
  SET_AD_SET_FAILURE,
  FETCH_AD_SET_PENDING,
  FETCH_AD_SET_SUCCESS,
  FETCH_AD_SET_FAILURE,
  UPDATE_AD_SET_PENDING,
  UPDATE_AD_SET_SUCCESS,
  UPDATE_AD_SET_FAILURE,
  FETCH_AD_LOCATION_PENDING,
  FETCH_AD_LOCATION_SUCCESS,
  FETCH_AD_LOCATION_FAILURE,
  FETCH_AD_PACKAGES_PENDING,
  FETCH_AD_PACKAGES_SUCCESS,
  FETCH_AD_PACKAGES_FAILURE,
  FETCH_REGIONS_PENDING,
  FETCH_REGIONS_SUCCESS,
  FETCH_REGIONS_FAILURE,
  FETCH_CITIES_PENDING,
  FETCH_CITIES_SUCCESS,
  FETCH_CITIES_FAILURE,
  FETCH_ZONES_PENDING,
  FETCH_ZONES_SUCCESS,
  FETCH_ZONES_FAILURE,
  FETCH_AREAS_PENDING,
  FETCH_AREAS_SUCCESS,
  FETCH_AREAS_FAILURE,
  RESET_AD,
  FETCH_AD_PENDING,
  FETCH_AD_SUCCESS,
  FETCH_AD_FAILURE,
  FETCH_ADS_PENDING,
  FETCH_ADS_SUCCESS,
  FETCH_ADS_FAILURE,
  SET_AD_PENDING,
  SET_AD_SUCCESS,
  SET_AD_FAILURE,
  UPDATE_AD_PENDING,
  UPDATE_AD_SUCCESS,
  UPDATE_AD_FAILURE,
  FETCH_THEMES_PENDING,
  FETCH_THEMES_SUCCESS,
  FETCH_THEMES_FAILURE,
} from './actions'

export const initialState = {
  pending: false,
  refresh: false,
  campaign: {},
  adPackages: [],
  regions: [],
  zones: [],
  areas: [],
  cities: [],
  adSet: {},
  adSets: [],
  ad: [],
  adList: [],
  adLocations: [],
  themes: [],
}

export default handleActions({
  [RESET_AD_SETS]: () => ({
    adSets: [],
  }),

  [RESET_AD_LIST]: () => ({
    adList: [],
  }),

  [SET_CAMPAIGNS_PENDING]: state => ({
    ...state,
    pending: true,
  }),

  [SET_CAMPAIGNS_SUCCESS]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      pending: false,
      campaign: data,
      status,
    }
  },

  [SET_CAMPAIGNS_FAILURE]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      pending: false,
      data,
      status,
    }
  },

  [UPDATE_CAMPAIGNS_PENDING]: state => ({
    ...state,
    pending: true,
  }),

  [UPDATE_CAMPAIGNS_SUCCESS]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      pending: false,
      campaign: data,
      status,
    }
  },

  [UPDATE_CAMPAIGNS_FAILURE]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      pending: false,
      data,
      status,
    }
  },

  [SET_AD_SET_PENDING]: state => ({
    ...state,
  }),

  [SET_AD_SET_SUCCESS]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      data,
      status,
    }
  },

  [SET_AD_SET_FAILURE]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      pending: false,
      data,
      status,
    }
  },

  [FETCH_AD_SET_PENDING]: state => ({
    ...state,
  }),

  [FETCH_AD_SET_SUCCESS]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      adSets: data,
      status,
    }
  },

  [FETCH_AD_SET_FAILURE]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      pending: false,
      data,
      status,
    }
  },

  [UPDATE_AD_SET_PENDING]: state => ({
    ...state,
    pending: true,
  }),

  [UPDATE_AD_SET_SUCCESS]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      pending: false,
      data,
      status,
    }
  },

  [UPDATE_AD_SET_FAILURE]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      pending: false,
      data,
      status,
    }
  },

  [FETCH_AD_LOCATION_PENDING]: state => ({
    ...state,
    pending: true,
  }),

  [FETCH_AD_LOCATION_SUCCESS]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      pending: false,
      adLocations: data,
      status,
    }
  },

  [FETCH_AD_LOCATION_FAILURE]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      pending: false,
      data,
      status,
    }
  },

  [FETCH_AD_PACKAGES_PENDING]: state => ({
    ...state,
    pending: true,
  }),

  [FETCH_AD_PACKAGES_SUCCESS]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      pending: false,
      adPackages: data,
      status,
    }
  },

  [FETCH_AD_PACKAGES_FAILURE]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      pending: false,
      data,
      status,
    }
  },

  [FETCH_REGIONS_PENDING]: state => ({
    ...state,
    pending: true,
  }),

  [FETCH_REGIONS_SUCCESS]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      pending: false,
      regions: data,
      status,
    }
  },

  [FETCH_REGIONS_FAILURE]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      pending: false,
      data,
      status,
    }
  },

  [FETCH_CITIES_PENDING]: state => ({
    ...state,
    cities: [],
    zones: [],
    areas: [],
  }),

  [FETCH_CITIES_SUCCESS]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      cities: data,
      status,
    }
  },

  [FETCH_CITIES_FAILURE]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      data,
      status,
    }
  },

  [FETCH_ZONES_PENDING]: state => ({
    zones: [],
    ...state,
  }),

  [FETCH_ZONES_SUCCESS]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      zones: data,
      status,
    }
  },

  [FETCH_ZONES_FAILURE]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      data,
      status,
    }
  },

  [FETCH_AREAS_PENDING]: state => ({
    areas: [],
    ...state,
  }),

  [FETCH_AREAS_SUCCESS]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      areas: data,
      status,
    }
  },

  [FETCH_AREAS_FAILURE]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      data,
      status,
    }
  },

  [RESET_AD]: state => ({
    ...state,
    ad: [],
  }),

  [FETCH_AD_PENDING]: state => ({
    ...state,
    pending: true,
  }),

  [FETCH_AD_SUCCESS]: (state, action) => {
    const { data } = action.payload
    return {
      ...state,
      pending: false,
      ad: data,
    }
  },

  [FETCH_AD_FAILURE]: (state, action) => {
    const { data } = action.payload
    return {
      ...state,
      data,
    }
  },

  [FETCH_ADS_PENDING]: state => ({
    ...state,
    pending: true,
  }),

  [FETCH_ADS_SUCCESS]: (state, action) => {
    const { data } = action.payload
    return {
      ...state,
      pending: false,
      adList: [
        ...state.adList,
        head(data),
      ],
    }
  },

  [FETCH_ADS_FAILURE]: (state, action) => {
    const { data } = action.payload
    return {
      pending: false,
      ...state,
      data,
    }
  },

  [SET_AD_PENDING]: state => ({
    ...state,
    pending: true,
  }),

  [SET_AD_SUCCESS]: (state, action) => {
    const { status } = action.payload
    return {
      ...state,
      status,
      pending: false,
    }
  },

  [SET_AD_FAILURE]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      data,
      status,
      pending: false,
    }
  },

  [UPDATE_AD_PENDING]: state => ({
    ...state,
    pending: true,
  }),

  [UPDATE_AD_SUCCESS]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      ad: [data],
      pending: false,
      status,
    }
  },

  [UPDATE_AD_FAILURE]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      data,
      pending: false,
      status,
    }
  },

  [FETCH_THEMES_PENDING]: state => ({
    ...state,
  }),

  [FETCH_THEMES_SUCCESS]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      themes: data,
      status,
    }
  },

  [FETCH_THEMES_FAILURE]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      data,
      status,
    }
  },

}, initialState)
