/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import PropTypes from 'prop-types'

import Description from './Description'
import { AppearanceAnimation } from '../unit/animation'

const makeStyles = () => ({
  desciption: ({ spacing }) => css`
    margin-bottom: ${spacing(5)}rem;

    :last-of-type {
      margin-bottom: 0;
    }
  `,
  root: ({ mq, spacing }) => css`
    ${mq({
    alignItems: ['', 'center'],
    display: ['block', 'flex'],
    flexDirection: ['raw', 'column'],
    justifyContent: ['', 'center'],
    margin: ['0', '0 auto'],
    padding: [`${spacing(10)}rem 0`, `${spacing(15)}rem 0`],
    textAlign: ['center', 'left'],
    width: ['auto', '102.4rem'],
  })}
  `,
  subTitle: ({
    colors: { grey }, font, spacing, mq,
  }) => css`
    ${mq({
    color: grey[700],
    fontSize: [font.body2.fontSize, font.body1.fontSize],
    lineHeight: [font.body2.lineHeight, font.body1.lineHeight],
    marginBottom: [`${spacing(0.5)}rem`, '0'],
  })}
  `,
  title: ({ font, mq }) => css`
    ${mq({
    fontSize: [font.title3.fontSize, font.title1.fontSize],
    fontWeight: 'bold',
    lineHeight: [font.title3.lineHeight, font.title1.lineHeight],
  })}
  `,
  titleWrap: ({ mq, spacing }) => css`
    ${mq({
    marginBottom: `${spacing(5)}rem`,
    marginRight: ['0', 'auto'],
    paddingLeft: ['0', '11.2rem'],
  })}
  `,
})

const Content = ({ descriptions, title }) => {
  const styles = makeStyles()

  return (
    <div css={styles.root}>
      <div css={styles.titleWrap}>
        <AppearanceAnimation>
          <h4 css={styles.subTitle}>클래스팅 광고는</h4>
          <h3 css={styles.title}>{title}</h3>
        </AppearanceAnimation>
      </div>
      {descriptions.map(({
        direction, image, interval, text, text2,
      }, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <div css={styles.desciption} key={idx}>
          <AppearanceAnimation>
            <Description
              direction={direction}
              image={image}
              interval={interval}
              text={text}
              text2={text2}
            />
          </AppearanceAnimation>
        </div>
      ))}
    </div>
  )
}

Content.defaultProps = {
  descriptions: [],
  title: '',
}

Content.propTypes = {
  descriptions: PropTypes.arrayOf(
    PropTypes.shape({
      direction: PropTypes.oneOf(['row', 'row-reverse']),
      image: PropTypes.string.isRequired,
      interval: PropTypes.string.isRequired,
      text: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
      text2: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    }),
  ),
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
}

export default Content
