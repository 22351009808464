import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    margin: '0',
  },
}))

const PreparingGuide = () => {
  const styles = useStyles()

  return (
    <p className={styles.root}>광고 소재 미리보기를 지원하지 않는 광고 타입입니다.</p>
  )
}

export default PreparingGuide
