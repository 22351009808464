/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { useContext } from 'react'

import { uiStore, VIEW_SIZE_PC } from '@landing/contexts/ui'
import { useNavigationPointing } from '@landing/hooks/navigation'
import Questions from './Questions'
import { AppearanceAnimation } from '../unit/animation'
import { InquireButton } from '../unit/button'

const makeStyles = () => ({
  inquireBtnWrap: () => css`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  quenstionWrap: () => css`
    width: 100%;
  `,
  root: ({ colors: { grey }, spacing, mq }) => css`
    ${mq({
    background: grey[50],
    padding: [`${spacing(15)}rem 0 ${spacing(10)}rem`, `${spacing(30)}rem 0`],
  })}
  `,
  subTitle: ({ colors: { grey }, font, mq }) => css`
    ${mq({
    color: grey[700],
    fontSize: [font.body1.fontSize, font.body1.fontSize],
    lineHeight: [font.body1.lineHeight, font.body1.lineHeight],
    textAlign: 'center',
  })}
  `,
  title: ({
    colors: { grey }, font, spacing, mq,
  }) => css`
    ${mq({
    color: grey[900],
    fontSize: [font.title3.fontSize, font.title1.fontSize],
    fontWeight: 'bold',
    lineHeight: [font.title3.lineHeight, font.title1.lineHeight],
    marginBottom: `${spacing(5)}rem`,
  })}
  `,
  wrap: ({ mq, spacing }) => css`
    ${mq({
    alignItems: 'center',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    margin: '0 auto',
    maxWidth: '102.4rem',
    padding: [`0 ${spacing(1.875)}rem`, `0 ${spacing(14.375)}rem`],
  })}
  `,
})

const navId = 'FAQ'

const FAQ = () => {
  const styles = makeStyles()
  const [wrapEl] = useNavigationPointing(navId)
  const {
    state: { viewSize },
  } = useContext(uiStore)

  return (
    <section css={styles.root} ref={wrapEl} id={navId}>
      <div css={styles.wrap}>
        <AppearanceAnimation>
          <p css={styles.subTitle}>FAQ</p>
          <h2 css={styles.title}>자주 묻는 질문</h2>
        </AppearanceAnimation>
        <div css={styles.quenstionWrap}>
          <AppearanceAnimation>
            <Questions />
            {viewSize === VIEW_SIZE_PC && (
              <div css={styles.inquireBtnWrap}>
                <InquireButton />
              </div>
            )}
          </AppearanceAnimation>
        </div>
      </div>
    </section>
  )
}

export default FAQ
