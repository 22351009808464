import React from 'react'
import { Text } from '../../components'

const actionLabelItems = ['바로가기', '다운로드', '구매하기', '신청하기', '무료체험하기', '설치하기', '참여하기', '가입하기', '자세히 보기', '예매하기']

/**
  @return {
    [formType]: {
      name: string,
      fields: [{
        id: string,
        labelName: string,
        maxLimit?: number,
        caution?: string | ReactElement,
        rule?: string | ReactElement,
        type: 'text' | 'file' | 'textArea'  | 'colorPicker' | dropdown | plainText,
        value: string | { name: string; url: string } // for file typ
        placeholder?: string,
        buttonName?: string,
        items: [] // required when type is dropBox
        size?: 'sm', 'md' | 'lg' // for textArea type
      }]
    }
  }
*/

const previewFormFieldFactory = () => ({
  postImage: {
    name: '게시글(이미지)',
    fields: [
      {
        id: 'advertiserProfileImage',
        labelName: '광고주 로고',
        rule: `
        · 이미지 크기: 300 x 300 px
        · 파일 형식: png, jpg
        `.trim(),
        type: 'file',
        value: {
          name: '',
          url: '',
        },
        placeholder: '추가할 이미지를 드래그하거나 클릭하여 선택하세요',
      },
      {
        id: 'advertiserName',
        labelName: '광고주 이름',
        rule: `
        · 반드시 광고주명 또는 브랜드명으로 작성(광고주 정보와 일치하는 명칭)
        · 수식어 사용 불가
        `.trim(),
        type: 'text',
        placeholder: '광고주명 또는 브랜드명을 입력해주세요',
        value: '',
      },
      {
        id: 'description',
        labelName: '광고 내용',
        maxLimit: 100,
        rule: `
        · 최대 100자(띄어쓰기 포함)
        · 총 6줄 제한(빈 줄 포함)
        · 1줄 당 최대 22자
        `.trim(),
        type: 'textArea',
        value: '',
        placeholder: '광고 내용을 입력해주세요',
        size: 'lg',
      },
      {
        id: 'imageUrl',
        labelName: '배너 이미지',
        rule: `
        ·  이미지 크기: 1200 x 900 px
        · 텍스트는 이미지의 20% 이내 (로고 비율 제외)
        · 저속한 도안, 사진, 합성 이미지, 가독성이 떨어지는 이미지 사용 불가
        `.trim(),
        type: 'file',
        value: {
          name: '',
          url: '',
        },
        placeholder: '추가할 이미지를 드래그하거나 클릭하여 선택하세요',
      },
      {
        id: 'title',
        labelName: '이미지 하단 문구',
        rule: '· 최대 18자(띄어쓰기 포함)',
        maxLimit: 18,
        type: 'text',
        value: '',
        placeholder: '이미지 하단 문구를 입력해주세요',
      },
      {
        id: 'highlightColor',
        labelName: '하단 액션 컬러링',
        caution: '※ 광고 노출 1초 뒤 색상이 전환됩니다.',
        rule: '· 배경 색상의 6자리 코드 예) #00c896',
        type: 'colorPicker',
        value: '',
        placeholder: '예) #00c896',
      },
      {
        id: 'actionLabel',
        labelName: '광고 버튼',
        type: 'dropdown',
        value: actionLabelItems[0],
        items: actionLabelItems,
      },
    ],
  },
  postVideo: {
    name: '게시글(동영상)',
    fields: [
      {
        id: 'advertiserProfileImage',
        labelName: '광고주 로고',
        rule: `
        · 이미지 크기: 300 x 300 px
        · 파일 형식: png, jpg
        `.trim(),
        type: 'file',
        value: { name: '', url: '' },
        placeholder: '추가할 이미지를 드래그하거나 클릭하여 선택하세요',
      },
      {
        id: 'advertiserName',
        labelName: '광고주 이름',
        rule: `
        · 반드시 광고주명 또는 브랜드명으로 작성(광고주 정보와 일치하는 명칭)
        · 수식어 사용 불가
        `.trim(),
        type: 'text',
        value: '',
        placeholder: '광고주명 또는 브랜드명을 입력해주세요',
      },
      {
        id: 'description',
        labelName: '광고 내용',
        rule: `
        · 최대 100자(띄어쓰기 포함)
        · 총 6줄 제한(빈 줄 포함)
        · 1줄 당 최대 22자
        `.trim(),
        maxLimit: 100,
        type: 'textArea',
        value: '',
        placeholder: '광고 내용을 입력해주세요',
        size: 'lg',
      },
      {
        id: 'video',
        labelName: '영상',
        caution: `
          ※ 동영상 상세 가이드라인을 참고하여 제작해주세요.
          ※ 실제 광고 화면에서는 동영상 자동재생됩니다.
        `.trim(),
        rule: (
          <Text>
            · 영상 화면 비율 : 16:9 권장
            <br />
            · 해상도 : 제한 없음
            <br />
            · 동영상 재생 길이&용량 : 제한 없음
            <br />
            · 파일 형식 : 제한 없음
            <br />
            <br />
            <Text bold>주의사항</Text>
            · 저속한 영상, 표시, 음향 불가
            <br />
            · 저작권 및 초상권이 확보된 영상만 사용 가능
            <br />
            · 이용자의 주목도, 가독성 등을 저해하거나 또는 저해할 우려가 있는 영상 불가
            <br />
            · 기타 품질이 낮은 소재 등
            <br />
          </Text>
        ),
        type: 'plainText',
        value: '미리 보기에서는 동영상 재생 기능을 지원하지 않습니다.',
      },
      {
        id: 'imageUrl',
        labelName: '썸네일 이미지',
        caution: '※ 영상 화면 비율과 동일한 사이즈의 이미지가 필요합니다.',
        rule: '· 영상 화면 비율과 동일한 사이즈',
        type: 'file',
        value: {
          name: '',
          url: '',
        },
        placeholder: '추가할 이미지를 드래그하거나 클릭하여 선택하세요',
      },
      {
        id: 'title',
        labelName: '동영상 하단 문구',
        maxLimit: 18,
        rule: '· 최대 18자(띄어쓰기 포함)',
        type: 'text',
        value: '',
        placeholder: '동영상 하단 문구를 입력해주세요',
      },
      {
        id: 'highlightColor',
        labelName: '하단 액션 컬러링',
        caution: '※ 광고 노출 1초 뒤 색상이 전환됩니다.',
        rule: '· 배경 색상의 6자리 코드 예) #00c896',
        type: 'colorPicker',
        value: '',
        placeholder: '예) #00c896',
      },
      {
        id: 'actionLabel',
        labelName: '광고 버튼',
        type: 'dropdown',
        value: actionLabelItems[0],
        items: actionLabelItems,
      },
    ],
  },
  commentImage: {
    name: '댓글(이미지)',
    fields: [
      {
        id: 'advertiserProfileImage',
        labelName: '광고주 로고',
        rule: '· 이미지 크기: 300 x 300 px\n· 파일 형식: png, jpg',
        type: 'file',
        value: {
          name: '',
          url: '',
        },
        placeholder: '추가할 이미지를 드래그하거나 클릭하여 선택하세요',
      },
      {
        id: 'title',
        labelName: '광고주 이름',
        rule: '· 반드시 광고주명 또는 브랜드명으로 작성(광고주 정보와 일치하는 명칭)\n· 수식어 사용 불가 \n· 최대 8자 (띄어쓰기 포함)',
        type: 'text',
        value: '',
        maxLimit: 8,
        placeholder: '광고주명 또는 브랜드명을 입력해주세요',
      },
      {
        id: 'description',
        labelName: '광고 내용',
        maxLimit: 27,
        rule: '· 총 2줄 제한\n· 1줄 당 최대 13자(띄어쓰기 포함)\n· 기본 특수문자 가능(이모지, 이모티콘 불가)',
        type: 'textArea',
        value: '',
        placeholder: '광고 내용을 입력해주세요',
      },
      {
        id: 'imageUrl',
        labelName: '배너 이미지',
        rule: '· 이미지 크기: 4:3 비율(최소 400*300px 이상)\n· 이미지 내 텍스트 사용 불가 (로고 삽입 가능)\n· 저속한 도안, 사진, 합성 이미지 불가',
        type: 'file',
        value: {
          name: '',
          url: '',
        },
        placeholder: '추가할 이미지를 드래그하거나 클릭하여 선택하세요',
      },
      {
        id: 'actionLabel',
        labelName: '광고 버튼',
        type: 'dropdown',
        value: actionLabelItems[0],
        items: actionLabelItems,
      },
    ],
  },
  commentText: {
    name: '댓글(텍스트)',
    fields: [
      {
        id: 'advertiserProfileImage',
        labelName: '광고주 로고',
        rule: '· 이미지 크기: 300 x 300 px\n· 파일 형식: png, jpg',
        type: 'file',
        value: {
          name: '',
          url: '',
        },
        placeholder: '추가할 이미지를 드래그하거나 클릭하여 선택하세요',
      },
      {
        id: 'title',
        labelName: '광고주 이름',
        rule: '· 반드시 광고주명 또는 브랜드명으로 작성 (광고주 정보와 일치하는 명칭)\n· 수식어 사용 불가\n· 최대 8자 (띄어쓰기 포함) ',
        type: 'text',
        value: '',
        maxLimit: 8,
        placeholder: '광고주명 또는 브랜드명을 입력해주세요',
      },
      {
        id: 'description',
        labelName: '광고 내용',
        maxLimit: 48,
        rule: '· 총 2줄 제한\n· 1줄 당 최대 24자(띄어쓰기 포함)\n· 기본 특수문자 가능(이모지, 이모티콘 불가)',
        type: 'textArea',
        value: '',
        placeholder: '광고 내용을 입력해주세요',
      },
      {
        id: 'actionLabel',
        labelName: '광고 버튼',
        type: 'dropdown',
        value: actionLabelItems[0],
        items: actionLabelItems,
      },
    ],
  },
  classTab: {
    name: '클래스탭 배너',
    fields: [
      {
        id: 'title',
        labelName: '광고 제목',
        rule: '· 최대 12자(띄어쓰기 포함)',
        maxLimit: 12,
        type: 'text',
        value: '',
        placeholder: '브랜드명 또는 제목을 입력해주세요',
      },
      {
        id: 'description',
        labelName: '광고 내용',
        maxLimit: 25,
        rule: `
        · 총 2줄 제한, 1줄 당 최대 12자(띄어쓰기 포함)
        · 기본 특수문자 사용 가능
        `.trim(),
        type: 'textArea',
        value: '',
        placeholder: '광고 내용을 입력해주세요',
      },
      {
        id: 'imageUrl',
        labelName: '배너 이미지',
        rule: `
        · 이미지 크기 : 288 x 288 px
        · 파일 형식 : png / 50KB 미만
        · 이미지 내 텍스트 사용 불가(로고 삽입 가능)
        · 누끼 이미지(외곽을 딴 이미지, 배경색 없음) 권장
        · 누끼 이미지 사용이 불가능한 경우 Radius 96 적용
        `.trim(),
        type: 'file',
        value: {
          name: '',
          url: '',
        },
        placeholder: '추가할 이미지를 드래그하거나 클릭하여 선택하세요',
      },
    ],
  },
  closePopup: {
    name: '종료팝업',
    fields: [
      {
        id: 'imageUrl',
        labelName: '배너 이미지',
        rule: `
        · 이미지 크기 : 720 x 1080 px
        · 파일 형식 :  png, jpg / 120KB 미만
        · 이미지 가이드라인 없음(텍스트 비율 제한 없음, CTA 버튼 문구, 형식 자유)
        단, 이미지 내 반드시 다음 중 1가지 이상이 포함되어야 함
        1) 광고주명 2) 브랜드명 3) 로고
        · 그라데이션 적용 대상
        1) 이미지 배경이 단색이 아닌 경우
        2) 부득이하게 이미지가 잘릴 경우
        · 양 끝 높이 48px의 가이드라인에 단색으로 그라데이션 적용 필요(배경 색상 값과 동일)
        `.trim(),
        type: 'file',
        value: {
          name: '',
          url: '',
        },
        placeholder: '추가할 이미지를 드래그하거나 클릭하여 선택하세요',
      },
      {
        id: 'highlightColor',
        labelName: '배경 색상 값',
        caution: '※ 배너 이미지의 배경 색상과 동일하게 입력해주세요.',
        rule: '· 배경 색상의 6자리 코드 예) #00c896 ',
        type: 'colorPicker',
        value: '',
        placeholder: '예) #00c896',
      },
    ],
  },
  notificationTextlink: {
    name: '알림탭 배너(텍스트)',
    fields: [
      {
        id: 'advertiserProfileImage',
        labelName: '광고주 로고',
        rule: `
        · 이미지 크기: 300 x 300 px
        · 파일 형식: png, jpg
        `.trim(),
        type: 'file',
        value: {
          name: '',
          url: '',
        },
        placeholder: '추가할 이미지를 드래그하거나 클릭하여 선택하세요',
      },
      {
        id: 'advertiserName',
        labelName: '광고주 이름',
        rule: `
        · 반드시 광고주명 또는 브랜드명으로 작성(광고주 정보와 일치하는 명칭)
        · 수식어 사용불가
        `.trim(),
        type: 'text',
        value: '',
        placeholder: '광고주명 또는 브랜드명을 입력해주세요',
      },
      {
        id: 'title',
        labelName: '광고 제목',
        rule: `
        · 최대 12자(띄어쓰기 포함)
        · 교육 환경을 저해할 수 있는 내용 검수함
        · 기본 특수문자 사용 가능
        `.trim(),
        type: 'text',
        value: '',
        maxLimit: 12,
        placeholder: '브랜드명 또는 제목을 입력해주세요',
      },
      {
        id: 'description',
        labelName: '광고 내용',
        rule: `
        · 최대 22자(띄어쓰기 포함)
        · 교육 환경을 저해할 수 있는 내용 검수함
        · 기본 특수문자 사용 가능
        `.trim(),
        type: 'text',
        value: '',
        maxLimit: 22,
        placeholder: '광고 내용을 입력해주세요',
      },
    ],
  },
  notificationImagelink: {
    name: '알림탭 배너(이미지)',
    fields: [
      {
        id: 'advertiserProfileImage',
        labelName: '광고주 로고',
        rule: `
        · 이미지 크기: 300 x 300 px
        · 파일 형식: png, jpg
        `.trim(),
        type: 'file',
        value: {
          name: '',
          url: '',
        },
        placeholder: '추가할 이미지를 드래그하거나 클릭하여 선택하세요',
      },
      {
        id: 'advertiserName',
        labelName: '광고주 이름',
        rule: `
        · 반드시 광고주명 또는 브랜드명으로 작성(광고주 정보와 일치하는 명칭)
        · 수식어 사용불가
        `.trim(),
        type: 'text',
        value: '',
        placeholder: '광고주명 또는 브랜드명을 입력해주세요',
      },
      {
        id: 'description',
        labelName: '광고 내용',
        rule: `
        · 최대 22자(띄어쓰기 포함)
        · 교육 환경을 저해할 수 있는 내용 검수함
        · 기본 특수문자 사용 가능
        `.trim(),
        type: 'text',
        value: '',
        maxLimit: 22,
        placeholder: '광고 내용을 입력해주세요',
      },
      {
        id: 'imageUrl',
        labelName: '배너 이미지',
        rule: `
        · 이미지 크기 : 828 x 276 px
        · 파일 형식 : png / 100KB 미만
        · 이미지 내 텍스트 사용 불가(로고 삽입 가능)
        · 누끼 이미지(외곽을 딴 이미지, 배경색 없음) 권장
        · 이미지 상세 가이드는 별도 파일(PSD) 참고
        `.trim(),
        type: 'file',
        value: {
          name: '',
          url: '',
        },
        placeholder: '추가할 이미지를 드래그하거나 클릭하여 선택하세요',
      },
    ],
  },
  postBanner: {
    name: '포스트 배너',
    fields: [
      {
        id: 'imageUrl',
        labelName: '배너 이미지',
        rule: `
        · 이미지 크기 : 1125 x 360 px
        · 파일 형식 : png / 100KB 미만
        · 누끼 이미지(외곽을 딴 이미지, 배경색 없음) 권장
        · 이미지 상세 가이드는 별도 파일(PSD) 참고
        `.trim(),
        type: 'file',
        value: {
          name: '',
          url: '',
        },
        placeholder: '추가할 이미지를 드래그하거나 클릭하여 선택하세요',
      },
    ],
  },
})

export default previewFormFieldFactory
