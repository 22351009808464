/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { useState } from 'react'
import PropTypes from 'prop-types'

import arrowDownIcon from '@landing/assets/images/arrow_down.svg'
import DropdownOption from './DropdownOption'

const makeStyles = ({ isShowOptionBox, value }) => ({
  arrowDownIconWrap: () => css`
    margin-left: auto;
  `,
  label: ({ colors: { grey }, font, spacing }) => css`
    color: ${grey[900]};
    font-size: ${font.body2.fontSize};
    line-height: ${font.body2.lineHeight};
    margin-bottom: ${spacing()}rem;
  `,
  optionBox: ({ spacing }) => css`
    position: absolute;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 0px 6px rgba(0, 0, 0, 0.1);
    margin-top: ${spacing(0.5)}rem;
    padding: ${spacing()}rem 0;
    width: 100%;
    top: auto;
  `,
  root: css`
    position: relative;
    cursor: pointer;
  `,
  selectBox: ({ colors: { grey, green }, font, spacing }) => css`
    display: flex;
    color: ${value ? grey[900] : grey[400]};
    border: 1px solid ${isShowOptionBox ? green[100] : grey[400]};
    border-radius: 4px;
    font-size: ${font.body2.fontSize};
    line-height: ${font.body2.lineHeight};
    padding: ${spacing(2)}rem;
  `,
})

const Dropdown = ({
  customStyles,
  isRequired,
  items,
  label,
  selectOption,
  placeholder,
  value,
}) => {
  const [isShowOptionBox, setIsShowOptionBox] = useState(false)
  const styles = makeStyles({ isShowOptionBox, value })

  const handleSelectClick = () => {
    setIsShowOptionBox(!isShowOptionBox)
  }

  const optionClick = (name) => {
    setIsShowOptionBox(false)
    selectOption(name)
  }

  return (
    <div css={[styles.root, customStyles]}>
      <div css={styles.label}>
        {label}
        {isRequired ? ' *' : ''}
      </div>
      <div css={styles.selectBox} onClick={handleSelectClick}>
        {value || placeholder}
        <div css={styles.arrowDownIconWrap}>
          <img src={arrowDownIcon} alt="" />
        </div>
      </div>
      {isShowOptionBox && (
        <div css={styles.optionBox}>
          {items.map(name => (
            <DropdownOption key={name} name={name} selectOption={optionClick} />
          ))}
        </div>
      )}
    </div>
  )
}

Dropdown.defaultProps = {
  isRequired: false,
  label: '',
  customStyles: '',
}

Dropdown.propTypes = {
  customStyles: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  isRequired: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  selectOption: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
}

export default Dropdown
