import { forEach } from 'lodash'
import { createAction } from 'redux-actions'
import { http } from '@/shared/utils'
import { CLASSTING_AD_API_URL } from '@/shared/constants'

export const RESET_USER = 'user/RESET_USER'
export const USER_UPDATE_PASSWORD_POST_PENDING = 'user/UPDATE_PASSWORD_POST_PENDING'
export const USER_UPDATE_PASSWORD_POST_SUCCESS = 'user/UPDATE_PASSWORD_POST_SUCCESS'
export const USER_UPDATE_PASSWORD_POST_FAILURE = 'user/UPDATE_PASSWORD_POST_FAILURE'
export const USER_GET_SUCCESS = 'user/USER_UPDATE_GET_SUCCESS'
export const USER_GET_PENDING = 'user/USER_UPDATE_GET_PENDING'
export const USER_GET_FAILURE = 'user/USER_UPDATE_GET_FAILURE'
export const UPDATE_USER_PUT_SUCCESS = 'signUp/UPDATE_USER_PUT_SUCCESS'
export const UPDATE_USER_PUT_PENDING = 'signUp/UPDATE_USER_PUT_PENDING'
export const UPDATE_USER_PUT_FAILURE = 'signUp/UPDATE_USER_PUT_FAILURE'

export const resetUser = createAction(RESET_USER)
export const userUpdatePasswordPending = createAction(USER_UPDATE_PASSWORD_POST_PENDING)
export const userUpdatePasswordSuccess = createAction(USER_UPDATE_PASSWORD_POST_SUCCESS)
export const userUpdatePasswordFailure = createAction(USER_UPDATE_PASSWORD_POST_FAILURE)
export const fetchUserPending = createAction(USER_GET_PENDING)
export const fetchUserSuccess = createAction(USER_GET_SUCCESS)
export const fetchUserFailure = createAction(USER_GET_FAILURE)
export const updateUserPending = createAction(UPDATE_USER_PUT_PENDING)
export const updateUserSuccess = createAction(UPDATE_USER_PUT_SUCCESS)
export const updateUserFailure = createAction(UPDATE_USER_PUT_FAILURE)

export const updateUserPassword = data => (dispatch, getState) => {
  dispatch(userUpdatePasswordPending())
  return http.post(`${CLASSTING_AD_API_URL}/agencies/update_password`, { ...data })
    .then(res => (
      dispatch(userUpdatePasswordSuccess(res))
    ))
    .then(() => (
      getState().user
    ))
    .catch(err => (
      dispatch(userUpdatePasswordFailure(err.response))
    ))
}

export const fetchUser = () => (dispatch) => {
  dispatch(fetchUserPending())
  return http.get(`${CLASSTING_AD_API_URL}/agencies/me`)
    .then(res => dispatch(fetchUserSuccess(res)))
    .catch(err => (
      dispatch(fetchUserFailure(err.response))
    ))
}

export const updateUser = (data, userId) => (dispatch) => {
  const formData = new FormData()
  forEach(data, (value, key) => {
    formData.append(key, value || '')
  })
  dispatch(updateUserPending())
  return http.put(`${CLASSTING_AD_API_URL}/agencies/${userId}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
    .then(res => (
      dispatch(updateUserSuccess(res))
    ))
    .catch(err => (
      dispatch(updateUserFailure(err.response))
    ))
}
