/** @jsx jsx */
import { jsx, css } from '@emotion/react'

import {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react'
import PropTypes from 'prop-types'

import { uiStore } from '@landing/contexts/ui'
import { fadeInSlide, easeOutQuad } from '@landing/styles/animation'

const defaultDuration = 400

const makeStyles = ({ animationDone, duration, isShow }) => ({
  animation: () => css`
    animation-name: ${isShow ? fadeInSlide : 'none'};
    animation-timing-function: ${isShow ? easeOutQuad : 'ease'};
    animation-duration: ${(() => {
    if (isShow) {
      return (duration || defaultDuration) / 1000
    }

    return 0
  })()}s;
    animation-fill-mode: forwards;
    opacity: 0;
    transform: translateY(20px);
    height: 100%;
  `,
  root: () => css`
    height: 100%;
    overflow: ${animationDone ? 'visible' : 'hidden'};
  `,
})

const AppearanceAnimation = ({ children, duration }) => {
  const {
    state: { headerHeight },
  } = useContext(uiStore)
  const [isShow, setIsShow] = useState(false)
  const [animationDone, setAnimationDone] = useState(false)
  const elRef = useRef(null)
  const styles = makeStyles({
    animationDone,
    duration,
    isShow,
  })

  const checkElementIsShow = useCallback(() => {
    const { top } = elRef.current.getBoundingClientRect()
    if (top - window.innerHeight / 2 <= 0) {
      setIsShow(true)
      setTimeout(
        () => {
          setAnimationDone(true)
        },
        duration || defaultDuration,
      )
    }
  }, [elRef.current, headerHeight])

  const handleScroll = useCallback(() => {
    checkElementIsShow()
  }, [])

  useEffect(() => {
    if (isShow) {
      window.removeEventListener('scroll', handleScroll)
    } else {
      window.addEventListener('scroll', handleScroll)
      checkElementIsShow()
    }
  }, [isShow])

  return (
    <div ref={elRef} css={styles.root}>
      <div css={styles.animation}>{children}</div>
    </div>
  )
}

AppearanceAnimation.defaultProps = {
  children: [],
  duration: defaultDuration,
}

AppearanceAnimation.propTypes = {
  children: PropTypes.node,
  duration: PropTypes.number,
}

export default AppearanceAnimation
