import moment from 'moment'

const EXPIRATION_DAYS = 1
const expirationKey = key => `${key}_exp`

export const removeItem = (key) => {
  localStorage.removeItem(expirationKey(key))
  return localStorage.removeItem(key)
}

export const getItem = (key) => {
  const expiredAt = parseInt(localStorage.getItem(expirationKey(key)), 10)
  if (expiredAt && moment().valueOf() > expiredAt) {
    removeItem(key)
  }

  return localStorage.getItem(key)
}

export const setItem = (key, value) => {
  localStorage.setItem(expirationKey(key), moment().add(EXPIRATION_DAYS, 'days').valueOf())
  return localStorage.setItem(key, value)
}

export const getSessionItem = key => sessionStorage.getItem(key)

export const setSessionItem = (key, value) => sessionStorage.setItem(key, value)
