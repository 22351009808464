/** @jsx jsx */
import { css, jsx } from '@emotion/react'

import { LineBreak } from '../unit/string'

const makeStyles = () => ({
  description: ({ spacing }) => css`
    :first-of-type {
      margin-bottom: ${spacing(2)}rem;
    }
  `,
  descriptionWrap: ({ colors: { grey }, font }) => css`
    color: ${grey[500]};
    font-size: ${font.body4.fontSize};
    line-height: ${font.body4.lineHeight};
  `,
  root: ({ colors: { grey }, spacing }) => css`
    background-color: ${grey[50]};
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: ${spacing(2)}rem;
    padding: ${spacing(2.5)}rem ${spacing(3)}rem;
  `,
  title: ({ colors: { grey }, font, spacing }) => css`
    color: ${grey[600]};
    font-size: ${font.body3.fontSize};
    line-height: ${font.body3.lineHeight};
    margin-bottom: ${spacing()}rem;
  `,
})

const Agreement = () => {
  const styles = makeStyles()

  return (
    <div css={styles.root}>
      <p css={styles.title}>개인정보 수집 및 이용 동의 내용</p>
      <div css={styles.descriptionWrap}>
        <p css={styles.description}>
          <LineBreak
            text={
              '· 목적 : 클래스팅 광고/제휴 비즈니스 문의, 상담/견적, 소재자료 요청 등에 대한 처리\n· 항목 : 회사명, 사업자분류, 성명, 직함, 이메일, 연락처\n· 보유기간 : 문의 등록 후, 1년 까지 보관'
            }
          />
        </p>
        <p css={styles.description}>
          제공정보는 광고 문의를 위한 목적으로만 사용됩니다. 문의를 등록하시면
          해당 정보 수집에 동의하는 것으로 간주합니다.
        </p>
      </div>
    </div>
  )
}

export default Agreement
