import { handleActions } from 'redux-actions'
import {
  RESET_AD_PAYMENT,
  SET_AD_PAYMENT_PENDING,
  SET_AD_PAYMENT_SUCCESS,
  SET_AD_PAYMENT_FAILURE,
  FETCH_AD_PAYMENT_PENDING,
  FETCH_AD_PAYMENT_SUCCESS,
  FETCH_AD_PAYMENT_FAILURE,
  FETCH_AD_PAYMENTS_PENDING,
  FETCH_AD_PAYMENTS_SUCCESS,
  FETCH_AD_PAYMENTS_FAILURE,
} from './actions'

export const initialState = {
  pending: false,
  status: 0,
  paymentData: {},
  payments: [],
}

export default handleActions({
  [RESET_AD_PAYMENT]: () => ({
    payments: [],
  }),

  [SET_AD_PAYMENT_PENDING]: state => ({
    ...state,
    pending: true,
  }),

  [SET_AD_PAYMENT_SUCCESS]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      status,
      paymentData: data,
      pending: false,
    }
  },

  [SET_AD_PAYMENT_FAILURE]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      status,
      data,
      pending: false,
    }
  },

  [FETCH_AD_PAYMENT_PENDING]: state => ({
    ...state,
    pending: true,
  }),

  [FETCH_AD_PAYMENT_SUCCESS]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      status,
      paymentData: data,
      pending: false,
    }
  },

  [FETCH_AD_PAYMENT_FAILURE]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      status,
      data,
      pending: false,
    }
  },

  [FETCH_AD_PAYMENTS_PENDING]: state => ({
    ...state,
    pending: true,
  }),

  [FETCH_AD_PAYMENTS_SUCCESS]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      status,
      payments: data,
      pending: false,
    }
  },

  [FETCH_AD_PAYMENTS_FAILURE]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      status,
      data,
      pending: false,
    }
  },

}, initialState)
