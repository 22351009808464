/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { useContext } from 'react'

import { useNavigationPointing } from '@landing/hooks/navigation'
import { uiStore, VIEW_SIZE_MOBILE } from '@landing/contexts/ui'
import Content from './Content'
import SectionHeader from './SectionHeader'

const makeStyles = () => ({
  wrap: ({ mq }) => css`
    ${mq({
    alignItems: 'center',
    display: 'flex',
    height: ['72rem', '131rem'],
    justifyContent: 'center',
  })}
  `,
})
const navId = 'process'

const Process = () => {
  const {
    state: { viewSize },
  } = useContext(uiStore)
  const styles = makeStyles()
  const [wrapEl] = useNavigationPointing(navId)

  return (
    <section>
      <SectionHeader />
      <div css={styles.wrap} ref={wrapEl} id={navId}>
        <Content isMobileViewSize={viewSize === VIEW_SIZE_MOBILE} />
      </div>
    </section>
  )
}

export default Process
