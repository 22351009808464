import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withTracker } from '@/shared/enhancers'
import { NavigationBar, Linear } from '@/shared/components'
import { SignUpForm } from '../components'
import { signUp, signUpResetState } from '../actions'

const styles = ({
  signUpFormContainer: {
    maxWidth: '846px',
    margin: '0 auto',
    padding: '64px 0 0 0',
  },
})

const mapStateToProps = state => ({
  pending: state.signUp.pending,
  status: state.signUp.status,
})

const mapDispatchToProps = dispatch => ({
  onSignUp: values => dispatch(signUp(values)),
  onSignUpResetState: () => dispatch(signUpResetState()),
})

class SignUpPage extends Component {
  componentDidMount() {
    const { onSignUpResetState } = this.props
    onSignUpResetState()
  }

  render() {
    const {
      classes, onSignUp, history, status, pending,
    } = this.props
    return (
      <div>
        {pending && <Linear />}
        <NavigationBar />
        <SignUpForm
          container={classes.signUpFormContainer}
          status={status}
          onSignUp={onSignUp}
          history={history}
        />
      </div>
    )
  }
}

SignUpPage.propTypes = {
  classes: PropTypes.shape({
    signUpFormContainer: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({}).isRequired,
  onSignUp: PropTypes.func.isRequired,
  onSignUpResetState: PropTypes.func.isRequired,
  status: PropTypes.number.isRequired,
  pending: PropTypes.bool.isRequired,
}

export default compose(
  withTracker,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(SignUpPage)
