/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { useContext } from 'react'

import { uiStore, VIEW_SIZE_MOBILE, VIEW_SIZE_PC } from '@landing/contexts/ui'
import { useNavigationPointing } from '@landing/hooks/navigation'
import { DownloadButton } from '@landing/components/unit/button'

import advertisersImage from '@landing/assets/images/advertisers.png'
import advertisersImageForMobile1 from '@landing/assets/images/advertisers_m_1.png'
import advertisersImageForMobile2 from '@landing/assets/images/advertisers_m_2.png'
import advertisersImageForMobile3 from '@landing/assets/images/advertisers_m_3.png'

import { AppearanceAnimation } from '../unit/animation'
import { Slider } from '../unit/slider'


const makeStyles = () => ({
  btnWrap: () => css`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  imgWrap: ({ mq, spacing }) => css`
    ${mq({
    height: ['39.3rem', 'auto'],
    marginBottom: ['0', `${spacing(5)}rem`],
    marginTop: [`${spacing(3)}rem`, '0'],
    width: ['22rem', '79.4rem'],
  })}

    img {
      display: block;
      width: 100%;
    }
  `,
  root: ({ mq, spacing }) => css`
    ${mq({
    paddingTop: [`${spacing(2.5)}rem`, '0'],
  })}
  `,
  subTitle: ({
    colors: { grey }, font, mq, spacing,
  }) => css`
    ${mq({
    color: grey[700],
    fontSize: [font.body2.fontSize, font.body1.fontSize],
    lineHeight: [font.body2.lineHeight, font.body1.lineHeight],
    marginBottom: [`${spacing(0.5)}rem`, '0'],
  })}
  `,
  title: ({ colors: { grey }, font, mq }) => css`
    ${mq({
    color: grey[900],
    fontSize: [font.title3.fontSize, font.title1.fontSize],
    fontWeight: 'bold',
    lineHeight: [font.title3.lineHeight, font.title1.lineHeight],
  })}
  `,
  titleWrap: ({ mq, spacing }) => css`
    ${mq({
    marginBottom: [`${spacing(2)}rem`, `${spacing(5)}rem`],
  })}
  `,
  wrap: ({ mq }) => css`
    ${mq({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: ['72rem', '98.4rem'],
    justifyContent: 'center',
    margin: ['0 auto', '0'],
    textAlign: 'center',
    width: ['22rem', 'auto'],
  })}
  `,
})

const navId = 'caseOfExecution'
const advertisersImagesForMobile = [
  advertisersImageForMobile1,
  advertisersImageForMobile2,
  advertisersImageForMobile3,
]

const CaseOfExecution = () => {
  const styles = makeStyles()
  const [wrapEl] = useNavigationPointing(navId)
  const {
    state: { viewSize },
  } = useContext(uiStore)

  return (
    <section css={styles.root} ref={wrapEl} id={navId}>
      <div css={styles.wrap}>
        <div css={styles.titleWrap}>
          <AppearanceAnimation>
            <p css={styles.subTitle}>
              {viewSize === VIEW_SIZE_PC
                ? '국내외 공신력있는 광고주님들과 함께'
                : '공신력있는 광고주님들과 함께'}
            </p>
            <h2 css={styles.title}>
              {viewSize === VIEW_SIZE_PC
                ? '주요 광고주 및 집행사례'
                : '집행사례'}
            </h2>
          </AppearanceAnimation>
        </div>
        <div>
          <AppearanceAnimation>
            {viewSize === VIEW_SIZE_MOBILE && (
              <div css={styles.btnWrap}>
                <DownloadButton action="https://ads.classting.com" color="blue">
                  집행 사례 자료 다운로드
                </DownloadButton>
              </div>
            )}
            {viewSize === VIEW_SIZE_PC ? (
              <figure css={styles.imgWrap}>
                <img src={advertisersImage} alt="" />
              </figure>
            ) : (
              <div css={styles.imgWrap}>
                <Slider items={advertisersImagesForMobile} />
              </div>
            )}
            {viewSize === VIEW_SIZE_PC && (
              <div css={styles.btnWrap}>
                <DownloadButton action="https://ads.classting.com" color="blue">
                  집행 사례 자료 다운로드
                </DownloadButton>
              </div>
            )}
          </AppearanceAnimation>
        </div>
      </div>
    </section>
  )
}

export default CaseOfExecution
