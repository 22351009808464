import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { makeStyles } from '@material-ui/core/styles'

import { Text } from '../atoms'

const useItemStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    marginRight: '24px',
    cursor: 'pointer',

    '& > .isSelected': {
      color: '#00c896',
    },
    '& > .isSelected + .line': {
      position: 'absolute',
      bottom: '0',
      width: '100%',
      borderRadius: '2px',
      height: '2px',
      background: '#00c896',
    },
  },
}))

const useNavigationStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    height: '44px',
  },
}))

const Item = ({
  id, name, isSelected, setSelectedItem,
}) => {
  const classes = useItemStyles({ isSelected })

  const handleItemClick = () => {
    setSelectedItem(id)
  }

  return (
    <div className={classes.root} onClick={handleItemClick}>
      <Text className={cn({ isSelected })} bold={isSelected}>{name}</Text>
      { isSelected && <span className="line" /> }
    </div>
  )
}

const Navigation = ({ items, selectedItem, setSelectedItem }) => {
  const classes = useNavigationStyles()

  return (
    <div className={classes.root}>
      {
        items.map(({ id, name }) => (
          <Item
            key={id}
            id={id}
            name={name}
            isSelected={selectedItem === id}
            setSelectedItem={setSelectedItem}
          />
        ))
      }
    </div>
  )
}

Item.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  setSelectedItem: PropTypes.func.isRequired,
}

Navigation.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  selectedItem: PropTypes.string.isRequired,
  setSelectedItem: PropTypes.func.isRequired,
}

export default Navigation
