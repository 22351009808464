import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withTracker } from '@/shared/enhancers'
import { setSessionItem } from '@/shared/utils'
import { ReportContainer } from '../containers'

const styles = ({})

class PublicReportPage extends Component {
  state = {
    isPrivate: false,
  }

  componentWillMount() {
    const { match: { params: { guestToken } } } = this.props
    setSessionItem('guestToken', guestToken)
  }

  render() {
    const {
      match, location, history, match: { params: { guestToken } },
    } = this.props
    const { isPrivate } = this.state

    return (
      <ReportContainer
        match={match}
        guestToken={guestToken}
        history={history}
        location={location}
        isPrivate={isPrivate}
      />
    )
  }
}

PublicReportPage.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      guestToken: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
}

export default compose(
  withTracker,
  withStyles(styles),
)(PublicReportPage)
