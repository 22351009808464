import React from 'react'
import PropTypes from 'prop-types'

import { getToCommas } from '@/shared/utils'

import {
  GRAPH_STATS,
  STAT_VTR,
  STAT_CTR,
} from '@/shared/constants/graph'

const formatTooltip = (id, value) => (
  id === STAT_VTR || id === STAT_CTR
    ? `${value === 0 ? '0.00' : value}%` : getToCommas(value))

const ToolTip = ({ slice }) => (
  <div
    style={{
      background: 'white',
      padding: '9px 12px',
      border: '1px solid #ccc',
      fontSize: 12,
    }}
  >
    <div>{`날짜: ${slice.points[0].data.x}`}</div>
    {slice.points.map(point => (
      <div
        key={point.id}
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div>
          <svg width="17" height="15">
            <rect
              x="0"
              y="3"
              rx="10"
              ry="10"
              width="10"
              height="10"
              style={{ fill: point.serieColor }}
            />
          </svg>
        </div>
        <div>
          <strong>{`${GRAPH_STATS[point.serieId].label}: `}</strong>
          <span>{formatTooltip(point.serieId, point.data.yFormatted)}</span>
        </div>
      </div>
    ))}
  </div>
)

ToolTip.propTypes = {
  slice: PropTypes.any,
}

ToolTip.defaultProps = {
  slice: {},
}

export default ToolTip
