import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import { Text, Skeleton } from '../atoms'

const useStyles = makeStyles(() => ({
  root: ({ backgroundColor }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '360px',
    height: '640px',
    background: backgroundColor || '#fff',
  }),
  adImage: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0',

    '& img': {
      display: 'block',
      width: '100%',
      color: 'transparent',
    },
  },
}))

const SkeletonContent = () => (
  <div
    style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#EEEEEE',
    }}
  >
    <Text>파일을 첨부하시면 해당 이미지가 노출됩니다</Text>
  </div>
)

const ClosePopupAd = ({ backgroundColor, imageUrl }) => {
  const classes = useStyles({ backgroundColor })

  return (
    <div className={classes.root}>
      <Skeleton
        width="100%"
        height="100%"
        skeletonContent={<SkeletonContent />}
        isShow={!!imageUrl}
      >
        <figure className={classes.adImage}>
          <img src={imageUrl} alt="광고 이미지" />
        </figure>
      </Skeleton>
    </div>
  )
}

ClosePopupAd.defaultProps = {
  backgroundColor: '#fff',
}

ClosePopupAd.propTypes = {
  backgroundColor: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
}

export default ClosePopupAd
