/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import PropTypes from 'prop-types'

import DownloadIcon from '@landing/assets/images/download.svg'
import Button from './Button'

const makeStyles = () => ({
  iconWrap: ({ mq, spacing }) => css`
    ${mq({
    display: 'inline-block',
    height: ['1.6rem', '2rem'],
    marginLeft: `${spacing(0.5)}rem`,
    width: ['1.6rem', '2rem'],
  })}

    svg {
      ${mq({
    width: ['1.1rem', '1.2rem'],
  })}
    }
  `,
})

const DownloadButton = ({
  action, color, children, fullWidth,
}) => {
  const styles = makeStyles()

  return (
    <a href={action} download>
      <Button color={color} fullWidth={fullWidth}>
        {children}
        <div css={styles.iconWrap}>
          <img src={DownloadIcon} alt="광고 상품소개서 다운로드" />
        </div>
      </Button>
    </a>
  )
}

DownloadButton.defaultProps = {
  fullWidth: false,
  children: [],
  color: 'green',
}

DownloadButton.propTypes = {
  action: PropTypes.string.isRequired,
  children: PropTypes.node,
  color: PropTypes.oneOf(['green', 'blue']),
  fullWidth: PropTypes.bool,
}

export default DownloadButton
