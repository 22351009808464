/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import icChevronRight from '@/shared/assets/ic-chevron-right.svg'
import icVideoSoundOn from '@/shared/assets/ic-video-soundon.svg'
import { Avatar, Skeleton, Text } from '../atoms'

const useStyles = makeStyles(() => ({
  root: {
    width: '360px',
    background: '#fff',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    padding: '12px 12px 10px 12px',
  },
  advertiserInfo: {
    display: 'flex',
  },
  advertiserProfile: {
    width: '36px',
    height: '36px',
    borderRadius: '50%',
    overflow: 'hidden',
    border: 'solid 1px rgba(0, 0, 0, 0.1)',
    margin: '0px',

    '& img': {
      display: 'block',
      width: '100%',
    },
  },
  advertiserName: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    marginLeft: '8px',
    marginBottom: '0px',
  },
  description: {
    marginTop: '12px',
    marginBottom: '0px',
  },
  descriptionText: {
    wordBreak: 'break-all',
    whiteSpace: 'break-spaces',
  },
  content: {
    position: 'relative',
  },
  thumbnail: {
    width: '100%',
    margin: '0px',

    '& img': {
      display: 'block',
      width: '100%',
    },
  },
  videoUnit: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
  },
  more: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    top: '12px',
    right: '12px',
    padding: '8px 12px',
    color: '#FFF',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
  },
  icRightArrow: {
    marginLeft: '4px',
  },
  volume: {
    position: 'absolute',
    bottom: '12px',
    right: '12px',
  },
  footer: ({ highlightColor }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    height: '52px',
    background: `${!highlightColor ? '#fff' : highlightColor}`,
    padding: '10px 12px',
    alignItems: 'center',
  }),
  title: {
    flex: '1',
  },
  actionLabel: ({ highlightColor }) => ({
    flexShrink: '0',
    boxSizing: 'border-box',
    padding: '6px 14px',
    border: `1px solid ${!highlightColor ? '#757575' : '#fff'}`,
    borderRadius: '4px',
    marginLeft: '12px',
  }),
}))

const ImageSkeletonContent = () => (
  <div
    style={{
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Text color="gray">
      파일을 첨부하시면 해당 이미지가 노출됩니다.
    </Text>
  </div>
)

const PostAd = ({
  advertiserName,
  advertiserProfileImage,
  actionLabel,
  highlightColor,
  description,
  imageUrl,
  isVideoType,
  title,
}) => {
  const classes = useStyles({ highlightColor })

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.advertiserInfo}>
          <Avatar src={advertiserProfileImage} alt="광고주 이미지" />
          <div className={classes.advertiserName}>
            <Skeleton width="107px" height="16px" isShow={!!advertiserName}>
              <Text bold color="darkGray" component="p">
                {advertiserName}
              </Text>
            </Skeleton>
            <Text size="xs" color="lightGray2">Sponsored</Text>
          </div>
        </div>
        <div className={classes.description}>
          <Skeleton width="107px" height="16px" isShow={!!description}>
            <Text className={classes.descriptionText} color="darkGray" component="p" size="md">{description}</Text>
          </Skeleton>
          <Skeleton isShow={!!description} width="158px" height="16px" marginTop="5px" />
          <Skeleton isShow={!!description} width="244px" height="16px" marginTop="5px" />
        </div>
      </div>
      <Skeleton
        height="240px"
        isShow={!!imageUrl}
        skeletonContent={<ImageSkeletonContent />}
      >
        <div className={classes.content}>
          <figure className={classes.thumbnail}>
            <img src={imageUrl} alt="광고 썸네일" />
          </figure>
          {
            isVideoType && (
              <div className={classes.videoUnit}>
                <div className={classes.more}>
                  더 알아보기
                  <img className={classes.icRightArrow} src={icChevronRight} alt="화살표 아이콘" />
                </div>
                <div className={classes.volume}>
                  <img src={icVideoSoundOn} alt="볼륨 아이콘" />
                </div>
              </div>
            )
          }
        </div>
      </Skeleton>
      <div className={classes.footer}>
        <Skeleton width="174px" height="16px" isShow={!!title}>
          <Text className={classes.title} color={!highlightColor ? 'darkGray' : 'white'}>{title}</Text>
        </Skeleton>
        <Text
          component="div"
          className={classes.actionLabel}
          color={!highlightColor ? 'gray' : 'white'}
        >
          {actionLabel}
        </Text>
      </div>
    </div>
  )
}

PostAd.defaultProps = {
  advertiserProfileImage: '',
  actionLabel: '',
  highlightColor: '',
  imageUrl: '',
  title: '',
}

PostAd.propTypes = {
  advertiserName: PropTypes.string.isRequired,
  advertiserProfileImage: PropTypes.string,
  actionLabel: PropTypes.string,
  highlightColor: PropTypes.string,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  isVideoType: PropTypes.bool.isRequired,
  title: PropTypes.string,
}

export default PostAd
