import React from 'react'
import PropTypes from 'prop-types'

import Text from './Text'

const Caution = ({ className, text, size }) => <Text component="p" className={className} color="lightGray2" size={size}>{text}</Text>

Caution.defaultProps = {
  size: 'sm',
  className: '',
}

Caution.propTypes = {
  text: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  className: PropTypes.string,
}

export default Caution
