import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'

import {
  Layout,
} from '@/shared/components'
import {
  CampaignReport,
  AdSummaryReport,
  AdReportsDetail,
  ShareButton,
  ExportButton,
} from '../components'
import {
  getCampaignReportStartDateSelector,
  getCampaignReportEndDateSelector,
  getCampaignReportTitleSelector,
} from '../selectors'

import {
  fetchCampaign,
  handleAdCountFromNewPipeline,
  fetctAdSets,
} from '../actions'

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
  buttonGroup: {
    paddingBottom: '14px',
  },
  tableCell: {
    border: '1px solid rgba(224, 224, 224, 1)',
    padding: '12px',
    whiteSpace: 'break-spaces',
  },
  exportPublicUrl: {
    color: 'rgba(0, 0, 0, 0.54)',
    backgroundColor: '#f3f3f3',
    height: '64px',
    borderRadius: '2px',
    padding: '14px',
    margin: '0 0 14px 0',
  },
  reportToolbar: {
    position: 'relative',
    height: '64px',
  },
  reportTitle: {
    display: 'inline-block',
  },
  progressButton: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dateChangeButton: {
    marginLeft: '16px',
    color: '#ffffff',
  },
  copyToText: {
    margin: '14px 0 14px 0px',
  },
  progressContent: {
    height: '160px',
    position: 'relative',
  },
  progressPosition: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  campaignDetailReportWrap: {
    marginTop: 20,
  },
})

const ReportContainer = ({
  onMount,
  onAdCountCacheMount,
  onAdSetMount,
  match: { params: { campaignId } },
  campaignReportTitle,
  campaignReportStartDate,
  campaignReportEndDate,
  classes,
  pending,
  isPrivate,
  refresh,
}) => {
  useEffect(() => {
    onMount(campaignId)
      .then(() => onAdSetMount(campaignId))
      .then((res) => {
        const { data } = res.payload

        const advertisements = data.map(item => item.advertisements)
        const adIds = advertisements.flatMap(item => item).map(item => item.id)

        if (campaignReportStartDate && campaignReportEndDate) {
          let endDate = campaignReportEndDate

          const nowMoment = moment().add(-1, 'days')
          const endDateMoment = moment(campaignReportEndDate)

          if (endDateMoment.isAfter(nowMoment)) {
            endDate = nowMoment.format('YYYY-MM-DD')
          }

          onAdCountCacheMount({
            adIds,
            startDate: campaignReportStartDate,
            endDate,
            campaignId,
          })
        }
      })
  }, [campaignReportStartDate, campaignReportEndDate])

  return (
    <Layout
      pageTitle={campaignReportTitle || ''}
      isPrivate={isPrivate}
      refresh={refresh}
    >
      {!pending && (
        <>
          <ExportButton
            classes={classes}
          />
          <ShareButton
            classes={classes}
            campaignId={campaignId}
            isPrivate={isPrivate}
          />
        </>
      )}

      <CampaignReport classes={classes} />

      <AdSummaryReport classes={classes} />

      <AdReportsDetail classes={classes} campaignId={campaignId} />
    </Layout>
  )
}

ReportContainer.propTypes = {
  classes: PropTypes.shape({
    progressContent: PropTypes.string,
    progressPosition: PropTypes.string,
    progressButton: PropTypes.string,
    tableCell: PropTypes.string,
    reportToolbar: PropTypes.string,
    reportTitle: PropTypes.string,
    dateChangeButton: PropTypes.string,
    exportPublicUrl: PropTypes.string,
    rightIcon: PropTypes.string,
    iconSmall: PropTypes.string,
    copyToText: PropTypes.string,
  }).isRequired,
  onMount: PropTypes.func.isRequired,
  onAdCountCacheMount: PropTypes.func.isRequired,
  onAdSetMount: PropTypes.func.isRequired,
  campaignReportTitle: PropTypes.string,
  campaignReportStartDate: PropTypes.string,
  campaignReportEndDate: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      campaignId: PropTypes.string,
    }),
  }).isRequired,
  pending: PropTypes.bool.isRequired,
  isPrivate: PropTypes.bool.isRequired,
  refresh: PropTypes.bool.isRequired,
}

ReportContainer.defaultProps = {
  campaignReportTitle: '',
  campaignReportStartDate: '',
  campaignReportEndDate: '',
}

export default connect(
  state => ({
    pending: state.campaign.pending,
    campaignReportTitle: getCampaignReportTitleSelector(state),
    refresh: state.campaign.refresh,
    campaignReportStartDate: getCampaignReportStartDateSelector(state),
    campaignReportEndDate: getCampaignReportEndDateSelector(state),
  }),
  {
    onMount: fetchCampaign,
    onAdCountCacheMount: handleAdCountFromNewPipeline,
    onAdSetMount: fetctAdSets,
  },
)(withStyles(styles)(ReportContainer))
