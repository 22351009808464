import { withTracker } from '@/shared/enhancers'
import LegacyTodayPage from './page'

export default [
  {
    key: 'legacy-today',
    requiresAuth: true,
    exact: true,
    path: '/legacy-today',
    component: withTracker(LegacyTodayPage),
  },
]
