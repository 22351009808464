import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  withStyles, Table, TableBody, TableCell, TableHead, TableRow, Paper, TableSortLabel,
} from '@material-ui/core'
import { ArrowDropDown } from '@material-ui/icons'
import classNames from 'classnames'
import { map } from 'lodash'
import { getItem } from '@/shared/utils'
import Tooltip from '@/shared/components/Tooltip'

const styles = ({
  root: {
    width: '100%',
    boxShadow: 'none',
  },
  table: {
    minWidth: 700,
  },
  rowBackground: {
    background: '#f5f5f5',
  },
  tableCell: {
    borderTop: '1px solid #dbdbdb',
    borderBottom: '1px solid #dbdbdb',
    padding: '18px 24px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 600,
  },
  tableCellCenter: {
    textAlign: 'center',
  },
  tableSortLabel: {
    display: 'block',
  },
})

class TableHeader extends Component {
  createSortHandler = property => (event) => {
    const { onRequestSort } = this.props
    onRequestSort(event, property)
  }

  isAdmin = () => JSON.parse(getItem('isAdmin'))

  getTableLabel = (row) => {
    const { adPackageType } = this.props

    if (row.id === 'guaranteedExposeView' && adPackageType === 'region') {
      return '예상 노출 수'
    }

    if (row.id === 'vtr') {
      return (
        <Tooltip
          message="동영상이 게시된 일자의 재생 수 / 동영상이 게시된 일자의 노출 수"
          title={row.label}
          iconStyle={{ fontSize: 13, margin: '-1px 0 0 1px' }}
        />
      )
    }

    if (row.id === 'watch') {
      return (
        <Tooltip
          message="3초 이상 재생 수 기준"
          title={row.label}
          iconStyle={{ fontSize: 13 }}
        />
      )
    }

    return row.label
  }

  render() {
    const { createSortHandler, isAdmin, getTableLabel } = this
    const {
      classes, order, rows, children, isHideRow,
      isCampaignList, adPackageType,
    } = this.props

    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead className={classes.rowBackground}>
            <TableRow>
              <>
                {map(rows, (row, index) => {
                  if (isHideRow && (row.id === 'watch' || row.id === 'vtr')) return null
                  if (!isAdmin() && row.id === 'agency_ids') return null
                  if (adPackageType === 'region' && row.id === 'cpm') return null
                  return (
                    <TableCell
                      colSpan={row.colSpan || 1}
                      className={row.alignCenter
                        ? classNames(classes.tableCell, classes.tableCellCenter)
                        : classes.tableCell}
                      key={index}
                      numeric={row.numeric}
                      padding={row.disablePadding ? 'none' : 'default'}
                      sortDirection={order[row.id] && row.isOrder ? order[row.id] : false}
                    >
                      {row.isOrder
                        ? (
                          <TableSortLabel
                            className={classes.tableSortLabel}
                            active={order[row.id] !== undefined}
                            direction={order[row.id]}
                            onClick={createSortHandler(row.id)}
                            IconComponent={ArrowDropDown}
                          >
                            {getTableLabel(row)}
                          </TableSortLabel>
                        )
                        : getTableLabel(row)
                    }
                    </TableCell>
                  )
                })}
                {isAdmin() && isCampaignList
                && (
                <TableCell
                  colSpan={1}
                  className={classNames(classes.tableCell, classes.tableCellCenter)}
                  numeric={false}
                  padding="default"
                >
                  소재 수정
                </TableCell>
                )
              }
              </>
            </TableRow>
          </TableHead>
          <TableBody>
            {children}
          </TableBody>
        </Table>
      </Paper>
    )
  }
}

TableHeader.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    table: PropTypes.string,
    rowBackground: PropTypes.string,
    tableCell: PropTypes.string,
    tableCellCenter: PropTypes.string,
    tableSortLabel: PropTypes.string,
  }).isRequired,
  onRequestSort: PropTypes.func,
  order: PropTypes.shape({}),
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  children: PropTypes.node.isRequired,
  isHideRow: PropTypes.bool,
  isCampaignList: PropTypes.bool,
  adPackageType: PropTypes.string,
}

TableHeader.defaultProps = {
  order: {},
  onRequestSort: () => {},
  isHideRow: true,
  isCampaignList: false,
  adPackageType: 'impression',
}

export default withStyles(styles)(TableHeader)
