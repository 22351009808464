import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    width: ({ width }) => width,
    height: ({ height }) => height,
    background: ({ theme }) => (theme === 'white' ? 'rgba(0, 0, 0, 0.06)' : 'rgba(255, 255, 255, 0.06)'),
    marginTop: ({ marginTop }) => marginTop,
    borderRadius: ({ borderRadius }) => borderRadius,
  },
})

const Skeleton = ({
  isShow,
  width,
  height,
  theme,
  children,
  marginTop,
  borderRadius,
  skeletonContent,
}) => {
  const classes = useStyles({
    width, height, theme, marginTop, borderRadius,
  })

  if (isShow) return children || null
  return (
    <div className={classes.root}>
      {skeletonContent}
    </div>
  )
}

Skeleton.defaultProps = {
  theme: 'white',
  width: '100%',
  height: '20px',
  marginTop: '0px',
  borderRadius: '0px',
  skeletonContent: <></>,
}

Skeleton.propTypes = {
  theme: PropTypes.oneOf(['white', 'dark']),
  isShow: PropTypes.bool.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  marginTop: PropTypes.string,
  borderRadius: PropTypes.string,
  children: PropTypes.node.isRequired,
  skeletonContent: PropTypes.element,
}

export default Skeleton
