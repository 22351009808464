import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import {
  isEmpty, find, filter, map, head,
} from 'lodash'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import queryString from 'query-string'
import { withTracker } from '@/shared/enhancers'
import { CircularUnderLoad, Layout, Stepper } from '@/shared/components'
import { fetchCampaign } from '../../campaign/actions'
import { AdForm } from '../components'
import {
  fetchAdPackage,
  fetchThemes,
  fetchAdSet,
  resetAd,
  fetchAd,
  updateAd,
  setAd,
} from '../actions'

const styles = ({
  container: {
    padding: '24px',
  },
  paper: {
    padding: '24px',
    margin: '24px 0 24px 0',
    position: 'relative',
  },
  subTitle: {
    fontSize: '14px',
    color: '#757575',
    marginLeft: '24px',
  },
  loadingOverlay: {
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: 200,
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
})

const mapStateToProps = state => ({
  pending: state.editing.pending,
  status: state.editing.status,
  campaign: state.campaign.campaignReport,
  adPackages: state.editing.adPackages,
  themes: state.editing.themes,
  adSets: state.editing.adSets,
  ad: head(state.editing.ad),
})

const mapDispatchToProps = dispatch => ({
  onCampaign: campaignId => dispatch(fetchCampaign(campaignId)),
  onAdPackage: adPackageType => dispatch(fetchAdPackage({ package_type: adPackageType })),
  onThemes: () => dispatch(fetchThemes()),
  onAdSet: campaignId => dispatch(fetchAdSet(campaignId)),
  onResetAd: () => dispatch(resetAd()),
  onAd: params => dispatch(fetchAd(params)),
  onAdSubmit: (adData, adSetData, adSetId) => dispatch(setAd(adData, adSetData, adSetId)),
  onAdUpdate: (adData, adSetData, adSetId, adId) => (
    dispatch(updateAd(adData, adSetData, adSetId, adId))
  ),
})

class AdFormPage extends Component {
  static defaultProps = {
    ad: {},
  }

  state = {
    packageTypeId: 0,
    selectedProductId: null,
    loading: false,
  }

  componentDidMount() {
    const {
      onCampaign, onAdPackage, onThemes, onAdSet, onAd, history,
    } = this.props
    const { getCampaignId, findAdPackageType, findAdSet } = this

    /* eslint-disable no-alert */
    this.unblock = history.block(() => {
      if (window.confirm('생성 중인 해당 캠페인이 저장되지 않습니다. 그래도 나가시겠습니까?')) {
        return true
      }
      return false
    })
    /* eslint-disable */

    this.setState({ loading: true })
    onCampaign(getCampaignId())
      .then(campaign => {
        this.setState({ packageTypeId: campaign.ad_package && campaign.ad_package.id })
        return onAdPackage(campaign.ad_package && campaign.ad_package.package_type)
      })
      .then(() => (
        this.setState({ selectedProductId: head(findAdPackageType().ad_products) && head(findAdPackageType().ad_products).id || 0 })
      ))
      .then(() => onAdSet(getCampaignId()))
      .then(adSets => {
        if (!isEmpty(findAdSet(adSets).advertisements || [])) {
          return onAd({ advertisement_set_id: (findAdSet(adSets).id || 0) })
        }

        return true
      })
      .then(() => onThemes())
      .then(() => this.setState({ loading: false }))
  }

  componentWillUnmount() {
    this.unblock()
 }

  getCampaignId = () => {
    const { location } = this.props
    return queryString.parse(location.search).campaign_id
  }

  getAdPackageType = () => {
    const { location } = this.props
    return queryString.parse(location.search).ad_package_type
  }

  getAdFormType = () => {
    const { location } = this.props
    return queryString.parse(location.search).form_type || 'create'
  }

  findAdPackageType = () => {
    const { filterAdPackageType } = this
    const { packageTypeId } = this.state
    return find(filterAdPackageType(), { 'id': Number(packageTypeId) }) || {}
  }

  findAdProduct = () => {
    const { findAdPackageType } = this
    const { selectedProductId } = this.state
    return find(findAdPackageType().ad_products, { 'id': Number(selectedProductId) }) || {}
  }

  findAdSet = (adSets) => {
    const { findAdProduct } = this
    return find(adSets, { 'view_template': findAdProduct().view_template }) || {}
  }

  filterAdPackageType = () => {
    const { adPackages } = this.props
    const { getAdPackageType } = this
    return filter(adPackages, item => item.package_type === getAdPackageType())
  }

  handleAdProductClick = (selectedProductId) => () => {
    const { onAd, adSets } = this.props
    const { findAdSet } = this

    this.setState({ selectedProductId }, () => {
      if (!isEmpty(findAdSet(adSets).advertisements)) {
        this.setState({ loading: true })
        onAd({ advertisement_set_id: findAdSet(adSets).id || 0 })
          .then(() => this.setState({ loading: false }))
      }
    })
  }

  renderAdProductLabel = item => {
    if (item.view_template === 'post' && item.product_type === 'video') {
      return '동영상 게시글'
    }

    if (item.view_template === 'post' && item.product_type === 'image') {
      return '이미지 게시글'
    }

    if (item.view_template === 'notice' && item.product_type === 'text') {
      return '공지글'
    }

    if (item.view_template === 'comment' && item.product_type === 'text') {
      return '댓글 형식'
    }

    if (item.view_template === 'comment' && item.product_type === 'image') {
      return '댓글 형식'
    }

    if (item.view_template === 'post_slide' && item.product_type === 'image') {
      return '뉴스피드 썸네일'
    }

    return '지원하지 않는 상품'
  }

  render() {
    const {
      classes,
      themes,
      adSets,
      campaign,
      onAdSet,
      ad,
      history,
      onAdSubmit,
      onAdUpdate,
    } = this.props

    const {
      selectedProductId,
      loading,
    } = this.state

    const {
      renderAdProductLabel,
      findAdPackageType,
      handleAdProductClick,
      getAdFormType,
      getCampaignId,
      getAdPackageType,
    } = this

    return (
      <Layout pageTitle="캠페인 만들기">
        {loading &&
          <div className={classes.loadingOverlay}>
            <CircularUnderLoad styles={classes.loading} />
          </div>
        }
        <Stepper activeStep={2} />
        <Paper className={classes.paper}>
          <div className={classes.container}>
            <Typography variant="h5" gutterBottom>
              소재 등록하기
              <span className={classes.subTitle}>모든 소재 입력을 완료 및 저장해야 다음 단계 버튼이 활성화됩니다.</span>
            </Typography>
            <Tabs
              value={selectedProductId}
              textColor="primary"
              indicatorColor="primary"
            >
              {map(findAdPackageType().ad_products, (item, index) => (
                <Tab
                  label={renderAdProductLabel(item)}
                  value={item.id}
                  key={index}
                  onClick={handleAdProductClick(item.id)}
                />
              ))}
            </Tabs>
            {map(findAdPackageType().ad_products, item => (
              <div style={{ display: selectedProductId === item.id ? 'block' : 'none' }} key={item.id}>
                <AdForm
                  campaign={campaign}
                  adProduct={item}
                  themes={themes}
                  adSets={adSets}
                  history={history}
                  ad={ad}
                  adFormType={getAdFormType()}
                  onAdSet={onAdSet}
                  campaignId={getCampaignId()}
                  selectedProductId={selectedProductId}
                  onAdSubmit={onAdSubmit}
                  onAdUpdate={onAdUpdate}
                  adPackageType={getAdPackageType()}
                  unblock={this.unblock}
                />
              </div>
            ))}
          </div>
        </Paper>
      </Layout>
    )
  }
}

AdFormPage.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({
    block: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({}).isRequired,
  adSets: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  ad: PropTypes.shape({}),
  themes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  adPackages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  campaign: PropTypes.shape({}).isRequired,
  onCampaign: PropTypes.func.isRequired,
  onAdPackage: PropTypes.func.isRequired,
  onThemes: PropTypes.func.isRequired,
  onAdSet: PropTypes.func.isRequired,
  onAd: PropTypes.func.isRequired,
  onAdSubmit: PropTypes.func.isRequired,
  onAdUpdate: PropTypes.func.isRequired,
}

export default compose(
  withTracker,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(AdFormPage)
