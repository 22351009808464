/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import PropTypes from 'prop-types'

const makeStyles = () => ({
  lineBox: ({ colors: { grey } }) => css`
    position: relative;
    color: transparent;
    width: 2rem;
    height: 2rem;

    ::before,
    ::after {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      background: ${grey[700]};
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
    }
    ::before {
      transform: rotate(45deg);
    }
    ::after {
      transform: rotate(135deg);
    }
  `,
  root: css`
    margin-left: auto;
  `,
})

const Close = ({ onClose }) => {
  const styles = makeStyles()

  return (
    <button type="button" css={styles.root} onClick={onClose}>
      <div css={styles.lineBox}>close</div>
    </button>
  )
}

Close.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default Close
