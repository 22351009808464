import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import {
  setTokenToRequestHeader, setGuestTokenToRequestHeader, getItem, getSessionItem,
} from '@/shared/utils'
import reducer from './reducer'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const configureStore = () => {
  const store = createStore(
    reducer,
    composeEnhancers(applyMiddleware(thunk)),
  )

  store.subscribe(() => {
    if (getSessionItem('guestToken')) {
      setGuestTokenToRequestHeader({ accessToken: getSessionItem('guestToken') })
    } else if (getItem('token')) {
      setTokenToRequestHeader({ tokenType: 'bearer', accessToken: getItem('token') })
    }
  })

  return store
}


export default configureStore
