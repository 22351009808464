import {
  adPostMockupBelow,
  adPostMockupUpperMyfeed,
  adPostMockupUpperNotice,
  adCommentUpper,
  adCommentBelow,
  adClasstabMockupBelow,
  adClasstabMockupUpper,
  adClosepopupMockupBelow,

  adnotificationImagelinkMockupBelow,
  adnotificationImagelinkMockupUpper,
  adNotificationTextlinkMockupBelow,
  adNotificationTextlinkMockupUpper,
  adPostbannerOnDetailMockupBelow,
  adPostbannerOnDetailMockupUpper,
  adPostbannerOnListMockupBelow,
  adPostbannerOnListMockupUpper,
} from '@/shared/assets'

const mockupImage = {
  postImage: {
    upperImage: adPostMockupUpperMyfeed,
    belowImage: adPostMockupBelow,
  },
  postVideo: {
    upperImage: adPostMockupUpperNotice,
    belowImage: adPostMockupBelow,
  },
  commentImage: {
    upperImage: adCommentUpper,
    belowImage: adCommentBelow,
  },
  commentText: {
    upperImage: adCommentUpper,
    belowImage: adCommentBelow,
  },
  classTab: {
    upperImage: adClasstabMockupUpper,
    belowImage: adClasstabMockupBelow,
  },
  closePopup: {
    belowImage: adClosepopupMockupBelow,
  },
  notificationImagelink: {
    upperImage: adnotificationImagelinkMockupUpper,
    belowImage: adnotificationImagelinkMockupBelow,
  },
  notificationTextlink: {
    upperImage: adNotificationTextlinkMockupUpper,
    belowImage: adNotificationTextlinkMockupBelow,
  },
  postBannerOnList: {
    upperImage: adPostbannerOnListMockupUpper,
    belowImage: adPostbannerOnListMockupBelow,
  },
  postBannerOnDetail: {
    upperImage: adPostbannerOnDetailMockupUpper,
    belowImage: adPostbannerOnDetailMockupBelow,
  },
}

export default mockupImage
