import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'

import { CircularUnderLoad } from '@/shared/components'

import { exportXlsx } from '../xlsxExport/xlsxExport'
import {
  isEmptyAdStatsSelector,
  getAdReportsSelector,
  getAdTotalSelector,
} from '../selectors'

const ExportButton = ({
  classes,
  isEmptyAdStats,
  exportFile,
  campaignReport,
  adSets,
  adSummaryReport,
  adReports,
}) => {
  const handleExportReportClick = () => {
    exportXlsx(campaignReport, adSets, adSummaryReport, adReports)
  }

  return (
    <>
      {!isEmptyAdStats && (
        <Button
          variant="outlined"
          color="default"
          size="large"
          disable={`${exportFile}`}
          style={{ marginRight: '10px' }}
          onClick={handleExportReportClick}
        >
          엑셀로 저장
          {exportFile && <CircularUnderLoad styles={classes.progressButton} size={20} />}
        </Button>
      )}
    </>
  )
}

ExportButton.propTypes = {
  classes: PropTypes.any.isRequired,
  isEmptyAdStats: PropTypes.bool.isRequired,
  exportFile: PropTypes.bool.isRequired,
  campaignReport: PropTypes.shape({
    title: PropTypes.string,
    package_name: PropTypes.string,
    tag: PropTypes.string,
    budget: PropTypes.number,
    cpm: PropTypes.number,
    guaranteed_expose_view: PropTypes.number,
    advertiser: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  adSets: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  adSummaryReport: PropTypes.shape({
    view: PropTypes.number,
    click: PropTypes.number,
    watch: PropTypes.number,
    watchView: PropTypes.number,
  }).isRequired,
  adReports: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default connect(
  state => ({
    status: state.campaign.status,
    isEmptyAdStats: isEmptyAdStatsSelector(state, { selectRole: 'all' }),
    exportFile: state.campaign.exportFile,
    campaignReport: state.campaign.campaignReport,
    adSets: state.campaign.adSets,
    adSummaryReport: getAdTotalSelector(state, { selectRole: 'all' }),
    adReports: getAdReportsSelector(state),
  }),
  { },
)(ExportButton)
