import 'js-polyfills/typedarray'
import 'react-app-polyfill/ie11'
import './polyfills'

import React from 'react'
import ReactDOM from 'react-dom'
import { addLocaleData } from 'react-intl'
import ko from 'react-intl/locale-data/ko'
import en from 'react-intl/locale-data/en'
import ja from 'react-intl/locale-data/ja'
import zh from 'react-intl/locale-data/zh'
import App from './App'
import * as serviceWorker from './serviceWorker'

import './index.css'

addLocaleData([...ko, ...en, ...ja, ...zh])

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
