import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

import get from 'lodash/get'

import { getToCommas } from '@/shared/utils'

import {
  TableHeader,
  EnhancedPaper,
  CircularUnderLoad,
} from '@/shared/components'

import {
  adTotalReportRows,
} from '@/features/campaign/containers/tableData'
import { getAdTotalSelector } from '../selectors'

const AdSummaryReport = ({
  classes,
  adSummaryReport,
  pending,
}) => {
  const isStaticHideWatchData = () => get(adSummaryReport, 'watch') === 0
  const isEmpty = JSON.stringify(adSummaryReport) === '{}'

  return (
    isEmpty || pending
      ? (
        <EnhancedPaper styles={classes.progressContent}>
          <CircularUnderLoad styles={classes.progressPosition} />
        </EnhancedPaper>
      )
      : (
        <EnhancedPaper>
          <Typography variant="h5" gutterBottom>
            광고 성과 요약
          </Typography>
          <TableHeader
            rows={adTotalReportRows}
            isHideRow={isStaticHideWatchData()}
          >
            <TableRow>
              <TableCell component="th" scope="row" className={classes.tableCell}>
                {getToCommas(adSummaryReport.view)}
              </TableCell>
              <TableCell component="th" scope="row" className={classes.tableCell}>
                {getToCommas(adSummaryReport.click)}
              </TableCell>
              <TableCell component="th" scope="row" className={classes.tableCell}>
                {((adSummaryReport.click / adSummaryReport.view || 0) * 100).toFixed(2)}
                %
              </TableCell>
              {isStaticHideWatchData()
                ? null
                : (
                  <TableCell component="th" scope="row" className={classes.tableCell}>
                    {getToCommas(adSummaryReport.watch)}
                  </TableCell>
                )
              }
              {isStaticHideWatchData()
                ? null
                : (
                  <TableCell component="th" scope="row" className={classes.tableCell}>
                    {((adSummaryReport.watch / adSummaryReport.watchView) * 100).toFixed(2)}
                    %
                  </TableCell>
                )
              }
            </TableRow>
          </TableHeader>
        </EnhancedPaper>
      )
  )
}

AdSummaryReport.propTypes = {
  classes: PropTypes.any.isRequired,
  adSummaryReport: PropTypes.shape({
    view: PropTypes.number,
    click: PropTypes.number,
    watch: PropTypes.number,
    watchView: PropTypes.number,
  }).isRequired,
  pending: PropTypes.bool.isRequired,
}

export default connect(
  state => ({
    pending: state.campaign.pending,
    adSummaryReport: getAdTotalSelector(state, { selectRole: 'all' }),
  }),
  {},
)(AdSummaryReport)
