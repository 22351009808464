import { reducer as signUp } from './signUp'
import { reducer as signIn } from './signIn'
import { reducer as campaign } from './campaign'
import { reducer as user } from './user'
import { reducer as advertiser } from './advertiser'
import { reducer as editing } from './editing'
import { reducer as payment } from './payment'
import { reducer as today } from './today'
import { reducer as impression } from './impression'

export default {
  signIn,
  signUp,
  campaign,
  user,
  advertiser,
  editing,
  payment,
  today,
  impression,
}
