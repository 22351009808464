import { isNumber } from 'lodash'

export const getToCommas = (num) => {
  if (!num) {
    return 0
  }

  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const getRatio = (num = 0, total = 0) => {
  if (total === 0 || !isNumber(total)) {
    return '0.00'
  }

  return ((num / total) * 100).toFixed(2)
}
