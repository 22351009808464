/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import PropTypes from 'prop-types'

import Close from './Close'

const makeStyles = () => ({
  header: ({ spacing }) => css`
    display: flex;
    padding: 0 ${spacing(1.875)}rem;
    height: 6.4rem;
  `,
  root: css`
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    z-index: 100;
  `,
  wrap: css`
    flex: 1;
  `,
})

const Modal = ({ Content, closeModal }) => {
  const styles = makeStyles()

  return (
    <div css={styles.root}>
      <div css={styles.header}>
        <Close onClose={closeModal} />
      </div>
      <div css={styles.wrap}>
        <Content closeModal={closeModal} />
      </div>
    </div>
  )
}

Modal.propTypes = {
  Content: PropTypes.elementType.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default Modal
