import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    width: '36px',
    height: '36px',
    borderRadius: '50%',
    overflow: 'hidden',
    border: 'solid 1px rgba(0, 0, 0, 0.1)',
    margin: '0px',

    '& img': {
      display: 'block',
      width: '100%',
      color: 'transparent',
    },
  },
  sm: {
    width: '28px',
    height: '28px',
  },
  md: {
    width: '36px',
    height: '36px',
  },
})

const Avatar = ({
  src, alt, size, className,
}) => {
  const classes = useStyles()

  return (
    <figure className={cn(classes.root, size, className)}>
      {src ? <img src={src} alt={alt} /> : null}
    </figure>
  )
}

Avatar.defaultProps = {
  alt: '',
  className: '',
  size: 'md',
}

Avatar.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md']),
}

export default Avatar
