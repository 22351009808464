import { uniq, cloneDeep } from 'lodash'
import { templateTitle } from '@/shared/constants'

const SUMMARY_TITLE = { font: { sz: 18, bold: true }, alignment: 'left', border: {} }
const SUMMARY = { fill: { fgColor: { rgb: 'FFD9D9D9' } } }
const SECTION_TITLE = { font: { sz: 14, bold: true }, alignment: 'left', border: {} }
const TOTAL_ROW = { font: { bold: true, italic: true } }

/**
 * 값이 추가되려면 아래 파일들이 같이 바뀌어야 함
 * 파일 나열은 우선순위 별로 나열되어 있습니다.
 * @see src/shared/constants/advertisements/AdvertisementNameMap.js
 * @see src/shared/constants/gamAdUnitName.js
 * @see src/shared/constants/templateTitle.js
 * @see src/features/campaign/xlsxExport/sections.js
 * @see src/features/today/components/TodayTable/TodayTable.styles.js
 * @see src/features/today/components/TableExpander/TableExpander.styles.js
 * @see src/shared/utils/getViewTemplate.js
 */
const HIGHLIGHT_CELL = {
  total: { fill: { fgColor: { rgb: 'FFD9D9D9' } } },
  post: { fill: { fgColor: { rgb: 'E06666' } } },
  notice: { fill: { fgColor: { rgb: '93C47D' } } },
  comment: { fill: { fgColor: { rgb: '6D9EEB' } } },
  banner: { fill: { fgColor: { rgb: '6FA8DC' } } },
  post_banner: { fill: { fgColor: { rgb: 'c5e6fc' } } },
  notification_text_banner: { fill: { fgColor: { rgb: 'c5e6fc' } } },
  notification_image_banner: { fill: { fgColor: { rgb: 'c5e6fc' } } },
  start_popup: { fill: { fgColor: { rgb: 'c5e6fc' } } },
  end_popup: { fill: { fgColor: { rgb: 'c5e6fc' } } },
  class_tab_banner: { fill: { fgColor: { rgb: 'c5e6fc' } } },
  classtalk_tab_banner: { fill: { fgColor: { rgb: 'c5e6fc' } } },
  splash_banner: { fill: { fgColor: { rgb: 'c5e6fc' } } },
  gam_class_tab: { fill: { fgColor: { rgb: '8E7CC3' } } },
  gam_my_tab: { fill: { fgColor: { rgb: 'C27BA0' } } },
  gam_close_popup: { fill: { fgColor: { rgb: 'FFD966' } } },
  gam_ai_reward: { fill: { fgColor: { rgb: '76A5AF' } } },
  gam_post_banner: { fill: { fgColor: { rgb: '82ffe0' } } },
  gam_post_detail: { fill: { fgColor: { rgb: '82ffe0' } } },
  gam_classroom_post_list: { fill: { fgColor: { rgb: '82ffe0' } } },
  gam_classroom_post_detail: { fill: { fgColor: { rgb: '82ffe0' } } },
  gam_classroom_navigation: { fill: { fgColor: { rgb: '6AA84F' } } },
  gam_home_top_banner: { fill: { fgColor: { rgb: 'D0C9E5' } } },
  gam_home_left_top_banner1: { fill: { fgColor: { rgb: 'BBB4CE' } } },
  gam_home_left_top_banner2: { fill: { fgColor: { rgb: 'A8A2B9' } } },
  gam_home_middle_banner: { fill: { fgColor: { rgb: '9791A6' } } },
  gam_home_bottom_banner: { fill: { fgColor: { rgb: '878295' } } },
  gam_notification_first_slot: { fill: { fgColor: { rgb: 'ED9D9D' } } },
  gam_notification_second_slot: { fill: { fgColor: { rgb: 'EBE5A0' } } },
}
const HEADER_CELL = {
  total: { fill: { fgColor: { rgb: 'FFF3F3F3' } } },
  post: { fill: { fgColor: { rgb: 'F4CCCC' } } },
  post_video: { fill: { fgColor: { rgb: 'FCE5CD' } } },
  notice: { fill: { fgColor: { rgb: 'D9EAD3' } } },
  comment: { fill: { fgColor: { rgb: 'C9DAF8' } } },
  banner: { fill: { fgColor: { rgb: 'CFE2F3' } } },
  post_banner: { fill: { fgColor: { rgb: 'c5e6fc' } } },
  notification_text_banner: { fill: { fgColor: { rgb: 'c5e6fc' } } },
  notification_image_banner: { fill: { fgColor: { rgb: 'c5e6fc' } } },
  start_popup: { fill: { fgColor: { rgb: 'c5e6fc' } } },
  end_popup: { fill: { fgColor: { rgb: 'c5e6fc' } } },
  class_tab_banner: { fill: { fgColor: { rgb: 'c5e6fc' } } },
  classtalk_tab_banner: { fill: { fgColor: { rgb: 'c5e6fc' } } },
  splash_banner: { fill: { fgColor: { rgb: 'c5e6fc' } } },
  gam_class_tab: { fill: { fgColor: { rgb: 'D9D2E9' } } },
  gam_my_tab: { fill: { fgColor: { rgb: 'EAD1DC' } } },
  gam_close_popup: { fill: { fgColor: { rgb: 'FFF2CC' } } },
  gam_ai_reward: { fill: { fgColor: { rgb: 'D0E0E3' } } },
  gam_post_banner: { fill: { fgColor: { rgb: '82ffe0' } } },
  gam_post_detail: { fill: { fgColor: { rgb: '82ffe0' } } },
  gam_classroom_post_list: { fill: { fgColor: { rgb: '82ffe0' } } },
  gam_classroom_post_detail: { fill: { fgColor: { rgb: '82ffe0' } } },
  gam_classroom_navigation: { fill: { fgColor: { rgb: 'B6D7A8' } } },
  gam_home_top_banner: { fill: { fgColor: { rgb: 'D0C9E5' } } },
  gam_home_left_top_banner1: { fill: { fgColor: { rgb: 'BBB4CE' } } },
  gam_home_left_top_banner2: { fill: { fgColor: { rgb: 'A8A2B9' } } },
  gam_home_middle_banner: { fill: { fgColor: { rgb: '9791A6' } } },
  gam_home_bottom_banner: { fill: { fgColor: { rgb: '878295' } } },
  gam_notification_first_slot: { fill: { fgColor: { rgb: 'ED9D9D' } } },
  gam_notification_second_slot: { fill: { fgColor: { rgb: 'EBE5A0' } } },
}

const AD_COLUMN_SIZE = 3
const VIDEO_AD_COLUMN_SIZE = 5

const valueNames = ['view', 'click', 'watch', 'watchView']

const valuesAsZero = (keys) => {
  const value = {}
  keys.forEach((valueName) => {
    value[valueName] = 0
  })

  return value
}

const formularSumRange = (start, end) => ({
  type: 'sumRange',
  s: { r: start.r || 0, c: start.c || 0 },
  e: { r: end.r || 0, c: end.c || 0 },
})
const formularSumCells = (_sumCells) => {
  const sumCells = _sumCells.map(sumCell => ({ r: sumCell.r || 0, c: sumCell.c || 0 }))
  return {
    type: 'sumCells',
    sumCells,
  }
}
const formularDivide = (dividend, divisor) => {
  const result = {
    type: 'divide',
    dividend: { r: dividend.r || 0, c: dividend.c || 0 },
    divisor: { r: divisor.r || 0, c: divisor.c || 0 },
  }

  if (divisor.formular) {
    result.divisor = divisor
  }
  return result
}

const applyTemplateView = ({
  title,
  type,
  sumByGroup,
  dates,
  sumByGroupKeyAndDate,
  groupNameByKey,
  groupKeys,
}) => {
  const data = [
    [{ v: `${title} 광고 성과`, s: SECTION_TITLE }],
    [{ v: '기간', merge: { r: 2 }, s: HIGHLIGHT_CELL[type] }],
    [''],
    [{ v: '합계', s: TOTAL_ROW }],
  ]

  groupKeys.forEach((groupKey) => {
    const isVideoAd = sumByGroup[groupKey].watchView > 0

    const merge = { c: AD_COLUMN_SIZE }
    if (isVideoAd) {
      merge.c = VIDEO_AD_COLUMN_SIZE
    }

    let headerStyle = HEADER_CELL[type]
    if (type === 'total') {
      headerStyle = HEADER_CELL[groupKey]
    }

    data[1].push({
      v: groupNameByKey[groupKey], s: headerStyle, merge,
    }, '', '')
    data[2].push('Imprs.', 'Click', 'CTR')
    data[3].push(
      {
        v: sumByGroup[groupKey].view,
        formular: formularSumRange({ r: 1 }, { r: dates.length }),
        s: TOTAL_ROW,
      },
      {
        v: sumByGroup[groupKey].click,
        formular: formularSumRange({ r: 1 }, { r: dates.length }),
        s: TOTAL_ROW,
      },
      {
        v: sumByGroup[groupKey].click / sumByGroup[groupKey].view,
        t: 'f',
        formular: formularDivide({ c: -1 }, { c: -2 }),
        s: TOTAL_ROW,
      },
    )
    if (isVideoAd) {
      let columnSize = 0
      const watchViewCells = []
      groupKeys.forEach((gK) => {
        if (sumByGroup[gK].watchView > 0) {
          if (gK !== 'total') {
            watchViewCells.push({ c: columnSize - VIDEO_AD_COLUMN_SIZE + 1 })
          }

          columnSize += VIDEO_AD_COLUMN_SIZE
        } else {
          columnSize += AD_COLUMN_SIZE
        }
      })
      data[1].push('', '')
      data[2].push('Watch', 'VTR')
      data[3].push(
        {
          v: sumByGroup[groupKey].watch,
          formular: formularSumRange({ r: 1 }, { r: dates.length }),
          s: TOTAL_ROW,
        },
      )
      if (groupKey === 'total') {
        data[3].push({
          v: sumByGroup[groupKey].watch / sumByGroup[groupKey].watchView,
          t: 'f',
          formular: formularDivide({ c: -1 },
            {
              formular: formularSumCells(watchViewCells),
            }),
          s: TOTAL_ROW,
        })
      } else {
        data[3].push({
          v: sumByGroup[groupKey].watch / sumByGroup[groupKey].watchView,
          t: 'f',
          formular: formularDivide({ c: -1 }, { c: -4 }),
          s: TOTAL_ROW,
        })
      }
    }
  })

  dates.forEach((date) => {
    const row = [date]
    groupKeys.forEach((groupKey) => {
      if (groupKey === 'total') {
        const groupColumnSizes = []
        let columnSize = 0
        groupKeys.forEach((gK) => {
          if (gK !== 'total') {
            groupColumnSizes.push({ c: columnSize })
          }
          if (sumByGroup[gK].watch > 0) {
            columnSize += VIDEO_AD_COLUMN_SIZE
          } else {
            columnSize += AD_COLUMN_SIZE
          }
        })

        row.push({
          v: sumByGroupKeyAndDate[groupKey][date].view || 0,
          formular: formularSumCells(groupColumnSizes),
        })
        row.push({
          v: sumByGroupKeyAndDate[groupKey][date].click || 0,
          formular: formularSumCells(groupColumnSizes),
        })
        row.push(
          {
            v: (sumByGroupKeyAndDate[groupKey][date].click || 0)
              / (sumByGroupKeyAndDate[groupKey][date].view || 0),
            t: 'f',
            formular: formularDivide({ c: -1 }, { c: -2 }),
          },
        )
        if (sumByGroup[groupKey].watchView > 0) {
          const watchCells = []
          const watchViewCells = []
          columnSize = 0

          groupKeys.forEach((gK) => {
            if (sumByGroup[gK].watch > 0) {
              if (gK !== 'total') {
                watchCells.push({ c: columnSize })
                watchViewCells.push({ c: columnSize - 4 })
              }

              columnSize += 5
            } else {
              columnSize += 3
            }
          })

          if (sumByGroupKeyAndDate[groupKey][date].watchView > 0) {
            row.push(
              {
                v: sumByGroupKeyAndDate[groupKey][date].watch || 0,
                formular: formularSumCells(watchCells),
              },
              {
                v: (sumByGroupKeyAndDate[groupKey][date].watch || 0)
                  / (sumByGroupKeyAndDate[groupKey][date].watchView || 0),
                t: 'f',
                formular: formularDivide({ c: -1 }, {
                  formular: formularSumCells(watchViewCells),
                }),
              },
            )
          } else {
            row.push('', '')
          }
        }
      } else if (sumByGroupKeyAndDate[groupKey] && sumByGroupKeyAndDate[groupKey][date]) {
        row.push(sumByGroupKeyAndDate[groupKey][date].view || 0)
        row.push(sumByGroupKeyAndDate[groupKey][date].click || 0)
        row.push(
          {
            v: (sumByGroupKeyAndDate[groupKey][date].click || 0)
              / (sumByGroupKeyAndDate[groupKey][date].view || 0),
            t: 'f',
            formular: formularDivide({ c: -1 }, { c: -2 }),
          },
        )
        if (sumByGroup[groupKey].watchView > 0) {
          row.push(
            sumByGroupKeyAndDate[groupKey][date].watch || 0,
            {
              v: (sumByGroupKeyAndDate[groupKey][date].watch || 0)
                / (sumByGroupKeyAndDate[groupKey][date].watchView || 0),
              t: 'f',
              formular: formularDivide({ c: -1 }, { c: -4 }),
            },
          )
        }
      } else {
        row.push('', '', '')
        if (sumByGroup[groupKey].watch > 0) {
          row.push('', '')
        }
      }
    })
    data.push(row)
  })

  return data
}

export const summarySection = (campaign, adSummary) => {
  const campaignSummary = [
    [{ v: '광고 성과 보고서', s: SUMMARY_TITLE }],
    [
      { v: '광고주', s: SUMMARY }, campaign.advertiser.name,
      { v: '캠페인명', s: SUMMARY }, campaign.title,
      { v: '총 예산', s: SUMMARY }, campaign.budget,
    ],
    [],
    [
      { v: '상품', s: SUMMARY },
      { v: '과금 방식', s: SUMMARY },
      { v: '기간', s: SUMMARY },
      { v: '대상', s: SUMMARY },
      { v: '학년', s: SUMMARY },
      { v: '지역', s: SUMMARY },
      { v: '노출 수', s: SUMMARY },
      { v: '예산(VAT별도)', s: SUMMARY },
      { v: '상세 내용', s: SUMMARY },
    ],
    ...campaign.memo.map(({
      product = '', billingMethod = '', period = '', role = '', region = '', grade = '', impressions = '', budget = '', detail = '',
    }) => [product, billingMethod, period, role, grade, region, impressions, budget, detail]),
    [],
  ]

  const statSummary = [
    [
      { v: '총 노출 수', s: SUMMARY },
      { v: '총 클릭 수', s: SUMMARY },
      { v: '평균 CTR', s: SUMMARY },
    ],
    // eslint-disable-next-line max-len
    [adSummary.view, adSummary.click, { v: `${adSummary.ctr}%`, s: { alignment: { horizontal: 'right' } } }],
  ]

  if (adSummary.watchView > 0) {
    statSummary[0].push(
      { v: '재생 수', s: SUMMARY },
      { v: '평균 VTR', s: SUMMARY },
    )
    statSummary[1].push(adSummary.watch, { v: `${adSummary.vtr}%`, s: { alignment: { horizontal: 'right' } } })
  }

  return campaignSummary.concat(statSummary)
}

export const totalSection = (_adReports) => {
  const groupNameByKey = {
    total: '합계',
    ...templateTitle,
  }
  const adReports = cloneDeep(_adReports)

  // NOTE: 각 그룹의 view, click, watch, watchView 합계가 저장됨
  const sumByGroup = {
    total: valuesAsZero(valueNames),
  }

  // NOTE: 각 그룹의 날짜별 view, click, watch, watchView가 저장됨
  const sumByGroupKeyAndDate = {
    total: {},
  }

  const types = Object.keys(adReports)
  Object.keys(adReports).forEach((type, typeIndex) => {
    adReports[type].forEach((advertisementSet) => {
      const r = {
        default: [],
        video: [],
      }
      advertisementSet.advertisements.forEach((advertisement) => {
        if (advertisement.isVideoAd) {
          r.video.push(advertisement)
        } else {
          r.default.push(advertisement)
        }
      })

      if (r.video.length > 0) {
        const videoType = `${type}_video`
        if (!adReports[videoType]) {
          adReports[videoType] = []
          groupNameByKey[videoType] = `${groupNameByKey[type]}(동영상)`
          groupNameByKey[type] = `${groupNameByKey[type]}(이미지)`
          types.splice(typeIndex + 1, 0, videoType)
        }
        adReports[videoType].push(
          {
            ...advertisementSet,
            advertisements: r.video,
          },
        )
        // eslint-disable-next-line no-param-reassign
        advertisementSet.advertisements = r.default
      }
    })
  })

  let dates = []

  types.forEach((type) => {
    sumByGroup[type] = valuesAsZero(valueNames)
    sumByGroupKeyAndDate[type] = {}

    adReports[type].forEach((advertisementSet) => {
      advertisementSet.advertisements.forEach((advertisement) => {
        Object.keys(advertisement.adStats).forEach((date) => {
          dates.push(date)
          valueNames.forEach((valueName) => {
            const value = advertisement.adStats[date].all[valueName] || 0
            sumByGroup.total[valueName] += value
            sumByGroup[type][valueName] += value

            if (!sumByGroupKeyAndDate.total[date]) {
              sumByGroupKeyAndDate.total[date] = valuesAsZero(valueNames)
            }
            sumByGroupKeyAndDate.total[date][valueName] += value

            if (!sumByGroupKeyAndDate[type][date]) {
              sumByGroupKeyAndDate[type][date] = valuesAsZero(valueNames)
            }
            sumByGroupKeyAndDate[type][date][valueName] += value
          })
        })
      })
    })
  })

  dates = uniq(dates).sort()

  return applyTemplateView({
    title: '전체',
    type: 'total',
    sumByGroup,
    dates,
    sumByGroupKeyAndDate,
    groupNameByKey,
    groupKeys: ['total'].concat(types),
  })
}

export const typeSection = (type, adReports) => {
  const sumByGroup = {
    total: valuesAsZero(valueNames),
  }
  const sumByGroupKeyAndDate = {
    total: {},
  }
  let dates = []
  const advertisementMemoById = {
    total: '합계',
  }
  const advertisementIds = ['total']

  adReports[type].forEach((advertisementSet) => {
    advertisementSet.advertisements.forEach((advertisement) => {
      const adId = advertisement.id
      sumByGroup[adId] = valuesAsZero(valueNames)
      sumByGroupKeyAndDate[adId] = {}

      advertisementMemoById[adId] = advertisementSet.name || templateTitle[type]
      if (advertisement.memo) {
        advertisementMemoById[adId] += ` ${advertisement.memo}`
      }
      advertisementIds.push(adId)
      Object.keys(advertisement.adStats).forEach((date) => {
        dates.push(date)
        valueNames.forEach((valueName) => {
          const value = advertisement.adStats[date].all[valueName] || 0
          sumByGroup.total[valueName] += value
          sumByGroup[adId][valueName] += value

          if (!sumByGroupKeyAndDate.total[date]) {
            sumByGroupKeyAndDate.total[date] = valuesAsZero(valueNames)
          }
          sumByGroupKeyAndDate.total[date][valueName] += value

          if (!sumByGroupKeyAndDate[adId][date]) {
            sumByGroupKeyAndDate[adId][date] = valuesAsZero(valueNames)
          }
          sumByGroupKeyAndDate[adId][date][valueName] += value
        })
      })
    })
  })
  dates = uniq(dates).sort()

  return applyTemplateView({
    title: templateTitle[type],
    type,
    sumByGroup,
    dates,
    sumByGroupKeyAndDate,
    groupNameByKey: advertisementMemoById,
    groupKeys: advertisementIds,
  })
}
