/** @jsx jsx */
import { jsx } from '@emotion/react'
import { createContext, useReducer, Fragment } from 'react'
import PropTypes from 'prop-types'

import { breakpoints } from '@landing/styles'

const SET_VIEW_SIZE = 'SET_VIEW_SIZE'
const SELECT_NAVIGATION_ITEM = 'SELECT_NAVIGATION'
const UNSELECT_NAVIGATION_ITEM = 'UNSELECT_NAVIGATION_ITEM'
const SET_HEADER_HEIGHT = 'SET_HEADER_HEIGHT'
const OPEN_MODAL = 'OPEN_MODAL'
const CLOSE_MODAL = 'CLOSE_MODAL'
const OPEN_DIALOG = 'OPEN_DIALOG'
const CLOSE_DIALOG = 'CLOSE_DIALOG'

export const VIEW_SIZE_PC = 'PC'
export const VIEW_SIZE_MOBILE = 'MOBILE'

export const selectNavigationItemAction = id => ({
  payload: { id },
  type: SELECT_NAVIGATION_ITEM,
})

export const unSelectNavigationItemAction = id => ({
  payload: { id },
  type: UNSELECT_NAVIGATION_ITEM,
})

export const setHeaderHeightAction = headerHeight => ({
  payload: { headerHeight },
  type: SET_HEADER_HEIGHT,
})

export const setViewSizeAction = viewWidth => ({
  payload: {
    viewWidth,
  },
  type: SET_VIEW_SIZE,
})

export const openModalAction = Component => ({
  payload: {
    Component,
  },
  type: OPEN_MODAL,
})

export const closeModalAction = () => ({
  type: CLOSE_MODAL,
})

export const openDialogAction = ({
  description,
  primaryButton,
  secondaryButton,
}) => ({
  payload: {
    description,
    primaryButton,
    secondaryButton,
  },
  type: OPEN_DIALOG,
})

export const closeDialogAction = () => ({
  type: CLOSE_DIALOG,
})

const initialState = {
  ModalContent: () => <Fragment />,
  dialogProps: {
    description: '',
    primaryButton: { onClick: () => {}, text: '확인', visible: true },
    secondaryButton: { onClick: () => {}, text: '취소', visible: false },
  },
  headerHeight: 0,
  idOpenDialog: false,
  isOpenModal: false,
  navigations: [
    {
      id: 'introduce',
      isSelected: false,
      name: '소개',
    },
    {
      id: 'guidance',
      isSelected: false,
      name: '광고안내',
    },
    {
      id: 'caseOfExecution',
      isSelected: false,
      name: '집행사례',
    },
    {
      id: 'process',
      isSelected: false,
      name: '프로세스',
    },
    {
      id: 'FAQ',
      isSelected: false,
      name: 'FAQ',
    },
    {
      id: 'inquire',
      isSelected: false,
      name: '문의하기',
    },
  ],
  viewSize: '', // VIEW_SIZE_MOBILE, VIEW_SIZE_PC
}

export const uiStore = createContext(initialState)
const { Provider } = uiStore

export const UiProvider = ({ children }) => {
  const [state, dispatch] = useReducer((rootState, action) => {
    switch (action.type) {
      case SET_HEADER_HEIGHT: {
        return {
          ...rootState,
          headerHeight: action.payload.headerHeight,
        }
      }
      case SELECT_NAVIGATION_ITEM: {
        const result = rootState.navigations.map((nav) => {
          if (nav.id === action.payload.id) {
            return {
              ...nav,
              isSelected: true,
            }
          }
          return {
            ...nav,
            isSelected: false,
          }
        })

        return {
          ...rootState,
          navigations: result,
        }
      }
      case UNSELECT_NAVIGATION_ITEM: {
        const result = rootState.navigations.map((nav) => {
          if (nav.id === action.payload.id) {
            return {
              ...nav,
              isSelected: false,
            }
          }
          return nav
        })
        return {
          ...rootState,
          navigations: result,
        }
      }
      case SET_VIEW_SIZE: {
        const mobileSize = breakpoints[0]

        return {
          ...rootState,
          viewSize:
            action.payload.viewWidth > mobileSize
              ? VIEW_SIZE_PC
              : VIEW_SIZE_MOBILE,
        }
      }
      case OPEN_MODAL: {
        return {
          ...rootState,
          ModalContent: action.payload.Component,
          isOpenModal: true,
        }
      }
      case CLOSE_MODAL: {
        return {
          ...rootState,
          ModalContent: () => <Fragment />,
          isOpenModal: false,
        }
      }
      case OPEN_DIALOG: {
        return {
          ...rootState,
          dialogProps: {
            description: action.payload.description,
            primaryButton: action.payload.primaryButton,
            secondaryButton: action.payload.secondaryButton,
          },
          isOpenDialog: true,
        }
      }
      case CLOSE_DIALOG: {
        return {
          ...rootState,
          dialogProps: initialState.dialogProps,
          isOpenDialog: false,
        }
      }
      default:
        throw new Error()
    }
  }, initialState)

  return <Provider value={{ dispatch, state }}>{children}</Provider>
}

UiProvider.propTypes = {
  children: PropTypes.element.isRequired,
}
