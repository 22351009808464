/** @jsx jsx */
import { css, jsx } from '@emotion/react'

import { LineBreak } from '../unit/string'

const makeStyles = () => ({
  root: ({ colors: { grey }, spacing }) => css`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${grey[50]};
    border-radius: 4px;
    height: 9.2rem;
    padding: ${spacing(2.5)}rem 0;
  `,
  text: ({ colors: { grey }, font }) => css`
    color: ${grey[700]};
    font-size: ${font.body2.fontSize};
    line-height: ${font.body2.lineHeight};
    text-align: center;
  `,
})

const ProcessGuideText = () => {
  const styles = makeStyles()

  return (
    <div css={styles.root}>
      <p css={styles.text}>
        <LineBreak text={'단계별 진행 안내 과정을\n살펴보실 수 있습니다.'} />
      </p>
    </div>
  )
}

export default ProcessGuideText
