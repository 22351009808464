/** @jsx jsx */
import { jsx } from '@emotion/react'
import {
  useContext, useEffect, useRef, useState,
  Fragment,
} from 'react'
import PropTypes from 'prop-types'

import {
  uiStore,
  setViewSizeAction,
  closeModalAction,
  VIEW_SIZE_PC,
  VIEW_SIZE_MOBILE,
} from '@landing/contexts/ui'
import { breakpoints } from '@landing/styles'
import Footer from './footer'
import { Header } from './header'
import { Modal } from '../unit/modal'
import { Dialog } from '../unit/dialog'

const Layout = ({ children }) => {
  const {
    dispatch,
    state: {
      viewSize, isOpenModal, ModalContent, isOpenDialog, dialogProps,
    },
  } = useContext(uiStore)
  const [isScrolled, setIsScrolled] = useState(false)
  const scrollStatus = useRef(isScrolled)

  const handleModalCloseClick = () => {
    dispatch(closeModalAction())
  }

  const handleScroll = () => {
    if (window.scrollY > 0) {
      if (!scrollStatus.current) {
        setIsScrolled(true)
        scrollStatus.current = true
      }
    } else {
      setIsScrolled(false)
      scrollStatus.current = false
    }
  }

  const handleResize = () => {
    const mobileSize = breakpoints[0]
    const isViewWidthPcSize = window.innerWidth > mobileSize && viewSize === VIEW_SIZE_MOBILE
    const isViewWidthMobileSize = window.innerWidth <= mobileSize && viewSize === VIEW_SIZE_PC

    if (isViewWidthPcSize || isViewWidthMobileSize) {
      dispatch(setViewSizeAction(window.innerWidth))
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    dispatch(setViewSizeAction(window.innerWidth))

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [viewSize])

  return (
    <Fragment>
      {viewSize && (
        <Fragment>
          <Header isScrolled={isScrolled} />
          {children}
          <Footer />
          {isOpenModal && (
            <Modal Content={ModalContent} closeModal={handleModalCloseClick} />
          )}
          {isOpenDialog && (
            <Dialog
              secondaryButton={dialogProps.secondaryButton}
              primaryButton={dialogProps.primaryButton}
              description={dialogProps.description}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.element.isRequired,
}

export default Layout
