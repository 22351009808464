import React from 'react'
import PropTypes from 'prop-types'
import classesName from 'classnames'

import ReactTable from 'react-table'
import 'react-table/react-table.css'

import { withStyles } from '@material-ui/core/styles'

import tableExpanderStyles from './TableExpander.styles'
import { getTypeTableColumns } from './data'
import TheadComponent from './OverrideComponents/TheadComponent'
import ThComponent from './OverrideComponents/ThComponent'
import BaseComponent from './OverrideComponents/BaseComponent'

const TypeTotalTable = ({
  row, classes, type, onUpdateAdForcedStop, loading,
}) => {
  const typeStatus = row[`${type}_status`] || []

  if (typeStatus.length === 0) return <></>

  const typeClassName = type === 'post' || type === 'package'
    ? classesName(classes.totalPostTypeTable) : classesName(classes.totalTypeTable)

  return (
    <div style={{ margin: '10px' }}>
      <ReactTable
        data={typeStatus}
        columns={getTypeTableColumns({ classes, type, onUpdateAdForcedStop })}
        showPagination={false}
        className={classesName(typeClassName)}
        minRows={0}
        loading={loading}
        TheadComponent={props => <TheadComponent threadProps={props} classes={classes} />}
        ThComponent={props => <ThComponent thProps={props} classes={classes} />}
        TrComponent={trProps => (
          <BaseComponent tableProps={trProps} className={classesName(classes.tr)} />
        )}
      />
    </div>
  )
}

TypeTotalTable.propTypes = {
  row: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  onUpdateAdForcedStop: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

export default withStyles(tableExpanderStyles)(TypeTotalTable)
