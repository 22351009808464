import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import { ADVERTISEMENT_NAME_MAP } from '@/shared/constants'
import TypeTotalTable from './TypeTotalTable'
import tableExpanderStyles from './TableExpander.styles'

const AD_TYPES = Object.values(ADVERTISEMENT_NAME_MAP)

const TableExpander = ({
  row, classes, onUpdateAdForcedStop, isExpanderLoading,
}) => (
  <div style={{ margin: '10px' }}>
    {
      AD_TYPES.map(type => (
        <TypeTotalTable
          key={`${type}_table_expander`}
          row={row}
          classes={classes}
          type={type}
          onUpdateAdForcedStop={onUpdateAdForcedStop}
          loading={isExpanderLoading}
        />
      ))
    }
  </div>
)

TableExpander.propTypes = {
  row: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onUpdateAdForcedStop: PropTypes.func.isRequired,
  isExpanderLoading: PropTypes.bool.isRequired,
}

export default withStyles(tableExpanderStyles)(TableExpander)
