import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { Link } from 'react-router-dom'
import logo from '@/shared/assets/logo-ct-dashboard.svg'

const styles = {
  root: {
    flexGrow: 1,
  },
  AppBar: {
    background: '#ffffff',
    boxShadow: 'none',
    borderBottom: '1px #dbdbdb solid',
  },
}

function NavigationBar(props) {
  const { classes } = props

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default" className={classes.AppBar}>
        <Toolbar>
          <Link to="/sign_in">
            <img src={logo} className={classes.logo} alt="logo" />
          </Link>
        </Toolbar>
      </AppBar>
    </div>
  )
}

NavigationBar.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    AppBar: PropTypes.string,
    logo: PropTypes.string,
  }).isRequired,
}

export default withStyles(styles)(NavigationBar)
