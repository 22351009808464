/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { useContext } from 'react'

import { uiStore, VIEW_SIZE_PC } from '@landing/contexts/ui'
import focusCircleImage from '@landing/assets/images/dot_circle.png'
import { AppearanceAnimation } from '../unit/animation'

const makeStyles = () => ({
  circle: css`
    width: 100%;
    height: 100%;
    background: no-repeat center/cover url(${focusCircleImage});
  `,
  root: ({ mq }) => css`
    ${mq({
    background: 'linear-gradient(98.68deg, #5784da 0%, #415ad4 100%)',
    height: ['16rem', '40rem'],
  })}
  `,
  subTitle: ({ font }) => css`
    font-size: ${font.body1.fontSize};
    line-height: ${font.body1.lineHeight};
  `,
  title: ({ font, mq }) => css`
    ${mq({
    fontSize: [font.body1.fontSize, font.title1.fontSize],
    fontWeight: 'bold',
    lineHeight: [font.body1.lineHeight, font.title1.lineHeight],
  })}
  `,
  titleWrap: () => css`
    color: #fff;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
})

const SectionHeader = () => {
  const styles = makeStyles()
  const {
    state: { viewSize },
  } = useContext(uiStore)

  return (
    <AppearanceAnimation>
      <div css={styles.root}>
        <div css={styles.circle}>
          <div css={styles.titleWrap}>
            {viewSize === VIEW_SIZE_PC && (
              <p css={styles.subTitle}>정교하고 효과적인 광고 도달</p>
            )}
            <p css={styles.title}>왜 클래스팅 광고 일까요?</p>
          </div>
        </div>
      </div>
    </AppearanceAnimation>
  )
}

export default SectionHeader
