/**
 * 값이 추가되려면 아래 파일들이 같이 바뀌어야 함
 * 파일 나열은 우선순위 별로 나열되어 있습니다.
 * @see src/shared/constants/advertisements/AdvertisementNameMap.js
 * @see src/shared/constants/gamAdUnitName.js
 * @see src/shared/constants/templateTitle.js
 * @see src/features/campaign/xlsxExport/sections.js
 * @see src/features/today/components/TodayTable/TodayTable.styles.js
 * @see src/features/today/components/TableExpander/TableExpander.styles.js
 * @see src/shared/utils/getViewTemplate.js
 */
const styles = theme => ({
  table: {
    [theme.breakpoints.up('md')]: {
      maxHeight: 'calc(100vh - 200px)',
      width: 'calc(100vw - 300px)',
    },
  },
  tableCell: {
    fontSize: 13,
    height: 38,
  },
  tableNumberCell: {
    textAlign: 'right',
  },
  tableSwitchCell: {
    textAlign: 'center',
  },
  tableHeaderCell: {
    fontSize: '14px',
    fontWeight: '600',
    backgroundColor: '#f5f5f5',
  },
  basicHeader: {
    backgroundColor: '#ffffff !important',
  },
  packageHeader: {
    backgroundColor: '#ffe0e0 !important',
  },
  postHeader: {
    backgroundColor: '#fff4e2 !important',
  },
  post_bannerHeader: {
    backgroundColor: '#c5e6fc !important',
  },
  notification_text_bannerHeader: {
    backgroundColor: '#c5e6fc !important',
  },
  notification_image_bannerHeader: {
    backgroundColor: '#c5e6fc !important',
  },
  start_popupHeader: {
    backgroundColor: '#c5e6fc !important',
  },
  end_popupHeader: {
    backgroundColor: '#c5e6fc !important',
  },
  class_tab_bannerHeader: {
    backgroundColor: '#c5e6fc !important',
  },
  classtalk_tab_bannerHeader: {
    backgroundColor: '#c5e6fc !important',
  },
  splash_bannerHeader: {
    backgroundColor: '#c5e6fc !important',
  },
  noticeHeader: {
    backgroundColor: '#eefaef !important',
  },
  commentHeader: {
    backgroundColor: '#ecf3fc !important',
  },
  bannerHeader: {
    backgroundColor: '#ffe0ff !important',
  },
  gam_class_tabHeader: {
    backgroundColor: '#e0e8ff !important',
  },
  gam_close_popupHeader: {
    backgroundColor: '#e8e0ff !important',
  },
  gam_home_top_bannerHeader: {
    backgroundColor: '#d0c9e5 !important',
  },
  gam_home_left_top_banner1Header: {
    backgroundColor: '#bbb4ce !important',
  },
  gam_home_left_top_banner2Header: {
    backgroundColor: '#a8a2b9 !important',
  },
  gam_home_middle_bannerHeader: {
    backgroundColor: '#9791a6 !important',
  },
  gam_home_bottom_bannerHeader: {
    backgroundColor: '#878295 !important',
  },
  gam_notification_first_slotHeader: {
    backgroundColor: '#ED9D9D !important',
  },
  gam_notification_second_slotHeader: {
    backgroundColor: '#EBE5A0 !important',
  },
  gam_post_bannerHeader: {
    backgroundColor: '#82ffe0 !important',
  },
  gam_post_detailHeader: {
    backgroundColor: '#82ffe0 !important',
  },
  gam_classroom_post_listHeader: {
    backgroundColor: '#82ffe0 !important',
  },
  gam_classroom_post_detailHeader: {
    backgroundColor: '#82ffe0 !important',
  },
})

export default styles
