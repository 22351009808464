import React from 'react'
import PropTypes from 'prop-types'
import { Card, makeStyles } from '@material-ui/core'

const WIDTH = 320

const useStyles = makeStyles(() => ({
  panel: {
    width: WIDTH,
    borderTop: '1px solid #eeeeee',
  },
  panel__header: {
    height: '76px',
    background: '#ffffff',
    padding: '12px 16px',
  },
  panel__header__title: {
    display: 'flex',
    alignItems: 'center',
  },
  panel__header__title__badge: {
    height: '20px',
    background: '#edf9f6',
    borderRadius: '4px',
    padding: '4px',
    color: '#00c896',
    fontSize: '12px',
    lineHeight: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    letterSpacing: '-0.3px',
  },
  panel__header__title__text: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    marginLeft: '8px',
    color: '#212121',
  },
  panel__header__description: {
    fontSize: '16px',
    lineHeight: '24px',
    marginTop: '6px',
    color: '#757575',
  },
  panel__body: {
    position: 'relative',
    paddingBottom: '56.25%',
  },
  panel__body__content: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
  },
  panel__footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 12px',
    height: '52px',
    background: '#ffffff',
  },
  panel__footer__metadata: {
    display: 'flex',
    alignItems: 'center',
  },
  panel__footer__metadata__logo: {
    width: '32px',
    height: '32px',
    marginRight: '8px',
  },
  panel__footer__metadata__advertiser__name: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '18px',
    color: '#212121',
  },
  panel__footer__action: {
    display: 'flex',
    alignItems: 'center',
    background: '#ffffff',
    border: '1px solid #9e9e9e',
    boxSizing: 'border-box',
    borderRadius: '4px',
    padding: '8px 6px 8px 10px',
    color: '#757575',
    fontSize: '14px',
    lineHeight: '14px',
  },
  panel__footer__action__icon: {
    marginLeft: '5px',
    fill: '#9e9e9e',
  },
}))

const AdPreviewPostImageType = ({
  advertiserProfileImage,
  description,
  imageUrl,
  title,
  highlightColor,
  advertiserName,
  actionLabel,
}) => {
  const styles = useStyles({ highlightColor })

  return (
    <Card className={styles.panel}>
      <div className={styles.panel__header}>
        <div className={styles.panel__header__title}>
          <span className={styles.panel__header__title__badge}>
            AD
          </span>
          <div className={styles.panel__header__title__text}>{title}</div>
        </div>
        <div className={styles.panel__header__description}>{description}</div>
      </div>
      <div className={styles.panel__body}>
        <img className={styles.panel__body__content} src={imageUrl} alt="광고이미지" />
      </div>
      <div className={styles.panel__footer}>
        <div className={styles.panel__footer__metadata}>
          <img className={styles.panel__footer__metadata__logo} src={advertiserProfileImage} alt="광고주 로고" />
          <div className="panel__footer__metadata__advertiser">
            <div className={styles.panel__footer__metadata__advertiser__name}>
              {advertiserName}
            </div>
          </div>
        </div>
        <div className={styles.panel__footer__action}>
          {actionLabel}
          <svg
            className={styles.panel__footer__action__icon}
            width="6"
            height="10"
            viewBox="0 0 6 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M3.78145 4.99999L0.481445 1.69999L1.42411 0.757324L5.66678 4.99999L1.42411 9.24266L0.481445 8.29999L3.78145 4.99999Z" />
          </svg>
        </div>
      </div>
    </Card>
  )
}

AdPreviewPostImageType.defaultProps = {
  highlightColor: '#fff',
  advertiserProfileImage: '',
  description: '',
  title: '',
  advertiserName: '',
  actionLabel: '',
}

AdPreviewPostImageType.propTypes = {
  highlightColor: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  advertiserProfileImage: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
  advertiserName: PropTypes.string,
  actionLabel: PropTypes.string,
}

export default AdPreviewPostImageType
