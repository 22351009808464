import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: ({ isSelected }) => ({
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '24px',
    color: `${isSelected ? '#00c896' : '#9e9e9e'}`,
    cursor: 'pointer',
  }),
}))

const AdPrevViewMenuItem = ({
  id,
  name,
  selectedAdId,
  setSelectedAdId,
}) => {
  const styles = useStyles({ isSelected: id === selectedAdId })

  const handleMenuItemClick = () => {
    setSelectedAdId(id)
  }

  return (
    <div className={styles.root} onClick={handleMenuItemClick}>
      {name}
    </div>
  )
}

AdPrevViewMenuItem.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  selectedAdId: PropTypes.number.isRequired,
  setSelectedAdId: PropTypes.func.isRequired,
}

export default AdPrevViewMenuItem
