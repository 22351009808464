/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { useContext } from 'react'

import { uiStore, VIEW_SIZE_PC } from '@landing/contexts/ui'
import inquireBG from '@landing/assets/images/inquire_bg.png'
import { AppearanceAnimation } from '../unit/animation'
import { LineBreak } from '../unit/string'


const makeStyles = () => ({
  description: ({ font, mq }) => css`
    ${mq({
    fontSize: [font.body2.fontSize, font.body1.fontSize],
    lineHeight: [font.body2.lineHeight, font.body1.lineHeight],
  })}
  `,
  root: ({ spacing, mq }) => css`
    ${mq({
    backgroundColor: '#232E56',
    boxSizing: 'border-box',
    height: ['24rem', '44rem'],
    padding: [
      `${spacing(2.5)}rem 0 0 0`,
      `${spacing(11)}rem 0 ${spacing(6.5)}rem`,
    ],
    textAlign: ['center', 'left'],
  })}
  `,
  textWrap: ({ spacing, mq }) => css`
    ${mq({
    boxSizing: 'border-box',
    color: '#fff',
    margin: ['0', '0 auto'],
    maxWidth: ['none', '102.4rem'],
    padding: ['0', `${spacing(10)}rem ${spacing(14.375)}rem 0`],
  })}
  `,
  title: ({ font, spacing, mq }) => css`
    ${mq({
    fontSize: [font.title3.fontSize, font.title1.fontSize],
    fontWeight: 'bold',
    lineHeight: [font.title3.lineHeight, font.title1.lineHeight],
    marginBottom: [`${spacing(0.5)}rem`, `${spacing()}rem`],
  })}
  `,
  wrap: ({ mq }) => css`
    ${mq({
    alignItems: ['center', 'normal'],
    backgroundImage: ['none', `url(${inquireBG})`],
    backgroundPosition: ['initial', 'right bottom'],
    backgroundRepeat: ['initial', 'no-repeat'],
    backgroundSize: ['auto', '34rem'],
    display: ['flex', 'block'],
    height: '100%',
    justifyContent: ['center', 'normal'],
  })}
  `,
})

const SectionHeader = () => {
  const {
    state: { viewSize },
  } = useContext(uiStore)
  const styles = makeStyles()

  return (
    <AppearanceAnimation>
      <div css={styles.root}>
        <div css={styles.wrap}>
          <div css={styles.textWrap}>
            <AppearanceAnimation duration={700}>
              <h2 css={styles.title}>광고 문의</h2>
              <p css={styles.description}>
                <LineBreak
                  text={
                    viewSize === VIEW_SIZE_PC
                      ? '클래스팅은 파트너분들의 비즈니스 성장을 위해 함께 걸어갑니다.\n문의를 남겨주시면 빠르게 연락 드리겠습니다.'
                      : '문의를 남겨주시면\n빠르게 연락 드리겠습니다.'
                  }
                />
              </p>
            </AppearanceAnimation>
          </div>
        </div>
      </div>
    </AppearanceAnimation>
  )
}

export default SectionHeader
