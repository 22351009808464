/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Fragment } from 'react'
import PropTypes from 'prop-types'

const LineBreak = ({ text }) => (
  <Fragment>
    {text.split('\n').map(line => (
      <Fragment key={line}>
        {line}
        <br />
      </Fragment>
    ))}
  </Fragment>
)

LineBreak.propTypes = {
  text: PropTypes.string.isRequired,
}

export default LineBreak
