/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import logoSvg from '@landing/assets/images/logo.svg'

const makeStyles = () => ({
  img: css`
    display: block;
    width: 100%;
  `,
  root: css`
    width: 12rem;
  `,
})

const Logo = () => {
  const styles = makeStyles()

  return (
    <figure css={styles.root}>
      <img css={styles.img} src={logoSvg} alt="클래스팅" />
    </figure>
  )
}

export default Logo
