import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import queryString from 'query-string'
import { Layout, CircularUnderLoad } from '@/shared/components'
import { PaymentInformation } from '../components'
import { fetchAdPayment } from '../actions'

const styles = ({
  container: {
    padding: '14px',
  },
  paper: {
    padding: '24px',
    margin: '24px 0 24px 0',
    position: 'relative',
  },
  divider: {
    marginTop: '24px',
    marginBottom: '24px',
  },
  label: {
    color: '#212121',
  },
  subLabel: {
    color: '#757575',
  },
  listLabel: {
    color: '#00c896',
  },
  buttonGroup: {
    paddingTop: '14px',
    paddingBottom: '8px',
  },
})

const mapStateToProps = state => ({
  pending: state.payment.pending,
  payment: state.payment.paymentData,
})

const mapDispatchToProps = dispatch => ({
  onAdPayment: paymentId => dispatch(fetchAdPayment(paymentId)),
})

class PaymentResultPage extends Component {
  componentDidMount() {
    const { getPaymentId } = this
    const { onAdPayment } = this.props

    onAdPayment(getPaymentId())
  }

  getPageType = () => {
    const { location } = this.props
    return queryString.parse(location.search).page_type
  }

  getPaymentId = () => {
    const { location } = this.props
    return queryString.parse(location.search).payment_id
  }

  getCampaignId = () => {
    const { location } = this.props
    return queryString.parse(location.search).campaign_id
  }

  getMethodType = () => {
    const { payment } = this.props
    return payment && payment.pay_method
  }

  getMethodTypeLabel = () => {
    const { getMethodType } = this

    switch (getMethodType()) {
      case 'transfer':
        return '무통장 입금'
      case 'card':
        return '카드 결제'
      case 'vbank':
        return '가상 계좌'
      default:
        return ''
    }
  }

  handleFormPageClick = () => {
    const { history } = this.props
    const { getCampaignId } = this

    history.push({
      pathname: 'payment_form',
      search: queryString.stringify({ campaign_id: getCampaignId(), form_type: 'update' }),
    })
  }

  handleCampaignPageClick = () => {
    const { history } = this.props

    history.push({
      pathname: 'campaign',
    })
  }

  render() {
    const { classes, pending, payment } = this.props
    const {
      getPageType,
      getMethodType,
      handleFormPageClick,
      handleCampaignPageClick,
      getMethodTypeLabel,
    } = this

    return (
      <Layout pageTitle="결제결과">
        {pending ? <CircularUnderLoad />
          : (
            <Paper className={classes.paper}>
              {getPageType() === 'success'
              && (
              <>
                {getMethodType() === 'card'
                  && (
                  <>
                    <Typography variant="title" color="default">
                      결제가 완료되었습니다.
                    </Typography>
                    <Typography variant="subtitle1" color="default">
                      검수가 완료되는대로 자세히 안내해드리겠습니다.
                    </Typography>
                  </>
                  )
                }

                {getMethodType() !== 'card'
                  && (
                  <>
                    <Typography variant="title" color="default">
                      캠페인 견적 생성을 완료했습니다.
                    </Typography>
                    <Typography variant="subtitle1" color="default">
                      빠른 시일 내에 입금을 완료하시길 바랍니다.
                    </Typography>
                  </>
                  )
                }
                <div className={classes.buttonGroup}>
                  <Button
                    variant="outlined"
                    color="primary"
                    type="button"
                    onClick={handleFormPageClick}
                    style={{ marginRight: '14px' }}
                  >
                    결제한 캠페인 상세보기
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    type="button"
                    onClick={handleCampaignPageClick}
                  >
                    나의 캠페인 리스트
                  </Button>
                </div>

                <Divider className={classes.divider} />

                <Grid container spacing={24}>
                  {getMethodType() === 'card'
                    && (
                    <Grid item xs={4}>
                      <Typography variant="title" color="default">
                        결제 정보
                      </Typography>
                      <Typography variant="subtitle1" color="primary">
                        {getMethodTypeLabel()}
                      </Typography>
                    </Grid>
                    )
                  }

                  {getMethodType() !== 'card'
                    && (
                    <Grid item xs={4}>
                      <PaymentInformation
                        payment={payment}
                        methodType={getMethodTypeLabel()}
                      />
                    </Grid>
                    )
                  }

                </Grid>
              </>
              )
            }

              {getPageType() === 'failure'
              && (
              <>
                <Typography variant="title" color="default">
                  결제에 실패했습니다.
                </Typography>
                <Typography variant="subtitle1" color="default">
                  다시 시도해주시길 바랍니다.
                </Typography>
                <div className={classes.buttonGroup}>
                  <Button
                    variant="outlined"
                    color="primary"
                    type="button"
                    onClick={handleFormPageClick}
                  >
                    다시 결제 시도
                  </Button>
                </div>
              </>
              )
            }
            </Paper>
          )
        }
      </Layout>
    )
  }
}

PaymentResultPage.propTypes = {
  history: PropTypes.object.isRequired,
  pending: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  payment: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onAdPayment: PropTypes.func.isRequired,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(PaymentResultPage)
