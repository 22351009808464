import { TermsPage, PrivacyPage } from './page'

export default [
  {
    key: 'privacy',
    requiresAuth: false,
    exact: true,
    path: '/policy/privacy',
    component: PrivacyPage,
  },
  {
    key: 'terms',
    requiresAuth: false,
    exact: true,
    path: '/policy/terms',
    component: TermsPage,
  },
]
