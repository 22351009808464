import React from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import { makeStyles } from '@material-ui/core'

import {
  Icon, Input, Button, Text,
} from '../atoms'

const useStyles = makeStyles(() => ({
  btnIcon: {
    marginRight: '4px',
  },
  dropzone: {
    display: 'flex',
  },
  input: {
    '&:disabled': {
      background: '#fff',
    },
  },
  btn: {
    flex: '1 0 auto',
    marginLeft: '8px',
  },
}))

const FileInput = ({
  id, value, placeholder, buttonName, changeValue,
}) => {
  const classes = useStyles()
  const handleFileDrop = (file) => {
    const firstFile = file[0]

    changeValue({
      url: URL.createObjectURL(firstFile),
      name: firstFile.name,
    })
  }

  return (
    <Dropzone
      accept=".jpg,.jpeg,.png"
      onDrop={handleFileDrop}
    >
      {
        ({ getInputProps, getRootProps }) => (
          <div {...getRootProps()} className={classes.dropzone}>
            <Input disabled value={value} className={classes.input} placeholder={placeholder} />
            <Input id={id} {...getInputProps()} />
            <Button className={classes.btn}>
              <Icon className={classes.btnIcon} shape="clip" />
              <Text color="white">{buttonName}</Text>
            </Button>
          </div>
        )
      }
    </Dropzone>
  )
}

FileInput.defaultProps = {
  buttonName: '파일첨부',
  placeholder: '',
}

FileInput.propTypes = {
  id: PropTypes.string.isRequired,
  buttonName: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  changeValue: PropTypes.func.isRequired,
}

export default FileInput
