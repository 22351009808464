/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { Fragment } from 'react'
import { LineBreak } from '@landing/components/unit/string'
import { useNavigationPointing } from '@landing/hooks/navigation'

import guidanceImage1 from '@landing/assets/images/guidance_1.png'
import guidanceImage2 from '@landing/assets/images/guidance_2.png'
import guidanceImage3 from '@landing/assets/images/210623_guidance_3.png'
import guidanceImage4 from '@landing/assets/images/guidance_4.png'
import guidanceImage5 from '@landing/assets/images/210623_guidance_5.png'
import guidanceImage6 from '@landing/assets/images/guidance_6.png'

import Content from './Content'
import SectionHeader from './SectionHeader'

const makeStyles = () => ({
  root: ({ mq, spacing }) => css`
    ${mq({
    paddingTop: [`${spacing(5)}rem`, `${spacing(10)}rem`],
  })}
    background: linear-gradient(180deg, #FFFFFF 0%, #F2FFFC 35.42%, #EFF8FF 68.75%, rgba(238, 241, 255, 0) 100%);
  `,
})

const highlightStyle = ({ colors: { green } }) => css`
    color: ${green[100]};
    font-weight: bold;
  `

const navId = 'guidance'

const contents = [
  {
    descriptions: [
      {
        image: guidanceImage1,
        interval: '10rem',
        text: (
          <LineBreak
            text={'사용자 데이터를 기반으로\n역할별 광고 집행이 가능합니다.'}
          />
        ),
      },
      {
        direction: 'row-reverse',
        image: guidanceImage2,
        interval: '8rem',
        text: (
          <Fragment>
            <span css={highlightStyle}>자녀의 학년, 학교 지역</span>
            {' '}
            등
            <br />
            정밀한 타겟팅 활용이 가능합니다.
          </Fragment>
        ),
      },
    ],
    title: <LineBreak text={'학부모·학생·교사에게\n직접 광고 도달'} />,
  },
  {
    descriptions: [
      {
        image: guidanceImage3,
        interval: '7rem',
        text: (
          <LineBreak
            text={
              '일반적인 콘텐츠와 유사한 형태로\n사용자에게 거부감을 주지 않습니다.'
            }
          />
        ),
      },
      {
        direction: 'row-reverse',
        image: guidanceImage4,
        interval: '5rem',
        text: (
          <LineBreak
            text={`
            이미지, 동영상, 텍스트 등
            다양한 크리에이티브 활용이 가능합니다.
            `.trim()}
          />
        ),
      },
    ],
    title: <LineBreak text={'광고 메시지를\n자연스럽게 전달'} />,
  },
  {
    descriptions: [
      {
        image: guidanceImage5,
        interval: '15rem',
        text: (
          <LineBreak
            text={
              '외부 URL 이동,\n 통화 연결, 구매·결제 등\n다양한 기능을 제공합니다.'
            }
          />
        ),
        text2: (
          <LineBreak
            text={
              '외부 URL 이동, 통화 연결, 구매·결제 등\n다양한 기능을 제공합니다.'
            }
          />
        ),
      },
      {
        direction: 'row-reverse',
        image: guidanceImage6,
        interval: '8rem',
        text: (
          <LineBreak
            text={`
            노출 보장형으로
            안정적인 광고 집행이 가능합니다.
            `.trim()}
          />
        ),
      },
    ],
    title: <LineBreak text={'캠페인 목표를\n효과적으로 달성'} />,
  },
]

const Guidance = () => {
  const [wrapEl] = useNavigationPointing(navId)
  const styles = makeStyles()

  return (
    <section>
      <SectionHeader />
      <div css={styles.root} ref={wrapEl} id={navId}>
        {contents.map(({ descriptions, title }, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <Content key={idx} descriptions={descriptions} title={title} />
        ))}
      </div>
    </section>
  )
}

export default Guidance
