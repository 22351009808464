import PropTypes from 'prop-types'
import styled from 'styled-components'

const Label = styled.label`
  margin-bottom: 0;
`

Label.propTypes = {
  children: PropTypes.node.isRequired,
  htmlFor: PropTypes.string.isRequired,
}

export default Label
