import React from 'react'
import PropTypes from 'prop-types'
import { Card, makeStyles } from '@material-ui/core'

const WIDTH = 320

const useStyles = makeStyles(() => ({
  panel: ({ highlightColor }) => ({
    width: WIDTH,
    overflow: 'hidden',
    textAlign: 'center',
    background: highlightColor,
    height: '80px',
  }),
  panel__image__box: {
    height: '100%',
  },
  panel__body__image: ({ imageUrl }) => ({
    height: '100%',
    backgroundImage: `url(${imageUrl})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  }),
}))

const AdPreviewImageLinkType = ({ highlightColor, imageUrl }) => {
  const styles = useStyles({ highlightColor, imageUrl })

  return (
    <Card className={styles.panel}>
      <div className={styles.panel__image__box}>
        <div className={styles.panel__body__image} />
      </div>
    </Card>
  )
}

AdPreviewImageLinkType.defaultProps = {
  highlightColor: '#fff',
}

AdPreviewImageLinkType.propTypes = {
  highlightColor: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
}

export default AdPreviewImageLinkType
