import { SignInPage, AdminSignInPage } from './page'

export default [
  {
    key: 'sign_in',
    requiresAuth: false,
    exact: true,
    path: '/sign_in',
    component: SignInPage,
  },
  {
    key: 'admin_sign_in',
    requiresAuth: false,
    exact: true,
    path: '/admin/sign_in',
    component: AdminSignInPage,
  },
]
