import React from 'react'
import PropTypes from 'prop-types'

import classesName from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputBase from '@material-ui/core/InputBase'

import map from 'lodash/map'

import { templateTitle } from '@/shared/constants'

const BootstrapInput = withStyles(theme => ({
  root: {
    minWidth: '60%',
    flex: 1,
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
  disabled: {
    backgroundColor: '#fafafa',
  },
}))(InputBase)

const styles = () => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    marginTop: 2,
    marginBottom: 12,
    padding: 24,
    backgroundColor: '#fafafa',
    display: 'flex',
    justifyContent: 'center',
    boxShadow: 'none',
    borderRadius: 4,
    border: 'solid 1px #e0e0e0',
  },
  formControl: {
    // justifyContent: 'center',
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    position: 'relative',
    transform: 'none',
    marginRight: 16,
    marginBottom: 0,
    width: 120,
  },
  gridDivider: {
    display: 'flex',
    maxWidth: 1,
    padding: '0 24px',
  },
  divider: { borderLeft: 'solid 1px #e0e0e0' },
})

export const adSetLabel = (name, viewTemplate) => name || `${templateTitle[viewTemplate]} 타입`

const ReportSelectGrouping = ({
  classes,
  children,
  adReports,
  selectedAdSetId,
  selectedType,
  selectedAdId,
  handleAdSetSelectChange,
  handleAdSelectChange,
  totalAdSetValue,
  totalAdValue,
  totalAdType,
  handleAdTypeChange,
}) => {
  const selectedAdType = adReports[selectedType] || []
  const { advertisements = [] } = selectedAdType.find(({ id }) => id === selectedAdSetId) || {}

  const hasLessThanTwoAds = advertisements.length < 2

  const disabledAdSetSelect = selectedType === totalAdType
  const disabledAdSelect = selectedAdSetId === totalAdSetValue // 광고셋 타입이 전체일 때
    || hasLessThanTwoAds // 광고셋의 광고가 없거나 한개일 때

  return (
    <Grid item xs={12}>
      <Paper className={classesName(classes.paper)}>
        <Grid item xs={6}>
          <FormControl className={classesName(classes.formControl)}>
            <InputLabel
              className={classesName(classes.label)}
            >
              광고 타입
            </InputLabel>
            <Select
              value={selectedType}
              onChange={handleAdTypeChange}
              input={<BootstrapInput />}
            >
              <MenuItem value={totalAdType}>전체</MenuItem>
              {
                map(Object.keys(adReports), v => (
                  <MenuItem key={v} value={v}>
                    {`${templateTitle[v]} 타입`}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={1} className={classesName(classes.gridDivider)}>
          <div className={classesName(classes.divider)} />
        </Grid>
        <Grid item xs={6}>
          <FormControl
            className={classesName(classes.formControl)}
            disabled={disabledAdSetSelect}
          >
            <InputLabel
              className={classesName(classes.label)}
            >
              광고셋(혹은 그룹)
            </InputLabel>
            <Select
              value={selectedAdSetId}
              onChange={handleAdSetSelectChange}
              input={<BootstrapInput />}
            >
              <MenuItem disabled value="placeholder">
                <em>광고셋(혹은 그룹)을 선택해주세요</em>
              </MenuItem>
              <MenuItem value={totalAdSetValue}>전체</MenuItem>
              {
                map(selectedAdType.filter(({ name }) => (name !== templateTitle.gam_post_banner)), ({ id, name, viewTemplate }) => (
                  <MenuItem key={id} value={id}>
                    {adSetLabel(name, viewTemplate)}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={1} className={classesName(classes.gridDivider)}>
          <div className={classesName(classes.divider)} />
        </Grid>
        <Grid item xs={6}>
          <FormControl
            className={classesName(classes.formControl)}
            disabled={disabledAdSelect}
          >
            <InputLabel className={classesName(classes.label)}>
              광고 선택
            </InputLabel>
            <Select
              value={selectedAdId}
              onChange={handleAdSelectChange}
              input={<BootstrapInput />}
            >
              <MenuItem disabled value="placeholder">
                <em>광고를 선택해주세요</em>
              </MenuItem>
              <MenuItem value={totalAdValue}>전체</MenuItem>
              {
                map(advertisements, ad => (
                  <MenuItem key={ad.id} value={ad.id}>
                    {ad.memo ? `${ad.memo} (${ad.id})` : `광고 (${ad.id})`}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
      </Paper>
      {children}
    </Grid>
  )
}

ReportSelectGrouping.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  adReports: PropTypes.shape({}),
  selectedAdSetId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  selectedType: PropTypes.string.isRequired,
  selectedAdId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  handleAdSetSelectChange: PropTypes.func.isRequired,
  handleAdSelectChange: PropTypes.func.isRequired,
  handleAdTypeChange: PropTypes.func.isRequired,
  totalAdSetValue: PropTypes.number.isRequired,
  totalAdValue: PropTypes.number.isRequired,
  totalAdType: PropTypes.oneOf(['all']).isRequired,
}

ReportSelectGrouping.defaultProps = {
  selectedAdSetId: 'placeholder',
  selectedAdId: 'placeholder',
  adReports: {},
}

export default withStyles(styles)(ReportSelectGrouping)
