export default {
  parse(queryString) {
    const query = {}
    const pairs = (queryString[0] === '?' || queryString[0] === '#' ? queryString.substr(1) : queryString).split('&')
    pairs.forEach((item) => {
      const pair = item.split('=')
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
    })
    return query
  },
}
