import styled from 'styled-components'
import PropTypes from 'prop-types'

const styledInput = styled.input`
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  padding: 9px 15px;
  outline: none;
  appearance: none;
  overflow: auto;
  width: ${({ width, fullWidth }) => (width || (fullWidth ? '100%' : 'auto'))};

  ::placeholder {
    color: #bdbdbd
  }
`

styledInput.defaultProps = {
  width: '',
  fullWidth: true,
}

styledInput.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullWidth: PropTypes.bool,
}

export default styledInput
