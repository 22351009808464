import { createAction } from 'redux-actions'
import { http } from '@/shared/utils'
import { CLASSTING_AD_API_URL } from '@/shared/constants'

export const SET_AD_PAYMENT_PENDING = 'payment/SET_AD_PAYMENT_PENDING'
export const SET_AD_PAYMENT_SUCCESS = 'payment/SET_AD_PAYMENT_SUCCESS'
export const SET_AD_PAYMENT_FAILURE = 'payment/SET_AD_PAYMENT_FAILURE'
export const FETCH_AD_PAYMENT_PENDING = 'payment/FETCH_AD_PAYMENT_PENDING'
export const FETCH_AD_PAYMENT_SUCCESS = 'payment/FETCH_AD_PAYMENT_SUCCESS'
export const FETCH_AD_PAYMENT_FAILURE = 'payment/FETCH_AD_PAYMENT_FAILURE'
export const FETCH_AD_PAYMENTS_PENDING = 'payment/FETCH_AD_PAYMENTS_PENDING'
export const FETCH_AD_PAYMENTS_SUCCESS = 'payment/FETCH_AD_PAYMENTS_SUCCESS'
export const FETCH_AD_PAYMENTS_FAILURE = 'payment/FETCH_AD_PAYMENTS_FAILURE'
export const RESET_AD_PAYMENT = 'payment/RESET_AD_PAYMENT'

export const setAdPaymentPending = createAction(SET_AD_PAYMENT_PENDING)
export const setAdPaymentSuccess = createAction(SET_AD_PAYMENT_SUCCESS)
export const setAdPaymentFailure = createAction(SET_AD_PAYMENT_FAILURE)
export const resetAdPayment = createAction(RESET_AD_PAYMENT)
export const fetchAdPaymentPending = createAction(FETCH_AD_PAYMENT_PENDING)
export const fetchAdPaymentSuccess = createAction(FETCH_AD_PAYMENT_SUCCESS)
export const fetchAdPaymentFailure = createAction(FETCH_AD_PAYMENT_FAILURE)
export const fetchAdPaymentsPending = createAction(FETCH_AD_PAYMENTS_PENDING)
export const fetchAdPaymentsSuccess = createAction(FETCH_AD_PAYMENTS_SUCCESS)
export const fetchAdPaymentsFailure = createAction(FETCH_AD_PAYMENTS_FAILURE)

export const setAdPayment = data => (dispatch, getState) => {
  dispatch(setAdPaymentPending())
  return http.post(`${CLASSTING_AD_API_URL}/payments`, { ...data })
    .then(res => (
      dispatch(setAdPaymentSuccess(res))
    ))
    .then(() => (
      getState().payment.paymentData
    ))
    .catch(err => (
      dispatch(setAdPaymentFailure(err.response))
    ))
}

export const fetchAdPayment = paymentId => (dispatch) => {
  dispatch(fetchAdPaymentPending())
  return http.get(`${CLASSTING_AD_API_URL}/payments/${paymentId}`)
    .then(res => (
      dispatch(fetchAdPaymentSuccess(res))
    ))
    .catch(err => (
      dispatch(fetchAdPaymentFailure(err.response))
    ))
}

export const fetchAdPayments = params => (dispatch) => {
  dispatch(fetchAdPaymentsPending())
  return http.get(`${CLASSTING_AD_API_URL}/payments`, { params })
    .then(res => (
      dispatch(fetchAdPaymentsSuccess(res))
    ))
    .catch(err => (
      dispatch(fetchAdPaymentsFailure(err.response))
    ))
}
