/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import PropTypes from 'prop-types'

const makeStyles = () => ({
  root: ({ colors: { grey }, font }) => css`
    color: ${grey[500]};
    font-size: ${font.body4.fontSize};
    line-height: ${font.body4.lineHeight};
  `,
})

const Remark = ({ customStyles, text }) => {
  const styles = makeStyles()

  return <p css={[styles.root, customStyles]}>{text}</p>
}

Remark.defaultProps = {
  customStyles: '',
}

Remark.propTypes = {
  customStyles: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  text: PropTypes.string.isRequired,
}

export default Remark
