/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { useContext } from 'react'
import PropTypes from 'prop-types'

import { uiStore, selectNavigationItemAction } from '@landing/contexts/ui'

const makeStyles = ({ isSelected }) => ({
  root: ({ font: { body2 }, colors: { green } }) => css`
    color: ${isSelected ? green[100] : 'inherit'};
    font-size: ${body2.fontSize};
    font-weight: ${isSelected ? 'bold' : 'normal'};
    line-height: ${body2.lineHeight};
  `,
})

const BarMenuItem = ({ id, isSelected, name }) => {
  const styles = makeStyles({ isSelected })
  const { dispatch } = useContext(uiStore)

  const handleNaviClick = () => {
    dispatch(selectNavigationItemAction(id))
  }

  return (
    <li css={styles.root} className="menu_item" onClick={handleNaviClick}>
      <a href={`#${id}`}>{name}</a>
    </li>
  )
}

BarMenuItem.propTypes = {
  id: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
}

export default BarMenuItem
