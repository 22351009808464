import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { makeStyles } from '@material-ui/core/styles'

import { icClip, icDownTriangle, icGrayRightArrow } from '@/shared/assets'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& > i': {
      display: 'inline-block',
      width: '100%',
      height: '100%',
      fontSize: '0',
      textIndent: '-9999px',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    },
    '&.clip > i': {
      backgroundImage: `url(${icClip})`,
    },
    '&.downTriangle > i': {
      backgroundImage: `url(${icDownTriangle})`,
    },
    '&.grayRightArrow > i': {
      backgroundImage: `url(${icGrayRightArrow})`,
    },
    '&.medium': {
      width: '24px',
      height: '24px',
    },
  },
}))

const Icon = ({ className, shape, size }) => {
  const classes = useStyles()

  return (
    <div className={cn(classes.root, shape, size, className)}>
      <i>{shape}</i>
    </div>
  )
}

Icon.defaultProps = {
  className: '',
  size: 'medium',
}

Icon.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['medium']),
  shape: PropTypes.oneOf(['clip', 'downTriangle', 'grayRightArrow']).isRequired,
}

export default Icon
