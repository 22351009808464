import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { ADVERTISEMENT_NAME_MAP } from '@/shared/constants/advertisements/AdvertisementNameMap'
import { getViewTemplate } from '@/shared/utils'
import AdPreviewPostType from './AdPreviewPostType'
import AdPreviewNoticeType from './AdPreviewNoticeType'
import AdPreviewCommentType from './AdPreviewCommentType'
import AdPreviewPcBannerType from './AdPreviewPcBannerType'
import AdPreviewClassTabType from './AdPreviewClassTabType'
import AdPreviewClosePopupType from './AdPreviewClosePopupType'
import PreparingGuide from './PreparingGuide'
import AdPreviewNotificationType from './AdPreviewNotificationType'
import AdPreviewPostImageType from './AdPreviewPostImageType'
import AdPreviewImageLinkType from './AdPreviewImageLinkType'
import AdPreviewPostBannerType from './AdPreviewPostBannerType'
import AdPreviewClasstalkTabType from './AdPreviewClasstalkTabType'
import AdPreviewSplashBannerType from './AdPreviewSplashBannerType'
import AdPreviewStartPopupType from './AdPreviewStartPopupType'


const useStyles = makeStyles(() => ({
  root: {
    alignItems: 'center',
    flex: '1',
    backgroundColor: '#f5f5f5',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '40px 0',
  },
  previewContainer: {
    boxShadow: '3px 3px 5px rgba(0,0,0,0.3)',
  },
  actionUrl: {
    color: '#9e9e9e',
    fontSize: '16px',
    lineHeight: '24px',
    margin: 0,
    marginTop: '16px',
  },
}))

const renderAdPreView = ({
  advertisement_set: {
    view_template: originalViewTemplate,
    gam_ad_unit_id: gamAdUnitId,
  },
  advertiser: {
    name: advertiserName,
    profile_image: profileImage,
  },
  action_label: actionLabel,
  highlight_color: highlightColor,
  description,
  image,
  video,
  title,
}) => {
  const viewTemplate = getViewTemplate({
    view_template: originalViewTemplate,
    gam_ad_unit_id: gamAdUnitId,
  })

  switch (viewTemplate) {
    case ADVERTISEMENT_NAME_MAP.POST:
      return (
        <AdPreviewPostType
          advertiserName={advertiserName}
          advertiserProfileImage={profileImage}
          actionLabel={actionLabel}
          highlightColor={highlightColor}
          description={description}
          imageUrl={image ? image.original_url : ''}
          isVideoType={!!video}
          title={title}
        />
      )
    case ADVERTISEMENT_NAME_MAP.NOTICE:
      return (
        <AdPreviewNoticeType
          advertiserName={advertiserName}
          actionLabel={actionLabel}
          description={description}
        />
      )
    case ADVERTISEMENT_NAME_MAP.BANNER:
      return <AdPreviewPcBannerType imageUrl={image ? image.original_url : ''} />
    case ADVERTISEMENT_NAME_MAP.COMMENT:
      return (
        <AdPreviewCommentType
          title={title}
          advertiserProfileImage={profileImage}
          actionLabel={actionLabel}
          description={description}
          imageUrl={image ? image.original_url : ''}
          isImageType={!!image}
        />
      )
    case ADVERTISEMENT_NAME_MAP.CLASS_TAB_BANNER:
      return !(title && description && image.original_url)
        ? <PreparingGuide />
        : (
          <AdPreviewClassTabType
            title={title}
            description={description}
            imageUrl={image ? image.original_url : ''}
          />
        )
    case ADVERTISEMENT_NAME_MAP.CLASSTALK_TAB_BANNER:
      return !(image.original_url)
        ? <PreparingGuide />
        : (
          <AdPreviewClasstalkTabType
            imageUrl={image ? image.original_url : ''}
          />
        )
    case ADVERTISEMENT_NAME_MAP.SPLASH_BANNER:
      return !(image.original_url)
        ? <PreparingGuide />
        : (
          <AdPreviewSplashBannerType
            imageUrl={image ? image.original_url : ''}
          />
        )
    case ADVERTISEMENT_NAME_MAP.START_POPUP:
      return !(image.original_url)
        ? <PreparingGuide />
        : (
          <AdPreviewStartPopupType
            imageUrl={image ? image.original_url : ''}
          />
        )
    case ADVERTISEMENT_NAME_MAP.GAM_CLOSE_POPUP:
    case ADVERTISEMENT_NAME_MAP.END_POPUP:
      return !(image && image.original_url)
        ? <PreparingGuide />
        : (
          <AdPreviewClosePopupType
            imageUrl={image.original_url}
            backgroundColor={highlightColor}
          />
        )
    case ADVERTISEMENT_NAME_MAP.GAM_NOTIFICATION_FIRST_SLOT:
    case ADVERTISEMENT_NAME_MAP.GAM_NOTIFICATION_SECOND_SLOT:
    case ADVERTISEMENT_NAME_MAP.NOTIFICATION_TEXT_BANNER:
    case ADVERTISEMENT_NAME_MAP.NOTIFICATION_IMAGE_BANNER:
      return (
        <AdPreviewNotificationType
          advertiserProfileImage={profileImage}
          title={title}
          description={description}
          advertiserName={advertiserName}
          highlightColor={highlightColor}
          imageUrl={image ? image.original_url : ''}
        />
      )
    case ADVERTISEMENT_NAME_MAP.GAM_HOME_TOP_BANNER:
      return (
        <AdPreviewImageLinkType highlightColor={highlightColor} imageUrl={image ? image.original_url : ''} />
      )
    case ADVERTISEMENT_NAME_MAP.GAM_HOME_MIDDLE_BANNER:
      return (
        <AdPreviewPostImageType
          advertiserProfileImage={profileImage}
          title={title}
          description={description}
          imageUrl={image ? image.original_url : ''}
          advertiserName={advertiserName}
          actionLabel={actionLabel}
        />
      )

    case ADVERTISEMENT_NAME_MAP.GAM_POST_BANNER:
    case ADVERTISEMENT_NAME_MAP.GAM_POST_DETAIL:
    case ADVERTISEMENT_NAME_MAP.GAM_CLASSROOM_POST_LIST:
    case ADVERTISEMENT_NAME_MAP.GAM_CLASSROOM_POST_DETAIL:
    case ADVERTISEMENT_NAME_MAP.POST_BANNER:
      return (
        <AdPreviewPostBannerType
          imageUrl={image ? image.original_url : ''}
          backgroundColor={highlightColor}
        />
      )
    default: return <div>광고 소재 미리보기를 지원하지 않는 광고 타입입니다.</div>
  }
}

const AdPrevViewMedia = ({ advertisement }) => {
  if (!advertisement) return null
  const classes = useStyles()
  const viewTemplate = advertisement.advertisement_set.view_template

  return (
    <div className={classes.root}>
      <div className={classes.previewContainer}>
        {renderAdPreView(advertisement)}
      </div>
      {
        advertisement.action_url && viewTemplate !== 'splash_banner'
        && (
        <p className={classes.actionUrl}>
          클릭 시 랜딩 URL:
          {' '}
          {advertisement.action_url}
        </p>
        )
      }
    </div>
  )
}

AdPrevViewMedia.propTypes = {
  advertisement: PropTypes.shape({
    id: PropTypes.number.isRequired,
    description: PropTypes.string,
    action_label: PropTypes.string,
    action_url: PropTypes.string,
    title: PropTypes.string,
    video: PropTypes.object,
    image: PropTypes.shape({
      original_url: PropTypes.string,
    }),
    highlight_color: PropTypes.string,
    advertiser: PropTypes.shape({
      name: PropTypes.string.isRequired,
      profile_image: PropTypes.string,
    }).isRequired,
    advertisement_set: PropTypes.shape({
      view_template: PropTypes.string.isRequired,
    }),
  }).isRequired,
}

export default AdPrevViewMedia
