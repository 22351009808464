import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import { Skeleton } from '../atoms'

const useStyles = makeStyles(() => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    width: '328px',
    height: '246px',
    background: '#fafafa',
    boxSizing: 'border-box',
    borderRadius: '8px',
  },
  adLabel: {
    position: 'absolute',
    top: '6px',
    right: '6px',
    background: 'rgba(0, 0, 0, 0.2)',
    padding: '4px 6px',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  adText: {
    color: 'rgba(255, 255, 255, 0.8)',
    fontSize: '10px',
    lineHeight: '10px',
  },
  adImage: {
    width: '100%',
    height: '100%',
    margin: '0',
    position: 'relative',

    '& img': {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      color: 'transparent',

    },
  },
}))

const StartPopupAd = ({ imageUrl }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Skeleton isShow={!!imageUrl} borderRadius="32px" width="96px" height="96px">
        <figure className={classes.adImage}>
          <div className={classes.adLabel}>
            <span className={classes.adText}>AD</span>
          </div>
          {imageUrl ? <img src={imageUrl} alt="광고 이미지" /> : null}
        </figure>
      </Skeleton>
    </div>
  )
}

StartPopupAd.propTypes = {
  imageUrl: PropTypes.string.isRequired,
}

export default StartPopupAd
