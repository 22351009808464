import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withTracker } from '@/shared/enhancers'
import { Layout, EnhancedPaper, CircularUnderLoad } from '@/shared/components'
import { SignUpForm } from '../../signUp/components'
import { fetchUser } from '../actions'

const styles = ({
  accountContainer: {
    maxWidth: '946px',
    padding: '24px',
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
})

const mapStateToProps = state => ({
  pending: state.user.pending,
  status: state.user.status,
  userData: state.user.userData,
})

const mapDispatchToProps = dispatch => ({
  onUserMount: values => dispatch(fetchUser(values)),
})

class AccountPage extends Component {
  state = {
    isForUpdate: true,
  }

  componentDidMount() {
    const { onUserMount } = this.props
    onUserMount()
  }

  render() {
    const {
      classes, history, status, userData, pending,
    } = this.props
    const { isForUpdate } = this.state

    return (
      <Layout pageTitle="내 정보관리">
        <EnhancedPaper>
          {pending ? <CircularUnderLoad style={classes.progress} />
            : (
              <SignUpForm
                container={classes.accountContainer}
                isForUpdate={isForUpdate}
                userData={userData}
                status={status}
                onSignUp={() => {}}
                history={history}
              />
            )
          }
        </EnhancedPaper>
      </Layout>
    )
  }
}

AccountPage.propTypes = {
  classes: PropTypes.shape({
    accountContainer: PropTypes.string,
    progress: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({}).isRequired,
  status: PropTypes.number.isRequired,
  onUserMount: PropTypes.func.isRequired,
  userData: PropTypes.shape({}).isRequired,
  pending: PropTypes.bool.isRequired,
}

export default compose(
  withTracker,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(AccountPage)
