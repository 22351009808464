import { handleActions } from 'redux-actions'
import {
  SIGN_IN_POST_PENDING,
  SIGN_IN_POST_SUCCESS,
  SIGN_IN_POST_FAILURE,
  ADMIN_SIGN_IN_POST_PENDING,
  ADMIN_SIGN_IN_POST_SUCCESS,
  ADMIN_SIGN_IN_POST_FAILURE,
} from './actions'

export const initialState = {
  pending: false,
  success: false,
  failure: false,
  status: 200,
}

export default handleActions({
  [SIGN_IN_POST_PENDING]: state => ({
    ...state,
    pending: true,
  }),

  [SIGN_IN_POST_SUCCESS]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      pending: false,
      token: data.token,
      success: true,
      failure: false,
      status,
    }
  },

  [SIGN_IN_POST_FAILURE]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      pending: false,
      data,
      success: false,
      failure: true,
      status,
    }
  },

  [ADMIN_SIGN_IN_POST_PENDING]: state => ({
    ...state,
    pending: true,
  }),

  [ADMIN_SIGN_IN_POST_SUCCESS]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      pending: false,
      token: data.token,
      success: true,
      failure: false,
      status,
    }
  },

  [ADMIN_SIGN_IN_POST_FAILURE]: (state, action) => {
    const { data, status } = action.payload
    return {
      ...state,
      pending: false,
      data,
      success: false,
      failure: true,
      status,
    }
  },


}, initialState)
