import React, { Component } from 'react'
import PropTypes from 'prop-types'
import GoogleAnalytics from 'react-ga'

const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = (page) => {
    GoogleAnalytics.set({
      page,
      ...options,
    })
    GoogleAnalytics.pageview(page)
  }

  const EnhancedComponent = class extends Component {
    static propTypes = {
      location: PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.string,
      }).isRequired,
    }

    componentDidMount() {
      const { location } = this.props
      const page = location.pathname + location.search
      trackPage(page)
    }

    componentDidUpdate(prevProps) {
      const { location: prevLocation } = prevProps
      const { location: currentLocation } = this.props
      const prevPage = prevLocation.pathname + prevLocation.search
      const currentPage = currentLocation.pathname + currentLocation.search

      if (prevPage !== currentPage) {
        trackPage(currentPage)
      }
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  return EnhancedComponent
}

export default withTracker
