import React, { useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { InlineDatePicker } from 'material-ui-pickers'
import EventIcon from '@material-ui/icons/Event'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core'

const datePickerTheme = createMuiTheme({
  overrides: {
    MuiFormControl: {
      root: {
        marginLeft: '16px',
        height: '40px',
        width: '150px',
      },
    },
    MuiPickersDay: {
      '&$selected': {
        backgroundColor: '#00c896',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#00c896',
        },
      },
    },
    MuiInputBase: {
      input: {
        cursor: 'pointer',
      },
    },
    MuiInput: {
      underline: {
        '&:after': {
          borderBottom: '2px solid #00c896',
        },
      },
    },
    MuiButton: {
      root: {
        flex: '0 0 auto',
        color: 'rgba(0, 0, 0, 0.54)',
        padding: '12px',
        overflow: 'visible',
        fontSize: '1.5rem',
        textAlign: 'center',
        transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        borderRadius: '50%',
        minWidth: 48,
        position: 'absolute',
        display: 'inline-flex',
        margin: '-8px -35px',
        '&:focus': {
          outline: 'none',
        },
      },
    },
  },
})

const DatePicker = ({
  value,
  onChange,
  format,
}) => {
  const pickerRef = useRef(null)

  const openPicker = useCallback(
    (e) => {
      if (pickerRef.current) {
        pickerRef.current.open(e)
      }
    },
    [pickerRef.current],
  )

  return (
    <MuiThemeProvider theme={datePickerTheme}>
      <InlineDatePicker
        ref={pickerRef}
        onlyCalendar
        value={value}
        onChange={onChange}
        format={format}
      />
      <span>
        <Button onClick={openPicker}>
          <EventIcon />
        </Button>
      </span>
    </MuiThemeProvider>
  )
}

DatePicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  format: PropTypes.string,
}

DatePicker.defaultProps = {
  value: null,
  format: 'YYYY-MM-DD',
}

export default DatePicker
