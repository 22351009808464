import { ADVERTISEMENT_NAME_MAP } from './advertisements'

/**
 * @see src/constants/AdNameMap.js
 * @see src/constants/templateTitle.js
 * @see src/constants/gamAdUnitName.js
 * @see src/features/compaign/xlsxExport/sections.js
 * @see src/utils/getViewTemplate.js
 * @see src/features/today/components/TodayTable/TodayTable.styles.js
 * @see src/features/today/components/TableExpander/TableExpander.styles.js
 */
export const gamAdUnitName = {
  dev: {
    22156360459: ADVERTISEMENT_NAME_MAP.GAM_CLASS_TAB,
    22193401770: ADVERTISEMENT_NAME_MAP.GAM_MY_TAB,
    22245602944: ADVERTISEMENT_NAME_MAP.GAM_CLOSE_POPUP,
    22277389769: ADVERTISEMENT_NAME_MAP.GAM_AI_REWARD,
    22260208321: ADVERTISEMENT_NAME_MAP.GAM_POST_DETAIL,
    22272995491: ADVERTISEMENT_NAME_MAP.GAM_CLASSROOM_POST_LIST,
    22272995965: ADVERTISEMENT_NAME_MAP.GAM_CLASSROOM_POST_DETAIL,
    22273336551: ADVERTISEMENT_NAME_MAP.GAM_CLASSROOM_NAVIGATION,
    22500806203: ADVERTISEMENT_NAME_MAP.GAM_HOME_TOP_BANNER,
    22501054622: ADVERTISEMENT_NAME_MAP.GAM_HOME_LEFT_TOP_BANNER1,
    22500809641: ADVERTISEMENT_NAME_MAP.GAM_HOME_LEFT_TOP_BANNER2,
    22501053311: ADVERTISEMENT_NAME_MAP.GAM_HOME_MIDDLE_BANNER,
    22511993579: ADVERTISEMENT_NAME_MAP.GAM_HOME_BOTTOM_BANNER,
    22645533707: ADVERTISEMENT_NAME_MAP.GAM_NOTIFICATION_FIRST_SLOT,
    22644997134: ADVERTISEMENT_NAME_MAP.GAM_NOTIFICATION_SECOND_SLOT,
  },
  stag: {
    22156360459: ADVERTISEMENT_NAME_MAP.GAM_CLASS_TAB,
    22193401770: ADVERTISEMENT_NAME_MAP.GAM_MY_TAB,
    22245602944: ADVERTISEMENT_NAME_MAP.GAM_CLOSE_POPUP,
    22277389769: ADVERTISEMENT_NAME_MAP.GAM_AI_REWARD,
    22260208321: ADVERTISEMENT_NAME_MAP.GAM_POST_DETAIL,
    22272995491: ADVERTISEMENT_NAME_MAP.GAM_CLASSROOM_POST_LIST,
    22272995965: ADVERTISEMENT_NAME_MAP.GAM_CLASSROOM_POST_DETAIL,
    22273336551: ADVERTISEMENT_NAME_MAP.GAM_CLASSROOM_NAVIGATION,
    22500806203: ADVERTISEMENT_NAME_MAP.GAM_HOME_TOP_BANNER,
    22501054622: ADVERTISEMENT_NAME_MAP.GAM_HOME_LEFT_TOP_BANNER1,
    22500809641: ADVERTISEMENT_NAME_MAP.GAM_HOME_LEFT_TOP_BANNER2,
    22501053311: ADVERTISEMENT_NAME_MAP.GAM_HOME_MIDDLE_BANNER,
    22511993579: ADVERTISEMENT_NAME_MAP.GAM_HOME_BOTTOM_BANNER,
    22645533707: ADVERTISEMENT_NAME_MAP.GAM_NOTIFICATION_FIRST_SLOT,
    22644997134: ADVERTISEMENT_NAME_MAP.GAM_NOTIFICATION_SECOND_SLOT,
  },
  prod: {
    22156355110: ADVERTISEMENT_NAME_MAP.GAM_CLASS_TAB,
    22193400600: ADVERTISEMENT_NAME_MAP.GAM_MY_TAB,
    22254368127: ADVERTISEMENT_NAME_MAP.GAM_CLOSE_POPUP,
    22287366873: ADVERTISEMENT_NAME_MAP.GAM_AI_REWARD,
    22260208648: ADVERTISEMENT_NAME_MAP.GAM_POST_DETAIL,
    22273338171: ADVERTISEMENT_NAME_MAP.GAM_CLASSROOM_POST_LIST,
    22273052309: ADVERTISEMENT_NAME_MAP.GAM_CLASSROOM_POST_DETAIL,
    22272994489: ADVERTISEMENT_NAME_MAP.GAM_CLASSROOM_NAVIGATION,
    22606393396: ADVERTISEMENT_NAME_MAP.GAM_HOME_TOP_BANNER,
    22606786508: ADVERTISEMENT_NAME_MAP.GAM_HOME_LEFT_TOP_BANNER1,
    22606317357: ADVERTISEMENT_NAME_MAP.GAM_HOME_LEFT_TOP_BANNER2,
    22606317879: ADVERTISEMENT_NAME_MAP.GAM_HOME_MIDDLE_BANNER,
    22606787405: ADVERTISEMENT_NAME_MAP.GAM_HOME_BOTTOM_BANNER,
    22645123939: ADVERTISEMENT_NAME_MAP.GAM_NOTIFICATION_FIRST_SLOT,
    22645123942: ADVERTISEMENT_NAME_MAP.GAM_NOTIFICATION_SECOND_SLOT,
  },
}
