import { connect } from 'react-redux'

import TodayPage from './TodayPage'
import { fetchAd, fetchCountFromNewPipeline, updateAdForcedStop } from '../actions'

const mapStateToProps = state => ({
  ad: state.today.ad,
  isExpanderLoading: state.today.loading.expander,
})

const mapDispatchToProps = dispatch => ({
  onAd: params => dispatch(fetchAd(params)),
  onAdCount: params => dispatch(fetchCountFromNewPipeline(params)),
  onUpdateAdForcedStop: data => dispatch(updateAdForcedStop(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TodayPage)
