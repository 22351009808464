/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import PropTypes from 'prop-types'

const makeStyles = ({
  color, fullWidth, isRounded, boldText,
}) => ({
  root: ({ colors: { green, blue, grey }, font, mq }) => ({ spacing }) => css`
    ${mq({
    alignItems: 'center',
    backgroundColor: (() => {
      if (color === 'transparent') {
        return 'transparent'
      }

      if (color === 'green') {
        return green[100]
      }

      return blue[200]
    })(),
    borderRadius: isRounded ? '4px' : '0px',
    color: (() => {
      if (color === 'transparent') {
        return grey[700]
      }
      return '#fff'
    })(),
    display: 'flex',
    fontSize: [font.body3.fontSize, font.body2.fontSize],
    fontWeight: boldText ? 'bold' : '500',
    justifyContent: 'center',
    lineHeight: [font.body3.lineHeight, font.body3.lineHeight],
    padding: [
      `${spacing(1.5)}rem ${spacing(2)}rem`,
      `${spacing(2)}rem ${spacing(3)}rem`,
    ],
    width: fullWidth ? '100%' : 'auto',
  })}

    :active {
      background-color: ${(() => {
    if (color === 'transparent') {
      return 'transparent'
    }

    if (color === 'green') {
      return '#00A87E'
    }

    return '#374CB2'
  })()};
    }
  `,
})

const Button = ({
  color,
  children,
  customStyles,
  disabled,
  fullWidth,
  isRounded,
  type,
  onClick,
  boldText,
}) => {
  const styles = makeStyles({
    boldText, color, fullWidth, isRounded,
  })

  return (
    <button
      css={[styles.root, customStyles]}
      disabled={disabled}
      // eslint-disable-next-line react/button-has-type
      type={type}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

Button.defaultProps = {
  boldText: true,
  color: 'green',
  disabled: false,
  fullWidth: false,
  isRounded: true,
  type: 'button',
  children: [],
  onClick: () => {},
  customStyles: '',
}

Button.propTypes = {
  boldText: PropTypes.bool,
  children: PropTypes.node,
  color: PropTypes.oneOf(['green', 'blue', 'transparent']),
  customStyles: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.array,
  ]),
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  isRounded: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['button', 'submit']),
}

export default Button
