import { withTracker } from '@/shared/enhancers'
import LandingPage from './pages'

export default {
  key: 'landing',
  requiresAuth: false,
  exact: true,
  path: '/',
  component: withTracker(LandingPage),
}
