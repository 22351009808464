import React from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { NavigationBar } from '@/shared/components'
import { withTracker } from '@/shared/enhancers'

const styles = ({
  container: {
    maxWidth: '846px',
    margin: '0 auto',
    padding: '64px 0 0 0',
  },
  title: {
    color: '#00c896',
  },
  subTitle: {
    color: '#757575',
    marginTop: '14px',
    marginBottom: '14px',
  },
})

function RequestConfirmationPage({ classes }) {
  return (
    <div>
      <NavigationBar />
      <div className={classes.container}>
        <Typography variant="h4" gutterBottom className={classes.title}>
          이메일로 인증메일이 전송되었습니다.
        </Typography>
        <Typography variant="h6" gutterBottom className={classes.subTitle}>
          회원가입을 완료하시기 위해선 이메일을 확인해주세요.
        </Typography>
      </div>
    </div>
  )
}

RequestConfirmationPage.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
  }).isRequired,
}

export default compose(
  withTracker,
  withStyles(styles),
)(RequestConfirmationPage)
