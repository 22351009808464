import {
  PaymentFormPage,
  PaymentResponsePage,
  PaymentResultPage,
} from './page'

export default [
  {
    key: 'payment_form',
    requiresAuth: true,
    exact: true,
    path: '/payment_form',
    component: PaymentFormPage,
  },
  {
    key: 'payment_response',
    requiresAuth: true,
    exact: true,
    path: '/payment_response',
    component: PaymentResponsePage,
  },
  {
    key: 'payment_result',
    requiresAuth: true,
    exact: true,
    path: '/payment_result',
    component: PaymentResultPage,
  },
]
