/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import PropTypes from 'prop-types'

const makeStyles = () => ({
  input: ({ colors: { grey, green }, font, spacing }) => css`
    color: ${grey[900]};
    border: 1px solid ${grey[400]};
    border-radius: 4px;
    font-size: ${font.body2.fontSize};
    line-height: ${font.body2.lineHeight};
    padding: ${spacing(2)}rem;

    :focus {
      border-color: ${green[100]};
    }

    ::placeholder {
      color: ${grey[400]};
    }
  `,
  label: ({ colors: { grey }, font, spacing }) => css`
    color: ${grey[900]};
    font-size: ${font.body2.fontSize};
    line-height: ${font.body2.lineHeight};
    margin-bottom: ${spacing()}rem;
  `,
  root: () => css`
    display: flex;
    flex-direction: column;
  `,
})

const TextInput = ({
  customStyles,
  isRequired,
  label,
  name,
  onChange,
  placeholder,
  value,
}) => {
  const styles = makeStyles()

  return (
    <div css={[styles.root, customStyles]}>
      <label htmlFor={name} css={styles.label}>
        {label}
        {isRequired ? ' *' : ''}
      </label>
      <input
        type="text"
        name={name}
        css={styles.input}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
  )
}

TextInput.defaultProps = {
  isRequired: false,
  customStyles: '',
  label: '',
}

TextInput.propTypes = {
  customStyles: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}

export default TextInput
