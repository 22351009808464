import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import LineBreak from './LineBreak'

const useStyles = makeStyles(() => ({
  xs: {
    fontSize: '12px',
    lineHeight: '18px',
  },
  sm: {
    fontSize: '14px',
    lineHeight: '21px',
  },
  md: {
    fontSize: '16px',
    lineHeight: '24px',
  },
  lg: {
    fontSize: '20px',
    lineHeight: '30px',
  },
  lightGray: {
    color: '#bdbdbd',
  },
  lightGray2: {
    color: '#9e9e9e',
  },
  gray: {
    color: '#757575',
  },
  darkGray: {
    color: '#212121',
  },
  white: {
    color: '#fff',
  },
  bold: {
    fontWeight: 'bold',
  },
}))

const Text = ({
  bold,
  color,
  component,
  className,
  children,
  size,
}) => {
  const classes = useStyles()

  return (
    <Typography
      className={cn(classes[size], classes[color], className, { [classes.bold]: bold })}
      component={component}
    >
      <LineBreak>
        {children}
      </LineBreak>
    </Typography>
  )
}

Text.defaultProps = {
  bold: false,
  className: '',
  component: 'span',
  size: 'sm',
  color: 'gray',
}

Text.propTypes = {
  bold: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.oneOf(['lightGray', 'lightGray2', 'gray', 'darkGray', 'white']),
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
}

export default Text
