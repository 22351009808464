import axios from 'axios'

export const http = axios.create({
  headers: {
    Accept: 'application/json',
  },
})

export const setTokenToRequestHeader = ({ tokenType = '', accessToken = '' }) => {
  http.defaults.headers.common.Authorization = accessToken ? `${tokenType} ${accessToken}` : ''
}

export const setGuestTokenToRequestHeader = ({ accessToken = '' }) => {
  http.defaults.headers.common = {
    Authorization: 'guest',
    'Ctad-Report-Access-Token': accessToken,
  }
}

export const removeTokenFromRequestHeader = () => {
  delete http.defaults.headers.common.Authorization
}
