/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { useContext } from 'react'

import { uiStore } from '@landing/contexts/ui'
import BarMenuItem from './BarMenuItem'
import { AppearanceAnimation } from '../../unit/animation'

const makeStyles = () => ({
  root: theme => css`
    display: flex;

    .menu_item {
      padding-right: ${theme.spacing(3)}rem;
    }

    .menu_item:last-of-type {
      padding-right: 0;
    }
  `,
})

const BarMenu = () => {
  const styles = makeStyles()
  const {
    state: { navigations },
  } = useContext(uiStore)

  return (
    <AppearanceAnimation>
      <ul css={styles.root}>
        {navigations.map(({ id, isSelected, name }) => (
          <BarMenuItem key={id} id={id} isSelected={isSelected} name={name} />
        ))}
      </ul>
    </AppearanceAnimation>
  )
}

export default BarMenu
