/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import { icGrayRightArrow } from '@/shared/assets'
import { Avatar, Skeleton, Text } from '../atoms'
import styles from './NotificationImagelinkAd.module.css'

const NotificationImagelinkAd = ({
  advertiserName,
  description,
  advertiserProfileImage,
  imageUrl,
}) => (
  <div className={styles.panel}>
    <div className={styles['target-link']}>
      <aside className={styles.panel__aside}>
        <Avatar src={advertiserProfileImage} alt="광고주 이미지" />
      </aside>
      <div className={styles.panel__content}>
        <header className={styles.panel__header}>
          <div className={styles.panel__header__title}>
            <div className={styles.panel__header__title__container}>
              <Skeleton isShow={!!advertiserName} width="142px" height="20px">
                <div className={styles.panel__header__title__text}>{advertiserName}</div>
              </Skeleton>
              <span className={styles.panel__header__title__badge}>AD</span>
            </div>
            <svg
              className={styles.panel__header__title__icon}
              width="6"
              height="10"
              viewBox="0 0 6 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="#9e9e9e"
                d="M3.78145 4.99999L0.481445 1.69999L1.42411 0.757324L5.66678 4.99999L1.42411 9.24266L0.481445 8.29999L3.78145 4.99999Z"
              />
            </svg>
          </div>
          <Skeleton isShow={!!description} width="211px" height="16px" marginTop="9px">
            <div className={styles.panel__header__description}>{description}</div>
          </Skeleton>
        </header>
        <Skeleton isShow={!!imageUrl} width="276px" height="92px" marginTop="7px">
          <div className={styles.panel__body}>
            <img className={styles.panel__body__image} style={{ backgroundColor: '#F4F6F5' }} src={imageUrl} alt="배너 이미지" />
          </div>
        </Skeleton>
      </div>
    </div>
  </div>
)

NotificationImagelinkAd.defaultProps = {
  advertiserProfileImage: '',
  advertiserName: '',
  description: '',
  imageUrl: '',
}

NotificationImagelinkAd.propTypes = {
  advertiserName: PropTypes.string,
  description: PropTypes.string,
  advertiserProfileImage: PropTypes.string,
  imageUrl: PropTypes.string,
}

export default NotificationImagelinkAd
