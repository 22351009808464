import { withTracker } from '@/shared/enhancers'
import TodayPage from './page'

export default [
  {
    key: 'today',
    requiresAuth: true,
    exact: true,
    path: '/today',
    component: withTracker(TodayPage),
  },
]
