/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { Fragment } from 'react'
import PropTypes from 'prop-types'

const makeStyles = () => ({
  btnWrap: ({ spacing }) => css`
    margin-top: ${spacing(5)}rem;
    width: 100%;
  `,
  description: ({ colors: { grey }, font }) => css`
    color: ${grey[600]};
    font-size: ${font.body2.fontSize};
    line-height: ${font.body2.lineHeight};
  `,
  root: ({ spacing }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    text-align: center;
    padding: 0 ${spacing(1.875)}rem;
  `,
  title: ({ colors: { grey }, font, spacing }) => css`
    color: ${grey[800]};
    font-size: ${font.title3.fontSize};
    font-weight: bold;
    line-height: ${font.title3.lineHeight};
    margin-bottom: ${spacing()}rem;
  `,
})

const Description = ({
  title, description, Additional, onClose,
}) => {
  const styles = makeStyles()

  return (
    <div css={styles.root}>
      <div>
        <p css={styles.title}>{title}</p>
        <p css={styles.description}>{description}</p>
      </div>
      {Additional && (
        <div css={styles.btnWrap}>
          <Additional fullWidth closeModal={onClose} />
        </div>
      )}
    </div>
  )
}

Description.defaultProps = {
  Additional: <Fragment />,
}

Description.propTypes = {
  Additional: PropTypes.elementType,
  description: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
}

export default Description
