import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withTracker } from '@/shared/enhancers'
import { ReportContainer } from '../containers'

const styles = ({})

class ReportPage extends Component {
  state = {
    isPrivate: true,
  }

  render() {
    const { match, location, history } = this.props
    const { isPrivate } = this.state

    return (
      <ReportContainer
        match={match}
        history={history}
        location={location}
        isPrivate={isPrivate}
      />
    )
  }
}

ReportPage.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({}),
  }).isRequired,
  history: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
}

export default compose(
  withTracker,
  withStyles(styles),
)(ReportPage)
