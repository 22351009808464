/** @jsx jsx */
import { css, jsx } from '@emotion/react'

import businessImage from '@landing/assets/images/business.png'
import { AppearanceAnimation } from '../unit/animation'

const makeStyles = () => ({
  root: ({ mq }) => css`
    ${mq({
    background: `no-repeat center/cover url(${businessImage})`,
    height: ['16rem', '40rem'],
    width: '100%',
  })}
  `,
  subTitle: ({ font }) => css`
    font-size: ${font.body1.fontSize};
    line-height: ${font.body1.lineHeight};
  `,
  title: ({ font, mq }) => css`
    ${mq({
    fontSize: [font.body1.fontSize, font.title1.fontSize],
    fontWeight: 'bold',
    lineHeight: font.title1.lineHeight,
  })}
  `,
  titleWrap: () => css``,
  wrap: css`
    align-items: center;
    display: flex;
    color: #fff;
    height: 100%;
    justify-content: center;
    text-align: center;
    background-color: rgba(35, 46, 86, 0.8);
    width: 100%;
  `,
})

const SectionHeader = () => {
  const styles = makeStyles()

  return (
    <AppearanceAnimation>
      <div css={styles.root}>
        <div css={styles.wrap}>
          <div css={styles.titleWrap}>
            <AppearanceAnimation>
              <p css={styles.subTitle}>성공적인 비즈니스의 시작,</p>
              <p css={styles.title}>지금 시작해보세요</p>
            </AppearanceAnimation>
          </div>
        </div>
      </div>
    </AppearanceAnimation>
  )
}

export default SectionHeader
