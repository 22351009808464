export const campaignReportRows = [
  {
    id: 'advertiser', numeric: false, disablePadding: false, isOrder: false, label: '광고주',
  },
  {
    id: 'campaignTitle', numeric: false, disablePadding: false, isOrder: false, label: '캠페인 명',
  },
  {
    id: 'budget', numeric: false, disablePadding: false, isOrder: false, label: '총 예산',
  },
]

export const campaignReportMemoRows = [
  {
    id: 'product', numeric: false, disablePadding: false, isOrder: false, label: '상품',
  },
  {
    id: 'billingMethod', numeric: false, disablePadding: false, isOrder: false, label: '과금 방식',
  },
  {
    id: 'period', numeric: false, disablePadding: false, isOrder: false, label: '기간',
  },
  {
    id: 'role', numeric: false, disablePadding: false, isOrder: false, label: '대상',
  },
  {
    id: 'grade', numeric: false, disablePadding: false, isOrder: false, label: '학년',
  },
  {
    id: 'region',
    numeric: false,
    disablePadding: false,
    isOrder: false,
    label: '지역',
  },
  {
    id: 'impressions', numeric: false, disablePadding: false, isOrder: false, label: '노출 수',
  },
  {
    id: 'budget',
    numeric: false,
    disablePadding: false,
    isOrder: false,
    label: '예산(VAT별도)',
  },
  {
    id: 'detail', numeric: false, disablePadding: false, isOrder: false, label: '상세 내용',
  },
]

export const adTotalReportRows = [
  {
    id: 'view', numeric: false, disablePadding: false, isOrder: false, label: '총 노출 수',
  },
  {
    id: 'click', numeric: false, disablePadding: false, isOrder: false, label: '총 클릭 수',
  },
  {
    id: 'ctr', numeric: false, disablePadding: false, isOrder: false, label: '총 평균 CTR',
  },
  {
    id: 'watch', numeric: false, disablePadding: false, isOrder: false, label: '총 재생 수',
  },
  {
    id: 'vtr', numeric: false, disablePadding: false, isOrder: false, label: '총 평균 VTR',
  },
]

export const adReportRows = [
  {
    id: 'date', numeric: false, disablePadding: false, isOrder: false, label: '기간',
  },
  {
    id: 'view', numeric: false, disablePadding: false, isOrder: false, label: '노출 수',
  },
  {
    id: 'click', numeric: false, disablePadding: false, isOrder: false, label: '클릭 수',
  },
  {
    id: 'ctr', numeric: false, disablePadding: false, isOrder: false, label: 'CTR',
  },
  {
    id: 'watch', numeric: false, disablePadding: false, isOrder: false, label: '재생 수',
  },
  {
    id: 'vtr', numeric: false, disablePadding: false, isOrder: false, label: 'VTR',
  },
]
