import forEach from 'lodash/forEach'
import filter from 'lodash/filter'

import * as switchTypes from './types'

export const handleAdsForceStop = ({
  ads, onUpdateAdForcedStop, isForceStopShouldBeTrue,
}) => forEach(ads, (ad) => {
  onUpdateAdForcedStop({
    id: ad.id, forced_stop: isForceStopShouldBeTrue ? true : !ad.forced_stop,
  })
})

export const filterByForcedStoppedAds = ads => (
  filter(ads, ({ forced_stop: forcedStop }) => forcedStop === true)
)

/*
  case: 1
  item1: {forced_stop: true},
  item2: {forced_stop: false},
  item3: {forced_stop: false} => checked & STATUS_SWITCH_PARTIAL_ON

  case: 2
  item1: {forced_stop: true},
  item2: {forced_stop: true},
  item3: {forced_stop: true} => uncheked & STATUS_SWITCH_OFF

  case: 3
  item1: {forced_stop: false},
  item2: {forced_stop: false},
  item3: {forced_stop: false} => checked & STATUS_SWITCH_ON
*/
export const getToggleInfo = (ads) => {
  const forcedStoppedAds = filterByForcedStoppedAds(ads)
  const forcedStoppedAdLen = forcedStoppedAds.length
  const adsLen = ads.length

  if (forcedStoppedAdLen === 0) {
    return {
      type: switchTypes.STATUS_SWITCH_ON,
      checked: true,
    }
  }

  if (forcedStoppedAdLen === adsLen) {
    return {
      type: switchTypes.STATUS_SWITCH_OFF,
      checked: false,
    }
  }

  return {
    type: switchTypes.STATUS_SWITCH_PARTIAL_ON,
    checked: true,
  }
}
