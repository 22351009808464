import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  Col, Form, FormGroup, Label, Input,
} from 'reactstrap'
import { Formik } from 'formik'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core'
import * as Yup from 'yup'
import Button from '@material-ui/core/Button'
import { Layout, EnhancedPaper, CircularUnderLoad } from '@/shared/components'
import { updateUserPassword, resetUser } from '../actions'

const styles = ({
  container: {
    maxWidth: '846px',
    padding: '44px',
    position: 'relative',
  },
  validFeedback: {
    color: '#5aaafa',
    fontSize: '12px',
  },
  invalidFeedback: {
    color: '#e85d7b',
    fontSize: '12px',
  },
  button: {
    width: '24%',
    background: '#5aaafa',
    color: '#ffffff',
  },
  failure: {
    color: '#e85d7b',
    fontSize: '14px',
    margin: '14px 0 14px 0',
    textAlign: 'left',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
})

const mapStateToProps = state => ({
  status: state.user.status,
  pending: state.user.pending,
  success: state.user.success,
  failure: state.user.failure,
})

const mapDispatchToProps = dispatch => ({
  onUpdateUserPassword: values => dispatch(updateUserPassword(values)),
  onResetUser: () => dispatch(resetUser()),
})

class UpdateUserPasswordPage extends Component {
  componentDidMount() {
    const { onResetUser } = this.props
    onResetUser()
  }

  render() {
    const {
      classes, onUpdateUserPassword, failure, pending, history,
    } = this.props

    return (
      <Layout pageTitle="비밀번호 변경">
        <EnhancedPaper>
          {pending ? <CircularUnderLoad style={classes.progress} />
            : (
              <div className={classes.container}>
                <Typography variant="h5" gutterBottom>
                  비밀번호 변경
                </Typography>
                <br />
                <br />
                {failure && <div className={classes.failure}>비밀번호 정보가 올바르지 않습니다.</div>}
                <Divider />
                <br />
                <br />
                <Formik
                  initalValues={{
                    current_password: '',
                    password: '',
                    password_confirmation: '',
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    onUpdateUserPassword({ ...values })
                      .then(() => {
                        setSubmitting(false)
                        return history.push({
                          pathname: 'campaign',
                        })
                      })
                  }}
                  validationSchema={Yup.object().shape({
                    current_password: Yup.string()
                      .min('6', '비밀번호는 6자리 이상 입력해주세요.')
                      .max('12', '비밀번호는 12자리 까지 입력해주세요.')
                      .required('현재 비밀번호 입력은 필수 값입니다.'),
                    password: Yup.string()
                      .min('6', '비밀번호는 6자리 이상 입력해주세요.')
                      .max('12', '비밀번호는 12자리 까지 입력해주세요.')
                      .required('비밀번호 입력은 필수 값입니다.'),
                    password_confirmation: Yup.string()
                      .oneOf([Yup.ref('password')], '비밀번호가 일치하지 않습니다.')
                      .required('비밀번호 확인 입력은 필수 값입니다.'),
                  })}
                >
                  {(props) => {
                    const {
                      values,
                      touched,
                      errors,
                      isSubmitting,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                    } = props

                    return (
                      <Form onSubmit={handleSubmit}>
                        <FormGroup row>
                          <Label for="" sm={3}>현재 비밀번호</Label>
                          <Col sm={7}>
                            <Input
                              type="password"
                              name="current_password"
                              className={
                              errors.current_password && touched.current_password ? `${classes.fieldError}` : `${classes.field}`
                            }
                              value={values.current_password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="현재 비밀번호 입력"
                            />
                            {(errors.current_password && touched.current_password)
                              && (
                                <div className={classes.invalidFeedback}>
                                  {errors.current_password}
                                </div>
                              )
                            }
                          </Col>
                          <Col sm={2} />
                        </FormGroup>
                        <FormGroup row>
                          <Label for="" sm={3}>새 비밀번호</Label>
                          <Col sm={7}>
                            <Input
                              type="password"
                              name="password"
                              className={
                                errors.password && touched.password ? `${classes.fieldError}` : `${classes.field}`
                              }
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="새 비밀번호 입력"
                            />
                            {(errors.password && touched.password)
                              && (
                                <div className={classes.invalidFeedback}>{errors.password}</div>
                              )
                            }
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="" sm={3}>새 비밀번호 확인</Label>
                          <Col sm={7}>
                            <Input
                              type="password"
                              name="password_confirmation"
                              className={
                                errors.password_confirmation && touched.password_confirmation ? `${classes.fieldError}` : `${classes.field}`
                              }
                              value={values.password_confirmation}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="새 비밀번호 확인"
                            />
                            {(errors.password_confirmation && touched.password_confirmation)
                              && (
                                <div className={classes.invalidFeedback}>
                                  {errors.password_confirmation}
                                </div>
                              )
                            }
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="" sm={3} />
                          <Col sm={7}>
                            <Button
                              className={classes.button}
                              variant="contained"
                              color="secondary"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              수정완료
                            </Button>
                          </Col>
                        </FormGroup>
                      </Form>
                    )
                  }}
                </Formik>
              </div>
            )
          }
        </EnhancedPaper>
      </Layout>
    )
  }
}

UpdateUserPasswordPage.propTypes = {
  onUpdateUserPassword: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  pending: PropTypes.bool.isRequired,
  failure: PropTypes.bool.isRequired,
  onResetUser: PropTypes.func.isRequired,
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(UpdateUserPasswordPage)
