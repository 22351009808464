import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import { Skeleton, Text, Icon } from '../atoms'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '360px',
    padding: '12px 12px 16px 12px',
    backgroundColor: '#1f344f',
  },
  header: {
    marginTop: '12px',
  },
  advertiserName: {
    marginBottom: '4px',
  },
  content: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
  },
  description: {
    margin: '0',
    marginBottom: '12px',
    wordBreak: 'break-all',
    whiteSpace: 'break-spaces',
  },
  descriptionTxt: {
    color: 'rgba(255, 255, 255, 0.8)',
  },
  actionButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    borderRadius: '8px',
    height: '44px',
    marginTop: 'auto',
    color: '#424242',
    fontWeight: '600',
  },
}))

const NoticeAd = ({ advertiserName, actionLabel, description }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.advertiserName}>
          <Skeleton isShow={!!advertiserName} theme="dark">
            <Text bold color="white">{advertiserName}</Text>
          </Skeleton>
        </div>
        <Text size="xs" color="white">Sponsored</Text>
      </div>
      <div className={classes.content}>
        <div className={classes.description}>
          <Skeleton isShow={!!description} theme="dark">
            <Text component="p" className={classes.descriptionTxt}>{description}</Text>
          </Skeleton>
        </div>
        <div className={classes.actionButton}>
          <span>{actionLabel}</span>
          <Icon shape="grayRightArrow" />
        </div>
      </div>
    </div>
  )
}

NoticeAd.propTypes = {
  advertiserName: PropTypes.string.isRequired,
  actionLabel: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default NoticeAd
