import { createAction } from 'redux-actions'
import { forEach } from 'lodash'
import { http } from '@/shared/utils'

import { CLASSTING_AD_API_URL } from '@/shared/constants'

export const RESET_ADVERTISER = 'advertiser/RESET_ADVERTISER'
export const FETCH_ADVERTISERS_PENDING = 'advertiser/ADVERTISERS_GET_PENDING'
export const FETCH_ADVERTISERS_SUCCESS = 'advertiser/ADVERTISERS_GET_SUCCESS'
export const FETCH_ADVERTISERS_FAILURE = 'advertiser/ADVERTISERS_GET_FAILURE'
export const FETCH_ADVERTISER_PENDING = 'advertiser/ADVERTISER_GET_PENDING'
export const FETCH_ADVERTISER_SUCCESS = 'advertiser/ADVERTISER_GET_SUCCESS'
export const FETCH_ADVERTISER_FAILURE = 'advertiser/ADVERTISER_GET_FAILURE'
export const SET_ADVERTISER_PENDING = 'advertiser/ADVERTISER_POST_PENDING'
export const SET_ADVERTISER_SUCCESS = 'advertiser/ADVERTISER_POST_SUCCESS'
export const SET_ADVERTISER_FAILURE = 'advertiser/ADVERTISER_POST_FAILURE'
export const UPDATE_ADVERTISER_PENDING = 'advertiser/ADVERTISER_PUT_PENDING'
export const UPDATE_ADVERTISER_SUCCESS = 'advertiser/ADVERTISER_PUT_SUCCESS'
export const UPDATE_ADVERTISER_FAILURE = 'advertiser/ADVERTISER_PUT_FAILURE'

export const resetAdvertiser = createAction(RESET_ADVERTISER)
export const fetchAdvertisersPending = createAction(FETCH_ADVERTISERS_PENDING)
export const fetchAdvertisersSuccess = createAction(FETCH_ADVERTISERS_SUCCESS)
export const fetchAdvertisersFailure = createAction(FETCH_ADVERTISERS_FAILURE)
export const fetchAdvertiserPending = createAction(FETCH_ADVERTISER_PENDING)
export const fetchAdvertiserSuccess = createAction(FETCH_ADVERTISER_SUCCESS)
export const fetchAdvertiserFailure = createAction(FETCH_ADVERTISER_FAILURE)
export const setAdvertiserPending = createAction(SET_ADVERTISER_PENDING)
export const setAdvertiserSuccess = createAction(SET_ADVERTISER_SUCCESS)
export const setAdvertiserFailure = createAction(SET_ADVERTISER_FAILURE)
export const updateAdvertiserPending = createAction(UPDATE_ADVERTISER_PENDING)
export const updateAdvertiserSuccess = createAction(UPDATE_ADVERTISER_SUCCESS)
export const updateAdvertiserFailure = createAction(UPDATE_ADVERTISER_FAILURE)

function setAdvertiserRelationships(agencies, advertiser) {
  return http.post(`${CLASSTING_AD_API_URL}/agencies/${agencies.id}/agency_relationships`, { advertiser_id: advertiser.id })
}

export const fetchAdvertisers = params => (dispatch, getState) => {
  dispatch(fetchAdvertisersPending())
  return http.get(`${CLASSTING_AD_API_URL}/advertisers/`, { params })
    .then(res => (
      dispatch(fetchAdvertisersSuccess(res))
    ))
    .then(() => (
      getState().advertiser.advertisers
    ))
    .catch(err => (
      dispatch(fetchAdvertisersFailure(err.response))
    ))
}

export const fetchAdvertiser = advertiserId => (dispatch) => {
  dispatch(fetchAdvertiserPending())
  return http.get(`${CLASSTING_AD_API_URL}/advertisers/${advertiserId}`)
    .then(res => (
      dispatch(fetchAdvertiserSuccess(res))
    ))
    .catch(err => (
      dispatch(fetchAdvertiserFailure(err.response))
    ))
}

export const updateAdvertiser = data => (dispatch) => {
  const formData = new FormData()
  forEach(data, (value, key) => {
    formData.append(key, value || '')
  })
  dispatch(updateAdvertiserPending())
  return http.put(`${CLASSTING_AD_API_URL}/advertisers/${data.id}`,
    formData,
    { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(res => (
      dispatch(updateAdvertiserSuccess(res))
    ))
    .catch(err => (
      dispatch(updateAdvertiserFailure(err.response))
    ))
}

export const setAdvertiser = data => (dispatch, getState) => {
  const formData = new FormData()
  forEach(data, (value, key) => {
    formData.append(key, value || '')
  })
  dispatch(setAdvertiserPending())
  return http.post(`${CLASSTING_AD_API_URL}/advertisers`,
    formData,
    { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(res => (
      dispatch(setAdvertiserSuccess(res))
    ))
    .then(() => (
      setAdvertiserRelationships(getState().user.userData, getState().advertiser.advertiserData)
    ))
    .then(() => (
      getState().advertiser.advertiserData
    ))
    .catch(err => (
      dispatch(setAdvertiserFailure(err.response))
    ))
}

export function deleteAdvertiser(advertiserId) {
  return http.delete(`${CLASSTING_AD_API_URL}/advertisers/${advertiserId}`)
}
